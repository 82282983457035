import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Button } from "flowbite-react";
import {
  fetchStore,
  fetchUser,
  updateManufacturer,
} from "../../utils/globalFunctions";
import TableComponent from "../../components/TableComponent";
import { useNavigate } from "react-router-dom";
import {
  WhatsappShareButton,
} from "react-share";
import { migrateProducts } from "../../utils/firebase";

const Teams = ({ user, manufacturer }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLimited, setIsLimited] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const nav = useNavigate();
  const rowsPerPage = 6;

  useEffect(() => {
    if (manufacturer) {
      if (manufacturer?.subscribers?.length > manufacturer.sellerLimit) {
        setIsLimited(true);
      }
      fetchTeamData().finally(() => setIsLoading(false));
    }
  }, [manufacturer]);

  const fetchTeamData = async () => {
    setIsLoading(true);
    const data = await Promise.all(
      manufacturer?.subscribers?.map(async (s) => {
        const store = await fetchStore(s);
        // remove from subscribers if store is not found
        console.log(store);
        if (!store) {
          manufacturer.subscribers = manufacturer.subscribers.filter(
            (sub) => sub !== s
          );

          await updateManufacturer(manufacturer)
          return null;
        }
        const user = await fetchUser(s);
        console.log(store);
        return {
          storeName: store.storeName,
          id: s,
          email: user.email,
          status: store.status,
          createdAt: store.createdAt.toDate().toLocaleDateString(), // Convert timestamp to string
          orderCount: store?.storeStats?.orderCount || 0,
          manufacturerTotal: store?.storeStats?.manufacturerTotalAmount || 0,
        };
      })
    );
    // remove null values
    data.filter((d) => d);
    setTeamData(data);
    setIsLoading(false);
    setIsLastPage(data.length <= rowsPerPage);
  };

  const columns = [
    { Header: "Seller Name", accessor: "storeName" },
    { Header: "Email", accessor: "email" },
    { Header: "Status", accessor: "status" },
    { Header: "Orders", accessor: "orderCount" },
    { Header: "Created At", accessor: "createdAt" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <Button
          onClick={() => {

            nav(`/teams/${row.id}`);

          }}
          className="bg-gray-500 hover:bg-gray-600 h-8 p-2 rounded text-white text-xs ml-2"
        >
          View
        </Button>
      ),
    }
  ];

  const handleNextPage = () => {
    if (!isLastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    checkIsLastPage();
  }, [teamData, currentPage]);

  const checkIsLastPage = () => {
    setIsLastPage((currentPage + 1) * rowsPerPage >= teamData.length);
  };

  const paginatedData = teamData.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  return (
    <>
      <div className="flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">Teams</h1>
          <div className="flex mt-2 flex-col">
            <p className="font-bold">
              Invite Link:{" "}
              <a className="text-xs font-bold text-gray-500 ">
                https://supplycircle.io/register/{encodeURI(user.manufacturerID)}
              </a>
            </p>
            <WhatsappShareButton
              url={`https://supplycircle.io/register/${encodeURI(user.manufacturerID)}`}
              title={"Join SupplyCircle"}
              className="mt-2"
            >
              <button className="bg-green-500 hover:bg-green-600 h-8 p-2 rounded text-white text-xs ml-2">
                Share with Whatsapp
              </button>
            </WhatsappShareButton>
          </div>
        </div>
      </div>

      <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center mt-8">
        <div className="w-full overflow-x-auto">
          <div className="table-container">
            <p className="text-sm text-gray-700 dark:text-gray-300">
              {manufacturer?.subscribers?.length} /{" "}
              <a className="font-bold text-gray-500 text-lg ">
                {manufacturer.sellerLimit} Limit
              </a>
            </p>
            <div className="w-full mx-auto">
              <TableComponent
                columns={columns}
                data={paginatedData}
                isLoading={isLoading}
                SkeletonComponent={
                  <TableSkeleton columns={columns.length} rows={8} />
                }
              />
              <div className="flex justify-between mt-4">
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 0}
                >
                  Previous
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                  onClick={handleNextPage}
                  disabled={isLastPage}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



const TableSkeleton = ({ columns, rows }) => (
  Array.from({ length: rows }).map((_, rowIndex) => (
    <tr key={rowIndex}>
      {Array.from({ length: columns }).map((_, colIndex) => (
        <td className="p-4" key={colIndex}>
          <Skeleton width={125} count={1} />
        </td>
      ))}
    </tr>
  ))
);

export default Teams;