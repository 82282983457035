import React, { useEffect, useState } from "react";
import OrderEntry from "../../components/OrderEntry";
import OrderListStore from "../../components/OrderListStore";
import axios from "axios";
import { groupByCustomerID } from "../../utils/globalFunctions";
import { individualOrderPriceCalc,removeDuplicateArrays } from "../../utils/globalFunctions";
import SearchBox from "../../components/SearchBox";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { addressURL } from "../../utils/globalFunctions";
export default function Dashboard({user}) {
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [newOrderEntered, setNewOrderEntered] = useState(false);
  const [renderIndex, setRenderIndex] = useState(0);
  const [more, setMore] = useState(true);
  const navigate = useNavigate();
  console.log(renderIndex);


  const [products,setProducts] = useState([])

      
      
  useEffect(() => {

      axios
        .get(addressURL + `/api/price-list?fromWhere=allProducts`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        })
        .then((res) => {
          setProducts(res.data.result);

        })
        .catch((err) => console.log(err));
    }, []);



  async function getOrderWithGroupByCustomerID() {
    try {
      const res = await axios.get(addressURL + `/api/getOrders?index=${renderIndex+1}&limit=${renderIndex+2}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        withCredentials: true,
      });
      if (res.data.data.length === 0 ) {
        setRenderIndex(0);
        setMore(false);
        return;
      }else if (res.data.data.length === 1) {
        setMore(false);
        let newOrders = [...orders, ...res.data.data];
        setOrders(removeDuplicateArrays(newOrders));
        setRenderIndex(0);
      }else{
        let newOrders = [...orders, ...res.data.data];
        setOrders(removeDuplicateArrays(newOrders));
        setMore(true);
        setRenderIndex(renderIndex + 2);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setOrdersLoading(false);
    }
  }

  useEffect(() => {
    if(products.length > 0){
      getOrderWithGroupByCustomerID();
    }
  }, [products]);



  const fetchMoreData = () => {
   getOrderWithGroupByCustomerID();
  };

  function LoadingSkeleton() {
    return (
      <div className="animate-pulse mt-12 pl-8">
        <div className="flex flex-col mb-2">
          <div className="h-4 bg-gray-400 rounded w-1/3 mb-1"></div>
          <div className="h-4 bg-gray-400 rounded w-1/4"></div>
        </div>
        <div className="border-collapse border border-gray-200 w-full mb-2">
          {[...Array(4)].map((_, rowIndex) => (
            <div key={rowIndex} className="flex">
              {[...Array(window.innerWidth < 700 ?  4 : 10)].map((_, colIndex) => (
                <div
                  key={colIndex}
                  className="h-10  bg-gray-400  rounded mr-1 mb-1 flex-grow border border-gray-200"
                ></div>
              ))}
            </div>
          ))}
        </div>
        <div className="flex flex-col mb-2 mt-16">
          <div className="h-4 bg-gray-400 rounded w-1/4"></div>
        </div>
        <div className={window.innerWidth < 700 ? "border-collapse border border-gray-200 w-full " : "border-collapse border border-gray-200 w-1/2 "}>  
          {[...Array(3)].map((_, rowIndex) => (
            <div key={rowIndex} className="flex">
              {[...Array( window.innerWidth < 700 ? 3 : 5 )].map((_, colIndex) => (
                <div
                  key={colIndex}
                  className="h-10 bg-gray-400 rounded mr-1 mb-1 flex-grow border border-gray-200"
                ></div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }

  


  return (
    <>

 
      {/* <SearchBox user={user} /> */}

      <p className="mt-4 text-center text-2xl font-bold mb-4">Orders</p>

      {ordersLoading ? (
        <>
        <LoadingSkeleton />
        <LoadingSkeleton />
        </>
      ) : orders && orders.length > 0  && products.length > 0  ? (
        <>
            <InfiniteScroll
              dataLength={orders.length} //This is important field to render the next data
              next={fetchMoreData}
              hasMore={more}
              loader={<h4 className="text-center mb-4">Loading...</h4>}
              endMessage={
                <p  className="text-center mb-4">
                  <b>No more orders</b>
                </p>
              }
              // below props only if you need pull down functionality
              // refreshFunction={this.refresh}
              // pullDownToRefresh
              // pullDownToRefreshThreshold={50}
              // pullDownToRefreshContent={
              //   <h3 style={{ textAlign: "center" }}>
              //     &#8595; Pull down to refresh
              //   </h3>
              // }
              // releaseToRefreshContent={
              //   <h3 style={{ textAlign: "center" }}>
              //     &#8593; Release to refresh
              //   </h3>
              // }
            >
                 {products.length > 0 && orders.map((order,index) => {
                  return (
                      <OrderListStore
                        customerID={order.customerID}
                        orders={order.orders}
                        totalPrice={order.totalPrice}
                        dealerTotalPrice={order.dealerTotalPrice}
                        extras={order.extras}
                        setNewOrderEntered={setNewOrderEntered}
                        index={index}
                        products={products}
                      />
                      );
                    })}
            </InfiniteScroll>
        </>
      ) : (
        <h1 className="w-full h-48 flex justify-center items-center text-2xl text-gray-500 font-bold">
          No orders yet
        </h1>
      )}
    </>
  );
}
