// src/components/NewsletterEditor.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {StoreNewsletterEmailTemplate} from '../../templates/StoreNewsletterEmailTemplate';
import {sendEmail} from '../../utils/firebase';


const NewsletterEditor = ({ emails }) => {
  // State variables for newsletter content
  const [header, setHeader] = useState('Weekly Newsletter');
  const [body, setBody] = useState('Hello Store Owner,<br><br>Welcome to our weekly newsletter!');
  const [includeSurvey, setIncludeSurvey] = useState(false);
  const [surveyQuestion, setSurveyQuestion] = useState('');
  const [surveyLink, setSurveyLink] = useState('');
  const [includeVoting, setIncludeVoting] = useState(false);
  const [votingOptions, setVotingOptions] = useState(['Option A', 'Option B']);
  const [footer, setFooter] = useState('Thank you for being a valued partner.<br><br>If you wish to unsubscribe, please click <a href="#">here</a>.');
  const [sentEmails, setSentEmails] = useState(0);

  // State variables for email selection
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Handler to toggle selection of a single email
  const handleEmailSelect = (email) => {
    if (selectedEmails.includes(email)) {
      setSelectedEmails(selectedEmails.filter((e) => e !== email));
    } else {
      setSelectedEmails([...selectedEmails, email]);
    }
  };

  // Handler to toggle select all emails
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedEmails([]);
      setSelectAll(false);
    } else {
      setSelectedEmails([...emails]);
      setSelectAll(true);
    }
  };

  // Handler to toggle inclusion of survey section
  const handleSurveyToggle = () => {
    setIncludeSurvey(!includeSurvey);
    if (includeSurvey) {
      setSurveyQuestion('');
      setSurveyLink('');
    }
  };

  // Handler to toggle inclusion of voting section
  const handleVotingToggle = () => {
    setIncludeVoting(!includeVoting);
    if (includeVoting) {
      setVotingOptions(['Option A', 'Option B']);
    }
  };

  // Function to generate survey HTML
  const generateSurveyHTML = () => {
    if (!surveyQuestion || !surveyLink) return '';
    return `
      <div style="border:1px solid #ccc; padding:10px; margin:10px 0;">
        <h3>Survey</h3>
        <p>${surveyQuestion}</p>
        <a href="${surveyLink}" class="button">Respond to Survey</a>
      </div>
    `;
  };

  // Function to generate voting HTML
  const generateVotingHTML = () => {
    if (!votingOptions.length) return '';
    const optionsHTML = votingOptions
      .map(
        (option, index) => `
        <li><a href="${generateVotingLink(option)}" class="button">${option}</a></li>
      `
      )
      .join('');
    return `
      <div style="border:1px solid #ccc; padding:10px; margin:10px 0;">
        <h3>Vote for Your Favorite Option</h3>
        <ul style="list-style-type: none; padding: 0;">
          ${optionsHTML}
        </ul>
      </div>
    `;
  };

  // Function to generate voting link (replace with your actual voting endpoint)
  const generateVotingLink = (option) => {
    const votingId = Date.now(); // Unique ID for each voting instance
    return `https://yourdomain.com/vote?option=${encodeURIComponent(option)}&id=${votingId}`;
  };

  // Function to generate the final HTML
  const generateEmailHTML = () => {
    const surveySection = includeSurvey ? generateSurveyHTML() : '';
    const votingSection = includeVoting ? generateVotingHTML() : '';
    return StoreNewsletterEmailTemplate({
      header,
      body,
      surveySection,
      votingSection,
      footer,
    });
  };

  // Handler to send the newsletter
  const handleSend = async () => {
    const emailHtml = generateEmailHTML();

    // Validation
    if (includeSurvey && (!surveyQuestion || !surveyLink)) {
      alert('Please provide both survey question and link.');
      return;
    }

    if (includeVoting && votingOptions.some((opt) => !opt.trim())) {
      alert('Please provide valid voting options.');
      return;
    }

    if (selectedEmails.length === 0) {
      alert('Please select at least one recipient.');
      return;
    }

    // Confirm before sending
    if (!window.confirm('Are you sure you want to send the newsletter to the selected recipients?')) {
      return;
    }

    try {
      for (const email of selectedEmails) {
        await sendEmail({
          subject: header,
          html: emailHtml,
          to: email,
          sender: 'Supply Circle',
          attachments: [],
        });
        // Wait 1 second before sending the next email
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setSentEmails((count) => count + 1);
      }

      alert('Newsletters have been sent successfully!');
      // Optionally, reset the form or keep the current state
    } catch (error) {
      console.error('Error sending emails:', error);
      alert('There was an error sending the newsletters. Please try again.');
    }
  };

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white shadow-md rounded-md">
      <div
        className="bg-blue-50 p-4 rounded-md mb-6"
      >
        <p>
          <strong>{sentEmails}</strong> newsletters have been sent out of <strong>{selectedEmails.length}</strong> selected recipients.
        </p>
      </div>
      <h2 className="text-3xl font-semibold mb-6">Create Newsletter</h2>

      {/* Email Selection Section */}
      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Select Recipients</h3>
        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
            className="form-checkbox h-5 w-5 text-blue-600"
            id="selectAll"
          />
          <label htmlFor="selectAll" className="ml-2 text-gray-700 font-medium">Select All</label>
        </div>
        <div className="max-h-60 overflow-y-auto border border-gray-300 rounded-md p-2">
          {emails.map((email, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="checkbox"
                checked={selectedEmails.includes(email)}
                onChange={() => handleEmailSelect(email)}
                className="form-checkbox h-5 w-5 text-blue-600"
                id={`email-${index}`}
              />
              <label htmlFor={`email-${index}`} className="ml-2 text-gray-700">{email}</label>
            </div>
          ))}
        </div>
      </div>

      {/* Newsletter Content Section */}
      <div className="space-y-6">
        {/* Header */}
        <div>
          <label className="block text-gray-700 font-medium">Header</label>
          <input
            type="text"
            value={header}
            onChange={(e) => setHeader(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            placeholder="Enter email header"
          />
        </div>

        {/* Body */}
        <div>
          <label className="block text-gray-700 font-medium">Body Content (HTML Supported)</label>
          <textarea
            value={body}
            onChange={(e) => setBody(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 h-32"
            placeholder="Enter email body content"
          ></textarea>
        </div>

        {/* Survey Section */}
        <div>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={includeSurvey}
              onChange={handleSurveyToggle}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700 font-medium">Include Survey</span>
          </label>
          {includeSurvey && (
            <div className="mt-4 space-y-4">
              <div>
                <label className="block text-gray-700 font-medium">Survey Question</label>
                <input
                  type="text"
                  value={surveyQuestion}
                  onChange={(e) => setSurveyQuestion(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                  placeholder="Enter your survey question"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium">Survey Link</label>
                <input
                  type="url"
                  value={surveyLink}
                  onChange={(e) => setSurveyLink(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                  placeholder="Enter the survey URL"
                />
              </div>
            </div>
          )}
        </div>

        {/* Voting Section */}
        <div>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={includeVoting}
              onChange={handleVotingToggle}
              className="form-checkbox h-5 w-5 text-green-600"
            />
            <span className="ml-2 text-gray-700 font-medium">Include Voting Options</span>
          </label>
          {includeVoting && (
            <div className="mt-4 space-y-4">
              <label className="block text-gray-700 font-medium">Voting Options</label>
              {votingOptions.map((option, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={option}
                    onChange={(e) => {
                      const newOptions = [...votingOptions];
                      newOptions[index] = e.target.value;
                      setVotingOptions(newOptions);
                    }}
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                    placeholder={`Option ${index + 1}`}
                  />
                  {votingOptions.length > 2 && (
                    <button
                      type="button"
                      onClick={() => {
                        const newOptions = votingOptions.filter((_, i) => i !== index);
                        setVotingOptions(newOptions);
                      }}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={() => setVotingOptions([...votingOptions, ''])}
                className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                Add Option
              </button>
            </div>
          )}
        </div>

        {/* Footer */}
        <div>
          <label className="block text-gray-700 font-medium">Footer Content (HTML Supported)</label>
          <textarea
            value={footer}
            onChange={(e) => setFooter(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 h-24"
            placeholder="Enter email footer content"
          ></textarea>
        </div>
      </div>

      {/* Preview and Send Buttons */}
      <div className="mt-8 flex space-x-4">
        {/* Preview Button */}
        <button
          type="button"
          onClick={() => {
            const emailHtml = generateEmailHTML();
            const previewWindow = window.open('', '_blank');
            previewWindow.document.write(emailHtml);
            previewWindow.document.close();
          }}
          className="px-6 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
        >
          Preview Email
        </button>

        {/* Send Button */}
        <button
          type="button"
          onClick={handleSend}
          className="px-6 py-2 bg-purple-500 text-white rounded hover:bg-purple-600"
        >
          Send Newsletter
        </button>
      </div>
    </div>
  );
};

NewsletterEditor.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  sendEmail: PropTypes.func.isRequired,
};

export default NewsletterEditor;