import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function CustomizationEditorComponent({
  user,
  materials,
  manufacturer,
  customization: initialCustomization,
  onSave,
  onCancel,
}) {
  const [customization, setCustomization] = useState(initialCustomization);
  const [expandedItems, setExpandedItems] = useState({});
  const [expandedDetails, setExpandedDetails] = useState({});

  useEffect(() => {
    setCustomization(initialCustomization);
  }, [initialCustomization]);

  const handleInputChange = (field, value) => {
    setCustomization((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleItemChange = (itemIndex, field, value) => {
    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex][field] = value;

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const handleDetailChange = (itemIndex, detailIndex, field, value) => {
    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex].variation_details[detailIndex][field] = value;

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const handleDetailItemChange = (
    itemIndex,
    detailIndex,
    detailItemIndex,
    field,
    value
  ) => {
    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex].variation_details[detailIndex].variation_detail_items[
      detailItemIndex
    ][field] = value;

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const addNewVariationItem = () => {
    const newItem = {
      id: uuid(),
      variation_name: "",
      variation_price: "",
      isAvailable: true,
      materialID: "",
      variation_details: [],
    };

    setCustomization((prev) => ({
      ...prev,
      variationItems: [...prev.variationItems, newItem],
    }));
  };

  const addNewVariationDetail = (itemIndex) => {
    const newDetail = {
      id: uuid(),
      variation_detail_title: "",
      isAvailable: true,
      variation_detail_items: [],
    };

    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex].variation_details.push(newDetail);

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const addNewVariationDetailItem = (itemIndex, detailIndex) => {
    const newDetailItem = {
      id: uuid(),
      variation_detail_name: "",
      variation_detail_price: "",
      isAvailable: true,
      materialID: "",
    };

    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex].variation_details[detailIndex].variation_detail_items.push(
      newDetailItem
    );

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const handleDeleteVariationItem = (itemIndex) => {
    const updatedItems = customization.variationItems.filter(
      (_, index) => index !== itemIndex
    );
    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const handleDeleteVariationDetail = (itemIndex, detailIndex) => {
    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex].variation_details = updatedItems[
      itemIndex
    ].variation_details.filter((_, index) => index !== detailIndex);

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const handleDeleteVariationDetailItem = (
    itemIndex,
    detailIndex,
    detailItemIndex
  ) => {
    const updatedItems = [...customization.variationItems];
    const detailItems =
      updatedItems[itemIndex].variation_details[detailIndex].variation_detail_items;
    updatedItems[itemIndex].variation_details[
      detailIndex
    ].variation_detail_items = detailItems.filter(
      (_, index) => index !== detailItemIndex
    );

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const toggleItemExpansion = (itemIndex) => {
    setExpandedItems((prev) => ({
      ...prev,
      [itemIndex]: !prev[itemIndex],
    }));
  };

  const toggleDetailExpansion = (itemIndex, detailIndex) => {
    setExpandedDetails((prev) => ({
      ...prev,
      [`${itemIndex}-${detailIndex}`]: !prev[`${itemIndex}-${detailIndex}`],
    }));
  };

  const handleSave = () => {
    // Validation Logic
    let isValid = true;
    let errorMessage = "";

    if (!customization.variation_title.trim()) {
      errorMessage = "Customization Title cannot be empty.";
      isValid = false;
    }

    customization.variationItems.forEach((item, itemIndex) => {
      if (!item.variation_name.trim()) {
        errorMessage = `Customization Name at position ${itemIndex + 1} cannot be empty.`;
        isValid = false;
      } else if (
        item.variation_price === "" ||
        isNaN(item.variation_price) ||
        Number(item.variation_price) < 0
      ) {
        errorMessage = `Invalid Customization Price at position ${itemIndex + 1}. Please enter a non-negative number.`;
        isValid = false;
      }

      item.variation_details.forEach((detail, detailIndex) => {
        if (!detail.variation_detail_title.trim()) {
          errorMessage = `Detail Title at Customization ${itemIndex + 1}, Detail ${detailIndex + 1} cannot be empty.`;
          isValid = false;
        }

        // Only validate options if there are any
        if (detail.variation_detail_items.length > 0) {
          detail.variation_detail_items.forEach((detailItem, detailItemIndex) => {
            if (!detailItem.variation_detail_name.trim()) {
              errorMessage = `Option Name at Customization ${itemIndex + 1}, Detail ${detailIndex + 1}, Option ${detailItemIndex + 1} cannot be empty.`;
              isValid = false;
            } else if (
              detailItem.variation_detail_price === "" ||
              isNaN(detailItem.variation_detail_price) ||
              Number(detailItem.variation_detail_price) < 0
            ) {
              errorMessage = `Invalid Option Price at Customization ${itemIndex + 1}, Detail ${detailIndex + 1}, Option ${detailItemIndex + 1}. Please enter a non-negative number.`;
              isValid = false;
            }
          });
        }
      });
    });

    if (!isValid) {
      toast.error(errorMessage);
      return;
    }

    let c = { ...customization };
    c.isStore = true;
    c.store_id = user.store_id;
    console.log(c);
    onSave(c);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg">
      {/* Customization Header */}
      <h1 className="text-2xl font-semibold mb-6 text-gray-800">
        {customization.id ? "Edit Customization" : "Create Customization"}
      </h1>
      <div className="mb-8">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Customization Title
          </label>
          <input
            type="text"
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder="Enter Customization Title"
            value={customization.variation_title}
            onChange={(e) => handleInputChange("variation_title", e.target.value)}
          />
        </div>
      </div>

      {/* Variation Items Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {customization.variationItems.map((item, itemIndex) => (
          <div
            key={item.id}
            className="p-6 bg-white rounded-lg shadow-md border border-gray-200"
          >
            <div className="flex justify-between items-center mb-4">
              <input
                type="text"
                className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Customization Name"
                value={item.variation_name}
                onChange={(e) =>
                  handleItemChange(itemIndex, "variation_name", e.target.value)
                }
              />
              <button
                onClick={() => handleDeleteVariationItem(itemIndex)}
                className="text-gray-500 hover:text-red-600 ml-2 focus:outline-none"
              >
                &times;
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Price
              </label>
              <div className="flex items-center">
                <span className="text-gray-700 mr-1">$</span>
                <input
                  type="number"
                  step="0.01"
                  className="block w-24 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="0.00"
                  value={item.variation_price}
                  onChange={(e) =>
                    handleItemChange(itemIndex, "variation_price", e.target.value)
                  }
                />
              </div>
            </div>
            {user.role === "admin" && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Material
                </label>
                <select
                  className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  value={item.materialID}
                  onChange={(e) =>
                    handleItemChange(itemIndex, "materialID", e.target.value)
                  }
                >
                  <option value="">Select a material</option>
                  {materials.map((material) => (
                    <option key={material.id} value={material.id}>
                      {material.material_name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {/* Expand/Collapse Details */}
            <button
              onClick={() => toggleItemExpansion(itemIndex)}
              className="w-full bg-indigo-600 text-white py-2 rounded-md focus:outline-none hover:bg-indigo-700 transition duration-300"
            >
              {expandedItems[itemIndex] ? "Hide Details" : "Show Details"}
            </button>

            {expandedItems[itemIndex] && (
              <div className="mt-4">
                {/* Add Detail Button */}
                <button
                  onClick={() => addNewVariationDetail(itemIndex)}
                  className="w-full bg-gray-100 text-gray-700 py-2 rounded-md focus:outline-none hover:bg-gray-200 transition duration-300 mb-4"
                >
                  Add Detail
                </button>

                {/* Details List */}
                {item.variation_details.map((detail, detailIndex) => (
                  <div
                    key={detail.id}
                    className="mb-4 p-4 border rounded-md bg-gray-50"
                  >
                    <div className="flex justify-between items-center mb-2">
                      <input
                        type="text"
                        className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder="Detail Title"
                        value={detail.variation_detail_title}
                        onChange={(e) =>
                          handleDetailChange(
                            itemIndex,
                            detailIndex,
                            "variation_detail_title",
                            e.target.value
                          )
                        }
                      />
                      <button
                        onClick={() =>
                          handleDeleteVariationDetail(itemIndex, detailIndex)
                        }
                        className="text-gray-500 hover:text-red-600 ml-2 focus:outline-none"
                      >
                        &times;
                      </button>
                    </div>
                    {/* Expand/Collapse Options */}
                    <button
                      onClick={() => toggleDetailExpansion(itemIndex, detailIndex)}
                      className="w-full bg-indigo-500 text-white py-1 rounded-md focus:outline-none hover:bg-indigo-600 transition duration-300"
                    >
                      {expandedDetails[`${itemIndex}-${detailIndex}`]
                        ? "Hide Options"
                        : "Show Options"}
                    </button>

                    {expandedDetails[`${itemIndex}-${detailIndex}`] && (
                      <div className="mt-4">
                        {/* Add Option Button */}
                        <button
                          onClick={() =>
                            addNewVariationDetailItem(itemIndex, detailIndex)
                          }
                          className="w-full bg-gray-100 text-gray-700 py-1 rounded-md focus:outline-none hover:bg-gray-200 transition duration-300 mb-2"
                        >
                          + Add Option
                        </button>

                        {/* Options List */}
                        {detail.variation_detail_items.map(
                          (detailItem, detailItemIndex) => (
                            <div
                              key={detailItem.id}
                              className="mt-2 p-2 border rounded-md bg-white"
                            >
                              <div className="flex justify-between items-center mb-2">
                                <input
                                  type="text"
                                  className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                  placeholder="Option Name"
                                  value={detailItem.variation_detail_name}
                                  onChange={(e) =>
                                    handleDetailItemChange(
                                      itemIndex,
                                      detailIndex,
                                      detailItemIndex,
                                      "variation_detail_name",
                                      e.target.value
                                    )
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleDeleteVariationDetailItem(
                                      itemIndex,
                                      detailIndex,
                                      detailItemIndex
                                    )
                                  }
                                  className="text-gray-500 hover:text-red-600 ml-2 focus:outline-none"
                                >
                                  &times;
                                </button>
                              </div>
                              <div className="mb-2">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                  Option Price
                                </label>
                                <div className="flex items-center">
                                  <span className="text-gray-700 mr-1">$</span>
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="block w-24 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    placeholder="0.00"
                                    value={detailItem.variation_detail_price}
                                    onChange={(e) =>
                                      handleDetailItemChange(
                                        itemIndex,
                                        detailIndex,
                                        detailItemIndex,
                                        "variation_detail_price",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              {user.role === "admin" && (
                                <div className="mb-2">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Material
                                  </label>
                                  <select
                                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    value={detailItem.materialID || ""}
                                    onChange={(e) =>
                                      handleDetailItemChange(
                                        itemIndex,
                                        detailIndex,
                                        detailItemIndex,
                                        "materialID",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">Select a material</option>
                                    {materials.map((material) => (
                                      <option key={material.id} value={material.id}>
                                        {material.material_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        {/* Add New Variation Item Button */}
        <button
          onClick={addNewVariationItem}
          className="p-6 border-2 border-dashed border-gray-300 rounded-lg text-gray-500 hover:border-indigo-500 hover:text-indigo-500 focus:outline-none flex flex-col items-center justify-center transition-colors duration-300"
        >
          <svg
            className="w-8 h-8 mb-2"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
          <span className="text-lg font-semibold">Add Customization Item</span>
        </button>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-4 mt-8">
        <button
          onClick={handleCancel}
          className="bg-gray-500 text-white text-sm font-semibold py-2 px-6 rounded-md focus:outline-none hover:bg-gray-600 transition duration-300"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="bg-indigo-600 text-white text-sm font-semibold py-2 px-6 rounded-md focus:outline-none hover:bg-indigo-700 transition duration-300"
        >
          Save Customization
        </button>
      </div>
    </div>
  );
}