import React from 'react'

export default function Button({name,onClick,className,disabled}) {
  return (
    <button
        className={className}
        disabled={disabled}
        type='button'
        style={{
        backgroundColor: '#333',
        color: '#fff',
        padding: '0.5rem 1rem',
        borderRadius: '0.25rem',
        border: 'none',
        fontSize:'14px'

        }}
        onClick={onClick}
    >
        {name}
    </button>
  )
}
