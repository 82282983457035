import React, { useEffect, useState } from 'react';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { storage } from '../utils/firebase';

function ImageUploader({ setDownloadURL,downloadURL}) {
  const [image, setImage] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [imageRef, setImageRef] = useState(null);

  const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
      const timestamp = Date.now();
      const imageName = `images/${timestamp}_${file.name}`;
      const storageRef = ref(storage, imageName);

      const uploadTask = uploadBytesResumable(storageRef, file);

      setUploading(true);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Update progress
          const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(prog);
        },
        (error) => {
          // Handle errors
          setError('Error uploading image');
          console.error('Error uploading image:', error);
          setUploading(false);
          reject(error);
        },
        () => {
          // Upload completed successfully
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setDownloadURL(url);
            setImageRef(storageRef);
            setUploading(false);
            setProgress(0);
            resolve(url);
          });
        }
      );
    });
  };

  const handleChange = async (e) => {
    setError('');
    setProgress(0);
    if (e.target.files[0]) {
      const file = e.target.files[0];
      setImage(file);
      try {
        await uploadImage(file);
      } catch (error) {
        setError('Failed to upload image');
      }
    }
  };

  const handleDelete = async () => {
    if (imageRef) {
      try {
        await deleteObject(imageRef);
        // Reset state
        setDownloadURL(null);
        setImage(null);
        setImageRef(null);
        setError('');
      } catch (error) {
        setError('Error deleting image');
        console.error('Error deleting image:', error);
      }
    }
  };



  return (
    <div className="max-w-md mx-auto  p-6 bg-white rounded-lg">
      <h2 className="text-sm font-bold mb-6 text-center">Upload Image</h2>
      {error && (
        <div className="mb-4 text-red-500 text-center">
          {error}
        </div>
      )}
      <div className="flex flex-col items-center">
        <label
          htmlFor="fileInput"
          className={`relative w-full h-32 flex flex-col items-center justify-center border-2 border-dashed rounded-lg cursor-pointer ${
            uploading ? 'border-gray-300' : 'border-blue-400 hover:border-blue-500'
          }`}
        >
          {uploading ? (
            <div className="flex flex-col items-center">
              <svg
                className="animate-spin h-10 w-10 text-blue-500 mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
              <p className="text-blue-500 font-semibold">Uploading... {Math.round(progress)}%</p>
            </div>
          ) : downloadURL ? (
            <>
              <img
                src={downloadURL}
                alt="Uploaded"
                className="h-full w-full object-cover rounded-lg"
              />
              <button
                type="button"
                onClick={handleDelete}
                className="absolute top-2 right-2 bg-white bg-opacity-75 hover:bg-opacity-100 text-gray-700 hover:text-gray-900 font-bold py-1 px-2 rounded-full focus:outline-none"
              >
                &#x2716;
              </button>
            </>
          ) : (
            <div className="flex flex-col items-center">
              <svg
                className="w-12 h-12 text-blue-500"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M16.88 9.94a1 1 0 00-1.76-.96L13 11.58V3a1 1 0 10-2 0v8.59l-2.12-2.64a1 1 0 00-1.76.96l3.88 4.83a1 1 0 001.76 0l3.88-4.84z" />
              </svg>
              <p className="mt-2 text-base leading-normal text-gray-500">
                Click to select an image
              </p>
            </div>
          )}
          <input
            id="fileInput"
            type="file"
            className="hidden"
            onChange={handleChange}
            accept="image/*"
          />
        </label>
      </div>
    </div>
  );
}

export default ImageUploader;