import React,{useEffect, useState} from 'react'
import { db } from '../../utils/firebase';
import {getFirestore} from "firebase/firestore"
import { collection,addDoc,serverTimestamp } from "firebase/firestore";
import { globalAlert, globalSuccess } from '../../utils/globalFunctions';
import { addressURL } from '../../utils/globalFunctions';
import axios from 'axios'
export default function Notify(props) {
    const [notification,setNotification] = useState('')
    const [sellers,setSellers] = useState([]);
    const [selectedSeller,setSelectedSeller] = useState('All')


    console.log(sellers)
    useEffect(() => {
        if (props.user.role === "admin") {
          //get all the sellers except admin.
          axios
            .get(addressURL + "/api/getAllSellers", {
              // NOT MANUFACTURERS
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              withCredentials: true,
            })
            .then((res) => {
              setSellers(res.data.sellers);
            })
            .catch((err) => console.log(err));
        }
      }, [props.user]);
  return (
    <div className='flex-col flex w-full items-center'>
        <p className='font-bold'>Notify your dealer</p>
        <textarea 
            value={notification}
            onChange={(e)=> setNotification(e.target.value)}
            className='w-3/4'
            />

        <select  defaultValue={'All'} value={selectedSeller} onChange={(e)=> setSelectedSeller(e.target.value)} className='mt-2'>
            <option value={"All"}>All</option>
            {sellers.map((seller, index) => (
                <option key={index} value={seller.email}>
                {seller.storename}
                </option>
            ))}
            </select>
            <button 
            className='bg-gray-500 p-3 mt-2 rounded text-white font-bold'
                onClick={async(e)=>{
                    e.preventDefault()
                    if(notification.length === 0){
                        return
                    }
                    if(!selectedSeller){
                        return globalAlert('Seller has no registered email')
                    }
                    if(selectedSeller === 'All'){

                        let emailArray = []
                        sellers.forEach((seller)=>{
                            if(seller.email)
                                emailArray.push(seller.email)
                        }
                        )
                        console.log(emailArray)
                        try {
                            const docRef = await addDoc(collection(db, "notifications"), {
                              Notification: notification,
                              seenBy: [],
                              sender:'Cachet Admin',
                              forWhom: 'All',
                              createdAt: serverTimestamp(),
                            });
                        
                            axios.post(addressURL + '/api/notifications/send-email',{
                                emails:emailArray,
                                notification:notification,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    },
                                    withCredentials: true,
                            })
                            .then((res)=>{
                                console.log(res)
                            })
                            .catch((err)=>{
                                console.log(err)
                            })
    
                            console.log("Document written with ID: ", docRef.id);
                            globalSuccess("Successfuly notified the users")
                            setNotification('')
                          } catch (e) {
                            console.error("Error adding document: ", e);
                            globalAlert("There was something wrong, please contact with developer")
                            setNotification('')
                          }
                            
                    }else{

                        let storename = null
                        sellers.forEach((value)=>{
                            if(value.email === selectedSeller){
                                storename = value.storename
                            }
                        })
                        try {
                            const docRef = await addDoc(collection(db, "notifications"), {
                              Notification: notification,
                              seenBy: [],
                              forWhom: storename,
                              sender: 'Cachet Admin',
                              createdAt: serverTimestamp(),
                            });
                        
                            axios.post(addressURL + '/api/notifications/send-email',{
                                emails:[selectedSeller],
                                notification:notification,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    },
                                    withCredentials: true,
                            })
                            .then((res)=>{
                                console.log(res)
                            })
                            .catch((err)=>{
                                console.log(err)
                            })
    
                            console.log("Document written with ID: ", docRef.id);
                            globalSuccess("Successfuly notified the users")
                            setNotification('')
                          } catch (e) {
                            console.error("Error adding document: ", e);
                            globalAlert("There was something wrong, please contact with developer")
                            setNotification('')
                          }
                    }
                     
                
             
             
                }}
            >
                Notify
            </button>

    </div>
  )
}
