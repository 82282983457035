import React, { useEffect } from 'react'
import { Zebra,Vertical,Roller } from '../../utils/globalVariables'
import { globalAlert,createGlobalRule, globalSuccess,getGlobalRules, updateGlobalRule } from '../../utils/globalFunctions'

export default function NewRules() {
    const [selectedVariable,setSelectedVariable] = React.useState("")
    const [data,setData] = React.useState({})
    const [savedRules,setSavedRules] = React.useState(null)

    console.log(selectedVariable)
    const organizeData = (variants) => {
        let vars = {};
        variants?.forEach((variant) => {
            if (vars[variant.name]) {
                vars[variant.name].push(variant);
            } else {
                vars[variant.name] = [variant];
            }
        });
        console.log(vars)
        return vars
    } 
    useEffect(()=>{
        if(selectedVariable){
            if(selectedVariable === "Zebra"){
                console.log(Zebra)
                let vars = organizeData(Zebra)
                setData(vars)
            }
            if(selectedVariable === "Roller"){
                console.log(Roller)
                let vars = organizeData(Roller)
                setData(vars)
            }
            if(selectedVariable === "Vertical"){
                console.log(Vertical)
                let vars = organizeData(Vertical)
                setData(vars)
            }
        }
    },[selectedVariable])

    const handleSubmit = () => {
        let keys = Object.keys(data)

        let rules = {}
        keys.forEach((key)=>{
            let value = document.getElementById(key).value
            rules[key] = value
        })

        console.log(rules)
        let ruleName = document.getElementById('RuleName').value
        let cassetteCut = document.getElementById('CassetteCut').value
        let tubeCut = document.getElementById('TubeCut').value
        let bottomBar = document.getElementById('BottomBar').value
        let bottomTube = document.getElementById('BottomTube').value
        let fabricWidth = document.getElementById('FabricWidth').value


        if(ruleName === "")
        {
            globalAlert("Please enter a rule name")
            return
        }

        if(isNaN(cassetteCut) || 
        isNaN(tubeCut) ||
        isNaN(bottomBar) ||
        isNaN(bottomTube) ||
        isNaN(fabricWidth)){
            globalAlert("Please enter a valid number")
            return
        }

        let deductions = {
            cassetteCut,
            tubeCut,
            bottomBar,
            bottomTube,
            fabricWidth
        }
        let variable = null
        if(selectedVariable === "Zebra"){
            variable = "Zebra"
        }else if(selectedVariable === "Roller"){
            variable = "Roller Shade"
        }else{
            variable = "Vertical Sheer"
        }
        let rule = {
            ruleName,
            product_type: variable,
            rules,
            deductions
        }

        createGlobalRule(rule).then((res)=>{
            if(res){
                globalSuccess("Rule created successfully")
                document.getElementById('RuleName').value = ""
                document.getElementById('CassetteCut').value = 0
                document.getElementById('TubeCut').value = 0
                document.getElementById('BottomBar').value = 0
                document.getElementById('BottomTube').value = 0
                document.getElementById('FabricWidth').value = 0
                getRules()
            }
        })
    }

    useEffect(()=>{
        getRules()
    },[])

    const getRules = () => {
        getGlobalRules().then((res)=>{
            console.log(res)
            setSavedRules(res)
        })
    }

  return (
    <div>
        <div>
            <p>Rules for</p>
            <select
                className="bg-white text-xs p-2 w-full mt-1 rounded border"
                onChange={(e) =>{ 
                    setSelectedVariable(e.target.value)
                }}
            >
                <option value="">Select Product Type</option>
                <option value={"Zebra"}>Zebra</option>
                <option value={"Roller"}>Roller</option>
                <option value={"Vertical"}>Vertical</option>
            </select>

                <input      
                type="text"
                id='RuleName'
                placeholder="Enter Rule Name"
                className="bg-white text-xs p-2 w-full mt-1 rounded border"
                />
            <div>
                {
                    Object.keys(data).length > 0 &&
                    <div>
                        {
                            Object.keys(data).map((
                                key,index
                            )=>{
                                return(
                                    <div key={key}>
                                        <p>{key}</p>
                                        <div>
                                            <select
                                                className="bg-white text-xs p-2 w-full mt-1 rounded border"
                                                id={key}
                                                defaultValue={
                                                    data[key][0].value
                                                }
                                            >
                                                {
                                                    data[key].map((variant,index)=>{
                                                        return(
                                                            <option key={index} value={variant.value}>{variant.value}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                 
                                        </div>
                                
                                    </div>
                                )
                            }

                            )
                        }
                             <div>
                                            <p className='mt-4'>
                                                Deductions
                                            </p>
                                            <div>
                                                <input defaultValue={0} type="text" placeholder="Cassette Cut" id='CassetteCut' />
                                                <input defaultValue={0} type="text" placeholder="Tube Cut" id='TubeCut' />
                                                <input defaultValue={0} type="text" placeholder="Bottom Bar" id='BottomBar' />
                                                <input defaultValue={0} type="text" placeholder="Bottom Tube" id='BottomTube' />
                                                <input defaultValue={0} type="text" placeholder="Fabric Width" id='FabricWidth' />
                                            </div>
                            </div>
                            <button onClick={handleSubmit}>Save Rule</button>
          
                    </div>
                }
            </div>

            <div>
                <p>Rules</p>
                {
                    savedRules && savedRules.map((rule,index)=>{
                        return(
                            <div key={index}>
                                <p>{rule.ruleName} ({rule.product_type})</p>
                                {
                                    Object.keys(rule.deductions).map((
                                        key,index
                                    )=>{
                                        return(
                                            <div key={key}>
                                                <p>{key}</p>
                                           
                                              <input
                                                defaultValue={rule.deductions[key]}
                                                onChange={(e)=>{
                                                    rule.deductions[key] = e.target.value
                                                }}
                                                type="text"
                                                placeholder={key}
                                                id={key}
                                                />
                                            </div>
                                        )
                                    }
                                        
                                        )
                                  
                                }
                                <button
                                    onClick={()=>{
                                        updateGlobalRule(rule).then((res)=>{
                                            if(res){
                                                globalSuccess("Rule updated successfully")
                                            }
                                        })
                                    }}
                                >
                                    Update Rule
                                </button>
                              
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </div>
  )
}
