
import Dashboard from "./dashboard";
import { fetchManufacturer } from "../../utils/globalFunctions";
import React from "react";

import { useUser,useManufacturer } from "../../utils/context";

import { useContext } from "react";

import { updateManufacturerRules } from "../../utils/globalFunctions";
import { doc, onSnapshot } from "@firebase/firestore";
import { db } from "../../utils/firebase";
import { useEffect } from "react";


export default function Index(props) {
  const { user,setUser } = useUser();
  const {manufacturer,setManufacturer} = useManufacturer();



  if(!manufacturer){
    return <div>Loading...</div>
  }


  return (
    <>
  
        <Dashboard manufacturer={manufacturer} setManufacturer={setManufacturer} />
    </>
  );
}
