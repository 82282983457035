import React from 'react'
import StoreDashboardTemplate from '../../components/StoreDashboardTemplate'
import { useNavigate } from 'react-router-dom'


const extensions = [{
    name: 'Blinds Estimate',
    description: 'This extension will allow you to provide a blinds estimate to your customers.',
    href: '/extension/blindsEstimate'
},
{
  name: 'Calendar & Booking',
  description: 'This extension will allow you to provide a calendar and booking system to your customers.',
  href: '/extension/calendarBooking'
},
{
  name: 'Price Lists',
  description: 'This extension only works with products that have a price lists. You can view the price lists for your store here.Default prices are the manufacturer prices, but you can change them to your own prices to calculate your customer estimates.',
  href: '/extension/priceLists'
},
]

export default function Extensions() {
  const navigate = useNavigate()
  return (


    <StoreDashboardTemplate secondaryNavigation={[]}
    >
      {
        extensions.map((extension, index) => (
          <div key={index} className="bg-white mt-4 shadow-lg rounded-lg p-6 hover:shadow-2xl transition-shadow duration-300">
            <h2 className="text-xl font-semibold text-gray-800">{extension.name}</h2>
            <p className="text-gray-600 mt-2">{extension.description}</p>
            <button
              className="bg-indigo-500 text-white text-sm py-2 px-4 rounded mt-4 hover:bg-indigo-600 transition-colors duration-300"
              onClick={() => navigate(extension.href)}
            >
              View
            </button>
          </div>
        ))
      }
    


      </StoreDashboardTemplate>
  )
}
