import React, { useState } from 'react';


const ChatPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  // Dummy user IDs for sender and receiver
  const senderId = 'user1';
  const receiverId = 'user2';

  const sendMessage = () => {
    if (newMessage.trim()) {
      const message = {
        id: new Date().getTime(), // simple unique ID
        text: newMessage,
        senderId: senderId
      };
      setMessages([...messages, message]);
      setNewMessage('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div className="chat-popup-container">
      <button onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? 'Close Chat' : 'Open Chat'}
      </button>

      <div className={`chat-window ${!isOpen ? 'closed' : ''}`}>
        <h2>Support</h2>
        <div className="chat-messages">
            {messages.map(msg => (
              <div key={msg.id} className={msg.senderId === senderId ? 'message sender' : 'message receiver'}>
                {msg.text}
              </div>
            ))}
          </div>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default ChatPopup;
