import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Button, Modal, Label, TextInput } from "flowbite-react";
import {
  fetchSuppliers,
  createSupplier,
  deleteSupplier,
} from "../../utils/globalFunctions";
import TableComponent from "../../components/TableComponent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Suppliers = ({ user, manufacturer }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLimited, setIsLimited] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [supplierName, setSupplierName] = useState("");
  const [supplierPhone, setSupplierPhone] = useState("");
  const [supplierEmail, setSupplierEmail] = useState("");
  const nav = useNavigate();
  const rowsPerPage = 6;

  useEffect(() => {
    if (manufacturer) {
      if (manufacturer?.subscribers?.length > manufacturer.sellerLimit) {
        setIsLimited(true);
      }
      console.log(manufacturer);
      fetchSuppliers(manufacturer.id).then((data) => {
        setTeamData(data);
        setIsLoading(false);
      });
    }
  }, [manufacturer]);

  const columns = [
    { Header: "Supplier Name", accessor: "supplierName" },
    { Header: "Email", accessor: "supplierEmail" },
    { Header: "Phone Number", accessor: "supplierPhoneNumber" },
    { Header: "Portal ID", accessor: "portalID" },
    {
      Header: "Created At",
      Cell: ({ row }) => (
        <span>{new Date(row.createdAt.toDate()).toLocaleDateString()}</span>
      ),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <Button
          onClick={() => {

            deleteSupplier(row.id).then((res) => {
              console.log(res);
              fetchSuppliers(manufacturer.id).then((data) => {
                setTeamData(data);
                setIsLoading(false);
              });
              toast.success("Supplier deleted successfully");
            }
            );

          }}
          className="bg-gray-500 hover:bg-gray-600 h-8 p-2 rounded text-white text-xs ml-2"
        >
          Delete
        </Button>
      ),
    }
  ];
  

  const handleNextPage = () => {
    if (!isLastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    checkIsLastPage();
  }, [teamData, currentPage]);

  const checkIsLastPage = () => {
    setIsLastPage((currentPage + 1) * rowsPerPage >= teamData.length);
  };

  const paginatedData = teamData.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  const handleCreateSupplier = () => {
    // Handle supplier creation logic here

    createSupplier({
      supplierName:supplierName,
      supplierPhoneNumber:supplierPhone,
      supplierEmail:supplierEmail,
      creator:manufacturer.id,
      isStore:false
    }).then((res) => {
      if(!res){
        toast.error("Please fill the all fields and try again");
        return;
      }
      console.log(res);
      fetchSuppliers(manufacturer.id).then((data) => {
        setTeamData(data);
        setIsLoading(false);
      });
      toast.success("Supplier created successfully");
    }).finally(()=>{
          // Close the modal after creation
        setShowModal(false);
        // Reset form fields
        setSupplierName("");
        setSupplierPhone("");
        setSupplierEmail("");

    })





  };

  return (
    <>
      <div className="flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">Suppliers</h1>
          <div className="flex mt-2 flex-col">
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 w-32 p-2 rounded text-white text-xs"
              onClick={() => setShowModal(true)}
            >
              Create Supplier
            </button>
          </div>
        </div>
      </div>

      {/* Modal for Creating Supplier */}
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header className="p-2">Create Supplier</Modal.Header>
        <Modal.Body>
          <div className="space-y-4">
            <div>
              <Label htmlFor="supplierName">Supplier Name</Label>
              <TextInput
                id="supplierName"
                placeholder="Enter supplier name"
                value={supplierName}
                onChange={(e) => setSupplierName(e.target.value)}
                required
              />
            </div>
            <div>
              <Label htmlFor="supplierPhone">Phone Number</Label>
              <TextInput
                id="supplierPhone"
                placeholder="Enter phone number"
                value={supplierPhone}
                onChange={(e) => setSupplierPhone(e.target.value)}
                required
              />
            </div>
            <div>
              <Label htmlFor="supplierEmail">Email</Label>
              <TextInput
                id="supplierEmail"
                type="email"
                placeholder="Enter email address"
                value={supplierEmail}
                onChange={(e) => setSupplierEmail(e.target.value)}
                required
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={handleCreateSupplier}>Submit</Button>
          <Button color="gray" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center mt-8">
        <div className="w-full overflow-x-auto">
          <div className="table-container">
            <div className="w-full mx-auto">
              <TableComponent
                columns={columns}
                data={paginatedData}
                isLoading={isLoading}
                SkeletonComponent={
                  <TableSkeleton columns={columns.length} rows={8} />
                }
              />
              <div className="flex justify-between mt-4">
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 0}
                >
                  Previous
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                  onClick={handleNextPage}
                  disabled={isLastPage}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TableSkeleton = ({ columns, rows }) =>
  Array.from({ length: rows }).map((_, rowIndex) => (
    <tr key={rowIndex}>
      {Array.from({ length: columns }).map((_, colIndex) => (
        <td className="p-4" key={colIndex}>
          <Skeleton width={125} count={1} />
        </td>
      ))}
    </tr>
  ));

export default Suppliers;