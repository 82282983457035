import React, { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../utils/firebase';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'CAD',
  });

function Statistics({stats}) {
    const [statistic, setStatistic] = useState([
      { name: 'Total Orders', stat: stats.orderCount },
      { name: 'Avarage Monthly Sale by Seller', stat: (stats.totalAmount / 6) / 8},
      { name: 'Average Order Value', stat: stats.totalAmount },
    ])
    return (
      <div >
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Total Orders</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{stats.orderCount}</dd>
            </div>
       


            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Avg. Monthly Sales by Seller</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{formatter.format((stats.totalAmount / 6) / 10)}</dd>
            </div>


            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Total Gross Sale</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{formatter.format(stats.totalAmount)}</dd>
            </div>
        </dl>
      </div>
    )
  }

const OrderStats = () => {
    const [stats, setStats] = useState({ totalAmount: 0, orderCount: 0, /* other custom data */ });

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, 'metadata', 'orderStats'), (doc) => {
            if (doc.exists()) {
                setStats(doc.data());
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <div className='w-full flex justify-center'>
            <div>
            <h1 className='text-center font-bold text-3xl sm:pt-16'>Order Statistics</h1>
            {/* <p>Total Orders: {stats.orderCount}</p>
            <p>Total Amount: {stats.totalAmount}</p> */}
  
            <Statistics  stats={stats}/>
     
             
            </div>

        </div>
    );
};

export default OrderStats;
