import React from "react";
import { Table } from "flowbite-react";
import Skeleton from "react-loading-skeleton";

const TableComponent = ({ columns, isLoading, data }) => {
  return (
    <Table className="mb-4 border" hoverable>
      <Table.Head>
        {columns.map((column, index) => (
          <Table.HeadCell
            key={index}
            className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width p-4"
          >
            <a className="flex">
              {column.Header}
              {column.HeaderIcon && column.HeaderIcon}
            </a>
          </Table.HeadCell>
        ))}
      </Table.Head>
      <Table.Body className="divide-y">
        {isLoading ? (
          Array.from({ length: 8 }).map((_, index) => (
            <Table.Row key={index}>
              {columns.map((column, colIndex) => (
                <Table.Cell className="p-7" key={colIndex}>
                  <Skeleton width={125} count={1} />
                </Table.Cell>
              ))}
            </Table.Row>
          ))
        ) : (
          <>
            {data.length > 0 ? (
              data.map((rowData, rowIndex) => (
                <Table.Row className={"bg-white"} key={rowIndex}>
                  {columns.map((column, colIndex) => (
                    <Table.Cell className="p-4" key={colIndex}>
                      {column.Cell ? (
                        column.Cell({ row: rowData })
                      ) : (
                        rowData[column.accessor]
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={columns.length} className="p-4">
                  No data found
                </Table.Cell>
              </Table.Row>
            )}
          </>
        )}
      </Table.Body>
    </Table>
  );
};

export default TableComponent;