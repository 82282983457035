"use client";

import React, { useEffect, useState, Fragment } from "react";
import {
  Disclosure,
  Dialog,
  Transition,
} from "@headlessui/react";
import {
  FunnelIcon,
  ShoppingBagIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import {
  collection,
  query,
  where,
  limit,
  startAfter,
  getDocs,
  orderBy,
  startAt,
  doc,
  endAt,
  addDoc,
  serverTimestamp,
  endBefore,
  limitToLast,
} from "firebase/firestore";
import { db } from "../utils/firebase"; // Ensure you have your Firebase config imported
import { getPriceListByRef,getWholesaleProfileBySlug } from "../utils/globalFunctions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Product Component
const Product = ({ product, manufacturer, addToCart }) => {
  const [price, setPrice] = useState(0);
  const [priceMap, setPriceMap] = useState({});
  const [lengthWhole, setLengthWhole] = useState("1");
  const [lengthFraction, setLengthFraction] = useState("");
  const [widthWhole, setWidthWhole] = useState("1");
  const [isWidthDisabled, setIsWidthDisabled] = useState(false);
  const [isHeightDisabled, setIsHeightDisabled] = useState(false);
  const [widthFraction, setWidthFraction] = useState("");
  const [heightWhole, setHeightWhole] = useState("1");
  const [heightFraction, setHeightFraction] = useState("");
  const [quantity, setQuantity] = useState("1");

  useEffect(() => {
    if (!product.wholesaleMetaData.isPriceList) {
      console.log(product.material_name);
      console.log(product);
      if(product.unitType === "AREA"){
        if(product.wholesaleMetaData.isLimited){
          let minW = product.wholesaleMetaData.minWidth;
          let maxW = product.wholesaleMetaData.maxWidth;
          if(minW == maxW){
            setWidthWhole(maxW);
            setIsWidthDisabled(true);
          }
        }
      }

    } else {
      // Get the price list for the product
      getPriceListByRef(product.wholesaleMetaData.priceList).then(
        (priceList) => {
          const priceMapData = priceList.priceMap;
          setPriceMap(priceMapData);
          // Calculate the initial price after loading the price map
          calculateItemTotal(priceMapData);
        }
      );
    }
  }, []);

  useEffect(() => {
    // Recalculate price whenever input values change
    calculateItemTotal();
  }, [
    lengthWhole,
    lengthFraction,
    widthWhole,
    widthFraction,
    heightWhole,
    heightFraction,
    quantity,
    priceMap,
  ]);

  const parseMeasurement = (wholeStr, fractionStr) => {
    let whole = parseFloat(wholeStr);
    if (isNaN(whole)) whole = 0;

    let frac = 0;
    if (fractionStr) {
      const fractionParts = fractionStr.trim().split("/");
      if (fractionParts.length === 2) {
        const numerator = parseFloat(fractionParts[0]);
        const denominator = parseFloat(fractionParts[1]);
        if (!isNaN(numerator) && !isNaN(denominator) && denominator !== 0) {
          frac = numerator / denominator;
        }
      }
    }

    return whole + frac;
  };

  const calculateItemTotal = () => {
    const type = product.unitType;
    let calculatedPrice = 0;
    let quantityInt = parseInt(quantity);
    if (isNaN(quantityInt) || quantityInt <= 0) {
      quantityInt = 1;
    }

    if (
      product.wholesaleMetaData.isPriceList &&
      Object.keys(priceMap).length === 0
    ) {
      // Price map is not yet loaded
      return;
    }

    if (type === "AREA") {
      let width = 0;
      let height = 0;

      if (product.wholesaleMetaData.metricType === "cm") {
        width = parseFloat(widthWhole);
        height = parseFloat(heightWhole);


      } else {
        width = parseMeasurement(widthWhole, widthFraction);
        height = parseMeasurement(heightWhole, heightFraction);
        if(width === 0 || height === 0){
          width = 1;
          height = 1;
        }
      }

      if (isNaN(width) || isNaN(height)) {
        setPrice(0);
        return;
      }

      // Find the closest available widths and heights in priceMap
      const keys = Object.keys(priceMap);

      // Extract widths and heights from keys
      const dimensions = keys.map((key) => {
        const [wStr, hStr] = key.split("-");
        return {
          width: parseFloat(wStr),
          height: parseFloat(hStr),
          key: key,
        };
      });

      // Get unique sorted widths and heights
      const availableWidths = [
        ...new Set(dimensions.map((d) => d.width)),
      ].sort((a, b) => a - b);
      const availableHeights = [
        ...new Set(dimensions.map((d) => d.height)),
      ].sort((a, b) => a - b);

      // Find the closest width >= input width
      let selectedWidth = availableWidths.find((w) => w >= width);
      if (selectedWidth === undefined) {
        // Use the largest width
        selectedWidth = availableWidths[availableWidths.length - 1];
      }

      // Find the closest height >= input height
      let selectedHeight = availableHeights.find((h) => h >= height);
      if (selectedHeight === undefined) {
        selectedHeight = availableHeights[availableHeights.length - 1];
      }

      // Find the key with these dimensions
      const selectedDimension = dimensions.find(
        (d) => d.width === selectedWidth && d.height === selectedHeight
      );

      if (selectedDimension) {
        const selectedKey = selectedDimension.key;
        const unitPrice = priceMap[selectedKey];
        calculatedPrice = unitPrice * quantityInt;
        setPrice(calculatedPrice);
      } else {
        // No matching key found
        if(!product.wholesaleMetaData.isPriceList){

          let price = product.wholesaleMetaData.price;
          price = width * height * price * quantityInt;
          setPrice(price);
        } else {
          setPrice(0);
        }
      }
    } else if (type === "LENGTH") {
      let len = 0;
      if (product.wholesaleMetaData.metricType === "cm") {
        len = parseFloat(lengthWhole);
      } else {
        len = parseMeasurement(lengthWhole, lengthFraction);
      }

      if (isNaN(len)) {
        setPrice(0);
        return;
      }

      // Find the closest length >= input length
      const availableLengths = Object.keys(priceMap)
        .map(parseFloat)
        .sort((a, b) => a - b);

      let selectedLength = availableLengths.find((l) => l >= len);
      if (selectedLength === undefined) {
        selectedLength = availableLengths[availableLengths.length - 1];
      }

      const unitPrice = priceMap[selectedLength];
      calculatedPrice = unitPrice * quantityInt;
      setPrice(calculatedPrice);
    } else {
      // For other unitTypes, use fixed price
      const unitPrice = product.wholesaleMetaData.price;
      calculatedPrice = unitPrice * quantityInt;
      setPrice(calculatedPrice);
    }
  };

  const handleAddToCart = () => {
    const quantityInt = parseInt(quantity);

    // Validation
    let errors = [];
    const metaData = product.wholesaleMetaData;

    // Quantity Validation
    if (quantityInt <= 0 || isNaN(quantityInt)) {
      errors.push("Quantity must be greater than zero.");
    } else {
      if (metaData.minQuantity && quantityInt < metaData.minQuantity) {
        errors.push(`Minimum quantity is ${metaData.minQuantity}.`);
      }
      if (metaData.isLimited && metaData.maxQuantity && quantityInt > metaData.maxQuantity) {
        errors.push(`Maximum quantity is ${metaData.maxQuantity}.`);
      }
    }

    if (product.unitType === "AREA") {
      let width =
        product.wholesaleMetaData.metricType === "cm"
          ? parseFloat(widthWhole)
          : parseMeasurement(widthWhole, widthFraction);
      let height =
      product.wholesaleMetaData.metricType === "cm"
          ? parseFloat(heightWhole)
          : parseMeasurement(heightWhole, heightFraction);

      if (width <= 0 || isNaN(width)) {
        errors.push("Width must be greater than zero.");
      } else {
        if (metaData.minWidth && width < metaData.minWidth) {
          errors.push(`Minimum width is ${metaData.minWidth} ${product.wholesaleMetaData.metricType}.`);
        }
        if (metaData.isLimited && metaData.maxWidth && width > metaData.maxWidth) {
          errors.push(`Maximum width is ${metaData.maxWidth} ${product.wholesaleMetaData.metricType}.`);
        }
      }

      if (height <= 0 || isNaN(height)) {
        errors.push("Height must be greater than zero.");
      } else {
        if (metaData.minHeight && height < metaData.minHeight) {
          errors.push(`Minimum height is ${metaData.minHeight} ${product.wholesaleMetaData.metricType}.`);
        }
        if (metaData.isLimited && metaData.maxHeight && height > metaData.maxHeight) {
          errors.push(`Maximum height is ${metaData.maxHeight} ${product.wholesaleMetaData.metricType}.`);
        }
      }
    } else if (product.unitType === "LENGTH") {
      let len =
        product.wholesaleMetaData.metricType === "cm"
          ? parseFloat(lengthWhole)
          : parseMeasurement(lengthWhole, lengthFraction);

      if (len <= 0 || isNaN(len)) {
        errors.push("Length must be greater than zero.");
      } else {
        if (metaData.minLength && len < metaData.minLength) {
          errors.push(`Minimum length is ${metaData.minLength} ${product.wholesaleMetaData.metricType}.`);
        }
        if (metaData.isLimited && metaData.maxLength && len > metaData.maxLength) {
          errors.push(`Maximum length is ${metaData.maxLength} ${product.wholesaleMetaData.metricType}.`);
        }
      }
    }

    if (errors.length > 0) {
      toast.error(errors.join(" "), {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    // Prepare the item
    const item = {
      productId: product.id,
      materialName: product.material_name,
      images: product.images,
      quantity: quantityInt,
      unitType: product.unitType,
      unitPrice: price / quantityInt,
      totalPrice: price,
      measurements: {},
    };

    if (product.unitType === "AREA") {
      item.measurements = {
        width:
          product.wholesaleMetaData.metricType === "cm"
            ? parseFloat(widthWhole)
            : parseMeasurement(widthWhole, widthFraction),
        height:
          product.wholesaleMetaData.metricType === "cm"
            ? parseFloat(heightWhole)
            : parseMeasurement(heightWhole, heightFraction),
          metricType: product.metricType,
      };
    } else if (product.unitType === "LENGTH") {
      item.measurements = {
        length:
          product.wholesaleMetaData.metricType === "cm"
            ? parseFloat(lengthWhole)
            : parseMeasurement(lengthWhole, lengthFraction),
          metricType: product.wholesaleMetaData.metricType,
      };
    }

    addToCart(item);

    // Reset fields after adding to cart
    // setQuantity("1");
    // setWidthWhole("1");
    // setWidthFraction("");
    // setHeightWhole("1");
    // setHeightFraction("");
    // setLengthWhole("1");
    // setLengthFraction("");

    toast.success("Item added to cart!", {
      position: "top-right",
      autoClose: 3000,
    });
  };


    // Generate fraction options from 0 to 15/16
    const fractionOptions = Array.from({ length: 17 }, (_, i) => {
      const numerator = i;
      const denominator = 16;
      const value = `${numerator}/${denominator}`;
      return (
        <option key={i} value={numerator === 0 ? "0" : value}>
          {numerator === 0 ? "0" : value}
        </option>
      );
    });

  return (
    <div className="group relative border-b border-r border-gray-200 p-4 sm:p-6">
      <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-200 group-hover:opacity-75">
        <img
          src={
            product.images && product.images.length > 0
              ? product.images[0]
              : "https://via.placeholder.com/150"
          }
          alt={product.material_name}
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className="pb-4 pt-4 text-center">
        <p>
          <span className="text-xs font-bold text-gray-500">{product.unitType}</span>
        </p>
        <h3 className="text-sm font-medium text-gray-900 pb-4">
          {product.material_name}
        </h3>

        <div className="flex flex-wrap items-center justify-center">
          {product.unitType === "AREA" && (
            <>
             {
              product.wholesaleMetaData.metricType === "cm" && (
                <>
                  <div className="text-xs text-gray-500 flex flex-col ml-1">
                    Width (cm)
                    <input
                      type="number"
                      min="0"
                      value={widthWhole}
                      disabled={isWidthDisabled}
                      onChange={(e) => {
                        setWidthWhole(e.target.value);
                      }}
                      className="w-20 border border-gray-300 rounded text-right"
                    />
                  </div>
                  <div className="text-xs text-gray-500 flex flex-col ml-1">
                    Height (cm)
                    <input
                      type="number"
                      min="0"
                      value={heightWhole}
                      onChange={(e) => {
                        setHeightWhole(e.target.value);
                      }}
                      className="w-20 border border-gray-300 rounded text-right"
                    />
                  </div>
                </>
              )
            }

            {
              product.wholesaleMetaData.metricType === "inch" && (
                <>
                  <div className="text-xs text-gray-500 flex flex-col ml-1">
                    Width (inches)
                    <input
                      type="number"
                      min="0"
                      value={widthWhole}
                      disabled={isWidthDisabled}
                      onChange={(e) => {
                        setWidthWhole(e.target.value);
                      }}
                      placeholder="Whole number"
                      className="w-20 border border-gray-300 rounded text-right"
                    />
                    <select
                      value={widthFraction}
                      onChange={(e) => {
                        setWidthFraction(e.target.value);
                      }}
                      className="w-20 border border-gray-300 rounded mt-1"
                    >
                      {fractionOptions}
                    </select>
                  </div>
                  <div className="text-xs text-gray-500 flex flex-col ml-1">
                    Height (inches)
                    <input
                      type="number"
                      min="0"
                      value={heightWhole}
                      onChange={(e) => {
                        setHeightWhole(e.target.value);
                      }}
                      placeholder="Whole number"
                      className="w-20 border border-gray-300 rounded text-right"
                    />
                    <select
                      value={heightFraction}
                      onChange={(e) => {
                        setHeightFraction(e.target.value);
                      }}
                      className="w-20 border border-gray-300 rounded mt-1"
                    >
                      {fractionOptions}
                    </select>
                  </div>
                </>
              )
            }

            {
              product.wholesaleMetaData.metricType === "ft" && (
                <>
                  <div className="text-xs text-gray-500 flex flex-col ml-1">
                    Width (ft)
                    <input
                      type="number"
                      min="0"
                      value={widthWhole}
                      disabled={isWidthDisabled}
                      onChange={(e) => {
                        setWidthWhole(e.target.value);
                      }}
                      placeholder="Whole number"
                      className="w-20 border border-gray-300 rounded text-right"
                    />
                    <select
                      value={widthFraction}
                      onChange={(e) => {
                        setWidthFraction(e.target.value);
                      }}
                      className="w-20 border border-gray-300 rounded mt-1"
                    >
                      {fractionOptions}
                    </select>
                  </div>
                  <div className="text-xs text-gray-500 flex flex-col ml-1">
                    Height (ft)
                    <input
                      type="number"
                      min="0"
                      value={heightWhole}
                      onChange={(e) => {
                        setHeightWhole(e.target.value);
                      }}
                      placeholder="Whole number"
                      className="w-20 border border-gray-300 rounded text-right"
                    />
                    <select
                      value={heightFraction}
                      onChange={(e) => {
                        setHeightFraction(e.target.value);
                      }}
                      className="w-20 border border-gray-300 rounded mt-1"
                    >
                      {fractionOptions}
                    </select>
                  </div>
                </>
              )
            }
            {
              product.wholesaleMetaData.metricType === "yard" && (
                <>
                  <div className="text-xs text-gray-500 flex flex-col ml-1">
                    Width (yd)
                    <input
                      type="number"
                      min="0"
                      value={widthWhole}
                      disabled={isWidthDisabled}
                      onChange={(e) => {
                        setWidthWhole(e.target.value);
                      }}
                      placeholder="Whole number"
                      className="w-20 border border-gray-300 rounded text-right"
                    />
                    {/* <select
                      value={widthFraction}
                      onChange={(e) => {
                        setWidthFraction(e.target.value);
                      }}
                      className="w-20 border border-gray-300 rounded mt-1"
                    >
                      {fractionOptions}
                    </select> */}
                  </div>
                  <div className="text-xs text-gray-500 flex flex-col ml-1">
                    Height (yd)
                    <input
                      type="number"
                      min="0"
                      value={heightWhole}
                      onChange={(e) => {
                        setHeightWhole(e.target.value);
                      }}
                      placeholder="Whole number"
                      className="w-20 border border-gray-300 rounded text-right"
                    />
                    {/* <select
                      value={heightFraction}
                      onChange={(e) => {
                        setHeightFraction(e.target.value);
                      }}
                      className="w-20 border border-gray-300 rounded mt-1"
                    >
                      {fractionOptions}
                    </select> */}
                  </div>
                </>
              )
            }



            </>
          )}

          {product.unitType === "LENGTH" && (
            <>
              {product.wholesaleMetaData.metricType === "cm" ? (
                <div className="text-xs text-gray-500 flex flex-col ml-1">
                  Length (cm)
                  <input
                    type="number"
                    min="0"
                    value={lengthWhole}
                    onChange={(e) => {
                      setLengthWhole(e.target.value);
                    }}
                    className="w-20 border border-gray-300 rounded text-right"
                  />
                </div>
              ) : (
                <div className="text-xs text-gray-500 flex flex-col ml-1">
                  Length ({product.wholesaleMetaData.metricType})
                  <input
                    type="number"
                    min="0"
                    value={lengthWhole}
                    onChange={(e) => {
                      setLengthWhole(e.target.value);
                    }}
                    placeholder="Whole number"
                    className="w-20 border border-gray-300 rounded text-right"
                  />
                  <select
                    value={lengthFraction}
                    onChange={(e) => {
                      setLengthFraction(e.target.value);
                    }}
                    className="w-20 border border-gray-300 rounded mt-1"
                  >
                    {fractionOptions}
                  </select>
                </div>
              )}
            </>
          )}
          <div className="text-xs text-gray-500 flex flex-col ml-1">
            Quantity
            <input
              type="number"
              min="1"
              value={quantity}
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
              className="w-20 border border-gray-300 rounded text-right"
            />
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <span className="text-xs text-gray-500">Price</span>
          <p className="text-xs font-bold">CAD {price.toFixed(2)}</p>
        </div>
        <div className="flex flex-col mt-4">
          <p className="text-xs font-bold text-gray-600">{product?.description} </p>
        </div>
        <button
          onClick={handleAddToCart}
          className="mt-4 bg-gray-800 text-white text-xs font-bold py-2 px-4 rounded"
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
};



const PAGE_SIZE = 10; // Number of products per page



export default function WholesalePortal() {
  const [profile, setProfile] = useState(null);
  const [manufacturer, setManufacturer] = useState(null);
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [isCartOpen, setIsCartOpen] = useState(false); // For cart modal
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isNextPageAvailable, setIsNextPageAvailable] = useState(false);
  const [isPreviousPageAvailable, setIsPreviousPageAvailable] = useState(false);
  const [pagesStack, setPagesStack] = useState([]); // To keep track of page cursors
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [buyerDiscounts, setBuyerDiscounts] = useState([]);
  const [email, setEmail] = useState("");
  // Get the slug from the URL
  const slug = window.location.pathname.split("/")[2];

  useEffect(() => {
    getWholesaleProfileBySlug(slug).then((manufacturerData) => {
      setProfile(manufacturerData.wholesaleProfile);
      setManufacturer(manufacturerData);
      const categoryOptions = manufacturerData.materialTypes;
      setCategories(categoryOptions);

      // Fetch initial products
      fetchProducts(manufacturerData.id, "initial");
    });
  }, []);

  useEffect(() => {

    if(!manufacturer?.id)
      return;

    setCurrentPage(1);
    setPagesStack([]);
    fetchProducts(manufacturer.id, "initial");



  }, [selectedCategories]);

  const fetchProducts = async (manufacturerID, direction = "next") => {
    try {
      let productsRef = collection(db, "Materials");

      // Base query with orderBy and manufacturer filter
      let q = query(
        productsRef,
        where("manufacturerID", "==", manufacturerID),
        where("wholesaleEnabled", "==", true),
        where("isDeleted", "==", false),
        orderBy("material_name_lowercase")
      );

      // Apply category filters if any
      if (selectedCategories.length > 0 && !searchTerm) {
        q = query(q, where("materialCategory", "in", selectedCategories));
      }

      // Apply search filter if any
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        q = query(q, startAt(searchLower), endAt(searchLower + "\uf8ff"));
      }

      // Apply pagination
      if (direction === "next" && pagesStack.length > 0) {
        const lastVisible = pagesStack[pagesStack.length - 1].lastVisible;
        q = query(q, startAfter(lastVisible), limit(PAGE_SIZE + 1));
      } else if (direction === "prev" && pagesStack.length > 1) {
        const firstVisible = pagesStack[pagesStack.length - 1].firstVisible;
        q = query(q, endBefore(firstVisible), limitToLast(PAGE_SIZE + 1));
      } else {
        // Initial load
        q = query(q, limit(PAGE_SIZE + 1));
      }

      const querySnapshot = await getDocs(q);
      let fetchedProducts = [];
      querySnapshot.forEach((doc) => {
        fetchedProducts.push({ id: doc.id, ...doc.data() });
      });

      // Determine if there's a next or previous page
      let hasMorePages = fetchedProducts.length > PAGE_SIZE;
      if (hasMorePages) {
        // Remove the extra document used to check for more pages
        if (direction === "next") {
          fetchedProducts.pop(); // Remove last item
        } else if (direction === "prev") {
          fetchedProducts.shift(); // Remove first item
        }
      }

      if (fetchedProducts.length > 0) {
        const firstDoc = querySnapshot.docs[0];
        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        if (direction === "next") {
          setPagesStack((prevStack) => [
            ...prevStack,
            { firstVisible: firstDoc, lastVisible: lastDoc },
          ]);
          setCurrentPage((prevPage) => prevPage + 1);
        } else if (direction === "prev") {
          setPagesStack((prevStack) => {
            const newStack = [...prevStack];
            newStack.pop(); // Remove the current page cursors
            return newStack;
          });
          setCurrentPage((prevPage) => prevPage - 1);
        } else {
          // Initial load or filters/search changed
          setPagesStack([{ firstVisible: firstDoc, lastVisible: lastDoc }]);
          setCurrentPage(1);
        }

        setProducts(fetchedProducts);
        setIsNextPageAvailable(hasMorePages);
        setIsPreviousPageAvailable(currentPage > 1 || direction === "prev");
      } else {
        // No products found
        setProducts([]);
        setIsNextPageAvailable(false);
        setIsPreviousPageAvailable(currentPage > 1 || direction === "prev");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Update isPreviousPageAvailable whenever currentPage changes
  useEffect(() => {
    setIsPreviousPageAvailable(currentPage > 1);
  }, [currentPage]);

  const handleSearch = () => {
    setCurrentPage(1);
    setPagesStack([]);
    fetchProducts(manufacturer.id, "initial");
  };

  const handleNextPage = () => {
    if (isNextPageAvailable) {
      fetchProducts(manufacturer.id, "next");
    }
  };

  const handlePreviousPage = () => {
    if (isPreviousPageAvailable) {
      fetchProducts(manufacturer.id, "prev");
    }
  };

  const addToCart = (item) => {
    setCartItems((prevItems) => [...prevItems, item]);
    setOrderTotal((prevTotal) => prevTotal + item.totalPrice);
  };

  const handleCategoryChange = (value) => {
    let updatedSelectedCategories = [...selectedCategories];

    if (updatedSelectedCategories.includes(value)) {
      updatedSelectedCategories = updatedSelectedCategories.filter(
        (category) => category !== value
      );
    } else {
      updatedSelectedCategories.push(value);
    }

    setSelectedCategories(updatedSelectedCategories);

    // Reset pagination and fetch products with new filters
    setCurrentPage(1);
    setPagesStack([]);

  };

  const getBuyerProfile = async(manufacturerID) => {

    let q = query(
      collection(db, "WholesaleBuyers"),
      where("email", "==", email),
      where("invitedBy", "==", manufacturerID), // can be only invited by a manufacturer for now (no public registration)
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    let buyerProfile = null;
    querySnapshot.forEach((doc) => {
      buyerProfile = { id: doc.id, ...doc.data() };
    })
    return buyerProfile;
  }

  const handleCheckout = async () => {
    if (!email) {
      toast.error("Please enter your email to proceed.");
      return;
    }
  
    const buyerProfile = await getBuyerProfile(manufacturer.id);
  
    if (buyerProfile) {
      // Get the subcollection of buyer profile Discounts
      const buyerDiscountsRef = collection(
        db,
        "WholesaleBuyers",
        buyerProfile.id,
        "Discounts"
      );
  
      // Adjusted query to use only one inequality filter
      const buyerDiscountsQuery = query(
        buyerDiscountsRef,
        where("startDate", "<=", new Date())
      );
  
      const querySnapshot = await getDocs(buyerDiscountsQuery);
      let discounts = [];

      querySnapshot.forEach((doc) => {
        const discountData = doc.data();
        // Check if discount is valid based on endDate
        const now = new Date();
        if (discountData.endDate.toDate() >= now) {
          discounts.push({
            id: discountData.id,
            type: discountData.type,
            value: discountData.value,
          });
        }
      });
      console.log(discounts)
      setBuyerDiscounts(discounts);
  
      console.log(cartItems)
      // Update the cart items with the buyer discounts
      const updatedCartItems = cartItems.map((item) => {
        const discount = discounts.find(
          (discount) => discount.id === item.productId
        );
        if (discount) {
          let discountedPrice = item.totalPrice;
          if (discount.type === "percentage") {
            discountedPrice =
              item.totalPrice * (1 - parseFloat(discount.value) / 100);
          } else if (discount.type === "fixed") {
            discountedPrice = item.totalPrice - parseFloat(discount.value);
          }
          return {
            ...item,
            discountApplied: true,
            discountedPrice: discountedPrice,
            discountValue: discount.value,
            discountType: discount.type,
          };
        } else {
          return item;
        }
      });
  
      // Calculate the new order total, some items may have discounts applied, some has not
      const discountedOrderTotal = updatedCartItems.reduce(
        (acc, item) => acc + (item.discountedPrice || item.totalPrice),
        0
      );
      // Update the cart items and order total
      setCartItems(updatedCartItems);
      setOrderTotal(discountedOrderTotal);
      setIsCheckOut(true);
    } else {
      // Buyer profile does not exist
      toast.error("Buyer profile not found. Please check the email address.");
      setIsCheckOut(false);
    }
  };


  console.log(cartItems)


  useEffect(()=>{
    if(isCheckOut){
     // get the  wholesale buyer profile

 
     handleCheckout()


    }

  },[isCheckOut])



  const handleProcessOrder = async () => {
    if(profile === null){
      toast.error("Please select a manufacturer to place an order.", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }
    let buyerProfile = await getBuyerProfile(manufacturer.id);
    // Create a new order document
    const orderData = {
      buyerEmail: buyerProfile.email,
      buyerID: buyerProfile.id,
      manufacturerID: manufacturer.id,
      status: "pending",
      orderTotal: orderTotal,
      items: cartItems,
      createdAt: serverTimestamp(),
    };

    console.log(orderData);
    // Add the order to the Orders collection
    try {
      const orderRef = await addDoc(collection(db, "WholesaleOrders"), orderData);
      console.log("Order added with ID: ", orderRef.id);
      toast.success("Order placed successfully!", {
        position: "top-right",
        autoClose: 5000,
      });
      // Clear the cart
      setCartItems([]);
      setOrderTotal(0);
      setIsCartOpen(false);
      setIsCheckOut(false);
    } catch (error) {
      console.error("Error adding order: ", error);
      toast.error("Error placing order. Please try again.", {
        position: "top-right",
        autoClose: 5000,
      });
    }

  }



  return (
    <div className="bg-white">
      {/* Toast Container */}
      <ToastContainer />

      {/* Main Content */}
      <main className="pb-24">
        <div className="px-4 py-16 text-center sm:px-6 lg:px-8">
          <div className="flex w-full justify-center">
            <img
              src={profile?.logo}
              alt={profile?.name}
              className="h-12 mb-4"
            />
          </div>
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            {profile?.name}
          </h1>
          <p className="mt-4 text-lg text-gray-600">{profile?.description}</p>
        </div>

        {/* Search Bar */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-6">
          <div className="flex">
            <input
              type="text"
              placeholder="Search by material name"
              className="w-full border border-gray-300 rounded-l px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              onClick={handleSearch}
              className="bg-indigo-600 text-white px-4 py-2 rounded-r hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Search
            </button>
          </div>
        </div>

        {/* Filters */}
        <Disclosure
          as="section"
          aria-labelledby="filter-heading"
          className="grid items-center border-b border-t border-gray-200"
        >
          <h2 id="filter-heading" className="sr-only">
            Filters
          </h2>
          <div className="relative col-start-1 row-start-1 py-4">
            <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
              <div>
                <Disclosure.Button className="group flex items-center font-medium text-gray-700">
                  <FunnelIcon
                    className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Category Filters
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="border-t border-gray-200 py-10">
            <div className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
              <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                {/* Category Filter */}
                <fieldset>
                  <legend className="block font-medium">Categories</legend>
                  <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                    {categories.map((category, idx) => (
                      <div
                        key={category}
                        className="flex items-center text-base sm:text-sm"
                      >
                        <input
                          id={`category-${idx}`}
                          name="category[]"
                          value={category}
                          checked={selectedCategories.includes(category)}
                          type="checkbox"
                          onChange={() => handleCategoryChange(category)}
                          className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          htmlFor={`category-${idx}`}
                          className="ml-3 min-w-0 flex-1 text-gray-600"
                        >
                          {category}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            </div>
          </Disclosure.Panel>
        </Disclosure>

        {/* Product grid */}
        <section
          aria-labelledby="products-heading"
          className="mx-auto max-w-7xl overflow-hidden sm:px-6 lg:px-8"
        >
          <h2 id="products-heading" className="sr-only">
            Products
          </h2>

          <div className="-mx-px grid grid-cols-2 border-l border-gray-200 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
            {products.map((product) => (
              <Product
                key={product.id}
                product={product}
                manufacturer={manufacturer}
                addToCart={addToCart}
              />
            ))}
          </div>
        </section>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6">
          <button
            onClick={handlePreviousPage}
            disabled={!isPreviousPageAvailable}
            className={`px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 ${
              !isPreviousPageAvailable ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <ChevronLeftIcon className="h-5 w-5 inline" /> Previous
          </button>
          <span>Page {currentPage}</span>
          <button
            onClick={handleNextPage}
            disabled={!isNextPageAvailable}
            className={`px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 ${
              !isNextPageAvailable ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Next <ChevronRightIcon className="h-5 w-5 inline" />
          </button>
        </div>

        {/* Floating Cart Button */}
        <div className="fixed bottom-4 right-4">
          <button
            onClick={() => setIsCartOpen(true)}
            className="bg-gray-800 text-white p-3 rounded-full flex items-center"
          >
            <ShoppingBagIcon className="h-6 w-6" aria-hidden="true" />
            <span className="ml-2">{cartItems.length}</span>
          </button>
        </div>

        {/* Cart Modal */}
        <Transition appear show={isCartOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => setIsCartOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-50" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-90"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-90"
                >
                  <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Your Cart
                    </Dialog.Title>
                    <div className="mt-4">
                      {cartItems.length === 0 ? (
                        <p>Your cart is empty.</p>
                      ) : (
                        <div>
                          <ul>
                            {cartItems.map((item, index) => (
                              <li key={index} className="border-b py-2">
                                <div className="flex items-center">
                                  <img
                                    src={
                                      item.images && item.images.length > 0
                                        ? item.images[0]
                                        : "https://via.placeholder.com/150"
                                    }
                                    alt={item.materialName}
                                    className="h-12 w-12 object-cover mr-4"
                                  />
                                  <div>
                                    <strong>{item.materialName}</strong>
                                    <div>Quantity: {item.quantity}</div>
                                    
                                    <div>
                                    Price:{item.discountApplied ?<a><a className="line-through"> CAD {item.totalPrice.toFixed(2)}</a>  CAD {item.discountedPrice.toFixed(2)}</a> : <strong>CAD {item.totalPrice.toFixed(2)}</strong>}
                                    </div>
                                    {item.unitType === "AREA" && (
                                      <div>
                                        Measurements: Width -{" "}
                                        {item.measurements.width}{" "}
                                        {item.measurements.metricType}, Height
                                        - {item.measurements.height}{" "}
                                        {item.measurements.metricType}
                                      </div>
                                    )}
                                    {item.unitType === "LENGTH" && (
                                      <div>
                                        Measurements: Length -{" "}
                                        {item.measurements.length}{" "}
                                        {item.measurements.metricType}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <div className="mt-4 text-right">
                            <strong>
                              Order Total: CAD {orderTotal.toFixed(2)}
                            </strong>
                          </div>
                        </div>
                      )}
                    </div>
                    <input
                          type="text"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="w-full mt-8 border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm"
                    />
                    {
                      isCheckOut &&
                      <div>
          
                        <button
                          type="button"
                          onClick={() => {
                            handleProcessOrder()

                          }}
                          className="mt-4 inline-flex justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-sm font-medium text-white hover:bg-gray-700"
                        >
                          Process Order
                        </button>
                      </div>
                    }
                    {
                         !isCheckOut &&
                      <div className="mt-4">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-sm font-medium text-white hover:bg-gray-700"
                        onClick={() => {setIsCartOpen(false);setIsCheckOut(false)}}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-sm font-medium text-white hover:bg-gray-700"
                        onClick={() => {
                          if(!email){
                            toast.error("Please enter your email to proceed.")
                            return
                          }
                          setIsCheckOut(true)
                        }}
                      >
                        Continue Checkout
                      </button>
                    </div>
                    }
               
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </main>
    </div>
  );
}

