import { Disclosure, Menu, Transition, Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { WhiteLogo, BlackLogo } from './Logo'
import { handleSignOut } from '../utils/helperFunctions'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar() {
  const navigate = useNavigate()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const navigation = [
    {
      name: 'Home',
      onClick: () => {
        navigate('/')
        setMobileMenuOpen(false)
      },
      desktopClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-home-desktop',
      mobileClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-home-mobile'
    },
    {
      name: 'Orders',
      onClick: () => {
        navigate('/orders')
        setMobileMenuOpen(false)
      },
      desktopClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-orders-desktop',
      mobileClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-orders-mobile'
    },
    {
      name: 'Extensions',
      onClick: () => {
        navigate('/extensions')
        setMobileMenuOpen(false)
      },
      desktopClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-extensions-desktop',
      mobileClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-extensions-mobile'
    },
    {
      name: 'Customers',
      onClick: () => {
        navigate('/customers')
        setMobileMenuOpen(false)
      },
      desktopClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-customers-desktop',
      mobileClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-customers-mobile'
    },
    {
      name: 'Inventory',
      onClick: () => {
        navigate('/store-products')
        setMobileMenuOpen(false)
      },
      desktopClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-inventory-desktop',
      mobileClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-inventory-mobile'
    },
    {
      name: 'Settings',
      onClick: () => {
        navigate('/settings')
        setMobileMenuOpen(false)
      },
      desktopClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-settings-desktop',
      mobileClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-settings-mobile'
    },
    {
      name: 'Logout',
      onClick: () => handleSignOut(),
      desktopClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-logout-desktop',
      mobileClassName: 'text-gray-900 hover:text-gray-700 cursor-pointer store-logout-mobile'
    }
  ]

  return (
    <header className="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10">
      <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
        <div className="flex flex-1 items-center gap-x-6">
          <button
            type="button"
            className="-m-3 p-3 lg:hidden menu-button"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-5 w-5 text-gray-900" aria-hidden="true" />
          </button>
          <BlackLogo width={150} />
        </div>
        <nav className="hidden lg:flex lg:gap-x-11 lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-700">
          {navigation.map((item, itemIdx) => (
            <a
              key={itemIdx}
              onClick={item.onClick}
              className={item.desktopClassName}
            >
              {item.name}
            </a>
          ))}
        </nav>
        <div className="flex flex-1 items-center justify-end gap-x-8">
          <a
            className="text-sm font-semibold leading-6 text-gray-900"
            data-canny-link
            href="https://supply-circle.canny.io"
          >
            Give feedback
          </a>
        </div>
      </div>
      <Dialog as="div" className="lg:hidden custom-menu" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
          <div className="-ml-0.5 flex h-16 items-center gap-x-6">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-2 space-y-2">
            {navigation.map((item) => (
              <a
                key={item.name}
                onClick={item.onClick}
                className={
                  '-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ' +
                  item.mobileClassName
                }
              >
                {item.name}
              </a>
            ))}
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}