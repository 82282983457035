// src/App.js
import React, { useEffect, useState } from 'react';
import './App.css';
import RouteComponent from './pages/Route';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc, query, where, onSnapshot, collection } from 'firebase/firestore';
import { auth, db } from './utils/firebase';
import { useUser } from './utils/context';
import loadingGif from './assets/Loader.gif';
import UpdatesComponent from './components/UpdatesComponent';

function App() {
  const { setUser, user, setUserLoading, userLoading } = useUser();
  const [maintanance, setMaintanance] = useState(false);
  const [newUpdate, setNewUpdate] = useState(null);
  const [isOnBoarding, setIsOnBoarding] = useState(false);

  

  // Handle Maintenance Mode
  useEffect(() => {
    const q = query(collection(db, 'settings'), where('id', '==', 'cachet-blinds-web'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.doc.data().maintanance) {
          setMaintanance(true);
        } else {
          setMaintanance(false);
        }
      });
    });

    return () => unsubscribe();
  }, []);

  // Handle Updates
  useEffect(() => {
    if (!user) return;
    const collectionRef = collection(db, 'updates');

    const unsubscribe = onSnapshot(
      collectionRef,
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          let users = change.doc.data()?.users || [];
          if (!users.includes(user.id)) {
            users.push(user.id);
            setNewUpdate(change.doc.data());
            let ref = doc(db, 'updates', change.doc.id);
            updateDoc(ref, { users: users });
          }
        });
      },
      (error) => {
        console.error(error);
      }
    );

    return () => unsubscribe();
  }, [user]);

  // Retrieve User from Local Storage
  // useEffect(() => {
  //   let usr = localStorage.getItem('user');
  //   if (usr) {
  //     setUser(JSON.parse(usr));
  //   }
  // }, [setUser]);

  // Handle Authentication State Changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        const userDocRef = doc(db, 'users', authUser.uid);
        const userSnap = await getDoc(userDocRef);

        if (!userSnap.exists()) {
          console.log('User document does not exist');
          setUser(null);
          setUserLoading(false);
        } else {
          console.log('User document exists');
          const storeId = userSnap.data().store_id;
          if (!storeId) {
            setUser({
              id: authUser.uid,
              email: authUser.email,
              ...userSnap.data(),
            });
            setIsOnBoarding(true);
            setUserLoading(false);
          } else {
            const storeDocRef = doc(db, 'Stores', storeId);
            const storeSnap = await getDoc(storeDocRef);
            if (!storeSnap.exists()) {
              setUser({
                id: authUser.uid,
                email: authUser.email,
                ...userSnap.data(),
              });
              setIsOnBoarding(true);
              setUserLoading(false);
            } else {
              setUser({
                id: authUser.uid,
                email: authUser.email,
                ...userSnap.data(),
                ...storeSnap.data(),
              });
              setUserLoading(false);
            }
          }
        }
      } else {
        console.log('No user is signed in');
        setUser(null);
        setUserLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  console.log(user);




  // If user is loading, show a loading screen
  if (userLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen w-full">
        <div className="flex flex-col items-center justify-center">
          <img src={loadingGif} alt="loading" className="success-img" />
        </div>
      </div>
    );
  }

  // If maintenance mode is active, show maintenance page
  if (maintanance) {
    return (
      <div className="maintanance flex flex-col items-center h-screen w-full justify-center">
        <div className="p-4">
          <h1 className="text-3xl font-bold text-gray-700">
            We are updating the platform. Thank you for your patience
          </h1>
          <h1 className="text-xl sm:text-center font-bold text-gray-600">
            We will be back soon
          </h1>

          <img
            src="https://cdn.dribbble.com/users/49067/screenshots/3710403/maintenance-panel800600.gif"
            alt="maintenance"
            className="success-img"
          />
        </div>
      </div>
    );
  }

  // // If there's a new update, show the UpdatesComponent
  // if (newUpdate && user) {
  //   return <UpdatesComponent currentUser={user} currentUpdate={newUpdate} setNewUpdate={setNewUpdate} />;
  // }

  // Render the centralized routing component
  return <RouteComponent />;
}

export default App;