import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMemo } from "react";
import {globalAlert} from "../utils/globalFunctions"
import {addressURL} from '../utils/globalFunctions'
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";

export default function ExtrasModal({ open, setOpen, customerID,setNewExtrasAdded ,index}) {

  const [selectedMotor,setSelectedMotor] = useState("novo")
  const handleSave = () => {
    let controller = 0
    let charger = 0
    let hub = 0
    let panel = 0

    if(selectedMotor === "novo"){
      controller = document.getElementById("remoteController").value;
      if (controller === 0 || !controller) {
        globalAlert("Please add at least 1 controller");
        return;
      }
    }else{


      controller = parseInt(document.getElementById("remoteController").value);
      charger = parseInt(document.getElementById("charger").value);
      hub = parseInt(document.getElementById("hub").value);
      panel = parseInt(document.getElementById("panel").value);

      if (!charger) {
        charger = 0;
      }
      if (!hub) {
        hub = 0;
      }
      if (!panel) {
        panel = 0;
      }

      if (controller === 0) {

        globalAlert("Please add at least 1 controller");
        return;
      }

    }


    axios
      .post(
        addressURL + "/api/addExtras",
        {
          customerID,
          motor: selectedMotor,
          controller,
          charger,
          hub,
          panel
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          setOpen(false);
          setNewExtrasAdded(true);
        } else {
          globalAlert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative  transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block ">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex justify-center w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    ></Dialog.Title>
                    <div className="mt-2 ">
                      <p className="text-sm text-gray-500">
                        Please enter amount of extras you want to add.
                      </p>
                    </div>
                    <div>
                      <div>
                        <label
                          htmlFor="remoteController"
                          className="w-full text-sm font-medium text-gray-700"
                        >
                          Motor Brand
                        </label>
                        <div className="flex w-full justify-center">
                          <select
                            id="motor"
                            name="motor"
                            className="block w-full rounded-full border-gray-300 px-4 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            defaultValue="novo"
                            onChange={
                              (e)=>{
                                setSelectedMotor(e.target.value)
                              }
                            }
                          >
                            <option value="novo">Novo Motor</option>
                            <option value="louvolite">Louvolite Motor</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div>
                      {
                        selectedMotor === "novo" &&
                        <div>
                        <label
                          htmlFor="remoteController"
                          className="w-full text-sm font-medium text-gray-700"
                        >
                         # of Remote Controller
                        </label>
                        <div className="flex w-full justify-center">
                          <input
                            type="text"
                            name="remoteController"
                            id="remoteController"
                            className="block w-full rounded-full border-gray-300 px-4 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            placeholder="1"
                          />
                        </div>
                       
                
                      </div>
                      }
                      {
                        selectedMotor === "louvolite" &&
                        <div>
                              <label
                          htmlFor="remoteController"
                          className="w-full text-sm font-medium text-gray-700"
                        >
                         # of Remote Controller
                        </label>
                        <div className="flex w-full justify-center">
                          <input
                            type="text"
                            name="remoteController"
                            id="remoteController"
                            className="block w-full rounded-full border-gray-300 px-4 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            placeholder="1"
                          />
                        </div>
                        <label
                          htmlFor="charger"
                          className="w-full text-sm font-medium text-gray-700"
                        >
                         # of controller Charger
                        </label>
                        <div className="flex w-full justify-center">
                          <input
                            type="text"
                            name="charger"
                            id="charger"
                            className="block w-full rounded-full border-gray-300 px-4 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            placeholder="0"
                          />
                        </div>
                        <label
                          htmlFor="hub"
                          className="w-full text-sm font-medium text-gray-700"
                        >
                         # of controller hub
                        </label>
                        <div className="flex w-full justify-center">
                          <input
                            type="text"
                            name="hub"
                            id="hub"
                            className="block w-full rounded-full border-gray-300 px-4 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            placeholder="0"
                          />
                        </div>
                        <label
                          htmlFor="panel"
                          className="w-full text-sm font-medium text-gray-700"
                        >
                         # of Solar Panel
                        </label>
                        <div className="flex w-full justify-center">
                          <input
                            type="text"
                            name="panel"
                            id="panel"
                            className="block w-full rounded-full border-gray-300 px-4 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            placeholder="0"
                          />
                        </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
