import React, { useState, useEffect } from "react";
import { db } from "../../utils/firebase"; // Your firebase config file
import { collection, addDoc } from "firebase/firestore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill editor styles

const AdminEditor = () => {
  const [title, setTitle] = useState("");
  const [contentHTML, setContentHTML] = useState(""); // HTML content
  const [version, setVersion] = useState("");
  const [isAnnouncement, setIsAnnouncement] = useState(false);
  const [users, setUsers] = useState(""); // list of viewed user IDs
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [templates, setTemplates] = useState([
    `
    <div style="font-family: 'Arial', sans-serif; background-color: #f4f4f4; padding: 40px;">
  <div style="max-width: 800px; margin: 0 auto; background-color: #ffffff; padding: 30px; border-radius: 10px; box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);">
    <!-- Header Section -->
    <header style="text-align: center; margin-bottom: 40px;">
      <h1 style="color: #2B6CB0; font-size: 2.5rem; font-weight: bold; margin-bottom: 10px;">Exciting New Feature!</h1>
      <h2 style="color: #718096; font-size: 1.5rem;">We've got something special for you</h2>
    </header>

    <!-- Main Content -->
    <section style="color: #4A5568; line-height: 1.8;">
      <p style="font-size: 1.1rem; margin-bottom: 20px;">
        We're thrilled to introduce a brand new feature designed to improve your experience. With this new feature, you'll be able to:
      </p>
      <ul style="list-style-type: disc; padding-left: 20px; margin-bottom: 20px;">
        <li style="margin-bottom: 10px;">Feature 1: [Describe the feature in detail]</li>
        <li style="margin-bottom: 10px;">Feature 2: [Explain the benefit]</li>
        <li style="margin-bottom: 10px;">Feature 3: [How this helps the user]</li>
      </ul>
      <p style="font-size: 1.1rem;">
        Our team has been working hard to make this happen, and we can't wait for you to try it out. Be sure to let us know what you think!
      </p>
    </section>
    <!-- Footer -->
    <footer style="text-align: center; margin-top: 20px; color: #A0AEC0; font-size: 0.9rem;">
      <p>If you have any questions, <a href="/contact-us" style="color: #3182CE;">contact our support team</a>.</p>
    </footer>
  </div>
</div>
    `,
    `
    <div style="font-family: 'Arial', sans-serif; background-color: #f0f4f8; padding: 40px;">
  <div style="max-width: 800px; margin: 0 auto; background-color: #ffffff; padding: 30px; border-radius: 10px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
    <!-- Header Section -->
    <header style="text-align: center; margin-bottom: 30px;">
      <h1 style="color: #38B2AC; font-size: 2.5rem; font-weight: bold;">Bug Fixes & Improvements</h1>
      <p style="color: #4A5568; font-size: 1.2rem;">Our latest update includes key fixes and enhancements</p>
    </header>

    <!-- Main Content -->
    <section style="color: #4A5568; line-height: 1.8;">
      <h3 style="color: #2D3748; font-size: 1.25rem; font-weight: bold;">Fixed Issues:</h3>
      <ul style="list-style-type: disc; padding-left: 20px; margin-bottom: 20px;">
        <li>Issue 1: [Description of the bug and how it was fixed]</li>
        <li>Issue 2: [Explanation of what has been resolved]</li>
        <li>Issue 3: [Brief detail on any performance improvements]</li>
      </ul>
      <h3 style="color: #2D3748; font-size: 1.25rem; font-weight: bold;">Enhancements:</h3>
      <ul style="list-style-type: disc; padding-left: 20px; margin-bottom: 20px;">
        <li>Improvement 1: [What was enhanced or improved]</li>
        <li>Improvement 2: [Additional benefits users can expect]</li>
        <li>Improvement 3: [Details on system or UX enhancements]</li>
      </ul>
      <p>
        We’re always looking to improve and appreciate your feedback. Thank you for being a part of our journey!
      </p>
    </section>

    <!-- Call to Action -->
    <div style="text-align: center; margin: 30px 0;">
      <a href="#" style="display: inline-block; background-color: #38B2AC; color: white; padding: 12px 25px; text-decoration: none; border-radius: 5px; font-size: 1.2rem;">See What's New</a>
    </div>

    <!-- Footer -->
    <footer style="text-align: center; margin-top: 20px; color: #718096; font-size: 0.9rem;">
      <p>For more details, feel free to <a href="#" style="color: #38B2AC;">reach out to support</a>.</p>
    </footer>
  </div>
</div>
    `,
    `
    <div style="font-family: 'Arial', sans-serif; background-color: #f7fafc; padding: 40px;">
  <div style="max-width: 800px; margin: 0 auto; background-color: #ffffff; padding: 40px; border-radius: 10px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
    <!-- Header Section -->
    <header style="text-align: center; margin-bottom: 40px;">
      <h1 style="color: #1A202C; font-size: 2.5rem; font-weight: bold;">Monthly Newsletter</h1>
      <p style="color: #718096; font-size: 1.2rem;">Catch up on the latest updates, features, and more!</p>
    </header>

    <!-- Main Content -->
    <section style="color: #4A5568; line-height: 1.8;">
      <h3 style="color: #2D3748; font-size: 1.25rem; font-weight: bold;">Highlights This Month:</h3>
      <ul style="list-style-type: circle; padding-left: 20px; margin-bottom: 20px;">
        <li>New Features: [Details on new product or service features]</li>
        <li>Upcoming Events: [Info about upcoming events, webinars, or launches]</li>
        <li>Behind the Scenes: [What’s been happening in the company]</li>
      </ul>
      <p>
        We’re constantly working on improving our platform and bringing you the best experience possible. This month, we’re excited to share new developments and upcoming changes that we know you’ll love.
      </p>
    </section>

    <!-- Image Section -->
    <div style="text-align: center; margin: 30px 0;">
      <img src="[Image URL]" alt="Newsletter Image" style="width: 100%; border-radius: 10px;" />
    </div>

    <!-- Call to Action -->
    <div style="text-align: center; margin: 30px 0;">
      <a href="#" style="display: inline-block; background-color: #2B6CB0; color: white; padding: 15px 30px; text-decoration: none; border-radius: 5px; font-size: 1.2rem;">Explore Features</a>
    </div>

    <!-- Footer -->
    <footer style="text-align: center; margin-top: 20px; color: #A0AEC0; font-size: 0.9rem;">
      <p>For any questions, feel free to <a href="#" style="color: #2B6CB0;">reach out to us</a>.</p>
      <p style="margin-top: 10px;">&copy; 2024 [Your Company Name]. All rights reserved.</p>
    </footer>
  </div>
</div>
    
    `

  ]);

  // Pre-fill the contentHTML with a template when the component mounts
  useEffect(() => {
    const templateHTML = templates[0];
    setContentHTML(templateHTML);
  }, []);

  // Quill toolbar configuration
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }], // Heading and font options
      [{ size: [] }], // Font size
      ["bold", "italic", "underline", "strike"], // Text formatting
      [{ color: [] }, { background: [] }], // Text color and background color
      [{ script: "sub" }, { script: "super" }], // Subscript/Superscript
      ["blockquote", "code-block"], // Blockquote and code block
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      [{ indent: "-1" }, { indent: "+1" }], // Indentation
      [{ align: [] }], // Text alignment
      ["link", "image", "video"], // Media
      ["clean"], // Clear formatting
    ],
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    if (!title || !contentHTML || !version) {
      setError("Please fill out all required fields.");
      setLoading(false);
      return;
    }

    const newUpdate = {
      title,
      contentHTML, // Save the raw HTML content
      version,
      isAnnouncement,
      users: ["mL7jTYKO6JgJerKAvT7xUKOs4Zf1"]
    };

    try {
      await addDoc(collection(db, "updates"), newUpdate);
      setSuccess("Update added successfully!");
      setTitle("");
      setContentHTML(""); // Clear the editor after submission
      setVersion("");
      setIsAnnouncement(false);
      setUsers("");
    } catch (err) {
      setError("Failed to save update.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full mt-8 ">
      <div className="bg-white shadow-md rounded-lg p-8 mx-auto">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Create New Update</h2>
        {error && <p className="bg-red-100 text-red-700 p-4 rounded mb-4">{error}</p>}
        {success && <p className="bg-green-100 text-green-700 p-4 rounded mb-4">{success}</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-gray-700 font-semibold mb-2">
              Title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 font-semibold mb-2">
              Content (HTML) <span className="text-red-500">*</span>
            </label>
            <ReactQuill
              theme="snow"
              value={contentHTML}
              onChange={setContentHTML}
              modules={modules}
              className="border border-gray-300 rounded " // Taller editor
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 font-semibold mb-2">
              Version <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
              required
            />
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              checked={isAnnouncement}
              onChange={(e) => setIsAnnouncement(e.target.checked)}
            />
            <label className="text-gray-700 font-semibold">Is Announcement</label>
          </div>

          <button
            type="submit"
            className={`w-full py-3 rounded-lg font-semibold text-white ${
              loading ? "bg-gray-400 cursor-not-allowed" : "bg-indigo-600 hover:bg-indigo-700"
            } focus:outline-none focus:ring-2 focus:ring-indigo-500`}
            disabled={loading}
          >
            {loading ? "Saving..." : "Create Update"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminEditor;