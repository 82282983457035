import React, { useState,useEffect } from 'react';
import { fetchStore,fetchUser, fetchManufacturerProducts ,fetchManufacturerProductVariations} from '../../utils/globalFunctions';
import { useNavigate } from 'react-router-dom';
import { createDiscount,fetchDiscount,saveDiscount } from '../../utils/globalFunctions';  
import { useParams } from 'react-router-dom'; 



function ProductCustomizations({ user,products,setProducts }) {


  console.log(products)
  
    const [isLoading, setIsLoading] = useState(false);
  
      const nav = useNavigate();
  
    // useEffect(() => {
    //   fetchManufacturerProductVariations(user.manufacturerID).then((data) => {
    //     setProducts(data);
    //     setIsLoading(false);
    //   });
    // }, []);
    console.log(products);
  
  
    const updateVariationPrice = (productIndex, variationIndex, newPrice) => {
      setProducts((currentProducts) => {
        const updatedProducts = [...currentProducts];
        const updatedVariation = {
          ...updatedProducts[productIndex].variationItems[variationIndex],
          variation_discount: newPrice,
        };
        updatedProducts[productIndex].variationItems[variationIndex] = updatedVariation;
        return updatedProducts;
      });
    };
  
  
    const updateDetailPrice = (productIndex, variationIndex, detailIndex, detailItemIndex, newPrice) => {
      setProducts(currentProducts => {
        // Clone the array of products
        const updatedProducts = [...currentProducts];
        
        // Clone the product object
        const updatedProduct = {...updatedProducts[productIndex]};
    
        // Clone the variation items array
        const updatedVariationItems = [...updatedProduct.variationItems];
    
        // Clone the specific variation
        const updatedVariation = {...updatedVariationItems[variationIndex]};
    
        // Clone the variation details array
        const updatedVariationDetails = [...updatedVariation.variation_details];
    
        // Clone the specific variation detail
        const updatedVariationDetail = {...updatedVariationDetails[detailIndex]};
    
        // Clone the variation detail items array
        const updatedVariationDetailItems = [...updatedVariationDetail.variation_detail_items];
    
        // Update the specific detail item's price
        const updatedVariationDetailItem = {
          ...updatedVariationDetailItems[detailItemIndex],
          variation_discount: newPrice
        };
    
        // Update the arrays and objects in the nested structure
        updatedVariationDetailItems[detailItemIndex] = updatedVariationDetailItem;
        updatedVariationDetail.variation_detail_items = updatedVariationDetailItems;
        updatedVariationDetails[detailIndex] = updatedVariationDetail;
        updatedVariation.variation_details = updatedVariationDetails;
        updatedVariationItems[variationIndex] = updatedVariation;
        updatedProduct.variationItems = updatedVariationItems;
        updatedProducts[productIndex] = updatedProduct;
    
        return updatedProducts;
      });
    };
  
    // const handleSave = () => {
    //   updateManufacturerVariation(products).then((res)=>{
    //     if(res){
    //       alert("Successfully updated")
    //     }else{
    //       alert("Error updating")
    //     }
  
    //   })
    // }
  

    console.log(products)
  
  
    return (
      <>
    
  

  
  {
          isLoading ? 
          <>
          <div className="sm:pl-8 sm:pr-8 flex justify-center items-center mt-4 h-96">
            <p className="text-center">
              Loading Customizations...
            </p>
  
  
  
          </div>
          </>
          :
          <>
                <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center mt-4">
          <div className="w-full overflow-x-auto">
            
            <div className="table-container">
              
              <div className="flex w-full flex-wrap justify-center">
                
              {
                products.map(
                  (v, productIndex) => (
                  
                    <div key={productIndex} className="p-4 bg-gray-100  sm:w-1/5  w-full m-1" >
                      <p className="text-xl ">
                        {v.variation_title}
                      </p>
                      <hr
                        className="my-2"
                        style={{ borderTop: "1px solid #eee" }}
                      />
                      {
                        v.variationItems.map(
                          (vItem, variationIndex) => (
                            <div key={variationIndex} className="flex flex-col mt-4">
                              <div className="flex items-center">
                              <p className="text-lg fixed-width" >{vItem.variation_name}</p>
                              <div className="relative mt-2 rounded-md shadow-sm ml-2">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
          <input
            type="text"
            name="price"
            id="price"
          
            onChange={(e) => {
              if(!isNaN(e.target.value))
                updateVariationPrice(productIndex, variationIndex, e.target.value)
            }}
            className="block w-24 rounded-md border-0 py-1.5 pl-7  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={vItem.variation_price}
            defaultValue={vItem.variation_discount}

            aria-describedby="price-currency"
          /> 
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        
          </div>
        </div>
                              </div>
  
  
                              {
                                vItem.variation_details.map((
                                  vDetail,detailIndex
                                )=>(
                                  <div key={detailIndex} className="flex flex-col ml-2">
                  
                                    {
                                      vDetail.variation_detail_items.map((
                                        vDetailItem,detailItemIndex
                                      )=>(
                                        <div key={detailItemIndex} className="flex flex-row ml-2 items-center">
                                          <p className="text-sm fixed-width" >{vDetailItem.variation_detail_name}:</p>
                                          <div className="relative mt-2 rounded-md shadow-sm ml-2">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              name="price"
              id="price"

              onChange={(e) => {
                if(!isNaN(e.target.value)){
                  updateDetailPrice(productIndex, variationIndex, detailIndex, detailItemIndex, e.target.value)
                }
  
              }
              }
              className="block w-24 rounded-md border-0 py-1.5 pl-7  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={vDetailItem.variation_detail_price}
              defaultValue={vDetailItem.variation_discount}

              aria-describedby="price-currency"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          
              </div>
              </div>
  
  
                                          
                                        </div>
                                      ))
                                    }
                                  </div>
                                )
  
                                )
                              }
             
               
                              {/* <input
                              className="border-2 border-gray-500"
                                type="text"
                                value={editPrice}
                                onChange={(e) => {
                                  setEditPrice(e.target.value);
                                }}
                              />
                              <button
                                className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
                                onClick={() => {
                                  handleUpdateVariationPrice({
                                    ...vItem,
                                    store_price: editPrice,
                                  });
                                }}
                              >
                                Update Price
                              </button> */}
                            </div>
                          )
                        )
                      }
                 
                    </div>
                )
                )
              
              }
              </div>
       
            </div>
          </div>
        </div>
  
        <div></div>
          </>
  
  }
  
      </>
    );
  }
  











function Store({s,selectedStore}){

    const [store, setStore] = useState(null);


  
    // const nav = useNavigate();
    useEffect(()=>{
      fetchStore(s).then((res)=>{
        console.log(res)
        setStore(res)
   
      });

    },[s])
    console.log(store)

  
  

      return (
      <>
      {
        store  &&
        <div className="store cursor-pointer">

        <p className={selectedStore == store.store_id ? 'text-xs  text-indigo-500': 'text-xs'}> You are editing discounts for {store.storeName}</p>
       </div>

      }
      </>)


 
  }
  function Products({p,setSelectedProducts,selectedProducts}){




  console.log(p)

  
  

      return (
      <>
      {
        p  &&
        <div className="store cursor-pointer" onClick={()=>{
           
            if(selectedProducts?.includes(p.id)){
                setSelectedProducts(selectedProducts.filter((sp)=>sp != p.id))
            }else{
                setSelectedProducts([...selectedProducts,p.id])
            }
        }}>

     <p className={selectedProducts?.includes(p.id) ? 'text-xs  text-indigo-500': 'text-xs'}> {p.product_title}</p>
       </div>

      }
      </>)


 
  }

const EditDiscount = ({user,manufacturer}) => {
    const [discount, setDiscount] = useState('');
    const [discountType, setDiscountType] = useState('');
    const [selectedStore,setSelectedStore] = useState(null)
    const [selectedProducts,setSelectedProducts] = useState([])
    const [products,setProducts] = useState([])
    const [customizations,setCustomizations] = useState([])
    const { id } = useParams();
    const nav = useNavigate();


    useEffect(()=>{
        if(products.length == 0){
            return
        }


        console.log(user.manufacturerID)
        fetchDiscount(id).then((res)=>{

            console.log(res)
            setCustomizations(res.customizations)
            setDiscount(res.discount)
            setDiscountType(res.discountType)
            if(res.prouducts == 'all'){
                let selected = []
                for (let i = 0; i < products.length; i++) {
                    selected.push(products[i].id)
                  
                  }
                    setSelectedProducts(selected)
            }else{
                setSelectedProducts(res.products)
            
            }
            setSelectedStore(res.store_id)
        })
    },[id,products])

    useEffect(()=>{
        console.log(user.manufacturerID)
        fetchManufacturerProducts(user.manufacturerID).then((res)=>{
            console.log(res)
            setProducts(res)
        })
    }
    ,[manufacturer])
    const handleDiscountChange = (e) => {
        setDiscount(e.target.value);
    };

    const handleDiscountTypeChange = (e) => {
        setDiscountType(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
      
        saveDiscount(id,{
            store_id:selectedStore,
            discountType:discountType,
            discount:discount,
            manufacturer:user.manufacturerID,
            isDeleted:false,
            products:selectedProducts.length === products.length ? "all" : selectedProducts,
            customizations:customizations
        }).then((res)=>{
            if(res){
                alert("Discount created")
            }else{
                alert("Error creating discount")
            }

        })
    };

    const getStores = async () => {

    };

    console.log(selectedProducts)
    return (
        <>
          <div className="pt-12 flex flex-row justify-center">
            <div className="flex flex-col sm:w-1/2">
              <h1 className="text-2xl font-bold">Edit Discount</h1>
              <div className="flex mt-2">
                <button
                  className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
                  onClick={() => {
                    nav("/discounts");
                  }}
                >
                  Discounts
                </button>
              </div>
            </div>
          </div>
    
          <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center mt-8">
            <div className="w-full">
              {
                selectedStore &&
                <Store s={selectedStore} setSelectedStore={setSelectedStore} selectedStore={selectedStore}/>
              }
        
            </div>
          </div>

   


     
                <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center mt-8">
                <div className="w-full">
                <p> Please select a product </p>
                <p onClick={()=>{
                    if(selectedProducts.length == products.length){
                        setSelectedProducts([])
                    }else{
                
                    let selected = []
                      for (let i = 0; i < products.length; i++) {
                        selected.push(products[i].id)
                      
                      }
                        setSelectedProducts(selected)
                    }
                    
                
                }}> Select all</p>
                <div className="store-selection">
                {
 
                    products && products.map((p)=>{
                        return <Products p={p} setSelectedProducts={setSelectedProducts} selectedProducts={selectedProducts}/>
                    })
             
                }
                       </div>
                <p> Please enter the discount percentage for product(s)</p>
                <input
                    type="number"
                    value={discount}
                    onChange={handleDiscountChange}
                    className="bg-gray-200 p-2 rounded w-full mt-2"
                />
                </div>
                </div>
     
                
       
                    <div className="sm:pl-8 sm:pr-8  justify-center mt-8">
                    <div className="w-full">
                    
                    <p> Please enter the discount percentage for customizations</p>
                    <ProductCustomizations user={user} products={customizations} setProducts={setCustomizations}/>

            
                    </div>
                    </div>
             
    
          <div className='w-full flex justify-center'>

            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs mt-4"
              onClick={handleSubmit}
            >
              Save Discount
            </button>
          </div>
        </>
      );
};

export default EditDiscount;