import React, { useEffect, useState } from 'react'
import ExtensionDetails from '../../components/ExtensionDetails'
import { useParams } from 'react-router-dom'
import {getExtensionMetaData} from '../../utils/globalFunctions'

export default function Extension({user}) {
    const { id } = useParams()
    const [url,setUrl] = useState(null)
    const[extensionInfo, setExtensionInfo]=useState([]) 

    
    // Fetch the extension information when this page is loaded.


    console.log(id)
    useEffect(()=>{
        if(id){
            console.log(id)
            // getExtensionMetaData({id}).then((res)=>{
            //     if(res){
            //         setUrl(res.url)
            //         setExtensionInfo(res)
            //     }
            // })
        }
    },[id])



  return (
    <div>
        {
      
            <ExtensionDetails  user={user} info={id}/>
        }
    </div>
  )
}
