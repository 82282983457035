import Logout from "../../components/logout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./dashboard";
import OrderEntryPage from "./orderEntryPage";
import { useUser } from "../../utils/context";
import Dealer from "../admin/dealer";
import { useEffect, useState } from "react";
import Notifications from './notifications'
import DealerCatalog from "./dealerCatalog";
import AddCatalog from "./addCatalog";
import Invoices from "./Invoices";
import Schedules from "./Schedules";
import Payment from "./payment";
import OrderEntry from "./orderEntry";
import { Disclosure, Menu, Transition ,Dialog} from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import Orders from "./Orders";
import OrderEntries from "./OrderEntries";
import { Modal } from "flowbite-react";
import Button from "../../components/Button";
import { createFeedBack, globalAlert, globalSuccess } from "../../utils/globalFunctions";
import { collection, onSnapshot, query, where,setDoc,doc } from "@firebase/firestore";
import { db } from "../../utils/firebase";
import { globalMessage } from "../../utils/globalFunctions";
import Products from "./products";
import ProductCustomizations from "./productCustomizations";
import DiscoverNewProducts from "./discoverNewProducts";
import Customers from "./customers";
import Home from "./home";
import { auth } from "../../utils/firebase";
import { signOut } from "@firebase/auth";
import NavBar from "../../components/NavBar";
import Extension from "./Extension";
import Settings from "./Settings";
import Extensions from "./Extensions";
import NotificationToggle from "../../components/NotificationToggle";
import Notification from "../../components/Notification";
import { ToastContainer } from "react-toastify";
import StorePriceListView from "../../extensions//priceExcelLoder/PriceListViewPage";
import Chats from "../store/chats";
import PriceListViewPage from "../../extensions/priceExcelLoder/PriceListViewPage";
import { getStoreData } from "../../utils/globalFunctions";
import TourGuide from "../../components/TourGuide";
import CreateProduct from "./createProduct";
import CustomizationEditor from "./CustomizationEditor";
import EditProduct from "./productEdit";

export const handleLogout = () => {
  signOut(auth).then(() => {
    localStorage.removeItem("user")
    window.location.replace("/");
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
};

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Orders', href: '/orders' },
  { name: 'Extensions', href: '/extensions' },
  { name: 'Customers', href: '/customers' },
  { name: 'Inventory', href: '/store-products' },
  { name: 'Logout', onClick:
  () => {
    handleLogout()
  }
 },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Index(props) {


    const {user,setUser} = useUser();
  // const [unseenNotificationCount,setUnseenNotificationCount] = useState(0);
    const [modal,setModal] = useState(false);



    useEffect(() => {

      const listenToDocumentChanges = (collectionName, docId) => {
        const docRef = doc(db, collectionName, docId);
      
        // Set up a listener to the document
        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const docData = docSnapshot.data();
            console.log("Document data:", docData);
          
            setUser({
              ...user,
              ...docData
            })
          } else {
            console.log("No such document!");
          }
        }, (error) => {
          console.error("Error listening to document changes:", error);
        });
      
        // Return the unsubscribe function to stop listening when necessary
        return unsubscribe;
      };
      
      // Example usage
      const unsubscribe = listenToDocumentChanges("Stores", user.store_id);
      
      // To stop listening later
      unsubscribe();

    }, [])




    // useEffect(() => {
    //   getStoreData({
    //     store_id: user.store_id,
    //   }).then((data) => {
    //     setUser(
    //       {
    //         ...user,
    //         ...data
    //       }
    //     )
    //   }).catch((error) => {
    //     console.log(error);
    //   })
    // }, [])




  return (

    user?.storeName ?
    <>


       {/* <Notification />
            <ToastContainer /> */}

{/* {
  user?.isTourComplete === false || user?.isTourComplete === undefined &&
  <TourGuide  user={user}/> 
} */}
    
       <NavBar user={user} />

         <Routes>
           {/* <Route path="/" element={<Dashboard />} /> */}
           <Route path="/order" element={<OrderEntries user={user} />} />
           <Route path="/order/:customerId" element={<OrderEntries user={user} />} />
           <Route path="/o/orders" element={<Dashboard user={user} />} />
           <Route path="/orders" element={<Orders user={user} />} />
            <Route path="/settings" element={<Settings user={user} />} />
           <Route path="/extension/:id" element={<Extension user={user} />} />
           <Route path="/extensions" element={<Extensions user={user} />} />
           <Route path="/store-price-lists/:id" element={<StorePriceListView user={user} />} />
           <Route path="/customers" element={<Customers user={user} />} />
           <Route path="/" element={<Home user={user} />} />
           <Route path="/store-products" element={<Products user={user} />} />
           <Route path="/priceListLoader/:id" element={<PriceListViewPage user={user} />} />
           <Route path="/discover-new-products" element={<DiscoverNewProducts user={user} />} />
           <Route path="/store-product-customizations" element={<ProductCustomizations user={user} />} />
           <Route path="/create-store-product" element={<CreateProduct user={user} />} />
            <Route path="/customization-editor" element={<CustomizationEditor user={user} />} />
           <Route path="/payment" element={<Payment user={user} />} />
           <Route path="/create-store-product/:id" element={<EditProduct user={user} />} />
       
           <Route path="/order-entry" element={<OrderEntryPage user={user}/>} />
           <Route path="/edit/order/:order_id" element={<OrderEntries user={user}/>} />
           <Route path="/invoices" element={<Invoices user={user} />} />
           <Route path="/schedules" element={<Invoices user={user} />} />
           <Route path="/dealers/:storename" element={<Dealer user={user} />} />
           <Route path="/dealers/catalog" element={<DealerCatalog user={user} />} />
           <Route path="/messages" element={<Chats user={user} />} />
           
           <Route path="/dealers/catalog/add" element={<AddCatalog user={user} />} />
           {/* <Route path="/notifications" element={<Notifications user={user} notifications={notifications} />} /> */}
           <Route path="*" element={
             <div className="flex justify-center items-center h-screen">
                 <h1 className="text-4xl text-gray-600 font-bold m-auto">404 Not Found</h1>
             </div>         
           } />
         </Routes>

    </>
    :
    <>
    </>
      


  );
}
