import React, { useState } from 'react';

const MessageInput = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
    }
  };

  return (
    <div className="p-2 border-t border-gray-300">
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded"
        placeholder="Type your message..."
      />
      <button
        onClick={handleSend}
        className="mt-2 w-full bg-indigo-500 text-white p-2 rounded"
      >
        Send
      </button>
    </div>
  );
};

export default MessageInput;