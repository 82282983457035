import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { cancelBooking } from '../../utils/globalFunctions';
import { sendEmail } from '../../utils/firebase';

const CancelBooking = () => {
  const { slug, slugId } = useParams();
  const navigate = useNavigate();
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);

  const handleCancel = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const bookingId = queryParams.get('bookingId');

    if (slug && slugId && bookingId) {
      setIsCancelling(true); // Set cancelling state when starting cancellation

      cancelBooking(slug, slugId, bookingId).then((res) => {
        if (res) {
          setCancelSuccess(true);
          setIsCancelling(false);

          let customerEmail = res.customerEmail;
          let storeEmails = res.storeEmails;
          let customerName = res.customerName;
          let storeName = res.storeName;

          // Customer Cancellation Email
          sendEmail({
            to: customerEmail,
            subject: 'Your Booking Has Been Cancelled',
            html: `
              <div style="font-family: Arial, sans-serif; color: #333; padding: 20px; line-height: 1.8;">
                <h2 style="color: #e74c3c; font-size: 26px; margin-bottom: 20px; text-align: center;">Booking Cancellation Confirmation</h2>
                <p style="font-size: 16px;">Hi ${customerName},</p>
                <p style="font-size: 16px;">We regret to inform you that your booking with <strong>${storeName}</strong> has been cancelled.</p>
                <div style="margin: 30px 0; text-align: center;">
                  <a href="mailto:${storeEmails[0]}" style="background-color: #007BFF; color: white; padding: 12px 24px; text-decoration: none; border-radius: 5px; font-size: 16px; display: inline-block;">Contact Us</a>
                </div>
                <p style="font-size: 16px;">Best regards,<br/><strong>${storeName}</strong> Team</p>
              </div>
            `,
            sender: 'Notifications',
            attachments: [],
            text: `Hi ${customerName}, your booking with ${storeName} has been cancelled. If you need assistance, please contact ${storeName} directly.`,
          });

          // Store Notification Email
          for (let i = 0; i < storeEmails.length; i++) {
            sendEmail({
              to: storeEmails[i],
              subject: 'Customer Booking Cancellation Notice',
              html: `
                <div style="font-family: Arial, sans-serif; color: #333; padding: 20px; line-height: 1.8;">
                  <h2 style="color: #e74c3c; font-size: 26px; margin-bottom: 20px;">Booking Cancellation by ${customerName}</h2>
                  <p style="font-size: 16px;">Dear <strong>${storeName}</strong> Team,</p>
                  <p style="font-size: 16px;">This is to notify you that <strong>${customerName}</strong> has cancelled their booking.</p>
                  <p style="font-size: 16px;">Best regards,<br/>Supply Circle</p>
                </div>
              `,
              sender: 'Notifications',
              attachments: [],
              text: `${customerName} has cancelled their booking with ${storeName}. Please follow up with them if needed.`,
            });
          }

          toast.success('Your booking has been cancelled successfully.');
        } else {
          setCancelSuccess(false);
          setIsCancelling(false);
          toast.error('There was a problem canceling your booking.');
        }
      });
    }
  };

  const goHome = () => {
    navigate('/');
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center">
        {!confirmCancel ? (
          <>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Confirm Cancellation</h2>
            <p className="text-gray-600 mb-6">
              Are you sure you want to cancel your booking? This action cannot be undone.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors"
                onClick={() => {
                  setConfirmCancel(true);
                  handleCancel(); // Call handleCancel once confirmed
                }}
              >
                Yes, Cancel Booking
              </button>
              <button
                className="bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600 transition-colors"
                onClick={goHome}
              >
                No, Go Back
              </button>
            </div>
          </>
        ) : isCancelling ? (
          <>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Processing Cancellation</h2>
            <p className="text-gray-600 mb-6">Please wait while we cancel your booking...</p>
            <div className="flex justify-center">
              <div className="w-12 h-12 border-4 border-indigo-400 border-t-transparent border-solid rounded-full animate-spin"></div>
            </div>
          </>
        ) : cancelSuccess ? (
          <>
            <h2 className="text-2xl font-semibold text-green-600 mb-4">Booking Cancelled</h2>
            <p className="text-gray-600 mb-6">Your booking has been successfully cancelled.</p>
            <div className="flex flex-col">
              <button
                className="bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600 transition-colors"
                onClick={() => navigate(`/calendar/${slug}/${slugId}`)}
              >
                Book Appointment
              </button>
              <button
                className="bg-indigo-500 mt-4 text-white py-2 px-4 rounded hover:bg-indigo-600 transition-colors"
                onClick={goHome}
              >
                Go to Homepage
              </button>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-2xl font-semibold text-red-600 mb-4">Cancellation Failed</h2>
            <p className="text-gray-600 mb-6">
              We encountered an issue while cancelling your booking. Please try again later.
            </p>
            <button
              className="bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600 transition-colors"
              onClick={goHome}
            >
              Go to Homepage
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CancelBooking;