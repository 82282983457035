// src/pages/Wholesale.js

import React, { useEffect, useState ,useRef} from 'react';
import {
  getWholesaleProducts,
  enableWholeSale,
} from '../../utils/globalFunctions';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { db } from '../../utils/firebase'; // Import your Firebase configuration
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  doc,
  setDoc,
  deleteDoc,
  orderBy,
  onSnapshot
} from 'firebase/firestore';
import ImageUploader from '../../components/ImageUploader';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { createQuickBooksInvoice } from '../../utils/firebase';

const Wholesale = ({ user, manufacturer }) => {
  const [wholeSaleProducts, setWholeSaleProducts] = useState([]);
  const [index, setIndex] = useState(0); // 0: Products, 1: Orders, 2: Wholesale Buyers
  const [buyers, setBuyers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loadingBuyers, setLoadingBuyers] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [showBuyerModal, setShowBuyerModal] = useState(false);
  const [createStartWholesaleModal, setCreateStartWholesaleModal] = useState(false);
  const [newBuyerName, setNewBuyerName] = useState('');
  const [newBuyerEmail, setNewBuyerEmail] = useState('');
  const [selectedBuyer, setSelectedBuyer] = useState(null);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [discounts, setDiscounts] = useState({});
  const [discountForm, setDiscountForm] = useState({});
  const [wholesaleName, setWholesaleName] = useState('');
  const [wholesaleLogo, setWholesaleLogo] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [editableItems, setEditableItems] = useState([]);
  const [newMessage,setNewMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);

const messagesEndRef = useRef(null);

useEffect(() => {
  if (messagesEndRef.current) {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [chatMessages]);
  const [orderTotal, setOrderTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch wholesale products on component mount
    getWholesaleProducts(user.manufacturerID).then((products) => {
      setWholeSaleProducts(products);
    });
  }, []);


  const handleCreateInvoice = async () => {
    try {
      const {result} = await createQuickBooksInvoice({
        order:JSON.stringify(selectedOrder),
      });
      if (result.success) {
        toast.success('Invoice created successfully.');
      }else{
        toast.error(result.message);
      }
    } catch (error) {
      console.error('Error creating invoice:', error);
      toast.error('Failed to create invoice.');
    }
  };


  const findInProducts = (id) => {
    return wholeSaleProducts.find((product) => product.id === id);
  };

  useEffect(() => {
    if (selectedOrder) {
      selectedOrder.items.forEach((item) => {
        const product = findInProducts(item.productId);
        item.product = product;
      });
      // Deep clone the items to avoid mutating the original state
      setEditableItems(JSON.parse(JSON.stringify(selectedOrder.items)));
      setOrderTotal(selectedOrder.orderTotal); // Initialize order total
    }
  }, [selectedOrder]);

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...editableItems];
    updatedItems[index][field] = parseInt(value) || 0;
    // Recalculate price for this item
    updatedItems[index] = recalculateItemPrice(updatedItems[index]);
    setEditableItems(updatedItems);
    // Recalculate order total based on updated items
    recalculateOrderTotal(updatedItems);
  };
  

  const recalculateItemPrice = (item) => {
    let price = item.product.wholesaleMetaData.price;
    let totalPrice = 0;
  
    if (item.unitType === 'AREA') {
      const width = item.measurements.width || 0;
      const height = item.measurements.height || 0;
      const area = width * height;
      totalPrice = price * area * item.quantity;
    } else if (item.unitType === 'LENGTH') {
      const length = item.measurements.length || 0;
      totalPrice = price * length * item.quantity;
    } else {
      totalPrice = price * item.quantity;
    }
  
    // Apply discount if any
    let discountedPrice = totalPrice;
    if (item.discountApplied) {
      if (item.discountType === 'percentage') {
        discountedPrice = discountedPrice * (1 - item.discountValue / 100);
      } else if (item.discountType === 'fixed') {
        discountedPrice = discountedPrice - item.discountValue;
      }
    }
  
    item.totalPrice = totalPrice;
    item.discountedPrice = discountedPrice;
    item.unitPrice =  totalPrice / item.quantity;
    return item;
  };



  const recalculateOrderTotal = (items) => {
    const newTotal = items.reduce((acc, item) => acc + item.totalPrice, 0);
    setOrderTotal(newTotal);
  };

  const handleSaveChanges = async () => {
    try {
      // Reference to the specific order document
      const orderRef = doc(db, 'WholesaleOrders', selectedOrder.id);
      
      // Update the order with new items and total
      await setDoc(orderRef, {
        items: editableItems,
        orderTotal: orderTotal,
      }, { merge: true });
  
      toast.success('Order updated successfully.');
      setShowOrderModal(false);
      setSelectedOrder(null);
      fetchOrders(); // Refresh the orders list
    } catch (error) {
      console.error('Error updating order:', error);
      toast.error('Failed to update order.');
    }
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...editableItems];
    updatedItems.splice(index, 1); // Remove the item at the specified index
    setEditableItems(updatedItems);
    recalculateOrderTotal(updatedItems);
  };
  
  const handleMeasurementChange = (index, dimension, value) => {
    const updatedItems = [...editableItems];
    updatedItems[index].measurements[dimension] = parseFloat(value) || 0;
    // Recalculate price for this item
    updatedItems[index] = recalculateItemPrice(updatedItems[index]);
    setEditableItems(updatedItems);
    // Recalculate order total based on updated items
    recalculateOrderTotal(updatedItems);
  };


  useEffect(() => {
    if (index === 1) {
      fetchOrders();
    }
  }, [index]);

  useEffect(() => {
    if (index === 2) {
      fetchBuyers();
    }
  }, [index]);

  const fetchOrders = async () => {
    setLoadingOrders(true);
    try {
      const ordersRef = collection(db, 'WholesaleOrders');
      const q = query(
        ordersRef,
        where('manufacturerID', '==', user.manufacturerID),
        orderBy('createdAt', 'desc')
      );
      const querySnapshot = await getDocs(q);
      const ordersList = [];
      querySnapshot.forEach(async (doc) => {

        let data
        let buyerName = '';
        let buyerId = '';


        ordersList.push({ id: doc.id, ...doc.data() });
      });
      setOrders(ordersList);
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('Failed to fetch orders.');
    }
    setLoadingOrders(false);
  };

  const fetchBuyers = async () => {
    setLoadingBuyers(true);
    try {
      const buyersRef = collection(db, 'WholesaleBuyers');
      const q = query(
        buyersRef,
        where('invitedBy', '==', user.manufacturerID)
      );
      const querySnapshot = await getDocs(q);
      const buyersList = [];
      querySnapshot.forEach((doc) => {
        buyersList.push({ id: doc.id, ...doc.data() });
      });
      setBuyers(buyersList);
    } catch (error) {
      console.error('Error fetching buyers:', error);
      toast.error('Failed to fetch buyers.');
    }
    setLoadingBuyers(false);
  };

  const handleCreateBuyer = async (e) => {
    e.preventDefault();
    if (!newBuyerName || !newBuyerEmail) {
      toast.error('Please provide both name and email.');
      return;
    }

    try {
      const buyersRef = collection(db, 'WholesaleBuyers');
      // first check if the buyer already exists
        const q = query(
            buyersRef,
            where('email', '==', newBuyerEmail)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            toast.error('There is already buyer with this email.');
            return;
        }
      await addDoc(buyersRef, {
        name: newBuyerName,
        email: newBuyerEmail,
        invitedBy: user.manufacturerID,
        createdAt: serverTimestamp(),
      });
      toast.success('Buyer created successfully.');
      setShowBuyerModal(false);
      setNewBuyerName('');
      setNewBuyerEmail('');
      fetchBuyers(); // Refresh the buyers list
    } catch (error) {
      console.error('Error creating buyer:', error);
      toast.error('Failed to create buyer.');
    }
  };

  const handleManageDiscounts = async (buyer) => {
    setSelectedBuyer(buyer);
    setShowDiscountModal(true);
    await fetchDiscounts(buyer.id);
  };

  const fetchDiscounts = async (buyerId) => {
    try {
      const discountsRef = collection(
        db,
        'WholesaleBuyers',
        buyerId,
        'Discounts'
      );
      const discountsSnapshot = await getDocs(discountsRef);
      const discountsData = {};
      discountsSnapshot.forEach((doc) => {
        discountsData[doc.id] = doc.data();
      });
      setDiscounts(discountsData);
    } catch (error) {
      console.error('Error fetching discounts:', error);
      toast.error('Failed to fetch discounts.');
    }
  };

  const handleDiscountChange = (productId, field, value) => {
    setDiscountForm((prevForm) => ({
      ...prevForm,
      [productId]: {
        ...prevForm[productId],
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    if (selectedOrder) {
      const chatRef = collection(db, 'WholesaleOrders', selectedOrder.id, 'chat');
      const q = query(chatRef, orderBy('timestamp', 'asc'));
  
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const messages = [];
        querySnapshot.forEach((doc) => {
          messages.push({ id: doc.id, ...doc.data() });
        });
        setChatMessages(messages);
      });
  
      return () => unsubscribe();
    }
  }, [selectedOrder]);

  const sendMessage = async () => {
    if (newMessage.trim() === '') return;
  
    try {
      const chatRef = collection(db, 'WholesaleOrders', selectedOrder.id, 'chat');
      await addDoc(chatRef, {
        sender: 'admin', // Change as needed based on user role
        content: newMessage.trim(),
        timestamp: serverTimestamp(),
      });
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message.');
    }
  };

  const saveDiscount = async (productId) => {
    if (!selectedBuyer) return;
    const buyerId = selectedBuyer.id;
    const discountData = discountForm[productId];
    if (!discountData) return;

    try {
      const discountRef = doc(
        db,
        'WholesaleBuyers',
        buyerId,
        'Discounts',
        productId
      );
      await setDoc(discountRef, {
        ...discountData,
        id: productId,
        startDate: discountData.startDate
          ? new Date(discountData.startDate)
          : null,
        endDate: discountData.endDate
          ? new Date(discountData.endDate)
          : null,
        updatedAt: serverTimestamp(),
      });
      toast.success('Discount saved successfully.');
      await fetchDiscounts(buyerId);
    } catch (error) {
      console.error('Error saving discount:', error);
      toast.error('Failed to save discount.');
    }
  };

  const deleteDiscount = async (productId) => {
    if (!selectedBuyer) return;
    const buyerId = selectedBuyer.id;

    try {
      const discountRef = doc(
        db,
        'WholesaleBuyers',
        buyerId,
        'Discounts',
        productId
      );
      await deleteDoc(discountRef);
      toast.success('Discount deleted successfully.');
      await fetchDiscounts(buyerId);
      setDiscountForm((prevForm) => {
        const newForm = { ...prevForm };
        delete newForm[productId];
        return newForm;
      });
    } catch (error) {
      console.error('Error deleting discount:', error);
      toast.error('Failed to delete discount.');
    }
  };

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    setShowOrderModal(true);
  };

  const handleUpdateOrderStatus = async (orderId, status) => {
    try {
      const orderRef = doc(db, 'WholesaleOrders', orderId);
      await setDoc(orderRef, { status }, { merge: true });
      toast.success('Order status updated.');
      fetchOrders(); // Refresh orders list
      setShowOrderModal(false);
    } catch (error) {
      console.error('Error updating order status:', error);
      toast.error('Failed to update order status.');
    }
  };

  if (!manufacturer) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-gray-500 text-lg">Loading...</div>
      </div>
    );
  }

  if (!manufacturer?.isWholesaleEnabled) {
    return (
      <div className="flex flex-col items-center justify-center mt-16">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Wholesale</h1>
        <p className="text-gray-600 mb-4">
          Wholesale is not enabled for this manufacturer.
        </p>
        <button
          className="bg-indigo-600 hover:bg-indigo-700 text-white px-6 py-2 rounded-md"
          onClick={() => {
            setCreateStartWholesaleModal(true);
          }}
        >
          Start Wholesaling
        </button>

        {/* Create Start Wholesale Modal */}
        {createStartWholesaleModal && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ zIndex: 1000 }} // Increase z-index
          >
            <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
              <h3 className="text-xl font-semibold mb-4 text-gray-800">
                Create Wholesale Profile
              </h3>
              <div>
                <div className="mb-4">
                  <label className="block mb-1 text-sm font-medium text-gray-700">
                    Logo
                  </label>
                  <ImageUploader
                    downloadURL={wholesaleLogo}
                    setDownloadURL={setWholesaleLogo}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                    value={wholesaleName}
                    onChange={(e) => setWholesaleName(e.target.value)}
                  />
                </div>

                <div className="flex justify-end">
                  <button
                    type="button"
                    className="mr-2 px-4 py-2 text-gray-700 hover:text-gray-900"
                    onClick={() => setCreateStartWholesaleModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-md"
                    onClick={() => {
                      let res = window.confirm(
                        'Are you sure you want to enable wholesale? This will create a public profile for your manufacturer.'
                      );
                      if (res) {
                        enableWholeSale(
                          manufacturer.id,
                          wholesaleName,
                          wholesaleLogo
                        ).then((res) => {
                          if (res) {
                            toast.success('Wholesale enabled successfully');
                          }
                        });
                      }
                    }}
                  >
                    Start Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  console.log(selectedOrder)
  return (
    <div className="px-4 py-6">
      {/* Tabs */}
      <div className="flex border-b mb-6">
        <button
          className={`px-4 py-2 -mb-px font-semibold text-sm ${
            index === 0
              ? 'border-b-2 border-indigo-600 text-indigo-600'
              : 'text-gray-600 hover:text-indigo-600'
          }`}
          onClick={() => setIndex(0)}
        >
          Products
        </button>
        <button
          className={`ml-4 px-4 py-2 -mb-px font-semibold text-sm ${
            index === 1
              ? 'border-b-2 border-indigo-600 text-indigo-600'
              : 'text-gray-600 hover:text-indigo-600'
          }`}
          onClick={() => setIndex(1)}
        >
          Orders
        </button>
        <button
          className={`ml-4 px-4 py-2 -mb-px font-semibold text-sm ${
            index === 2
              ? 'border-b-2 border-indigo-600 text-indigo-600'
              : 'text-gray-600 hover:text-indigo-600'
          }`}
          onClick={() => setIndex(2)}
        >
          Wholesale Buyers
        </button>
      </div>

      {index === 0 && (
        <div className="mt-4">
          <h2 className="text-2xl font-semibold mb-6 text-gray-800">
            Wholesale Products
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    Product Name
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    Category
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    Unit Type
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    Metric Type
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    Cost
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    Available
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {wholeSaleProducts.map((product) => (
                  <tr key={product.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                      {product.material_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                      {product.materialCategory}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                      {product.unitType}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                      {product.wholesaleMetaData.metricType}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                      ${product.cost}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {product.isAvailable ? (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Yes
                        </span>
                      ) : (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                          No
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <button
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={() => navigate(`/edit/material/${product.id}`)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {index === 1 && (
        <div>
          {/* Wholesale Orders */}
          <div className="mt-4">
            <h2 className="text-2xl font-semibold mb-6 text-gray-800">
              Wholesale Orders
            </h2>
            {loadingOrders ? (
              <div className="text-gray-600">Loading orders...</div>
            ) : orders.length === 0 ? (
              <div className="text-gray-600">No orders found.</div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Order ID
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Buyer Name
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Buyer Email
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Total Amount
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Status
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Created At
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {orders.map((order) => (
                      <tr key={order.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {order.id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {order.buyerName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {order.buyerEmail}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          ${order.orderTotal.toFixed(2)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                            {order.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {order.createdAt?.toDate().toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <button
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={() => handleViewOrder(order)}
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>



{/* Order Details Modal */}
{showOrderModal && selectedOrder && (
  <div
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    style={{ zIndex: 1000 }} // Ensure it's above other elements
  >
    <div className="bg-white p-6 rounded-md shadow-md w-full max-w-4xl max-h-screen overflow-y-auto relative">
      {/* Close Button */}
      <button
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        onClick={() => {
          setShowOrderModal(false);
          setSelectedOrder(null);
          setEditableItems([]);
          setChatMessages([]);
        }}
      >
        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
      </button>

      <h3 className="text-xl font-semibold mb-4 text-gray-800">
        Order Details
      </h3>
      <div>
        {/* Order Information */}
        <p>
          <strong>Order ID:</strong> {selectedOrder.id}
        </p>
        <p>
          <strong>Buyer Name:</strong> {selectedOrder.buyerName}
        </p>
        <p>
          <strong>Buyer Email:</strong> {selectedOrder.buyerEmail}
        </p>
        <p>
          <strong>Total Amount:</strong> $
          {selectedOrder.orderTotal.toFixed(2)}
        </p>
        <p>
          <strong>Status:</strong>{' '}
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
            {selectedOrder.status}
          </span>
        </p>
        <p>
          <strong>Created At:</strong>{' '}
          {selectedOrder.createdAt?.toDate().toLocaleString()}
        </p>

        {/* Items */}
        <h4 className="mt-4 text-lg font-semibold">Items:</h4>
        <ul className="list-disc list-inside">
          {editableItems.map((item, index) => (
            <li key={index} className="mb-4">
              <div className="flex justify-between items-center">
                <strong>{item.materialName} ({item.unitType})</strong>
                <button
                  className="text-red-600 hover:text-red-800 text-sm"
                  onClick={() => handleRemoveItem(index)}
                >
                  Remove
                </button>
              </div>
              
              {/* Quantity */}
              <div className="flex items-center mt-2">
                <label className="mr-2">Quantity:</label>
                <input
                  type="number"
                  min="1"
                  value={item.quantity}
                  onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                  className="w-20 border border-gray-300 rounded text-right px-2 py-1"
                />
              </div>

              {/* Measurements */}
              {item.unitType === 'AREA' && (
                <div className="mt-2">
                  <div className="flex items-center">
                    <label className="mr-2">Width ({item.measurements.metricType}):</label>
                    <input
                      type="number"
                      min="0"
                      value={item.measurements.width}
                      onChange={(e) =>
                        handleMeasurementChange(index, 'width', e.target.value)
                      }
                      className="w-20 border border-gray-300 rounded text-right px-2 py-1"
                    />
                  </div>
                  <div className="flex items-center mt-2">
                    <label className="mr-2">Height ({item.measurements.metricType}):</label>
                    <input
                      type="number"
                      min="0"
                      value={item.measurements.height}
                      onChange={(e) =>
                        handleMeasurementChange(index, 'height', e.target.value)
                      }
                      className="w-20 border border-gray-300 rounded text-right px-2 py-1"
                    />
                  </div>
                </div>
              )}
              {item.unitType === 'LENGTH' && (
                <div className="flex items-center mt-2">
                  <label className="mr-2">Length ({item.measurements.metricType}):</label>
                  <input
                    type="number"
                    min="0"
                    value={item.measurements.length}
                    onChange={(e) =>
                      handleMeasurementChange(index, 'length', e.target.value)
                    }
                    className="w-20 border border-gray-300 rounded text-right px-2 py-1"
                  />
                </div>
              )}

              {/* Pricing */}
              <p className="mt-2">
                Price: ${item.unitPrice.toFixed(2)} each, Total: $
                {item.totalPrice.toFixed(2)}
              </p>
              {/* TODO::Custom Discount */}
              {/* <div className="flex items-center mt-2">
                <label className="mr-2">Discount:</label>
                <select
                  value={item.discountApplied ? 'applied' : 'none'}
                  onChange={(e) =>
                    handleItemChange(
                      index,
                      'discountApplied',
                      e.target.value === 'applied'
                    )
                  }
                  className="border border-gray-300 rounded px-2 py-1"
                >
                  <option value="none">None</option>
                  <option value="applied">Applied</option>
                </select>
                {item.discountApplied && (
                  <div className="flex items-center ml-2">
                    <select
                      value={item.discountType}
                      onChange={(e) =>
                        handleItemChange(index, 'discountType', e.target.value)
                      }
                      className="border border-gray-300 rounded px-2 py-1"
                    >
                      <option value="percentage">Percentage</option>
                      <option value="fixed">Fixed</option>

                    </select>
                    <input
                      type="number"
                      min="0"
                      value={item.discountValue}
                      onChange={(e) =>
                        handleItemChange(index, 'discountValue', e.target.value)
                      }
                      className="w-20 border border-gray-300 rounded text-right px-2 py-1"
                    />
                  </div>
                )}
              </div> */}



            

              {/* Discount Info */}
              {item.discountApplied && (
                <>
                          <p className="text-sm text-green-600">
                  Discount Applied: {item.discountType === 'percentage' ? `${item.discountValue}%` : `$${item.discountValue}`}
                </p>
                <p className="text-sm text-green-600">
                  Discounted Price: ${item.discountedPrice.toFixed(2)}
                </p>
                </>
      
              )}
            </li>
          ))}
        </ul>
        <div className="mt-4 text-right">
          <strong>
            Order Total: ${selectedOrder.orderTotal.toFixed(2)}
          </strong>
        </div>

        {/* Update Status */}
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            Update Status
          </label>
          <select
            value={selectedOrder.status}
            onChange={(e) =>
              handleUpdateOrderStatus(selectedOrder.id, e.target.value)
            }
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="Pending">Pending</option>
            <option value="Processing">Processing</option>
            <option value="Shipped">Shipped</option>
            <option value="Delivered">Delivered</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>

        {/* Save Changes */}
        <div className="flex justify-end mt-6">
          <button
            className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded-md"
            onClick={() => {
              setShowOrderModal(false);
              setSelectedOrder(null);
              setEditableItems([]);
              setChatMessages([]);
            }}
          >
            Close
          </button>
          <button
            className="ml-2 bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-md"
            onClick={handleSaveChanges}
          >
            Save Changes
          </button>
          <button
            className="ml-2 bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-md"
            onClick={()=>{
              handleCreateInvoice()
            }}
          >
            Create Invoice
          </button>
        </div>

        {/* Divider */}
        <hr className="my-6" />

        {/* Chat Section */}
        <div className="mt-6">
          <h4 className="text-lg font-semibold mb-4">Chat</h4>
          <div className="flex flex-col h-80 border border-gray-300 rounded-md overflow-hidden">
            {/* Messages Display */}
            <div className="flex-1 p-4 overflow-y-auto" ref={messagesEndRef}>
              {chatMessages.map((message) => (
                <div
                  key={message.id}
                  className={`mb-4 flex ${
                    message.sender === 'admin' ? 'justify-end' : 'justify-start'
                  }`}
                >
                  <div
                    className={`max-w-xs px-4 py-2 rounded-lg ${
                      message.sender === 'admin'
                        ? 'bg-indigo-600 text-white'
                        : 'bg-gray-200 text-gray-800'
                    }`}
                  >
                    <p>{message.content}</p>
                    <span className="text-xs text-gray-300">
                      {message.timestamp?.toDate().toLocaleTimeString()}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            {/* Input Field */}
            <div className="p-4 border-t border-gray-300">
              <div className="flex">
                <input
                  type="text"
                  placeholder="Type your message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') sendMessage();
                  }}
                  className="flex-1 border border-gray-300 rounded-l-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <button
                  onClick={sendMessage}
                  className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-r-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)}


  </div>
  
      )}

      {index === 2 && (
        <div className="mt-4">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-800">
              Wholesale Buyers
            </h2>
            <button
              className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-md"
              onClick={() => setShowBuyerModal(true)}
            >
              Create Buyer
            </button>
          </div>
          {loadingBuyers ? (
            <div className="text-gray-600">Loading buyers...</div>
          ) : buyers.length === 0 ? (
            <div className="text-gray-600">No buyers found.</div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Name
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Email
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Created At
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {buyers.map((buyer) => (
                    <tr key={buyer.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                        {buyer.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                        {buyer.email}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                        {buyer.createdAt?.toDate().toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <button
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={() => handleManageDiscounts(buyer)}
                        >
                          Manage Discounts
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Create Buyer Modal */}
          {showBuyerModal && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
              style={{ zIndex: 1000 }} // Increase z-index
            >
              <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
                <h3 className="text-xl font-semibold mb-4 text-gray-800">
                  Create New Buyer
                </h3>
                <form onSubmit={handleCreateBuyer}>
                  <div className="mb-4">
                    <label className="block mb-1 text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <input
                      type="text"
                      className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                      value={newBuyerName}
                      onChange={(e) => setNewBuyerName(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block mb-1 text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <input
                      type="email"
                      className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                      value={newBuyerEmail}
                      onChange={(e) => setNewBuyerEmail(e.target.value)}
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="mr-2 px-4 py-2 text-gray-700 hover:text-gray-900"
                      onClick={() => setShowBuyerModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-md"
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* Manage Discounts Modal */}
          {showDiscountModal && selectedBuyer && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
              style={{ zIndex: 1000 }} // Increase z-index
            >
              <div className="bg-white p-6 rounded-md shadow-md w-full max-w-5xl max-h-screen overflow-y-auto">
                <h3 className="text-xl font-semibold mb-4 text-gray-800">
                  Manage Discounts for {selectedBuyer.name}
                </h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                          Product Name
                        </th>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                          Discount Type
                        </th>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                          Value
                        </th>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                          Start Date
                        </th>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                          End Date
                        </th>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {wholeSaleProducts.map((product) => {
                        const discount = discounts[product.id];
                        const form = discountForm[product.id] || {};
                        return (
                          <tr key={product.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                              {product.material_name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <select
                                value={form.type || discount?.type || ''}
                                onChange={(e) =>
                                  handleDiscountChange(
                                    product.id,
                                    'type',
                                    e.target.value
                                  )
                                }
                                className="border border-gray-300 px-2 py-1 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                              >
                                <option value="">Select Type</option>
                                <option value="percentage">Percentage</option>
                                <option value="fixed">Fixed Amount</option>
                              </select>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <input
                                type="number"
                                value={form.value || discount?.value || ''}
                                onChange={(e) =>
                                  handleDiscountChange(
                                    product.id,
                                    'value',
                                    e.target.value
                                  )
                                }
                                className="border border-gray-300 px-2 py-1 rounded-md w-24 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <input
                                type="date"
                                value={
                                  form.startDate ||
                                  (discount?.startDate
                                    ? new Date(
                                        discount.startDate.seconds * 1000
                                      )
                                        .toISOString()
                                        .split('T')[0]
                                    : '')
                                }
                                onChange={(e) =>
                                  handleDiscountChange(
                                    product.id,
                                    'startDate',
                                    e.target.value
                                  )
                                }
                                className="border border-gray-300 px-2 py-1 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <input
                                type="date"
                                value={
                                  form.endDate ||
                                  (discount?.endDate
                                    ? new Date(
                                        discount.endDate.seconds * 1000
                                      )
                                        .toISOString()
                                        .split('T')[0]
                                    : '')
                                }
                                onChange={(e) =>
                                  handleDiscountChange(
                                    product.id,
                                    'endDate',
                                    e.target.value
                                  )
                                }
                                className="border border-gray-300 px-2 py-1 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <button
                                className="text-indigo-600 hover:text-indigo-900 mr-2"
                                onClick={() => saveDiscount(product.id)}
                              >
                                Save
                              </button>
                              {discount && (
                                <button
                                  className="text-red-600 hover:text-red-800"
                                  onClick={() => deleteDiscount(product.id)}
                                >
                                  Delete
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded-md"
                    onClick={() => {
                      setShowDiscountModal(false);
                      setSelectedBuyer(null);
                      setDiscountForm({});
                      setDiscounts({});
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Wholesale;