// src/components/ConnectMaterialsToProducts.jsx

import React, { useEffect, useState, Fragment } from 'react';
import {
  fetchManufacturerProducts,
  connectMaterialToProducts,
  disconnectMaterialFromProducts,
  globalSuccess,
  globalAlert,
  getProductsByIds,
  getProductIdsByMaterialId,
} from '../utils/globalFunctions';
import { toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import 'tailwindcss/tailwind.css';

const ConnectMaterialsToProducts = ({ user, manufacturer, material, isDisconnect, setOpen }) => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]); // Stores selected product IDs
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);

  // Fetch products when component mounts or when user changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (user?.manufacturerID) {
          if (!isDisconnect) {
            const [productsData, selectedProductIds] = await Promise.all([
              fetchManufacturerProducts(user.manufacturerID),
              getProductIdsByMaterialId(material.id),
            ]);
            setProducts(productsData);
            setSelectedProducts(selectedProductIds);
          } else {
            const productIds = await getProductIdsByMaterialId(material.id);
            const productsData = await getProductsByIds(productIds);
            setProducts(productsData);
            setSelectedProducts(productIds);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch products.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user?.manufacturerID, isDisconnect, material.id]);

  // Filter products based on search term
  useEffect(() => {
    const filtered = products.filter((product) =>
      product.product_title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchTerm, products]);

  // Handle individual product selection
  const handleProductSelect = (productId) => {
    if (selectedProducts.includes(productId)) {
      // If already selected, deselect it
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    } else {
      // Otherwise, add it to the selected products
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  // Handle select all
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(filteredProducts.map((product) => product.id));
    }
    setSelectAll(!selectAll);
  };

  // Handle connect/disconnect action
  const handleAction = async () => {
    try {
      setIsLoading(true);
      if (isDisconnect) {
        const result = await disconnectMaterialFromProducts(material.id, selectedProducts);
        if (result) {
          globalSuccess('Material disconnected from products successfully');
          setOpen(false); // Close the window after successful disconnection
        } else {
          globalAlert('Material disconnection from products failed');
        }
      } else {
        const result = await connectMaterialToProducts(material.id, selectedProducts);
        if (result) {
          globalSuccess('Material connected to products successfully');
          setOpen(false); // Close the window after successful connection
        } else {
          globalAlert('Material connection to products failed');
        }
      }
    } catch (error) {
      console.error('Error during action:', error);
      toast.error('An error occurred during the operation.');
    } finally {
      setIsLoading(false);
      setIsActionModalOpen(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white shadow-md rounded-md p-6 relative">
        <h2 className="text-2xl font-semibold mb-4">{isDisconnect ? 'Disconnect' : 'Connect'} Material</h2>

        {/* Search Bar */}
        <div className="mt-4 mb-4">
          <input
            type="text"
            placeholder="Search products"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        {/* Select All */}
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={selectedProducts.length === filteredProducts.length && filteredProducts.length > 0}
            onChange={handleSelectAll}
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
          />
          <label className="ml-2 text-sm font-medium text-gray-700">Select All</label>
        </div>

        {/* Products List */}
        {isLoading ? (
          <div className="flex justify-center my-6">
            <svg
              className="animate-spin h-12 w-12 text-indigo-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          </div>
        ) : (
          <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-24">
            {filteredProducts.map((product) => (
              <li key={product.id} className="flex items-center bg-gray-50 p-4 rounded-md">
                <input
                  type="checkbox"
                  checked={selectedProducts.includes(product.id)}
                  onChange={() => handleProductSelect(product.id)}
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
                <label className="ml-2 text-sm text-gray-800">{product.product_title}</label>
              </li>
            ))}
          </ul>
        )}

        {/* Action Buttons */}
        <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md flex justify-between items-center">
          <button
            onClick={() => setOpen(false)}
            className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={() => setIsActionModalOpen(true)}
            disabled={selectedProducts.length === 0}
            className={`${
              selectedProducts.length === 0
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-indigo-600 hover:bg-indigo-700'
            } text-white font-medium py-2 px-4 rounded-md`}
          >
            {isDisconnect ? 'Disconnect' : 'Connect'} Selected Products
          </button>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Transition.Root show={isActionModalOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-50 inset-0 overflow-y-auto"
          open={isActionModalOpen}
          onClose={setIsActionModalOpen}
        >
          <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 transition-opacity" />
            </Transition.Child>

            {/* Centering container */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            {/* Modal panel */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg w-full">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="sm:flex sm:items-start">
                    {isDisconnect ? (
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    ) : (
                      <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    )}
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {isDisconnect ? 'Disconnect' : 'Connect'} Material
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to {isDisconnect ? 'disconnect' : 'connect'} the material to the
                          selected products?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={handleAction}
                    className={`${
                      isDisconnect ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700'
                    } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm`}
                  >
                    {isDisconnect ? 'Disconnect' : 'Connect'}
                  </button>
                  <button
                    onClick={() => setIsActionModalOpen(false)}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ConnectMaterialsToProducts;