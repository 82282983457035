import React, { useState,Fragment } from 'react';
import * as XLSX from 'xlsx';
import { db } from '../utils/firebase';
import {savePriceList,saveStorePriceList} from '../utils/globalFunctions';
import { toast } from 'react-toastify';

import { Transition,Dialog } from '@headlessui/react';


const ExcelLoader = ({user,name,setUploaded,isStore=false}) => {
  const [file, setFile] = useState(null);
  const [isModalOpen,setIsModalOpen] = useState(false)

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadArea = async () => {
    if (!file) {
      alert("please choose your file first")
      return
    };

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // get the columns headers
        const colHeaders = [];
        for (let i = 1; i <= 26; i++) {
          const cell = sheet[String.fromCharCode(64 + i) + '1'];
          if (cell) {
            colHeaders.push(cell.v);
          }
        }
        console.log(colHeaders)

        const rowHeaders = [];
        for (let i = 1; i <= 26; i++) {
          const cell = sheet['A' + i];
          if (cell) {
            rowHeaders.push(cell.v);
          }
        }

        console.log(sheet)

        const list = {};

        for (let i = 0; i < colHeaders.length; i++) {
            let col = colHeaders[i];
            let char = String.fromCharCode(65 + i + 1);
            for (let j = 0; j <= rowHeaders.length + 1; j++) {
                let row = rowHeaders[j];
                console.log(char + (j + 2))
                const cell = sheet[char + (j + 2)];
                console.log(cell)
                if (cell) {
                    list[`${col}-${row}`] = cell.v;
                }
            }
        }
        if(!isStore){
              let toBeSaved = {
                price_list_name: name,
                priceMap: list,
                columns: colHeaders,
                rows: rowHeaders,
                isDeleted: false,
                creator: user.manufacturerID,
                type: "AREA"
            }

              console.log(toBeSaved)


          let res =   await savePriceList("dnLLP341yLe4HvKOXd8X",user.manufacturerID,toBeSaved);


          if(res){
            toast.success('Data uploaded successfully');

          }else{
              toast.error('Something went wrong, please try again');
          }
        }else{
          // if its a store
       
          let toBeSaved = {
            price_list_name: name,
            priceMap: list,
            columns: colHeaders,
            rows: rowHeaders,
            isDeleted: false,
            creator: user.store_id,
            isStore: true,
            type: "AREA"
          }
          console.log(toBeSaved)
          let res = await saveStorePriceList(user.store_id,toBeSaved)

          if(res){
              toast.success('Data uploaded successfully');
              setUploaded(true);
          }else{
              toast.error('Something went wrong, please try again');
          }
        }
    };

    reader.readAsArrayBuffer(file);
  };




  const handleUploadLength = async () => {
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
  
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
  
      // Get the sheet range
      const range = XLSX.utils.decode_range(sheet['!ref']);
  
      // Find the first non-empty row (headers)
      let headerRowNum = range.s.r;
      let foundHeaders = false;
      while (!foundHeaders && headerRowNum <= range.e.r) {
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellAddress = XLSX.utils.encode_cell({ c: colNum, r: headerRowNum });
          const cell = sheet[cellAddress];
          if (cell && cell.v !== undefined && cell.v !== null && cell.v !== '') {
            foundHeaders = true;
            break;
          }
        }
        if (!foundHeaders) headerRowNum++;
      }
  
      if (!foundHeaders) {
        alert('No data found in the file');
        return;
      }
  
      // Get the column headers (widths)
      const colHeaders = [];
      const colIndices = [];
      for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
        const cellAddress = XLSX.utils.encode_cell({ c: colNum, r: headerRowNum });
        const cell = sheet[cellAddress];
        if (cell && cell.v !== undefined && cell.v !== null && cell.v !== '') {
          colHeaders.push(cell.v);
          colIndices.push(colNum);
        }
      }
  
      if (colHeaders.length === 0) {
        alert('No column headers found');
        return;
      }
  
      // Get the data from the row below the headers
      let dataRowNum = headerRowNum + 1;
      let dataFound = false;
      const list = {};
  
      for (let i = 0; i < colHeaders.length; i++) {
        let colNum = colIndices[i];
        let width = colHeaders[i];
  
        // Find the first non-empty cell in this column starting from dataRowNum
        let rowNum = dataRowNum;
        let cellValue = null;
        while (rowNum <= range.e.r) {
          const cellAddress = XLSX.utils.encode_cell({ c: colNum, r: rowNum });
          const cell = sheet[cellAddress];
          if (cell && cell.v !== undefined && cell.v !== null && cell.v !== '') {
            cellValue = cell.v;
            dataFound = true;
            break;
          }
          rowNum++;
        }
  
        if (cellValue !== null) {
          list[width] = cellValue;
        } else {
          // Handle missing data
          list[width] = null;
        }
      }
  
      if (!dataFound) {
        alert('No data found in the data rows');
        return;
      }
  
      console.log('List:', list);
  
      // Prepare data to be saved
      const toBeSaved = { // CANNOT BE CREATED BY STORES FOR NOW, THEY DONT NEED THIS.
        price_list_name: name,
        priceMap: list,
        columns: colHeaders,
        isDeleted: false,
        creator: user.manufacturerID,
        type: 'LENGTH',
        isStore: false,
      };
  
      console.log(toBeSaved);
  
      // Save the data
      let res = await savePriceList('dnLLP341yLe4HvKOXd8X', user.manufacturerID, toBeSaved);
      if (true) {
        toast.success('Data uploaded successfully');
      } else {
        toast.error('Something went wrong, please try again');
      }
    };
  
    reader.readAsArrayBuffer(file);
  };
  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button 
      className='bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mt-2'
      
      onClick={()=>{
        if(user.role ==="store"){
          handleUploadArea()
        }else{
          setIsModalOpen(true)
        }
      }}>Upload</button>

                {/* Category Modal */}
                <Transition appear show={isModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-50 inset-0 overflow-y-auto"
            onClose={() => setIsModalOpen(false)}
          >
            <div className="flex items-center justify-center min-h-screen px-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="bg-white rounded-lg max-w-md w-full mx-auto p-6 space-y-4 shadow-xl transition-all">
                  <Dialog.Title className="text-xl font-bold text-indigo-600">
                    Please select one of the options
                  </Dialog.Title>
                  <button
                  
                  onClick={
                    ()=>{
                      handleUploadLength()
                    }
                    }
                  >
                    Length
                  </button>
                  <button 
                  
                  onClick={
                  ()=>{
                    handleUploadArea()
                  }
                  }
                  >
                    Area
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
    </div>


  );
};

export default ExcelLoader;
