import jsPDF from "jspdf";
import { getCustomerName } from "../../utils/globalFunctions";

// Export the main generateEstimate function
export async function generateEstimate(order, user, templateId = "template1", isBlob = false) {
    switch (templateId) {
        case "template1":
            return generateEstimateTemplate1(order, user, isBlob);
        case "template2":
            return generateEstimateTemplate2(order, user, isBlob);
        case "template3":
            return generateEstimateTemplate3(order, user, isBlob);
        default:
            throw new Error("Invalid template ID");
    }
}

// Template 1: Classic Template
async function generateEstimateTemplate1(order, user, isBlob) {
    try {
        const doc = new jsPDF();

        // Extract necessary data
        const customer_id = order?.customer_id;
        const store_id = order?.store_id;
        const customerName = await getCustomerName({ store_id, customer_id });
        const total = parseFloat(order?.customerTotal) || 0;
        const storeName = user?.storeName || "";
        const userEmail = user?.email || "";

        // Calculate Discount and Tax
        let tax = 0;
        let discount = 0;
        let localTotal = total;

        if (order?.customerDiscountType === "percentage") {
            discount = (total * order.customerDiscount) / 100;
            localTotal -= discount;
        } else if (order?.customerDiscountType === "amount") {
            discount = order.customerDiscount;
            localTotal -= discount;
        }

        if (order.customerTax) {
            tax = (localTotal * 13) / 100;
            localTotal += tax;
        }

        const orders = order.orders;

        // --- Classic Design Start Here ---

        // Set Fonts and Colors for Classic Design
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);

        let currentY = 20;

        // Company Info
        doc.text(storeName, 15, currentY);
        doc.text(userEmail, 15, currentY + 5);

        // Estimate Title and Date
        const estimateDate = new Date().toLocaleDateString();
        doc.text("Estimate", 105, currentY, { align: "center" });
        doc.text(`Date: ${estimateDate}`, 195, currentY, { align: "right" });

        currentY += 15;

        // Customer Info
        doc.text("Estimate For:", 15, currentY);
        doc.text(customerName, 15, currentY + 5);

        currentY += 15;

        // Define column positions and widths
        const columns = {
            roomName: { x: 15, width: 25 },
            fabricName: { x: 45, width: 25 },
            description: { x: 75, width: 50 },
            quantity: { x: 130, width: 15 },
            unitPrice: { x: 150, width: 25 },
            amount: { x: 180, width: 15 },
        };

        // Item Table Header
        doc.setFont("Helvetica", "bold");
        doc.text("Room", columns.roomName.x, currentY);
        doc.text("Fabric", columns.fabricName.x, currentY);
        doc.text("Description", columns.description.x, currentY);
        doc.text("Qty", columns.quantity.x, currentY);
        doc.text("Unit Price", columns.unitPrice.x, currentY);
        doc.text("Amount", columns.amount.x, currentY);

        doc.setFont("Helvetica", "normal");
        currentY += 5;

        // Horizontal Line
        doc.setDrawColor(0, 0, 0);
        doc.line(15, currentY, 195, currentY);
        currentY += 5;

        // Initialize Y position for items
        let yPos = currentY;

        // Add Items
        orders &&
            orders.forEach((orderItem) => {
                const roomName = orderItem.user_inputs.room_name || "N/A";
                const fabricName = orderItem.selectedProduct?.fabric_name || "N/A";
                const description = buildItemDescription(orderItem);

                const quantityValue = orderItem.quantity ? parseFloat(orderItem.quantity) : 1;
                const amountValue = parseFloat(orderItem.customerTotal || 0);
                const unitPriceValue = amountValue / quantityValue;

                const quantity = quantityValue.toString();
                const unitPrice = `$${unitPriceValue.toFixed(2)}`;
                const amount = `$${amountValue.toFixed(2)}`;

                // Convert text to lines considering the column widths
                const roomNameLines = doc.splitTextToSize(roomName, columns.roomName.width);
                const fabricNameLines = doc.splitTextToSize(fabricName, columns.fabricName.width);
                const descriptionLines = doc.splitTextToSize(description, columns.description.width);

                // Determine the maximum number of lines
                const itemLineCount = Math.max(
                    roomNameLines.length,
                    fabricNameLines.length,
                    descriptionLines.length
                );

                // Check for page break
                if (yPos + itemLineCount * 5 > 270) {
                    doc.addPage();
                    yPos = 20;

                    // Re-add table headers on new page
                    doc.setFont("Helvetica", "bold");
                    doc.text("Room", columns.roomName.x, yPos);
                    doc.text("Fabric", columns.fabricName.x, yPos);
                    doc.text("Description", columns.description.x, yPos);
                    doc.text("Qty", columns.quantity.x, yPos);
                    doc.text("Unit Price", columns.unitPrice.x, yPos);
                    doc.text("Amount", columns.amount.x, yPos);

                    doc.setFont("Helvetica", "normal");
                    yPos += 5;

                    // Horizontal Line
                    doc.setDrawColor(0, 0, 0);
                    doc.line(15, yPos, 195, yPos);
                    yPos += 5;
                }

                // Output data
                // Room Name Column
                doc.text(roomNameLines, columns.roomName.x, yPos);

                // Fabric Name Column
                doc.text(fabricNameLines, columns.fabricName.x, yPos);

                // Description Column
                doc.text(descriptionLines, columns.description.x, yPos);

                // Qty
                doc.text(quantity, columns.quantity.x, yPos);

                // Unit Price
                doc.text(unitPrice, columns.unitPrice.x, yPos);

                // Amount
                doc.text(amount, columns.amount.x, yPos);

                // Calculate item height
                const itemHeight = itemLineCount * 5;

                yPos += itemHeight + 5;
            });

        // Add Summary Section
        if (yPos > 220) {
            doc.addPage();
            yPos = 20;
        }

        // Add Summary Title
        yPos += 10;
        doc.setFont("Helvetica", "bold");
        doc.setFontSize(14);
        doc.text("Summary", 15, yPos);
        yPos += 7;

        // Horizontal Line
        doc.setDrawColor(0, 0, 0);
        doc.line(15, yPos, 195, yPos);
        yPos += 10;

        // Subtotal
        doc.setFontSize(12);
        doc.text("Subtotal:", 130, yPos);
        doc.setFont("Helvetica", "normal");
        doc.text(`$${total.toFixed(2)}`, 195, yPos, { align: "right" });

        yPos += 7;

        // Discount
        doc.setFont("Helvetica", "bold");
        doc.text("Discount:", 130, yPos);
        doc.setFont("Helvetica", "normal");
        doc.text(`-$${discount.toFixed(2)}`, 195, yPos, { align: "right" });

        yPos += 7;

        // Tax
        doc.setFont("Helvetica", "bold");
        doc.text("Tax:", 130, yPos);
        doc.setFont("Helvetica", "normal");
        doc.text(`$${tax.toFixed(2)}`, 195, yPos, { align: "right" });

        yPos += 10;

        // Horizontal Line
        doc.setDrawColor(0, 0, 0);
        doc.line(130, yPos, 195, yPos);
        yPos += 7;

        // Total
        doc.setFont("Helvetica", "bold");
        doc.setFontSize(14);
        doc.text("Total:", 130, yPos);
        doc.text(`$${localTotal.toFixed(2)}`, 195, yPos, { align: "right" });

        // Footer
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.setTextColor(150, 150, 150);
            doc.text(`Page ${i} of ${pageCount}`, 105, 290, { align: "center" });
        }

        if (isBlob) {
            return doc.output("blob");
        }
        doc.save("estimate.pdf");
    } catch (error) {
        console.error("An error occurred while generating the estimate:", error);
        throw error;
    }
}
// Template 2: Modern Template
async function generateEstimateTemplate2(order, user, isBlob) {
    try {
        const doc = new jsPDF();

        // Extract necessary data
        const customer_id = order?.customer_id;
        const store_id = order?.store_id;
        const customerName = await getCustomerName({ store_id, customer_id });
        const total = parseFloat(order?.customerTotal) || 0;
        const storeName = user?.storeName || "";
        const userEmail = user?.email || "";

        // Calculate Discount and Tax
        let tax = 0;
        let discount = 0;
        let localTotal = total;

        if (order?.customerDiscountType === "percentage") {
            discount = (total * order.customerDiscount) / 100;
            localTotal -= discount;
        } else if (order?.customerDiscountType === "amount") {
            discount = order.customerDiscount;
            localTotal -= discount;
        }

        if (order.customerTax) {
            tax = (localTotal * 13) / 100;
            localTotal += tax;
        }

        const orders = order.orders;

        // --- Professional Font Adjustments Start Here ---

        // Define font families
        const primaryFont = "Times"; // Changed from "Helvetica" to "Times" for a more professional look
        const secondaryFont = "Times"; // Consistent font family for secondary text

        let currentY = 20;

        // Add Header Section with Company Name
        doc.setFont(primaryFont, "bold"); // Use "Times" Bold for headers
        doc.setFontSize(16); // Reduced from 24 for a more balanced look
        doc.setTextColor(0, 123, 255); // Keeping the primary color
        doc.text(storeName, 15, currentY);
        currentY += 7; // Adjusted spacing

        // Horizontal Line
        doc.setDrawColor(200, 200, 200);
        doc.setLineWidth(0.5);
        doc.line(15, currentY, 195, currentY);
        currentY += 10;

        // Add Estimate Title and Date
        doc.setFontSize(14); // Reduced from 18
        doc.setTextColor(0, 0, 0);
        doc.setFont(primaryFont, "bold"); // Bold for title
        doc.text("Estimate", 15, currentY);
        currentY += 7;

        const estimateDate = new Date().toLocaleDateString();
        doc.setFontSize(11); // Reduced from 12
        doc.setFont(primaryFont, "normal"); // Normal for date
        doc.text(`Date: ${estimateDate}`, 15, currentY);
        currentY += 13;

        // Add Customer and Company Info Side by Side
        doc.setFontSize(11); // Reduced from 12
        doc.setFont(primaryFont, "normal"); // Ensure font is normal

        // Customer Info
        doc.text("Estimate For:", 15, currentY);
        doc.setTextColor(108, 117, 125); // Gray color for secondary text
        doc.setFont(secondaryFont, "normal");
        doc.text(customerName, 15, currentY + 5);

        // Company Info
        doc.setTextColor(0, 0, 0);
        doc.setFont(primaryFont, "normal");
        doc.text("Estimate From:", 110, currentY);
        doc.setTextColor(108, 117, 125); // Gray color for secondary text
        doc.text(storeName, 110, currentY + 5);
        doc.text(userEmail, 110, currentY + 10);

        currentY += 25;

        // Define column positions and widths
        const columns = {
            roomName: { x: 15, width: 25 },
            fabricName: { x: 45, width: 25 },
            description: { x: 75, width: 50 },
            quantity: { x: 130 },
            unitPrice: { x: 150 },
            amount: { x: 175 },
        };

        // Item Table Header
        doc.setFontSize(11); // Reduced from 12
        doc.setFont(primaryFont, "bold"); // Use "Times" Bold for table headers
        doc.setTextColor(0, 0, 0);
        doc.text("Room", columns.roomName.x, currentY);
        doc.text("Fabric", columns.fabricName.x, currentY);
        doc.text("Description", columns.description.x, currentY);
        doc.text("Qty", columns.quantity.x, currentY);
        doc.text("Unit Price", columns.unitPrice.x, currentY);
        doc.text("Amount", columns.amount.x, currentY);

        // Horizontal Line
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.5);
        doc.line(15, currentY + 2, 195, currentY + 2);
        currentY += 8;

        // Reset Font
        doc.setFont(primaryFont, "normal"); // Ensure normal weight for items
        doc.setFontSize(10);

        // Initialize Y position for items
        let yPos = currentY;

        // Add Items
        orders &&
            orders.forEach((orderItem) => {
                const roomName = orderItem.user_inputs.room_name || "N/A";
                const fabricName = orderItem.selectedProduct?.fabric_name || "N/A";
                const description = buildItemDescription(orderItem);

                const quantityValue = orderItem.quantity ? parseFloat(orderItem.quantity) : 1;
                const amountValue = parseFloat(orderItem.customerTotal || 0);
                const unitPriceValue = amountValue / quantityValue;

                const quantity = quantityValue.toString();
                const unitPrice = `$${unitPriceValue.toFixed(2)}`;
                const amount = `$${amountValue.toFixed(2)}`;

                // Convert text to lines considering the column widths
                const roomNameLines = doc.splitTextToSize(roomName, columns.roomName.width);
                const fabricNameLines = doc.splitTextToSize(fabricName, columns.fabricName.width);
                const descriptionLines = doc.splitTextToSize(description, columns.description.width);

                // Determine the maximum number of lines
                const itemLineCount = Math.max(
                    roomNameLines.length,
                    fabricNameLines.length,
                    descriptionLines.length
                );

                // Check for page break
                if (yPos + itemLineCount * 5 > 270) {
                    doc.addPage();
                    yPos = 20;

                    // Re-add table headers on new page
                    doc.setFont(primaryFont, "bold");
                    doc.setFontSize(11);
                    doc.setTextColor(0, 0, 0);
                    doc.text("Room", columns.roomName.x, yPos);
                    doc.text("Fabric", columns.fabricName.x, yPos);
                    doc.text("Description", columns.description.x, yPos);
                    doc.text("Qty", columns.quantity.x, yPos);
                    doc.text("Unit Price", columns.unitPrice.x, yPos);
                    doc.text("Amount", columns.amount.x, yPos);

                    // Horizontal Line
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.5);
                    doc.line(15, yPos + 2, 195, yPos + 2);

                    doc.setFont(primaryFont, "normal");
                    doc.setFontSize(10);

                    yPos += 8;
                }

                // Output data
                // Room Name Column
                doc.setTextColor(0, 0, 0);
                doc.text(roomNameLines, columns.roomName.x, yPos);

                // Fabric Name Column
                doc.text(fabricNameLines, columns.fabricName.x, yPos);

                // Description Column
                doc.text(descriptionLines, columns.description.x, yPos);

                // Qty
                doc.text(quantity, columns.quantity.x, yPos);

                // Unit Price
                doc.text(unitPrice, columns.unitPrice.x, yPos);

                // Amount
                doc.text(amount, columns.amount.x, yPos);

                // Calculate item height
                const itemHeight = itemLineCount * 5;

                yPos += itemHeight + 5;
            });

        // Add Summary Section
        if (yPos > 220) {
            doc.addPage();
            yPos = 20;
        }

        // Add Summary Title
        yPos += 10;
        doc.setFont(primaryFont, "bold"); // Use "Times" Bold for summary title
        doc.setFontSize(12); // Slightly smaller than header
        doc.text("Summary", 15, yPos);
        yPos += 7;

        // Horizontal Line
        doc.setDrawColor(0, 0, 0);
        doc.line(15, yPos, 195, yPos);
        yPos += 10;

        // Subtotal
        doc.setFontSize(11); // Reduced from 12
        doc.text("Subtotal:", 130, yPos);
        doc.setFont(primaryFont, "normal");
        doc.text(`$${total.toFixed(2)}`, 195, yPos, { align: "right" });

        yPos += 7;

        // Discount
        doc.setFont(primaryFont, "bold"); // Bold label
        doc.text("Discount:", 130, yPos);
        doc.setFont(primaryFont, "normal");
        doc.text(`-$${discount.toFixed(2)}`, 195, yPos, { align: "right" });

        yPos += 7;

        // Tax
        doc.setFont(primaryFont, "bold"); // Bold label
        doc.text("Tax:", 130, yPos);
        doc.setFont(primaryFont, "normal");
        doc.text(`$${tax.toFixed(2)}`, 195, yPos, { align: "right" });

        yPos += 10;

        // Horizontal Line
        doc.setDrawColor(0, 0, 0);
        doc.line(130, yPos, 195, yPos);
        yPos += 7;

        // Total
        doc.setFont(primaryFont, "bold"); // Bold label
        doc.setFontSize(14); // Highlight total
        doc.text("Total:", 130, yPos);
        doc.text(`$${localTotal.toFixed(2)}`, 195, yPos, { align: "right" });

        // Footer
        const pageCount = doc.internal.getNumberOfPages();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.setTextColor(150, 150, 150);
            doc.setFont("Times", "normal"); // Ensure footer uses "Times" normal
            doc.text(`Page ${i} of ${pageCount}`, pageWidth / 2, pageHeight - 10, { align: "center" });
        }

        if (isBlob) {
            return doc.output("blob");
        }
        doc.save("estimate.pdf");
    } catch (error) {
        console.error("An error occurred while generating the estimate:", error);
        throw error;
    }
}
// Template 3: Premium Template
async function generateEstimateTemplate3(order, user, isBlob) {
    try {
        // Check if order is defined
        if (!order) {
            console.error('Order object is undefined.');
            throw new Error('Order object is undefined.');
        }

        const doc = new jsPDF();

        // Extract necessary data
        const customer_id = order?.customer_id;
        const store_id = order?.store_id;
        const customerName = await getCustomerName({ store_id, customer_id });
        const total = parseFloat(order?.customerTotal) || 0;
        const storeName = user?.storeName || "";
        const userEmail = user?.email || "";

        // Calculate Discount and Tax
        let tax = 0;
        let discount = 0;
        let localTotal = total;

        if (order?.customerDiscountType === "percentage") {
            discount = (total * order.customerDiscount) / 100;
            localTotal -= discount;
        } else if (order?.customerDiscountType === "amount") {
            discount = order.customerDiscount;
            localTotal -= discount;
        }

        if (order.customerTax) {
            tax = (localTotal * 13) / 100;
            localTotal += tax;
        }

        const orders = order.orders;

        // --- Premium Design Start Here ---

        // Add Company Logo
        const logoUrl = "https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/logoBlack.png?alt=media&token=76e7281c-17ee-4d7e-a52e-75d56bebe421"; // Replace with your logo path

        const logoData = await getImageData(logoUrl);
        if (logoData) {
            doc.addImage(logoData.dataUrl, "PNG", 15, 10, 40, 8);
        }

        let currentY = 20;

        // Set Fonts and Colors for Premium Design
        const primaryColor = [40, 40, 40]; // Dark gray
        const accentColor = [255, 0, 0]; // Red accent

        // Add Header Section
        doc.setFont("Times", "bold");
        doc.setFontSize(28);
        doc.setTextColor(primaryColor[0], primaryColor[1], primaryColor[2]);
        doc.text("Estimate", 105, currentY + 10, { align: "center" });
        currentY += 15;

        // Add Decorative Line
        doc.setDrawColor(accentColor[0], accentColor[1], accentColor[2]);
        doc.setLineWidth(1.5);
        doc.line(70, currentY, 140, currentY);
        currentY += 10;

        // Add Company Info
        doc.setFontSize(12);
        doc.setFont("Times", "normal");
        doc.text(storeName, 15, currentY);
        doc.text(userEmail, 15, currentY + 5);

        // Add Estimate Details
        const estimateDate = new Date().toLocaleDateString();
        const estimateNumber = order && order.order_id ? order.order_id.slice(-4).toUpperCase() : 'N/A';
        doc.text(`Date: ${estimateDate}`, 150, currentY);
        doc.text(`Estimate #: ${estimateNumber}`, 150, currentY + 5);

        currentY += 20;

        // Add Customer Info
        doc.text("Estimate For:", 15, currentY);
        doc.setFont("Times", "bold");
        doc.text(customerName, 15, currentY + 5);
        doc.setFont("Times", "normal");

        currentY += 15;

        // Add Item Table Header with Background
        doc.setFillColor(primaryColor[0], primaryColor[1], primaryColor[2]);
        doc.rect(15, currentY, 180, 10, "F");

        doc.setFont("Times", "bold");
        doc.setFontSize(12);
        doc.setTextColor(255, 255, 255); // White text
        doc.text("Room", 20, currentY + 7);
        doc.text("Fabric", 50, currentY + 7);
        doc.text("Description", 80, currentY + 7);
        doc.text("Qty", 130, currentY + 7);
        doc.text("Unit Price", 150, currentY + 7);
        doc.text("Amount", 175, currentY + 7);

        // Reset Font and Color
        doc.setFont("Times", "normal");
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);

        // Initialize Y position for items
        let yPos = currentY + 15;

        // Define column positions and widths
        const columns = {
            roomName: { x: 20, width: 25 },
            fabricName: { x: 50, width: 25 },
            description: { x: 80, width: 45 },
            quantity: { x: 130 },
            unitPrice: { x: 150 },
            amount: { x: 175 },
        };

        // Add Items
        orders &&
            orders.forEach((orderItem) => {
                const roomName = orderItem.user_inputs.room_name || "N/A";
                const fabricName = orderItem.selectedProduct?.fabric_name || "N/A";
                const description = buildItemDescription(orderItem);

                const quantityValue = orderItem.quantity ? parseFloat(orderItem.quantity) : 1;
                const amountValue = parseFloat(orderItem.customerTotal || 0);
                const unitPriceValue = amountValue / quantityValue;

                const quantity = quantityValue.toString();
                const unitPrice = `$${unitPriceValue.toFixed(2)}`;
                const amount = `$${amountValue.toFixed(2)}`;

                // Convert text to lines considering the column widths
                const roomNameLines = doc.splitTextToSize(roomName, columns.roomName.width);
                const fabricNameLines = doc.splitTextToSize(fabricName, columns.fabricName.width);
                const descriptionLines = doc.splitTextToSize(description, columns.description.width);

                // Determine the maximum number of lines
                const itemLineCount = Math.max(
                    roomNameLines.length,
                    fabricNameLines.length,
                    descriptionLines.length
                );

                // Check for page break
                if (yPos + itemLineCount * 5 > 250) {
                    doc.addPage();
                    yPos = 20;

                    // Re-add table headers on new page
                    doc.setFillColor(primaryColor[0], primaryColor[1], primaryColor[2]);
                    doc.rect(15, yPos, 180, 10, "F");

                    doc.setFont("Times", "bold");
                    doc.setFontSize(12);
                    doc.setTextColor(255, 255, 255); // White text
                    yPos += 7;
                    doc.text("Room", columns.roomName.x, yPos);
                    doc.text("Fabric", columns.fabricName.x, yPos);
                    doc.text("Description", columns.description.x, yPos);
                    doc.text("Qty", columns.quantity.x, yPos);
                    doc.text("Unit Price", columns.unitPrice.x, yPos);
                    doc.text("Amount", columns.amount.x, yPos);

                    // Reset Font and Color
                    doc.setFont("Times", "normal");
                    doc.setFontSize(10);
                    doc.setTextColor(0, 0, 0);

                    yPos += 10;
                }

                // Output data
                // Room Name
                doc.text(roomNameLines, columns.roomName.x, yPos);

                // Fabric Name
                doc.text(fabricNameLines, columns.fabricName.x, yPos);

                // Description
                doc.text(descriptionLines, columns.description.x, yPos);

                // Qty
                doc.text(quantity, columns.quantity.x, yPos);

                // Unit Price
                doc.text(unitPrice, columns.unitPrice.x, yPos);

                // Amount
                doc.text(amount, columns.amount.x, yPos);

                // Calculate item height
                const itemHeight = itemLineCount * 5;

                yPos += itemHeight + 5;
            });

        // Add Summary Section
        if (yPos > 220) {
            doc.addPage();
            yPos = 20;
        }

        // Add Summary Title
        yPos += 10;
        doc.setFont("Times", "bold");
        doc.setFontSize(14);
        doc.text("Summary", 15, yPos);
        yPos += 7;

        // Horizontal Line
        doc.setDrawColor(0, 0, 0);
        doc.line(15, yPos, 195, yPos);
        yPos += 10;

        // Subtotal
        doc.setFontSize(12);
        doc.text("Subtotal:", 125, yPos);
        doc.setFont("Times", "normal");
        doc.text(`$${total.toFixed(2)}`, 195, yPos, { align: "right" });

        yPos += 10;

        // Discount
        doc.setFont("Times", "bold");
        doc.text("Discount:", 125, yPos);
        doc.setFont("Times", "normal");
        doc.text(`-$${discount.toFixed(2)}`, 195, yPos, { align: "right" });

        yPos += 10;

        // Tax
        doc.setFont("Times", "bold");
        doc.text("Tax:", 125, yPos);
        doc.setFont("Times", "normal");
        doc.text(`$${tax.toFixed(2)}`, 195, yPos, { align: "right" });

        yPos += 10;

        // Horizontal Line
        doc.setDrawColor(0, 0, 0);
        doc.line(125, yPos, 195, yPos);
        yPos += 7;

        // Total
        doc.setFont("Times", "bold");
        doc.setFontSize(14);
        doc.text("Total:", 125, yPos);
        doc.text(`$${localTotal.toFixed(2)}`, 195, yPos, { align: "right" });

        // Footer with Thank You Note
        doc.setFontSize(12);
        doc.setFont("Times", "italic");
        doc.setTextColor(100, 100, 100);
        doc.text("Thank you for your business!", 105, 280, { align: "center" });

        // Page Numbers
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.setTextColor(150, 150, 150);
            doc.text(`Page ${i} of ${pageCount}`, 105, 290, { align: "center" });
        }

        if (isBlob) {
            return doc.output("blob");
        }
        doc.save("estimate.pdf");
    } catch (error) {
        console.error("An error occurred while generating the estimate:", error);
        throw error;
    }
}

// Helper Functions

// Function to build item description
function buildItemDescription(orderItem) {
    const description = descriptionBuilder(orderItem.variants);

    const measurementType = orderItem.user_inputs?.measurement_type;
    const width = orderItem.user_inputs?.width || "";
    const height = orderItem.user_inputs?.height || "";
    const fractionsWidth = orderItem.user_inputs?.fractions?.width || "";
    const fractionsHeight = orderItem.user_inputs?.fractions?.height || "";

    let size = "";
    if (measurementType === "inch") {
        size = `${width} ${fractionsWidth}in x ${height} ${fractionsHeight}in`;
    } else {
        size = `${width}cm x ${height}cm`;
    }

    return `${description}\nSize: ${size}`;
}

// Function to get image data (for logos)
async function getImageData(url) {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const dataUrl = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });

        return {
            dataUrl,
        };
    } catch (error) {
        console.error("Error fetching logo:", error);
        return null;
    }
}

// Description Builder Function
function descriptionBuilder(variants) {
    let descriptionParts = [];
    let variantKeys = Object.keys(variants || {});
    for (let variantKey of variantKeys) {
        let v = variants[variantKey];
        let variant_value = v.value || "";
        let detailsArray = [];

        let vDetails = Object.keys(v?.details || {});
        if (vDetails.length > 0) {
            for (let detail of vDetails) {
                detailsArray.push(v.details[detail].value || "");
            }
        }

        let detailsString = detailsArray.join(", ");

        let variantDescription = variant_value;
        if (detailsString) {
            variantDescription += ` (${detailsString})`;
        }

        descriptionParts.push(variantDescription);
    }
    return descriptionParts.join(" | ");
}