import React,{useEffect,useState} from 'react'
import { addressURL, globalAlert, globalSuccess } from '../../utils/globalFunctions';
import axios from 'axios'

export default function AddCatalog() {
    const [priceList,setPriceList] = useState([])
    const [noPriceList,setNoPriceList] = useState(false)
    const [loading,setLoading] = useState(true)
    const [trigger,setTrigger] = useState(false)
    useEffect(() => {

        axios
          .get(addressURL + `/api/dealer/manufacturerPricing`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true,
          })
          .then((res) => {
            if(res.data.success){
                console.log(res.data?.priceList)
                setPriceList(res.data?.priceList)
            }else{
                if(res.data?.message === "All included"){
                    console.log("No price list found")
                    setNoPriceList(true)
                    setPriceList([])
                }else{
                    globalAlert("Something is wrong, please contact with developer. Code:#7327")
                }
            }
            setLoading(false)
          })
          .catch((err) => console.log(err));
      }, [trigger]);

      function handleAdd(fabric_name){
        console.log(fabric_name)
        axios
          .post(addressURL +
            `/api/dealer/addCatalog`, {
                fabric_name: fabric_name
            }, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                },
                withCredentials: true,
            })
            .then((res) => {
                if(res.data.success){
                    globalSuccess(res.data.message)
                    setTrigger(!trigger)
                    if(fabric_name === 'All')
                        window.location.href = "/dealers/catalog"
                }else{
                    globalAlert(res.data.message)
                }
            })
            .catch((err) => console.log(err));


      }

    return (
        <div className='flex flex-col w-full justify-center'>
            <div className="m-auto">
               <button 
                    className="bg-gray-600 text-white p-2 mt-2 rounded mb-4"
                    onClick={(e) => {
                        if(noPriceList)
                            return
                        e.preventDefault()
                        handleAdd('All')
                    }
                    }
                >
                    Add All
                </button>
                </div>
                <div>
                    {
                        noPriceList && 
                        <>
                            <p className='text-center'>
                                You have added all the available items into your catalog.
                            </p>
                        </>
                    }
                </div>
            <div className="flex w-full justify-center">
             
                <div className="w-5/6  mb-16 ">
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                    {priceList.map((list) => (
                        <div
                        key={list.fabric_name}
                        onClick={(e) => {
                          
                            handleAdd(list.fabric_name)
                        }
                        }
                        className="relative flex-col items-center space-x-3 rounded-lg border border-gray-300 bg-white p-2 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                        >
                        {/* <div style={{marginLeft:0}} className="flex-shrink-0 mb-2">
                            {
                                service.icon
                            }
                        </div> */}
                        <div style={{marginLeft:0}}>
                            <a className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true" />
                            <p className="text-base font-medium  text-gray-900">
                                {list.fabric_name}
                            </p>
                            <p className="text-xs font-medium  text-gray-900">
                                {list.design_type}
                            </p>
                            <p className="text-sm text-gray-500">
                                ${list.price}
                            </p>
                            </a>
                        </div>
                        <div style={{marginLeft:0}} className="mb-2">
                            
                            <button
                                type="button"
                             
                                className="inline-flex mt-2 items-center p-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                    
                                    Add
                        
                            </button>
                            
                        </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
            
}
