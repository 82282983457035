import React, { useState } from 'react';
import {
  ArrowRightIcon,
} from '@heroicons/react/24/solid';
import Footer from '../components/Footer';
import { sendEmail } from '../utils/firebase';
import { joinWaitlist } from '../utils/globalFunctions';
import { toast } from 'react-toastify';

function WaitlistPage() {
  const [email, setEmail] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await joinWaitlist({ email, businessType });
      if (result.success) {
        await sendEmail({
            to: email,
            sender: 'Supply Circle',
            subject: 'Welcome to the Supply Circle Waitlist',
            text: `Dear ${email},
  
  Thank you for joining the Supply Circle waitlist!
  
  We're excited to have you on board and can't wait to show you how we're revolutionizing supply chain management.
  
  One of our specialists will contact you soon to guide you through the onboarding process.
  
  Best regards,
  The Supply Circle Team`,
            html: `
              <p>Dear ${email},</p>
              <p>Thank you for joining the <strong>Supply Circle</strong> waitlist!</p>
              <p>We're excited to have you on board and can't wait to show you how we're revolutionizing supply chain management.</p>
              <p>One of our specialists will contact you soon to guide you through the onboarding process.</p>
              <p>Best regards,<br/>The Supply Circle Team</p>
            `,
            attachments: [],
          }).then(()=>{
            toast.success(
                'You have successfully joined our waitlist. One of our specialists will contact you soon for your onboarding process.'
              );
              setSubmitted(true);
              setLoading(false);
          })
      } else {
        toast.error('Failed to join the waitlist. Please try again later.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An unexpected error occurred.');
      setLoading(false);
    }
  };

  return (
    <div className="bg-white text-gray-900">
      {/* Header */}
      <header className="fixed w-full bg-white shadow z-50">
        <nav className="container mx-auto px-6 py-4 flex items-center justify-between">
          <div className="flex items-center">
            {/* Logo */}
            <img
              src="/logoBlack.png"
              alt="Supply Circle Logo"
              className="h-10 w-auto mr-2"
              onClick={
                ()=>{
                  window.location.href = "/"
                }
              }
            />
          </div>
          <div className="hidden md:flex items-center space-x-6">
            <a href="/" className="hover:text-gray-500">
              Home
            </a>
            <a href="/contact" className="hover:text-gray-500">
              Contact
            </a>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="pt-24 bg-gray-50">
        <div className="container mx-auto px-6 py-20 flex flex-col lg:flex-row items-center">
          {/* Left Column */}
          <div className="w-full lg:w-1/2">
            <h1 className="text-5xl font-bold leading-tight mb-6">
              Join the <span className="text-black">Supply Circle</span> Revolution
            </h1>
            <p className="text-xl text-gray-700 mb-8">
              Be among the first to experience a new era in supply chain management. Connect directly with manufacturers, streamline your procurement process, and drive growth.
            </p>
            {submitted ? (
              <div className="bg-green-100 text-green-800 p-4 rounded">
                Thank you for joining our waitlist! We'll be in touch soon.
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="w-full max-w-md">
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-black"
                    placeholder="you@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 mb-2" htmlFor="businessType">
                    Business Type
                  </label>
                  <select
                    id="businessType"
                    className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-black"
                    value={businessType}
                    onChange={(e) => setBusinessType(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select your business type
                    </option>
                    <option value="Contractor">Contractor</option>
                    <option value="Blinds Dealer">Blinds Dealer</option>
                    <option value="Flooring Specialist">Flooring Specialist</option>
                    <option value="Tiles Retailer">Tiles Retailer</option>
                    <option value="Countertops Installer">Countertops Installer</option>
                    <option value="Wall Panels Supplier">Wall Panels Supplier</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <button
                  type="submit"
                  className="inline-flex items-center px-6 py-3 bg-black text-white text-lg rounded hover:bg-gray-800 transition duration-300"
                  disabled={loading}
                >
                  {loading ? 'Processing...' : 'Join Waitlist'}
                  <ArrowRightIcon
                    className={`w-5 h-5 ml-2 transition-transform duration-500 ${
                      loading ? 'animate-spin' : ''
                    }`}
                  />
                </button>
                <p className='mt-2'>
                    Do you already have an account, please <a className='text-indigo-600 underline' href='login'>login</a>
                </p>
              </form>
            )}
          </div>
          {/* Right Column */}
          <div className="w-full lg:w-1/2 mt-10 lg:mt-0">
            <img
              src="/logistics.svg"
              alt="Waitlist Illustration"
              className="w-full"
            />
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section id="mission" className="container mx-auto px-6 py-20">
        <h2 className="text-4xl font-bold text-center mb-12">Our Mission</h2>
        <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
          Supply Circle is dedicated to revolutionizing the supply chain by connecting manufacturers and dealers through an intuitive platform. We simplify order management, automate logistics, and ensure seamless delivery at every step. Our mission is to empower businesses with a user-friendly system that reduces manual work, minimizes errors, and drives growth.
        </p>
      </section>

      <Footer />
    </div>
  );
}

export default WaitlistPage;