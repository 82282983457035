
import React, { useEffect, useState } from 'react'
import { getCustomerName } from '../utils/globalFunctions'


export default function CustomerName({customer_id,store_id}){
    const [customerName, setCustomerName] = useState("")
    useEffect(() => {
     getCustomerName({store_id,customer_id}).then((res)=>{
      setCustomerName(res)
      
     })
    }, [customer_id,store_id])
    return <p>{customerName}</p>
  
  }