import React from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import Footer from '../components/Footer';

function AboutUsPage() {
  return (
    <div className="bg-white text-gray-900">
      {/* Header */}
      <header className="fixed w-full bg-white shadow z-50">
        <nav className="container mx-auto px-6 py-4 flex items-center justify-between">
          <div className="flex items-center">
            {/* Logo */}
            <img
              src="/logoBlack.png"
              alt="Supply Circle Logo"
              className="h-10 w-auto mr-2"
              onClick={
                ()=>{
                  window.location.href = "/"
                }
              }
            />
          </div>
          <div className="hidden md:flex items-center space-x-6">
            <a href="/" className="hover:text-gray-500">
              Home
            </a>
            <a href="/about" className="hover:text-gray-500">
              About
            </a>
            <a href="/contact" className="hover:text-gray-500">
              Contact
            </a>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="pt-24 bg-gray-50">
        <div className="container mx-auto px-6 py-20 text-center">
          <h1 className="text-5xl font-bold leading-tight mb-6">
            About <span className="text-black">Supply Circle</span>
          </h1>
          <p className="text-xl text-gray-700 max-w-3xl mx-auto">
            Revolutionizing the supply chain by connecting manufacturers and dealers through an intuitive platform that simplifies order management and automates logistics.
          </p>
        </div>
      </section>

      {/* Mission and Vision Section */}
      <section className="container mx-auto px-6 py-20">
        <div className="flex flex-col lg:flex-row items-center">
          {/* Left Column */}
          <div className="w-full lg:w-1/2 lg:pr-12">
            <h2 className="text-4xl font-bold mb-6">Our Mission</h2>
            <p className="text-lg text-gray-700 mb-6">
              Our mission is to empower businesses with a user-friendly system that reduces manual work, minimizes errors, and drives growth. By leveraging cutting-edge technology, we aim to foster productive, transparent partnerships within our network.
            </p>
            <h2 className="text-4xl font-bold mb-6">Our Vision</h2>
            <p className="text-lg text-gray-700">
              To revolutionize the B2B supply chain industry by creating a seamless ecosystem where manufacturers, wholesalers, and retailers collaborate efficiently, driving innovation and success for all stakeholders.
            </p>
          </div>
          {/* Right Column */}
          <div className="w-full lg:w-1/2 mt-12 lg:mt-0">
            <img
              src="/business.svg"
              alt="Our Mission Illustration"
              className="w-full"
            />
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="bg-gray-100">
        <div className="container mx-auto px-6 py-20">
          <h2 className="text-4xl font-bold text-center mb-12">Our Core Values</h2>
          <div className="flex flex-wrap -mx-6">
            {/* Value 1 */}
            <div className="w-full md:w-1/3 px-6 mb-12">
              <div className="bg-white rounded-lg shadow-md p-8 text-center">
                <CheckIcon className="w-16 h-16 mx-auto text-black mb-4" />
                <h3 className="text-2xl font-semibold mb-4">Innovation</h3>
                <p className="text-gray-700">
                  We embrace new ideas and technologies to continuously improve our platform and services.
                </p>
              </div>
            </div>
            {/* Value 2 */}
            <div className="w-full md:w-1/3 px-6 mb-12">
              <div className="bg-white rounded-lg shadow-md p-8 text-center">
                <CheckIcon className="w-16 h-16 mx-auto text-black mb-4" />
                <h3 className="text-2xl font-semibold mb-4">Transparency</h3>
                <p className="text-gray-700">
                  Building trust through open communication and honest relationships with our partners and clients.
                </p>
              </div>
            </div>
            {/* Value 3 */}
            <div className="w-full md:w-1/3 px-6 mb-12">
              <div className="bg-white rounded-lg shadow-md p-8 text-center">
                <CheckIcon className="w-16 h-16 mx-auto text-black mb-4" />
                <h3 className="text-2xl font-semibold mb-4">Customer Focus</h3>
                <p className="text-gray-700">
                  Delivering exceptional value and experiences by understanding and exceeding our customers' needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* Call to Action Section */}
      <section className="bg-black">
        <div className="container mx-auto px-6 py-20 text-center">
          <h2 className="text-4xl font-bold text-white mb-6">
            Join Us on Our Journey
          </h2>
          <p className="text-xl text-gray-300 mb-8">
            Be part of the revolution in supply chain management. Let's create a more connected and efficient industry together.
          </p>
          <a
            href="/contact"
            className="inline-flex items-center px-8 py-4 bg-white text-black text-lg rounded hover:bg-gray-200 transition duration-300"
          >
            Contact Us
          </a>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default AboutUsPage;



      // {/* Team Section */}
      // <section className="container mx-auto px-6 py-20">
      //   <h2 className="text-4xl font-bold text-center mb-12">Meet Our Team</h2>
      //   <div className="flex flex-wrap -mx-6">
      //     {/* Team Member 1 */}
      //     <div className="w-full md:w-1/3 px-6 mb-12">
      //       <div className="text-center">
      //         <img
      //           src="/images/team-member-1.jpg"
      //           alt="Team Member 1"
      //           className="w-40 h-40 rounded-full mx-auto mb-4"
      //         />
      //         <h3 className="text-2xl font-semibold mb-2">Jane Smith</h3>
      //         <p className="text-gray-700 mb-2">Chief Executive Officer</p>
      //         <p className="text-gray-600">
      //           Jane brings over 15 years of experience in supply chain management and is passionate about innovation.
      //         </p>
      //       </div>
      //     </div>
      //     {/* Team Member 2 */}
      //     <div className="w-full md:w-1/3 px-6 mb-12">
      //       <div className="text-center">
      //         <img
      //           src="/images/team-member-2.jpg"
      //           alt="Team Member 2"
      //           className="w-40 h-40 rounded-full mx-auto mb-4"
      //         />
      //         <h3 className="text-2xl font-semibold mb-2">John Doe</h3>
      //         <p className="text-gray-700 mb-2">Chief Technology Officer</p>
      //         <p className="text-gray-600">
      //           John leads our technology team with a focus on building scalable and user-friendly solutions.
      //         </p>
      //       </div>
      //     </div>
      //     {/* Team Member 3 */}
      //     <div className="w-full md:w-1/3 px-6 mb-12">
      //       <div className="text-center">
      //         <img
      //           src="/images/team-member-3.jpg"
      //           alt="Team Member 3"
      //           className="w-40 h-40 rounded-full mx-auto mb-4"
      //         />
      //         <h3 className="text-2xl font-semibold mb-2">Emily Johnson</h3>
      //         <p className="text-gray-700 mb-2">Chief Operations Officer</p>
      //         <p className="text-gray-600">
      //           Emily oversees our operations, ensuring seamless integration between our platform and clients.
      //         </p>
      //       </div>
      //     </div>
      //   </div>
      // </section>