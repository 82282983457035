import React from "react";
import axios from "axios";
import { useState, useMemo,useEffect } from "react";
import { Container } from "../../components/Container";
import {FadeIn} from "../../components/FadeIn";
import { motion } from "framer-motion";
import { GridPattern } from "../../components/GridPattern";
import loginWithEmail from "../../utils/loginWithEmail";
import { globalAlert } from "../../utils/globalFunctions";
import signUpWithEmail from "../../utils/signUpWithEmail";
import { createUserDocument } from "../../utils/globalFunctions";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { WhiteLogo, BlackLogo } from "../../components/Logo";
import { useNavigate } from "react-router-dom";
export default function OnBoarding({user}) {
const [manufacturer,setManufacturer] = useState(null)
let {manufacturerID} = useParams()
const navigate = useNavigate()



  useEffect(()=>{
    if(user){
      window.location.href = "/"
    }


    //  decode the uri 
    manufacturerID = decodeURIComponent(manufacturerID)
    console.log(manufacturerID)
    setManufacturer(manufacturerID)



    const checkManufacturerExistence = async () => {
        const db = getFirestore();
        const manufacturerRef = doc(db, "Manufacturers", manufacturerID);
        const manufacturerDoc = await getDoc(manufacturerRef);
        if (manufacturerDoc.exists()) {
            console.log("Manufacturer exists");
        } else {
           window.location.href = "/"
        }
    };

    checkManufacturerExistence();


  },[])



  const handleLogin = async () => {
    let username = document.getElementById("username").value;
    let password = document.getElementById("password").value;


    try {
   
      loginWithEmail(username,password).then((res)=>{
        window.location.href = "/"
      }).catch((err)=>{
        console.log(err.message)
        globalAlert(err.message)
      })

    } catch (error) {
      console.log(error);

    }
  };
  const handleRegister = async () => {
    let email = document.getElementById("email").value;
    let password = document.getElementById("password").value;
    let confirmPassword = document.getElementById("confirmPassword").value;

    if(password !== confirmPassword){
      globalAlert("Passwords do not match")
      return
    }

    try {
       signUpWithEmail(email,password).then((userCredential)=>{
        const user = userCredential.user;
        console.log(user)

        createUserDocument(user,manufacturer,true).then(()=>{
          navigate("/")
        })
        // window.location.href = "/"
       })


    } catch (error) {
      console.log(error);
    }
  };


  const [login,setLogin] = useState(false)

  if(manufacturerID == null){
    return <></>
}

  return (
    <div>
            <div className="">
    <motion.div
    layout
    className="relative flex flex-auto overflow-hidden bg-white pt-14 h-screen"
  >
    <button className="top-0 right-0 absolute z-20 text-white bg-black text-xs  p-4"
    onClick={()=>{
      window.location.href = "/login"
    }}
    >
     User Login
    </button>

    <div className="sm:mt-4 sm:ml-4 mt-2 ml-2" onClick={
      ()=>{
        window.location.href = "/"
      }
    }
    >

    </div>
    <motion.div
      layout
      className="relative isolate flex w-full flex-col pt-9"
    >
      <GridPattern
        className="absolute inset-x-0 -top-14 -z-10 h-[1000px] w-full fill-neutral-50 stroke-neutral-950/5 [mask-image:linear-gradient(to_bottom_left,white_40%,transparent_50%)]"
        yOffset={-96}
        interactive
      />

      <main className="w-full flex-auto">
      <Container className="mt-8 sm:mt-16 md:mt-32">
        <FadeIn className="max-w-3xl">

  {
    login  ? (
      <div>
      <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
        Login
      </h1>
      <p className="mt-6 text-xl text-neutral-600">
        We are building a digital bridge between manufacturers and retailers to help them connect and grow their business.
      </p>
      <div className="mt-6">
      <input
        className="w-full px-4 py-2 text-lg text-neutral-900 bg-neutral-100 border border-transparent rounded-md focus:border-neutral-500 focus:ring-0"
        placeholder="Email"
        type="email"
        id="username"
      />
      <input
        className="w-full px-4 py-2 mt-4 text-lg text-neutral-900 bg-neutral-100 border border-transparent rounded-md focus:border-neutral-500 focus:ring-0"
        placeholder="Password"
        type="password"
        id="password"
      />
      <button
        className="w-full px-4 py-2 mt-4 text-lg font-medium text-white bg-neutral-900 rounded-md"
        onClick={(e) => handleLogin()}
      >
        Login
      </button>
      <a
        className="block mt-4 text-sm text-neutral-600 hover:text-neutral-900 cursor-pointer"

        onClick={()=>{
          setLogin(false)
        }}
      >
        Register Now! 
      </a>
   </div>

      </div>
    ) : (
      <div>
      <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
        Register
      </h1>
      {
        manufacturer ? (
            <p className="mt-6 text-xl text-neutral-600">You have been invited by <a className="text-2xl font-bold">{manufacturer}</a></p>
        ) : (
            <p className="mt-6 text-xl text-neutral-600">
            We are building a digital bridge between manufacturers and retailers to help them connect and grow their business.
          </p>
        )
      }

   
      <div className="mt-6">
      <input
        className="w-full px-4 py-2 text-lg text-neutral-900 bg-neutral-100 border border-transparent rounded-md focus:border-neutral-500 focus:ring-0"
        placeholder="Email"
        id="email"
        type="email"
      />
      <input
        className="w-full px-4 py-2 mt-4 text-lg text-neutral-900 bg-neutral-100 border border-transparent rounded-md focus:border-neutral-500 focus:ring-0"
        placeholder="Password"
        id="password"
        type="password"
      />
      <input
        className="w-full px-4 py-2 mt-4 text-lg text-neutral-900 bg-neutral-100 border border-transparent rounded-md focus:border-neutral-500 focus:ring-0"
        placeholder="Confirm Password"
        id="confirmPassword"
        type="password"
      />
      <button
        className="w-full px-4 py-2 mt-4 text-lg font-medium text-white bg-neutral-900 rounded-md"

        onClick={(e) => handleRegister()}
      >
        Register
      </button>
      {/* <a
        className="block mt-4 text-sm text-neutral-600 hover:text-neutral-900 cursor-pointer"

        onClick={()=>{
          setLogin(true)
        }}
      >
        Login Now! 
      </a> */}
   </div>

      </div>
    )
  }


        </FadeIn>
      </Container>
      </main>


    </motion.div>
  </motion.div>
    </div>
    </div>
  )
}
