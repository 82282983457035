// src/components/DealerBookingsView.jsx
import React, { useState, useEffect } from 'react';
import { db, sendEmail } from '../../utils/firebase';
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  doc,
  getDoc,
  updateDoc,
  
} from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import {getCustomerData} from '../../utils/globalFunctions';


const Rows = ({booking,user,handleCancel,handleConfirm,navigate}) => {
    const [customerData, setCustomerData] = useState(null);

    useEffect(() => {
        getCustomerData({store_id:user.store_id,customer_id:booking.customerId}).then((res)=>{
            if(res){
                setCustomerData(res)
            }
        }
        )
    }
    ,[booking])

    return(
      <tr key={booking.id} className="hover:bg-gray-50">
      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-700">
      {customerData?.customerName}
      </td>
      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-700">
      {customerData?.customerEmail}
      </td>
      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-700">
      {customerData?.customerPhoneNumber}
      </td>
      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-700">
        {format(parseISO(booking.date), 'PPP')}
      </td>
      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-700">
        {booking.time}
      </td>
      <td className="px-4 py-4 whitespace-nowrap text-sm">
        <span
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
            booking.status === 'Confirmed'
              ? 'bg-green-100 text-green-800'
              : booking.status === 'Canceled'
              ? 'bg-red-100 text-red-800'
              : 'bg-yellow-100 text-yellow-800'
          }`}
        >
          {booking.status}
        </span>
      </td>
      <td className="px-4 py-4 whitespace-nowrap text-sm text-center">
        {booking.status === 'Pending Confirmation' && (
          <div className="flex justify-center space-x-2">
            <button
              onClick={() => handleConfirm(booking)}
              className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Confirm
            </button>
            <button
              onClick={() => handleCancel(booking)}
              className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Cancel
            </button>
          </div>
        )}
        {booking.status === 'Confirmed' && (
          <div className="flex justify-center space-x-2">
             <button
                          onClick={() => 
                            navigate(`/order/${booking.customerId}`)
                            
                          }
              className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Create Order
            </button>
            <button
              onClick={() => handleCancel(booking.id)}
              className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Cancel
            </button>
            </div>
        )}

        {
            booking.status === 'Canceled' && (
                <div className="flex justify-center space-x-2">
                    <p className='text-sm text-gray-600'>No actions required.</p>
                </div>
            )
        }

      </td>
    </tr>
    )

}


const DealerBookingsView = ({ user }) => {
  const currentUser = user;
  const [storeId, setStoreId] = useState(user.store_id);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  // Fetch bookings based on storeId
  useEffect(() => {
    const fetchBookings = async () => {
      if (!storeId) return;

      setLoading(true);
      setError(false);

      try {
        const bookingsRef = collection(db, 'Stores', storeId, 'bookings');
        const q = query(bookingsRef);

        // Real-time listener
        const unsubscribe = onSnapshot(
          q,
          (querySnapshot) => {
            const bookingsData = [];
            querySnapshot.forEach((doc) => {
                // fetch customer data
   

                bookingsData.push({ id: doc.id, ...doc.data() });
            });
            setBookings(bookingsData);
            setLoading(false);
          },
          (err) => {
            console.error('Error fetching bookings:', err);
            setError(true);
            setLoading(false);
            toast.error('Failed to fetch bookings.');
          }
        );

        // Cleanup listener on unmount
        return () => unsubscribe();
      } catch (err) {
        console.error('Error fetching bookings:', err);
        setError(true);
        setLoading(false);
        toast.error('Failed to fetch bookings.');
      }
    };

    fetchBookings();
  }, [storeId]);

  // Function to update booking status
  const updateBookingStatus = async (bookingId, newStatus) => {
    try {
      const bookingRef = doc(db, 'Stores', storeId, 'bookings', bookingId);
      await updateDoc(bookingRef, { status: newStatus });
      toast.success(`Booking ${newStatus.toLowerCase()} successfully!`);
    } catch (err) {
      console.error('Error updating booking status:', err);
      toast.error(`Failed to ${newStatus.toLowerCase()} booking.`);
    }
  };

  const handleConfirm = async (booking) => {
    if (
      window.confirm(
        'Are you sure you want to confirm this booking?'
      )
    ) {
      let bookingDate = booking.date;
      let time = booking.time;
      let storeName = user.storeName;
  
      updateBookingStatus(booking.id, 'Confirmed');
      getCustomerData({ store_id: user.store_id, customer_id: booking.customerId }).then((res) => {
        if (res) {
          if (!res?.customerEmail) {
            return toast.error('Customer email not found.');
          }
  
          let customerName = res.customerName;
          let customerEmail = res.customerEmail;
          sendEmail({
            to: customerEmail,
            subject: 'Booking Confirmation',
            html: `
              <div style="font-family: Arial, sans-serif; color: #333; padding: 20px; line-height: 1.6;">
                <h2 style="color: #4CAF50; font-size: 24px; margin-bottom: 20px;">Booking Confirmation</h2>
                
                <p style="font-size: 16px;">Dear ${customerName},</p>
                <p style="font-size: 16px;">We are pleased to confirm your booking with <strong>${storeName}</strong>. One of our representative will call you before the booking day. Below are the details of your appointment:</p>
  
                <table style="width: 100%; border-collapse: collapse; margin: 20px 0;">
                  <tr>
                    <td style="padding: 10px; border-bottom: 1px solid #eee;"><strong>Date:</strong></td>
                    <td style="padding: 10px; border-bottom: 1px solid #eee;">${bookingDate}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px; border-bottom: 1px solid #eee;"><strong>Time:</strong></td>
                    <td style="padding: 10px; border-bottom: 1px solid #eee;">${time}</td>
                  </tr>

                </table>
  
                <p style="font-size: 16px;">If you need to cancel or reschedule your booking, please click the button below:</p>
  
                <div style="text-align: center; margin: 30px 0;">
                  <a href="https://supplycircle.io/cancel-booking/${user.storeSlug}/${user.storeSlugId}?bookingId=${booking.id}" style="background-color: red; color: white; padding: 12px 24px; text-decoration: none; border-radius: 5px; font-size: 16px;">Cancel Booking</a>
                </div>
  
                <p style="font-size: 16px;">Thank you for choosing us!</p>
                <p style="font-size: 16px;">Best regards, <br/> ${storeName}</p>
              </div>
            `,
            sender: 'Notifications',
            attachments: [],
            text: `Dear ${customerName}, your booking at ${storeName} has been confirmed for ${bookingDate} at ${time}. If you wish to cancel your booking, please visit: http://localhost:3000/cancel-booking/${user.storeSlug}/${user.storeSlugId}?bookingId=${booking.id}`,
          });
        }
      });
    }
  };


  // Handler for canceling a booking
  const handleCancel = (bookingId,customerId) => {
    if (
      window.confirm(
        'Are you sure you want to cancel this booking?'
      )
    ) {
      updateBookingStatus(bookingId, 'Canceled');
      getCustomerData({store_id:user.store_id,customer_id:customerId}).then((res)=>{
     
        if(res){
            if(!res?.customerEmail){
                return toast.error('Customer email not found.')
            }
            sendEmail({
              to: res.customerEmail,
              subject: 'Booking Cancellation',
              html: `
                <div style="font-family: Arial, sans-serif; color: #333; padding: 20px; line-height: 1.6;">
                  <h2 style="color: #e74c3c; font-size: 24px; text-align: center; margin-bottom: 20px;">Your Booking Has Been Canceled</h2>
                  
                  <p style="font-size: 16px; text-align: center;">Dear Customer,</p>
                  <p style="font-size: 16px; text-align: center;">We regret to inform you that your booking has been canceled.</p>
                  <p style="font-size: 16px; text-align: center;">We sincerely apologize for the inconvenience this may have caused.</p>
            
                  <div style="margin: 30px 0; text-align: center;">
                    <a href="mailto:${user.email}" style="background-color: #007BFF; color: white; padding: 12px 24px; text-decoration: none; border-radius: 5px; font-size: 16px; display: inline-block;">
                      Contact ${user.storeName}
                    </a>
                  </div>
            
                  <p style="font-size: 16px; text-align: center;">If you have any questions or need further assistance, please don't hesitate to reach out to our support team.</p>
                  
                  <p style="font-size: 16px; text-align: center;">Best regards,<br />The Team</p>
                </div>
              `,
              sender: 'Notifications',
              attachments: [],
              text: 'Your booking has been canceled. We apologize for the inconvenience.',
            });
        }
        }
        )
 
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-red-500 text-xl">
          An error occurred while fetching bookings.
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-white shadow-lg rounded-lg mt-8">
      {/* Header */}
      <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center text-indigo-600">
        Your Bookings
      </h2>

      {/* Bookings Table */}
      {bookings.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-indigo-50">
              <tr>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-indigo-600 uppercase tracking-wider"
                >
                  Customer Name
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-indigo-600 uppercase tracking-wider"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-indigo-600 uppercase tracking-wider"
                >
                  Phone Number
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-indigo-600 uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-indigo-600 uppercase tracking-wider"
                >
                  Time
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-indigo-600 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-center text-xs font-medium text-indigo-600 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {bookings.map((booking) => (
                <>
                  <Rows booking={booking}  user={user} handleCancel={handleCancel} handleConfirm={handleConfirm} navigate={navigate}/>
                </>
                  

              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-500">No bookings found.</p>
      )}

      {/* Toast Notifications
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar /> */}
    </div>
  );
};

export default DealerBookingsView;