// src/ExcelEditor.js
import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";

const ExcelEditor = ({ data, setPriceList, increase }) => {
  // Default type to 'AREA' if not specified
  const type = data.type || "AREA";

  // State for actual prices and displayed prices
  const [actualPriceMap, setActualPriceMap] = useState(data.priceMap);
  const [priceMap, setPriceMap] = useState(data.priceMap);

  // Effect to update priceMap whenever 'increase' or 'actualPriceMap' changes
  useEffect(() => {
    const percentage = increase ? increase / 100 : 0;
    const newPriceMap = {};

    for (const [key, value] of Object.entries(actualPriceMap)) {
      const basePrice = parseFloat(value) || 0;
      const increasedPrice = parseFloat((basePrice + basePrice * percentage).toFixed(2));
      newPriceMap[key] = increasedPrice;
    }

    setPriceMap(newPriceMap);
    setPriceList({ ...data, priceMap: newPriceMap });
  }, [increase, actualPriceMap, data, setPriceList]);

  // Define table columns based on 'type'
  const columns = useMemo(() => {
    if (type === "LENGTH") {
      return [
        { Header: "Width Range", accessor: "width" },
        { Header: "Price", accessor: "price" },
      ];
    } else {
      // AREA type
      return [
        { Header: "H\\W", accessor: "size" },
        ...data.columns.map((col) => ({
          Header: col.toString(),
          accessor: col.toString(),
        })),
      ];
    }
  }, [data.columns, type]);

  // Prepare table data based on 'type'
  const rows = useMemo(() => {
    if (type === "LENGTH") {
      return data.columns.map((col) => ({
        width: col.toString(),
        price: priceMap[col.toString()] || "",
      }));
    } else {
      // AREA type
      return data.rows.map((row) => {
        const rowObj = { size: row.toString() };
        data.columns.forEach((col) => {
          const key = `${col}-${row}`;
          rowObj[col.toString()] = priceMap[key] || "";
        });
        return rowObj;
      });
    }
  }, [data.columns, data.rows, type, priceMap]);

  // Function to handle price updates
  const updatePrice = (col, row, value) => {
    let newActualPriceMap;
    if (type === "LENGTH") {
      newActualPriceMap = { ...actualPriceMap, [col]: parseFloat(value) || "" };
    } else {
      const key = `${col}-${row}`;
      newActualPriceMap = { ...actualPriceMap, [key]: parseFloat(value) || "" };
    }

    setActualPriceMap(newActualPriceMap);
    // The useEffect hook will automatically update 'priceMap' based on the new 'actualPriceMap'
  };

  // Function to render table cells
  const renderCell = (cell) => {
    const { column, row } = cell;
    if (type === "LENGTH") {
      if (column.id === "width") {
        return <span className="text-gray-700">{cell.value}</span>;
      } else if (column.id === "price") {
        return (
          <input
            type="text"
            value={cell.value}
            className="w-16 border border-gray-300 rounded text-right"
            onChange={(e) => updatePrice(row.values.width, null, e.target.value)}
          />
        );
      }
    } else {
      // AREA type
      if (column.id === "size") {
        return <span className="text-gray-700">{cell.value}</span>;
      } else {
        return (
          <input
            type="text"
            value={cell.value}
            className="w-16 border border-gray-300 rounded text-right"
            onChange={(e) => updatePrice(column.id, row.values.size, e.target.value)}
          />
        );
      }
    }
    return null;
  };

  // Set up the table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: tableRows,
    prepareRow,
  } = useTable({ columns, data: rows });

  return (
    <table
      {...getTableProps()}
      className="min-w-full divide-y divide-gray-200 table-fixed"
    >
      <thead className="bg-gray-50">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="px-4 py-3 text-left">
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody
        {...getTableBodyProps()}
        className="bg-white divide-y divide-gray-200"
      >
        {tableRows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="hover:bg-gray-100">
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className="px-4 py-4 w-8 whitespace-nowrap text-sm text-gray-500"
                >
                  {renderCell(cell)}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExcelEditor;