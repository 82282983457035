import React, { useEffect, useState } from 'react'
import CheckboxTable from '../../components/CheckboxTable'
import StripeCheckout from './stripeCheckout'
import { getDocs,collection, where,query,getDoc } from 'firebase/firestore'
import { db } from '../../utils/firebase'
import { getFirestore } from 'firebase/firestore'
import { Button } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../utils/context'
import { HashtagIcon } from '@heroicons/react/24/outline';





function downloadAsPDF(base64String) {

  
  const downloadLink = document.createElement("a");
  
  downloadLink.href = base64String;
  
  downloadLink.download = "invoice.pdf";
  
  downloadLink.click();
}

export default function Invoices({user}) {

    const [invoices,setInvoices] = useState([])
    const [selectedInvoices,setSelectedInvoices] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const [unpaidInvoices,setUnpaidInvoices] = useState(true)
    const nav = useNavigate()


    console.log(selectedInvoices)
    const columns = React.useMemo(
        () => [
          {
            Header: 'Invoice Number',
            HeaderIcon: <HashtagIcon width={10} />,
            accessor: 'invoiceID', // accessor is the "key" in the data
            labelLeft: '#'
          },
          {
            Header: 'Customer Name',
            accessor: 'customerName',
          },
          {
            Header: 'Total',
            accessor: 'tot',
            labelLeft:'$',
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'DUE',
            accessor: 'dueDate',
          }
        ],
        []
      )
 
      async function fetchDataNotPaid(){
        setIsLoading(true)
        const q = query(collection(db, "invoices"), where("isPaid", "==", false),where("storeEmail","==",user.email));
        const data = await getDocs(q);


        const invoices = await Promise.all(data.docs.map(async (doc)=>{
          const {orders,paymentID, ...rest} = doc.data()
          if(!paymentID){
            return {
              ...rest,
              paymentID: "",
              status:"Payment not made",
            }
          }
        const someDocData = (await getDoc(paymentID)).data();

        // except orders
         let data = {
            //dont include data.doc().orders
            ...rest,
            ...someDocData



         }
         
          return data
        }))
        console.log(invoices)
        setInvoices(invoices)
        setIsLoading(false)
      }

      async function fetchDataPaid(){
        setIsLoading(true)
        const q = query(collection(db, "invoices"), where("isPaid", "==", true),where("storeEmail","==",user.email));
        const data = await getDocs(q);


        const invoices = await Promise.all(data.docs.map(async (doc)=>{
          const {orders,paymentID, ...rest} = doc.data()
          if(!paymentID){
            return {
              ...rest,
              paymentID: "",
              status:"Payment not made"
            }
          }
        const someDocData = (await getDoc(paymentID)).data();

        // except orders
         let data = {
            //dont include data.doc().orders
            ...rest,
            ...someDocData



         }
         
          return data
        }))
        console.log(invoices)
        setInvoices(invoices)
        setIsLoading(false)
      }


      useEffect(()=>{
        if(!user){
          return
        }

          if(unpaidInvoices)
            fetchDataNotPaid()
          else
            fetchDataPaid()
    
      },[user,unpaidInvoices])

  

  return (
    <div>

{/* <div className='flex mb-4 ml-4 '> 
{
          selectedInvoices && selectedInvoices.length > 0 && unpaidInvoices &&
          <>

          <Button
          size={"xs"}
          onClick={()=>{
            console.log(selectedInvoices)

            nav('/payment',{
              state:{
                selectedInvoices: selectedInvoices
              }
            })
         
          }}
          className="bg-green-500 hover:bg-green-700 text-white font-bold rounded mr-2">
            Pay Inovice(s)
          </Button>
          </>


        }
        {unpaidInvoices ?

                       <Button
                       size={"xs"}
                       onClick={()=>{
                        setUnpaidInvoices(false)
                      
                       }}
                       className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2">
                         Paid Invoice(s)
                       </Button>
                        :
                        <Button
                        size={"xs"}
                        onClick={()=>{
                          setUnpaidInvoices(true)
                       
                        }}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2">
                         Unpaid Invoices(s)
                        </Button>
        }

</div>

<div className='w-full overflow-x-auto '>
  <Table  columns={columns} data={invoices} isLoading={isLoading} selectedItems={selectedInvoices} setSelectedItems={setSelectedInvoices} ActionButton={
    (item)=>{
      return (
        <Button
        size={"xs"}
        onClick={()=>{


         console.log(item.invoice.invoiceURI)
         let URI = item.invoice.invoiceURI
         downloadAsPDF(URI)
         // convert the uri to a pdf
          // then open the pdf in a new tab
  




        }}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2">
          View Invoice
        </Button>
      )
    }
  } />

</div> */}

   
    </div>
  )
}
