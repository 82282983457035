import React, { useState,useEffect } from 'react';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import {sendMessage,getMessages} from '../utils/globalFunctions'
import { serverTimestamp } from 'firebase/firestore';

const ChatWindow = ({storeID,manufacturerID,user}) => {
  const [messages, setMessages] = useState([]);


  useEffect(()=>{
    const fetchMessages = async () => {
      let res = await getMessages(storeID);
      if(res){
        setMessages(res)
      }
    }
    fetchMessages()
  },[storeID])



  const handleSendMessage = async (message) => {


    let data = {
      storeID: storeID,
      manufacturerID: manufacturerID,
      senderID: user.id,
      message: message,
      createdAt: serverTimestamp(),
      readBy: [user.id]
    }

    // Send message to the server
    
   let res = await sendMessage(storeID, data); // STORE ID IS THE KEY  
   if(res){
    setMessages([...messages, data]);
   }else{
      alert("Error sending message")
   }
  };


  return (
    <div className="w-full h-[720px] border border-gray-300 flex flex-col">
      <div className="bg-indigo-500 text-white p-4 text-center text-lg">
        Support Chat
      </div>
      <MessageList messages={messages} user={user} manufacturerID={manufacturerID} />
      <MessageInput onSendMessage={handleSendMessage} />
    </div>
  );
};

export default ChatWindow;