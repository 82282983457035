// src/components/BookingView.jsx
import React, { useState, useEffect, Fragment } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, Transition } from '@headlessui/react';
import { db, sendEmail } from '../../utils/firebase';
import { createCustomer } from '../../utils/globalFunctions';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {
  doc,
  getDoc,
  collection,
  getDocs,
  addDoc,
  query,
  where,
  Timestamp,
} from 'firebase/firestore';
import { useParams } from 'react-router-dom';

const BookingView = ({ user }) => {
  const [storeId, setStoreId] = useState(null);
  const { slug, id } = useParams();


  // State Variables
  const [availability, setAvailability] = useState(null); // Store's availability settings
  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize to today
  const [availableSlots, setAvailableSlots] = useState([]); // Available time slots for selected date
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
  }); // Customer details
  const [loading, setLoading] = useState(false); // Loading state for data fetching
  const [unavailableDates, setUnavailableDates] = useState([]); // Dates when store is closed
  const [componentLoading, setComponentLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility
  const [bookingDetails, setBookingDetails] = useState({ date: '', time: '' }); // Details for confirmation
  const [confirming, setConfirming] = useState(false); // Confirm button loading state
  const [phone, setPhone] = useState();

  // Function to format Date to YYYY-MM-DD
  const formatDate = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2); // Months are zero-indexed
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };

  // Function to format Time to HH:MM
  const formatTime = (date) => {
    const hours = (`0${date.getHours()}`).slice(-2);
    const minutes = (`0${date.getMinutes()}`).slice(-2);
    return `${hours}:${minutes}`;
  };

  // Fetch Store Availability on Component Mount
  useEffect(() => {

    if(slug && id ){
      const fetchStoreId = async () => {
        try {
          const storeRef = collection(db, 'Stores');
          const q = query(storeRef, where('storeSlug', '==', slug), where('storeSlugId', '==', id));
          const storeSnap = await getDocs(q);
          storeSnap.forEach((doc) => {
            console.log(doc.id, ' => ', doc.data());
            setStoreId(doc.id);
          });
        } catch (error) {
          console.error('Error fetching store ID:', error);
          toast.error('Failed to fetch store ID.');
        }
      };
      fetchStoreId();
    }else{
      if(user.store_id){
        setStoreId(user.store_id);
      }else{
        toast.error('Store ID is missing.');
      }
    }
  }, [slug, id]);

  useEffect(() => {

    if (!storeId) return;

    const fetchAvailability = async () => {
      try {
        const storeRef = doc(db, 'Stores', storeId);
        const storeSnap = await getDoc(storeRef);

        if (!storeSnap.exists()) {
          toast.error('Store not found.');
          return;
        }

        const storeData = storeSnap.data();
        if (storeData.availability) {
          setAvailability(storeData.availability);
        } else {
          toast.error('Store availability is not set.');
        }
      } catch (error) {
        console.error('Error fetching store availability:', error);
        toast.error('Failed to fetch store availability.');
      }
    };

    fetchAvailability();
  }, [storeId]);

  // Fetch Unavailable Dates (Closed Intervals) for Calendar
  useEffect(() => {
    const fetchUnavailableDates = async () => {
      if (!storeId) return;

      try {
        const exceptionsRef = collection(db, 'Stores', storeId, 'availabilityExceptions');
        const q = query(exceptionsRef, where('isClosed', '==', true));
        const exceptionsSnap = await getDocs(q);
        const closedIntervals = exceptionsSnap.docs.map((docSnap) => docSnap.data());

        const dates = [];

        closedIntervals.forEach((interval) => {
          const start = new Date(interval.startDate);
          const end = new Date(interval.endDate);
          let current = new Date(start);
          while (current <= end) {
            dates.push(new Date(current));
            current.setDate(current.getDate() + 1);
          }
        });

        setUnavailableDates(dates);
      } catch (error) {
        console.error('Error fetching unavailable dates:', error);
        toast.error('Failed to fetch unavailable dates.');
      }
    };

    fetchUnavailableDates();
  }, [storeId]);

  // Fetch Available Slots when Selected Date Changes
  useEffect(() => {
    if(!selectedDate) return;
    const fetchAvailableSlots = async () => {

      if (!selectedDate || !availability) {
        setAvailableSlots([]);
        return;
      }

      setLoading(true);
      try {
        const formattedDate = formatDate(selectedDate);

        // Check if the selected day is within the store's available days
        const dayOfWeek = selectedDate.getDay(); // 0 (Sun) to 6 (Sat)
        if (!availability.daysOfWeek.includes(dayOfWeek)) {
          toast.info('Selected day is not available for bookings.');
          setAvailableSlots([]);
          setLoading(false);
          return;
        }

        // Check if the selected date is in unavailableDates
        const isClosed = unavailableDates.some(
          (unavailableDate) =>
            unavailableDate.getFullYear() === selectedDate.getFullYear() &&
            unavailableDate.getMonth() === selectedDate.getMonth() &&
            unavailableDate.getDate() === selectedDate.getDate()
        );

        if (isClosed) {
          toast.info('The store is closed on the selected date.');
          setAvailableSlots([]);
          setLoading(false);
          return;
        }

        // Generate available time slots
        const slots = await generateTimeSlots(
          availability.startTime,
          availability.endTime,
          availability.slotDuration,
          availability.limitPerSlot,
          storeId,
          formattedDate
        );

        setAvailableSlots(slots);
      } catch (error) {
        console.error('Error fetching available slots:', error);
        toast.error('An error occurred while fetching available slots.');
      }
      setLoading(false);
    };

    fetchAvailableSlots();
  }, [selectedDate, availability, storeId, unavailableDates]);

  // Function to generate time slots
  const generateTimeSlots = async (
    startTime,
    endTime,
    slotDuration,
    limitPerSlot,
    storeId,
    date
  ) => {
    const slots = [];
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    let current = new Date(selectedDate);
    current.setHours(startHour, startMinute, 0, 0);
    const end = new Date(selectedDate);
    end.setHours(endHour, endMinute, 0, 0);

    while (current < end) {
      const slotStart = new Date(current);
      const slotEnd = new Date(current);
      slotEnd.setMinutes(slotEnd.getMinutes() + slotDuration);

      // Fetch current bookings for this slot
      const bookingsRef = collection(db, 'Stores', storeId, 'bookings');
      const q = query(
        bookingsRef,
        where('date', '==', date),
        where('time', '==', formatTime(slotStart))
      );
      const bookingsSnap = await getDocs(q);
      let bookedCount = bookingsSnap.size;

      // check the status of the booking
      bookingsSnap.forEach((doc) => {
        const booking = doc.data();
        if (booking.status === 'Canceled') {
          bookedCount--;
        }
      });




          
      if (bookedCount < limitPerSlot) {
        slots.push(formatTime(slotStart));
      }

      current = slotEnd;
    }

    return slots;
  };

  // Handle Booking Submission
  const handleBooking = (slotTime) => {
    if (!selectedDate) {
      toast.error('Please select a date.');
      return;
    }

    // Open Confirmation Modal without checking customer info
    setBookingDetails({ date: formatDate(selectedDate), time: slotTime });
    setIsModalOpen(true);
  };

  // Confirm Booking
  const confirmBooking = async () => {
    // Validate customer information inside the modal
    if (!customerInfo.name.trim() || !customerInfo.email.trim()) {
      toast.error('Please provide your name and email.');
      return;
    }

    if(!storeId){
      toast.error('Store ID is missing.');
      return;
    }

    setConfirming(true);
    const { date, time } = bookingDetails;

    try {
      const bookingRef = collection(db, 'Stores', storeId, 'bookings');

      // Real-Time Check if slot is still available
      const q = query(
        bookingRef,
        where('date', '==', date),
        where('time', '==', time),

      );
      let bookingsSnap = await getDocs(q);
      let bookedCount = bookingsSnap.size;
      // exluce canceled bookings
      bookingsSnap.forEach((doc) => {
        const booking = doc.data();
        if (booking.status === 'Canceled') {
          bookedCount--;
        }
      });

      if (bookedCount >= availability.limitPerSlot) {
        toast.error('Selected slot is fully booked.');
        setIsModalOpen(false);
        // Refresh available slots
        const updatedSlots = await generateTimeSlots(
          availability.startTime,
          availability.endTime,
          availability.slotDuration,
          availability.limitPerSlot,
          storeId,
          date
        );
        setAvailableSlots(updatedSlots);
        setConfirming(false);
        return;
      }

      // Create new customer
      let payload = {
        customerName: customerInfo.name,
        customerEmail: customerInfo.email,
        customerPhoneNumber: phone ? phone : '',
      };
      const customerId = await createCustomer(storeId, payload);
      // statuses are: Pending Confirmation, Confirmed, Canceled 
      // Add the booking
      await addDoc(bookingRef, {
        customerId: customerId,
        date: date,
        time: time,
        status: 'Pending Confirmation',
        createdAt: Timestamp.now(),
      });

      const userRef = doc(db, 'users', storeId);
      const userDoc = await getDoc(userRef);
      const storeEmail = userDoc.data().email;


      // Send notification email to store 

      await sendEmail({
        to: storeEmail,
        subject: 'New Booking Request',
        html: `
          <div style="font-family: Arial, sans-serif; color: #333; padding: 20px; line-height: 1.8;">

            <h2 style="color: #007BFF; font-size: 26px; margin-bottom: 20px;">New Booking Request</h2>

            <p style="font-size: 16px;">Hi ${userDoc.data().name},</p>

            <p style="font-size: 16px;">A new booking request has been received for the following details:</p>

            <p style="font-size: 16px;"><strong>Date:</strong> ${date}</p>

            <p style="font-size: 16px;"><strong>Time:</strong> ${time}</p>

            <p style="font-size: 16px;"><strong>Customer Name:</strong> ${customerInfo.name}</p>

            <p style="font-size: 16px;"><strong>Customer Email:</strong> ${customerInfo.email}</p>

            <p style="font-size: 16px;"><strong>Customer Phone:</strong> ${phone}</p>

            <p style="font-size: 16px;">Please review and confirm the booking as soon as possible.</p>

            <p style="font-size: 16px;">Best regards,<br/>Supply Circle</p>

          </div>
        `,
        sender: 'Notifications',
        attachments: [],
        text: `A new booking request has been received for ${date} at ${time}. Please review and confirm the booking.`,
      });

    toast.success('Your booking has been received and is pending confirmation. You will receive a confirmation email once it\'s confirmed.');
     






    
      setIsModalOpen(false);
      // Refresh available slots
      const updatedSlotsAfterBooking = await generateTimeSlots(
        availability.startTime,
        availability.endTime,
        availability.slotDuration,
        availability.limitPerSlot,
        storeId,
        date
      );
      setAvailableSlots(updatedSlotsAfterBooking);
      // Reset customer info
      setCustomerInfo({ name: '', email: '' });
    } catch (error) {
      console.error('Error booking slot:', error);
      toast.error('An error occurred while booking the slot.');
    }
    setConfirming(false);
  };

  // Function to determine disabled dates
  const disabledDays = unavailableDates.map((date) => new Date(date));

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6 lg:p-8 bg-white shadow-xl rounded-lg sm:mt-16 mt-4 overflow-hidden">
      {/* Header */}
      <h2 className="text-3xl sm:text-4xl font-extrabold sm:mb-6 mb-2 text-center text-indigo-600">
        Book an Appointment
      </h2>

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        {/* Calendar Section */}
        <div className='w-81'>
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={setSelectedDate}
            disabled={disabledDays}
            initialFocus
            className="rounded-lg shadow-md border-2 border-indigo-500 p-4"
            modifiersClassNames={{
              selected: 'bg-indigo-500 text-white',
              disabled: 'bg-red-200 text-gray-500 pointer-events-none',
              today: 'border border-indigo-500',
            }}
          />
        </div>

        {/* Time Slots */}
        <div className="lg:w-1/2 flex flex-col space-y-4 mt-4 lg:mt-0">
          {/* Available Time Slots */}
          {loading ? (
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-indigo-500"></div>
            </div>
          ) : (
            <>
              {availableSlots.length > 0 ? (
                <div >
                  <h3 className="text-2xl font-semibold mb-4 text-indigo-600 text-center">
                    Available Time Slots
                  </h3>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
                    {availableSlots.map((slot) => (
                      <button
                        key={slot}
                        onClick={() => handleBooking(slot)}
                        className="bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-600 transition transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        {slot}
                      </button>
                    ))}
                  </div>
                </div>
              ) : selectedDate && (
                <p className="text-center text-gray-500">No available slots for the selected date.</p>
              )}
            </>
          )}
        </div>
      </div>

      {/* Confirmation Modal */}
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={() => setIsModalOpen(false)}>
          <div className="flex items-center justify-center min-h-screen px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg max-w-md w-full mx-auto p-6 space-y-4 shadow-xl transition-all">
                <Dialog.Title className="text-2xl font-bold text-indigo-600">Confirm Your Booking</Dialog.Title>
                <Dialog.Description className="text-gray-700">
                  Please review and enter your details to confirm your booking.
                </Dialog.Description>

                <div className="space-y-2">
                  <p>
                    <span className="font-semibold">Date:</span> {bookingDetails.date}
                  </p>
                  <p>
                    <span className="font-semibold">Time:</span> {bookingDetails.time}
                  </p>
                </div>

                {/* Customer Information Inputs */}
                <div className="space-y-4">
                  <div>
                    <label htmlFor="modal-name" className="block text-gray-700 mb-1 text-lg font-medium">
                      Name
                    </label>
                    <input
                      type="text"
                      id="modal-name"
                      value={customerInfo.name}
                      onChange={(e) => setCustomerInfo((prev) => ({ ...prev, name: e.target.value }))}
                      className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                      required
                      placeholder="Enter your name"
                    />
                  </div>
                  <div>
                    <label htmlFor="modal-email" className="block text-gray-700 mb-1 text-lg font-medium">
                      Email
                    </label>
                    <input
                      type="email"
                      id="modal-email"
                      value={customerInfo.email}
                      onChange={(e) => setCustomerInfo((prev) => ({ ...prev, email: e.target.value }))}
                      className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                      required
                      placeholder="Enter your email"
                    />
                  </div>
                  
                  <div>
                  <label htmlFor="modal-phone" className="block text-gray-700 mb-1 text-lg font-medium">
                      Phone
                    </label>
                    <PhoneInput
                      defaultCountry="CA"
                      countries={['CA', 'US']}
                      value={phone}
                      onChange={setPhone}
                      className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                      required
                      placeholder="Enter your phone number"
                    />
                    </div>
      
                </div>

                {/* Action Buttons */}
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition focus:outline-none focus:ring-2 focus:ring-gray-500"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={confirmBooking}
                    className={`bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 transition focus:outline-none focus:ring-2 focus:ring-indigo-500 ${
                      confirming ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={confirming}
                  >
                    {confirming ? 'Confirming...' : 'Confirm'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Toast Notifications */}
      {/* <ToastContainer position="top-right" autoClose={5000} hideProgressBar /> */}

      {/* Footer */}
      <footer className="mt-12 text-center text-gray-500">
        © {new Date().getFullYear()} Supply Circle. All rights reserved.
      </footer>
    </div>
  );
};

export default BookingView;