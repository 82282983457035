import React, {useState, useEffect} from 'react'
import OrderEntry from '../../components/OrderEntry'
import axios from 'axios'
import OrderListStore from '../../components/OrderListStore';
import { useNavigate } from 'react-router-dom';
import {addressURL} from '../../utils/globalFunctions'

export default function OrderEntryPage(props) {
  const [newOrderEntered, setNewOrderEntered] = useState(false);
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [customerID, setCustomerID] = useState(0);



  const [products,setProducts] = useState([])

      
  useEffect(() => {

    axios
      .get(addressURL + `/api/price-list?fromWhere=notDeleted`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        withCredentials: true,
      })
      .then((res) => {
        setProducts(res.data.result);

      })
      .catch((err) => console.log(err));
  }, []);


    const navigate = useNavigate();
  // we will get the order by customer id 
 async function getOrderWithGroupByCustomerID() {
    setOrdersLoading(true);
    try {
        const res = await axios.get(addressURL + `/api/getOrderByCustomerID?customerID=${customerID}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            withCredentials: true,
        });
        const index = orders.findIndex(order => order.customerID === res.data.data[0].customerID);

        if (index !== -1) {
            orders[index] = res.data.data[0];
        } else {
            orders.unshift(res.data.data[0]);
        }

        setOrders([...orders]);
    } catch (err) {
        console.log(err);
    } finally {
        setOrdersLoading(false);
    }
}





  useEffect(() => {
    if (newOrderEntered) {
      getOrderWithGroupByCustomerID();
      setNewOrderEntered(false);
    }
  }, [newOrderEntered]);

  return (
    <div>

        {
          products.length > 0 && <OrderEntry setNewOrderEntered={setNewOrderEntered} setCustomerID={setCustomerID} products={products} />
        }
      
  

       {orders.length > 0 ? (
            <>
            {
            orders.map((order,index) => {
                return (
                    <OrderListStore
                      customerID={order.customerID}
                      orders={order.orders}
                      totalPrice={order.totalPrice}
                      extras={order.extras}
                      setNewOrderEntered={setNewOrderEntered}
                      key={index}
                      products={products}
                    />
                    );
                  })
            }
            </>
        ) : (
            <div className='text-lg mb-8 font-bold text-center'></div>
        )
        }
    
    </div>
  )
}
