// src/components/BuyerDashboard.js

import React, { useEffect, useState, Fragment, useRef } from 'react';
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  onSnapshot,
  addDoc,
  serverTimestamp,
  limit,
  doc,
} from 'firebase/firestore';
import { db } from '../utils/firebase'; // Adjust the path as necessary
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BuyerDashboard = () => {
  const [email, setEmail] = useState('');
  const [buyer, setBuyer] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);

  // Fetch Buyer by Email
  const fetchBuyerByEmail = async (buyerEmail) => {
    try {
      const buyersRef = collection(db, 'WholesaleBuyers');
      const q = query(
        buyersRef,
        where('email', '==', buyerEmail.toLowerCase()),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        toast.error('No buyer found with this email.');
        return null;
      }
      const buyerDoc = querySnapshot.docs[0];
      return { id: buyerDoc.id, ...buyerDoc.data() };
    } catch (error) {
      console.error('Error fetching buyer:', error);
      toast.error('Failed to fetch buyer information.');
      return null;
    }
  };

  // Handle Email Submission
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email.trim()) {
      toast.error('Please enter a valid email.');
      return;
    }
    const fetchedBuyer = await fetchBuyerByEmail(email.trim());
    if (fetchedBuyer) {
      setBuyer(fetchedBuyer);
      fetchOrders(fetchedBuyer.id);
    }
  };

  // Fetch Orders for Buyer
  const fetchOrders = async (buyerId) => {
    setLoadingOrders(true);
    try {
      const ordersRef = collection(db, 'WholesaleOrders');
      const q = query(
        ordersRef,
        where('buyerID', '==', buyerId),
        orderBy('createdAt', 'desc')
      );
      const querySnapshot = await getDocs(q);
      const ordersList = [];
      querySnapshot.forEach((doc) => {
        ordersList.push({ id: doc.id, ...doc.data() });
      });
      setOrders(ordersList);
      if (ordersList.length === 0) {
        toast.info('You have no orders yet.');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('Failed to fetch orders.');
    }
    setLoadingOrders(false);
  };

  // Handle Viewing Order Details
  const openOrderModal = (order) => {
    setSelectedOrder(order);
    setShowOrderModal(true);
  };

  const closeOrderModal = () => {
    setSelectedOrder(null);
    setShowOrderModal(false);
    setChatMessages([]);
    setNewMessage('');
  };

  // Listen to Chat Messages in Real-Time
  useEffect(() => {
    if (selectedOrder) {
      const chatRef = collection(db, 'WholesaleOrders', selectedOrder.id, 'chat');
      const q = query(chatRef, orderBy('timestamp', 'asc'));
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const messages = [];
          querySnapshot.forEach((doc) => {
            messages.push({ id: doc.id, ...doc.data() });
          });
          setChatMessages(messages);
          scrollToBottom();
        },
        (error) => {
          console.error('Error fetching chat messages:', error);
          toast.error('Failed to fetch chat messages.');
        }
      );

      return () => unsubscribe();
    }
  }, [selectedOrder]);

  // Scroll to Bottom of Chat
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Handle Sending a New Message
  const handleSendMessage = async () => {
    if (!newMessage.trim()) {
      toast.error('Cannot send an empty message.');
      return;
    }

    try {
      const chatRef = collection(db, 'WholesaleOrders', selectedOrder.id, 'chat');
      await addDoc(chatRef, {
        sender: 'buyer',
        content: newMessage.trim(),
        timestamp: serverTimestamp(),
      });
      setNewMessage('');
      toast.success('Message sent.');
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message.');
    }
  };

  // Handle Enter Key in Message Input
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      {/* Toast Container */}
      <ToastContainer />

      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
        {/* Buyer Authentication */}
        {!buyer ? (
          <form onSubmit={handleEmailSubmit} className="mb-6">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 text-center">
              Buyer Dashboard
            </h2>
            <div className="flex items-center">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border border-gray-300 px-4 py-2 rounded-l-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
              <button
                type="submit"
                className="bg-indigo-600 text-white px-4 py-2 rounded-r-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            </div>
          </form>
        ) : (
          <>
            {/* Welcome Message */}
            <div className="mb-6 text-center">
              <h2 className="text-xl font-semibold text-gray-800">
                Welcome, {buyer.name}!
              </h2>
              <p className="text-gray-600">Here are your orders.</p>
            </div>

            {/* Orders Table */}
            <div className="overflow-x-auto">
              {loadingOrders ? (
                <p className="text-gray-600">Loading orders...</p>
              ) : orders.length === 0 ? (
                <p className="text-gray-600">You have no orders yet.</p>
              ) : (
                <table className="min-w-full bg-white border border-gray-200">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Order ID
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Total Amount
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Status
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Created At
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {orders.map((order) => (
                      <tr key={order.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {order.id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          CAD {order.orderTotal.toFixed(2)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              order.status === 'Pending'
                                ? 'bg-yellow-100 text-yellow-800'
                                : order.status === 'Processing'
                                ? 'bg-blue-100 text-blue-800'
                                : order.status === 'Shipped'
                                ? 'bg-purple-100 text-purple-800'
                                : order.status === 'Delivered'
                                ? 'bg-green-100 text-green-800'
                                : 'bg-red-100 text-red-800'
                            }`}
                          >
                            {order.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {order.createdAt?.toDate().toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <button
                            onClick={() => openOrderModal(order)}
                            className="text-indigo-600 hover:text-indigo-900 font-medium"
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            {/* Order Details Modal */}
            <Transition appear show={showOrderModal} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeOrderModal}
              >
                <div className="min-h-screen px-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-50"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-50"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-black" />
                  </Transition.Child>

                  {/* Trick the browser into centering the modal contents */}
                  <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>

                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95 translate-y-4"
                    enterTo="opacity-100 scale-100 translate-y-0"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100 translate-y-0"
                    leaveTo="opacity-0 scale-95 translate-y-4"
                  >
                    <div className="inline-block w-full max-w-3xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                      <div className="flex justify-between items-center mb-4">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-semibold leading-6 text-gray-900"
                        >
                          Order Details - {selectedOrder?.id}
                        </Dialog.Title>
                        <button
                          onClick={closeOrderModal}
                          className="text-gray-400 hover:text-gray-600"
                        >
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>

                      {/* Order Information */}
                      <div className="mb-6">
                        <p>
                          <strong>Buyer Name:</strong> {selectedOrder?.buyerName}
                        </p>
                        <p>
                          <strong>Buyer Email:</strong> {selectedOrder?.buyerEmail}
                        </p>
                        <p>
                          <strong>Total Amount:</strong> CAD {selectedOrder?.orderTotal.toFixed(2)}
                        </p>
                        <p>
                          <strong>Status:</strong>{' '}
                          <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              selectedOrder?.status === 'Pending'
                                ? 'bg-yellow-100 text-yellow-800'
                                : selectedOrder?.status === 'Processing'
                                ? 'bg-blue-100 text-blue-800'
                                : selectedOrder?.status === 'Shipped'
                                ? 'bg-purple-100 text-purple-800'
                                : selectedOrder?.status === 'Delivered'
                                ? 'bg-green-100 text-green-800'
                                : 'bg-red-100 text-red-800'
                            }`}
                          >
                            {selectedOrder?.status}
                          </span>
                        </p>
                        <p>
                          <strong>Created At:</strong>{' '}
                          {selectedOrder?.createdAt?.toDate().toLocaleString()}
                        </p>
                      </div>

                      {/* Items List */}
                      <div className="mb-6">
                        <h4 className="text-lg font-semibold mb-2">Items:</h4>
                        <div className="overflow-x-auto">
                          <table className="min-w-full bg-white border border-gray-200">
                            <thead>
                              <tr className="bg-gray-50">
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">
                                  Product
                                </th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">
                                 Type
                                </th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">
                                  Measurements
                                </th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">
                                  Quantity
                                </th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">
                                  Unit Price
                                </th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">
                                  Total Price
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                              {selectedOrder?.items.map((item, idx) => (
                                <tr key={idx}>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-800">
                                    {item.materialName}
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-800">
                                    {item.unitType}
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-800">
                                    {item.unitType === 'AREA' ? (
                                      <>
                                        Width: {item.measurements.width} {item.measurements.metricType}, Height:{' '}
                                        {item.measurements.height} {item.measurements.metricType}
                                      </>
                                    ) : item.unitType === 'LENGTH' ? (
                                      <>Length: {item.measurements.length} {item.measurements.metricType}</>
                                    ) : (
                                      <>N/A</>
                                    )}
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-800">
                                    {item.quantity}
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-800">
                                    CAD {item.unitPrice.toFixed(2)}
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-800">
                                    CAD {item.totalPrice.toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {/* Chat Section */}
                      <div className="mb-6">
                        <h4 className="text-lg font-semibold mb-2">Chat:</h4>
                        <div className="border border-gray-300 rounded-md p-4 h-64 overflow-y-auto bg-gray-50">
                          {chatMessages.length === 0 ? (
                            <p className="text-gray-500 text-sm">No messages yet.</p>
                          ) : (
                            chatMessages.map((msg) => (
                              <div
                                key={msg.id}
                                className={`mb-2 flex ${
                                  msg.sender === 'buyer' ? 'justify-end' : 'justify-start'
                                }`}
                              >
                                <div
                                  className={`rounded-lg px-4 py-2 ${
                                    msg.sender === 'buyer'
                                      ? 'bg-blue-100 text-blue-800'
                                      : 'bg-gray-200 text-gray-800'
                                  }`}
                                >
                                  <p className="text-sm">{msg.content}</p>
                                  <span className="text-xs text-gray-500">
                                    {msg.timestamp
                                      ? msg.timestamp.toDate().toLocaleTimeString()
                                      : ''}
                                  </span>
                                </div>
                              </div>
                            ))
                          )}
                          <div ref={messagesEndRef} />
                        </div>
                        <div className="mt-4 flex">
                          <textarea
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyPress={handleKeyPress}
                            rows="2"
                            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 resize-none"
                            placeholder="Type your message..."
                          ></textarea>
                          <button
                            onClick={handleSendMessage}
                            className="ml-2 bg-indigo-600 text-white rounded-md px-4 py-2 hover:bg-indigo-700 flex items-center justify-center"
                          >
                            <PaperAirplaneIcon className="h-5 w-5 transform rotate-45" />
                          </button>
                        </div>
                      </div>

                      {/* Action Buttons */}
                      <div className="flex justify-end">
                        <button
                          onClick={closeOrderModal}
                          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold px-4 py-2 rounded-md"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>
          </>
        )}
      </div>
    </div>
  );
};

export default BuyerDashboard;