import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../utils/firebase';

import { getUser } from './globalFunctions';
const loginWithEmail = async (email, password) => {
  try{
    let res = await signInWithEmailAndPassword(auth, email, password);
    // get user doc
    let id = res.user.uid;
    console.log(id);  
    return await getUser(id);
  }
  catch(err){
    console.log(err);
    return false
  }
};

export default loginWithEmail;
