import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";

import CheckoutForm from "./CheckoutForm";
import { addressURL, globalAlert } from "../../utils/globalFunctions";


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.pk_test_51In85eBRfq98NepUyHFqc2bDpvzDKVRpttRFimWmlHjBEJCzHdkDe0kjWI3fOwgG7L8kIi5DwMDv2gDsBilZTUlr004RX8RE5Y
const stripePromise = loadStripe("pk_test_51In85eBRfq98NepUyHFqc2bDpvzDKVRpttRFimWmlHjBEJCzHdkDe0kjWI3fOwgG7L8kIi5DwMDv2gDsBilZTUlr004RX8RE5Y");


// const stripePromise = loadStripe("pk_live_51I3s5JDKwxukyzSiSTn0ePmfFm4dynCn9OXAdInJJhomeSFMi7tyxYRqmgRqXNzLX6dbgEj8VYxgh2qOKIsSqktQ00Q2qwU5iK");
export default function StripeCheckout(props) {
  const [clientSecret, setClientSecret] = useState("");
  const page = window.location.pathname
  const navigate = useNavigate();
 

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(addressURL + "/api/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ invoices: props.invoices }),
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        if(data.success){
          setClientSecret(data.clientSecret);
        }else{
          globalAlert(
            data.message,
            "error"
          )
          navigate("/invoices")
        }

      } ).catch((err)=>{
      })
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>

      {
        clientSecret  !=="" &&
        <Elements options={options} stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
      }
      
     

      
      

    </div>
  );
}