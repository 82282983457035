import React, { useState, useEffect } from 'react';
import Tour from 'reactour';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import {userTourCompleted} from '../utils/globalFunctions'

const CustomTourContent = ({ content, image }) => (
  <StyledContent>
    {image && <StyledImage src={image} alt="Tour Visual" />}
    <StyledText>{content}</StyledText>
  </StyledContent>
);

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const StyledText = styled.p`
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  max-width: 500px;
`;

const enContent = {
  welcome: "Welcome to your store’s control center! Manage your products, track sales performance, and streamline price updates with ease.",
  menu: 'Click here to open the menu and navigate through different sections of your store.',
  orders: 'Navigate to the Orders page to monitor, track, and efficiently manage your existing orders.',
  extensions: 'Access the Extensions section to explore add-ons designed to elevate your business efficiency and customer experience.',
  customers: 'Manage your customer database efficiently in this section. Capture essential customer details for personalized communication and enhanced service.',
  inventory: 'Visit the Inventory section to organize and manage your stock seamlessly. Keeping this updated ensures accurate store pricing.',
  inventoryPage: 'Welcome to the Inventory page! Here, you can manage your inventory, remove products, or create your own products in the near future.',
  priceUpdater: 'This is the Price Updater. Update your product prices together by specifying a profit percentage. Note that large inventories may take some time to update.',
  priceUpdate: 'You can also update product prices individually here. This gives you granular control over pricing adjustments.',
  newOrderButton: 'Use this New Order button to create an order quickly and efficiently for your store.',
  orderPage: 'Welcome to the Order Entry page. Here, you can add new items and create an order quickly.',
  customerCreation: 'Create new customers within the Order Entry page. The customer will be auto-selected for the order.',
  existingCustomer: 'You can also select an existing customer for the order.',
  productSelection: 'Search products by name, and customize each selected item as needed.',
  addItem: 'Click here to add the completed item to the order.',
  orderTable: 'View all added items in the order table. Scroll left to see more details.',
  taxDiscount: 'Add tax and discounts as needed. Prices will reflect your store’s set values.',
  summary: 'The summary updates as you add items. Customer total shows the due amount, and Manufacturer total indicates what you owe to your supplier.',
  saveOrder: 'Once all items are added, click here to save the order. Be careful: after processing, you can no longer modify the order.',
  extensionsWelcome: 'Welcome to the Extensions page! Here, you can explore and manage available add-ons to expand your business capabilities.',
  estimateIntro: 'Welcome to the Estimate Sender. Here, you can send price estimates to customers or download them for your records.',
  estimateSelectCustomer: 'Select a customer to generate an estimate for. New customers can also be created on the fly.',
  estimateSelectOrder: 'After selecting a customer, choose an order to include in the estimate.',
  estimateSelectTemplate: 'Choose from the available templates to customize the estimate layout to your preference.',
  estimateDownload: 'Download the estimate in PDF format for offline records and documentation.',
  estimateSendEmail: 'Alternatively, send the estimate directly via email. Make sure the customer email is correctly filled in.',
};

const trContent = {
  welcome: "Mağaza kontrol merkezine hoş geldiniz! Ürünlerinizi yönetin, satış performansını izleyin ve fiyat güncellemelerini kolayca yapın.",
  menu: 'Menüyü açmak ve mağazanızın farklı bölümlerine gitmek için buraya tıklayın.',
  orders: 'Mevcut siparişlerinizi takip etmek ve verimli bir şekilde yönetmek için Siparişler sayfasına gidin.',
  extensions: 'İşletme verimliliğinizi ve müşteri deneyiminizi artırmak için eklentiler bölümüne göz atın.',
  customers: 'Müşteri veritabanınızı burada verimli bir şekilde yönetin. İletişim bilgilerini girerek kişiselleştirilmiş hizmet sunun.',
  inventory: 'Stoklarınızı düzenlemek ve verimli bir şekilde yönetmek için Stok bölümüne gidin. Güncel tutmak, doğru fiyatlandırma sağlar.',
  inventoryPage: 'Stok sayfasına hoş geldiniz! Burada envanterinizi yönetebilir, ürünleri kaldırabilir veya yeni ürünler ekleyebilirsiniz.',
  priceUpdater: 'Bu, Fiyat Güncelleyicidir. Kâr yüzdesini belirleyerek toplu olarak ürün fiyatlarını güncelleyebilirsiniz.',
  priceUpdate: 'Ürün fiyatlarını tek tek buradan güncelleyebilirsiniz. Bu, fiyatlandırma değişikliklerinde daha ayrıntılı kontrol sağlar.',
  newOrderButton: 'Bu Yeni Sipariş düğmesini kullanarak hızlıca yeni bir sipariş oluşturabilirsiniz.',
  orderPage: 'Sipariş Giriş sayfasına hoş geldiniz. Burada yeni ürünler ekleyebilir ve sipariş oluşturabilirsiniz.',
  customerCreation: 'Sipariş giriş sayfasında yeni müşteriler oluşturabilirsiniz. Müşteri sipariş için otomatik olarak seçilecektir.',
  existingCustomer: 'Mevcut müşteriyi sipariş için seçebilirsiniz.',
  productSelection: 'Ürünleri ada göre arayın ve her birini özelleştirin.',
  addItem: 'Ürün tamamlandıktan sonra buraya tıklayın ve siparişe ekleyin.',
  orderTable: 'Tüm eklenen ürünleri sipariş tablosunda görebilirsiniz. Daha fazla ayrıntı görmek için sola kaydırın.',
  taxDiscount: 'Gerekirse vergi ve indirim ekleyin. Fiyatlar, mağazanızın belirlediği değerleri yansıtacaktır.',
  summary: 'Özet, ürün eklemeye başladıkça güncellenir. Toplam, müşterinizin borçlu olduğu tutarı ve tedarikçiye borçlu olduğunuz tutarı gösterir.',
  saveOrder: 'Tüm ürünler eklendikten sonra siparişi kaydetmek için buraya tıklayın. Dikkat edin: işleme aldıktan sonra siparişi değiştiremezsiniz.',
  extensionsWelcome: 'Eklentiler sayfasına hoş geldiniz! İşletmenizin yeteneklerini genişletmek için mevcut eklentilere göz atabilirsiniz.',
  estimateIntro: 'Teklif göndericiye hoş geldiniz. Burada müşterilere fiyat teklifleri gönderebilir veya kayıtlarınız için indirebilirsiniz.',
  estimateSelectCustomer: 'Teklif oluşturmak için bir müşteri seçin. Yeni müşteriler hızlıca eklenebilir.',
  estimateSelectOrder: 'Müşteriyi seçtikten sonra, teklif için bir sipariş seçin.',
  estimateSelectTemplate: 'Mevcut şablonlardan birini seçerek teklif düzenini özelleştirin.',
  estimateDownload: 'Teklifi PDF formatında indirerek çevrimdışı kayıt tutabilirsiniz.',
  estimateSendEmail: 'Alternatif olarak, teklifi doğrudan e-posta ile gönderin. Müşteri e-posta adresinin doğru olduğundan emin olun.',
};

const zhContent = {
  welcome: "欢迎来到您的商店控制中心！轻松管理您的产品、跟踪销售业绩，并简化价格更新流程。",
  menu: '点击此处打开菜单并浏览您的商店各个部分。',
  orders: '前往订单页面，监控、跟踪和高效管理现有订单。',
  extensions: '访问扩展部分，探索旨在提升业务效率和客户体验的附加功能。',
  customers: '在此部分高效管理客户数据库。捕获重要客户信息以便进行个性化沟通。',
  inventory: '访问库存部分，轻松组织和管理您的库存。保持更新可确保准确的店铺定价。',
  inventoryPage: '欢迎来到库存页面！在此，您可以管理库存、移除产品，或在未来创建自己的产品。',
  priceUpdater: '这是价格更新器。通过指定利润百分比，可以批量更新所有产品价格。',
  priceUpdate: '您还可以在这里逐个更新产品价格。这为价格调整提供了更详细的控制。',
  newOrderButton: '使用这个新订单按钮，为您的商店快速高效地创建订单。',
  orderPage: '欢迎来到订单输入页面。在这里，您可以快速添加新商品并创建订单。',
  customerCreation: '在订单输入页面中直接创建新客户。创建的客户将自动为订单选择。',
  existingCustomer: '您也可以为订单选择现有客户。',
  productSelection: '按名称搜索产品，选择产品后将显示定制选项。',
  addItem: '完成订单项后，单击此处将其添加到订单中。',
  orderTable: '在订单表中查看所有已添加的商品。向左滚动以查看更多详细信息。',
  taxDiscount: '根据需要添加税费和折扣。价格将根据您在库存中设置的商店价格计算。',
  summary: '当您开始添加订单项时，摘要将自动更新。客户总额为客户应付款，供应商总额为您需支付给供应商的金额。',
  saveOrder: '添加所有订单项后，点击此处保存订单。请注意：处理后无法再修改订单。',
  extensionsWelcome: '欢迎来到扩展页面！在这里，您可以探索和管理可用的附加功能。',
  estimateIntro: '欢迎使用报价发送器。在这里，您可以向客户发送价格估算或下载记录。',
  estimateSelectCustomer: '选择一个客户以生成报价。您可以快速添加新客户。',
  estimateSelectOrder: '选择客户后，选择要包括在报价中的订单。',
  estimateSelectTemplate: '从可用模板中选择，以自定义报价布局。',
  estimateDownload: '下载报价为PDF格式以便进行离线记录。',
  estimateSendEmail: '或者，直接通过电子邮件发送报价。请确保客户的电子邮件已正确填写。',
};

const TourGuide = ({user}) => {
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollLock, setScrollLock] = useState(false); // Track scroll lock state
  const [isBack,setIsBack] = useState(false)
  const [content,setContent] = useState({})
  const navigate = useNavigate();

  useEffect(() => {
    if(user?.isTourCompleted === true){
      setIsTourOpen(false)
    }else{
      setScrollLock(true)
    }
    setContent(enContent);
    const handleResize = () => setIsMobileView(window.innerWidth < 1024);
    window.addEventListener('resize', handleResize);
    return () =>{ window.removeEventListener('resize', handleResize); setScrollLock(false)};
  }, []);

  // Function to toggle menu
  const toggleMenu = (open) => {
    const menuButton = document.querySelector('.menu-button');
    if (menuButton) {
      if (open) {
        menuButton.click(); // Open menu
        setIsMenuOpen(true);
        let menu = document.querySelector('.custom-menu')?.style
        
        if(menu){
          menu.display = "block"
        }
        console.log('Menu opened');
      } else {
        menuButton.click(); // Close menu
        setIsMenuOpen(false);
        let menu = document.querySelector('.custom-menu')
        menu.style.display = "none"

        console.log('Menu closed');
      }
    }
  };

  const steps = isMobileView
    ? [
      {
        selector: '',
        content: () => (
          <>
          <p className='text-center text-lg font-bold'>Tour Guide</p>
          <p className='text-xs mb-2 text-center'>(approx. 2-3 minutes)</p>
          <p>Please Select a Language</p>
                    <select 
            className="bg-white border border-gray-300 rounded pr-8  mb-4"
            onChange={(e) => {
              
              if(e.target.value === "1"){
                setContent(enContent)
              }else if(e.target.value === "2"){
                setContent(trContent)
              }else{
                setContent(zhContent)
              }

            }}
          >
            <option value="1">English 🇨🇦</option>
            <option value="2">Turkish 🇹🇷</option>
            <option value="3">Chinese 🇨🇳</option>

              </select>
              <CustomTourContent
            content={content.welcome}
            image="https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/Image.webp?alt=media&token=65971dff-7db4-4563-8fc4-f131b2232020"
          />
          </>
  
        ),
        action: () => {
          navigate("/")
          if(isBack){
            toggleMenu(false)
          }
        }
      },

        {
          selector: '.menu-button',
          content: content.menu,
          action: () =>{

            setTimeout(() => {
              toggleMenu(true); 
            }, 650); // Delay to ensure smooth navigation
    
          },
          position: 'right',
        },
        {
          selector: '.store-orders-mobile',
          content: content.orders,
          position: 'right',
        },
        {
          selector: '.store-extensions-mobile',
          content: content.extensions,
          position: 'right',
        },
        {
          selector: '.store-customers-mobile',
          content:content.customers,
          position: 'right',
        },
        {
          selector: '.store-inventory-mobile',
          content: content.inventory,
          position: 'right',
        },
        {
          selector: '',
          action: () => {
            if(isBack){
              navigate("/")
              toggleMenu(true)
              setTimeout(() => setCurrentStep((prev) => prev - 1), 500);
            }else{
            navigate('/store-products');
            setTimeout(() => {
              toggleMenu(false); // Close the menu after navigating
              console.log('Navigated to inventory, menu should close');
              setCurrentStep((prev) => prev + 1);
            }, 500); // Delay to ensure smooth navigation
          }
          },
        },
        {
          selector: '',
          content: () => (
            <CustomTourContent
              content={content.inventoryPage}
              image="https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/Image%202.webp?alt=media&token=60321381-3775-480b-b3c9-68769a7e4d4f"
            />
          ),
          action: () => {
            // scroll the inventory-table to a little bit right 
            let inventoryTable = document.querySelector('.inventory-table');

            if (inventoryTable) {
              inventoryTable.scrollLeft += 100;
            }
          },
        },
        {
          selector: '.price-updater',
          content: content.priceUpdater,
          position: 'top',
        },
        {
          selector: '.price-update',
          content: content.priceUpdate,
          position: 'top',

        },
        {
          selector: '',
          content: '',
          position: 'top',
          action: () => {
            if(isBack){
              navigate("/store-products");
              setTimeout(() => setCurrentStep((prev) => prev - 1), 500);
            }else{
              navigate("/")
              setTimeout(() => {
                console.log('Navigated to inventory, menu should close');
                setCurrentStep((prev) => prev + 1);
              }, 500); // Delay to ensure smooth navigation
            }
          }
        },
        {
          selector: '.new-order-button',
          content: content.newOrderButton,
          position: 'bottom',
        },
        {
          selector: '',
          content: '',
          position: 'top',
          action: () => {
            if(isBack){
              navigate("/")
              setTimeout(() => setCurrentStep((prev) => prev - 1), 500);
            }else{
            navigate("/order")
            setTimeout(() => {
              console.log('Navigated to inventory, menu should close');
              setCurrentStep((prev) => prev + 1);
            }, 500); // Delay to ensure smooth navigation
          }
          }
        },
        {
          selector: '',
          content: () => (
            <CustomTourContent
              content={content.orderPage}
              image="https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/Image%202.webp?alt=media&token=60321381-3775-480b-b3c9-68769a7e4d4f"
            />
          ),
          action: () => {
            // scroll the inventory-table to a little bit right 
            let inventoryTable = document.querySelector('.inventory-table');

            if (inventoryTable) {
              inventoryTable.scrollLeft += 100;
            }
          },
        },
        {
          selector: '.create-a-new-customer',
          content: content.customerCreation,
        },
        {
          selector: '.select-a-customer',
          content: content.existingCustomer,
        },
        {
          selector: '.product',
          content: content.productSelection,
        },
        {
          selector: '.add-to-order-mobile',
          content: content.addItem,
        },
        {
          selector: '.order-table',
          content: content.orderTable,
        },
        {
          selector: '.tax-and-discount',
          content: content.taxDiscount,
        },
        {
          selector: '.order-summary',
          content: content.summary,
        },
        {
          selector: '.save-order',
          content: content.saveOrder,
        },
        {
          selector:'',
          content: '',
          action: () => {

            // scroll to top of the window
            window.scrollTo({
              top: 0
            })

            if(isBack){
              navigate("/order")
              setTimeout(()=>{
                setCurrentStep((prev) => prev - 1)
              },
            [500])
            }else{



            navigate('/extensions')

            setTimeout(()=>{         
              setCurrentStep((prev) => prev + 1)
            },
          [500])
            }
          }
        },
        {
          selector: '',
          content: () => (
            <CustomTourContent
              content={content.extensionsWelcome}
              image="https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/Image%202.webp?alt=media&token=60321381-3775-480b-b3c9-68769a7e4d4f"
            />
          ),
        },
        {
          selector: '',
          content: '',
          action: () =>{
            if(isBack){
              navigate("/extensions")
              setTimeout(()=>{         
                setCurrentStep((prev) => prev - 1)
              },
            [500])
            }else{
              navigate('/extension/blindsEstimate')
              setTimeout(()=>{      
                setCurrentStep((prev) => prev + 1)
              },
            [500])
            }

   
          }
        },

        {
          selector: '.estimate-select-customer',
          content: content.estimateSelectCustomer,
        },
        {
          selector: '.estimate-select-order',
          content: content.estimateSelectOrder,
        },
        {
          selector: '.estimate-select-template',
          content: content.estimateSelectTemplate,
        },
        {
          selector: '.estimate-download',
          content: content.estimateDownload,
        },
        {
          selector: '.estimate-send-email',
          content: content.estimateSendEmail,
        },
      ]
    : [
      {
        selector: '',
        content: () => (
          <>
          <p className='text-center text-lg font-bold'>Tour Guide</p>
          <p className='text-xs mb-2 text-center'>(approx. 2-3 minutes)</p>
          <p>Please Select a Language</p>
                    <select 
            className="bg-white border border-gray-300 rounded pr-8  mb-4"
            onChange={(e) => {
              
              if(e.target.value === "1"){
                setContent(enContent)
              }else if(e.target.value === "2"){
                setContent(trContent)
              }else{
                setContent(zhContent)
              }

            }}
          >
            <option value="1">English 🇨🇦</option>
            <option value="2">Turkish 🇹🇷</option>
            <option value="3">Chinese 🇨🇳</option>

              </select>
              <CustomTourContent
            content={content.welcome}
            image="https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/Image.webp?alt=media&token=65971dff-7db4-4563-8fc4-f131b2232020"
          />
          </>
  
        ),
        action: () => {
          navigate("/")
          if(isBack){
            toggleMenu(false)
          }
        }
      },
        {
          selector: '.store-orders-desktop',
          content: content.orders,
          position: 'right',
        },
        {
          selector: '.store-extensions-desktop',
          content: content.extensions,
          position: 'right',
        },
        {
          selector: '.store-customers-desktop',
          content: content.customers,
          position: 'right',
        },
        {
          selector: '.store-inventory-desktop',
          content: content.inventory,
          position: 'right',
        },
        {
          selector: '',
          action: () => {
            if(isBack){
              navigate("/");
              setTimeout(() => setCurrentStep((prev) => prev - 1), 500);
              
            }else{
              navigate('/store-products');
              setTimeout(() => setCurrentStep((prev) => prev + 1), 500);
            }
 
          },
        },
        {
          selector: '',
          content: () => (
            <CustomTourContent
              content={content.inventoryPage}
              image="https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/Image%202.webp?alt=media&token=60321381-3775-480b-b3c9-68769a7e4d4f"
            />
          ),
        },
        {
          selector: '.price-updater',
          content: content.priceUpdater,
          position: 'top',
        },
        {
          selector: '.price-update',
          content: content.priceUpdate,
          position: 'top',
        },
        {
          selector: '',
          content: '',
          position: 'top',
          action: () => {
            if(isBack){
              navigate("/store-products");
              setTimeout(() => setCurrentStep((prev) => prev - 1), 500);
            }else{
              navigate("/")
              setTimeout(() => setCurrentStep((prev) => prev + 1), 500);
            }
          }
        },
        {
          selector: '.new-order-button',
          content: content.newOrderButton,
          position: 'bottom',
        },
        {
          selector: '',
          content: '',
          position: 'top',
          action: () => {
            if(isBack){
              navigate("/");
              setTimeout(() => setCurrentStep((prev) => prev - 1), 500);
            }else{
              navigate("/order")
              setTimeout(() => {
                console.log('Navigated to inventory, menu should close');
                setCurrentStep((prev) => prev + 1);
              }, 500); // Delay to ensure smooth navigation
            }
  
          }
        },
        {
          selector: '',
          content: () => (
            <>
  
            <CustomTourContent
              content= {content.orderPage}
              image="https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/Image%202.webp?alt=media&token=60321381-3775-480b-b3c9-68769a7e4d4f"
            />
            </>
    
          ),
          action: () => {
            // scroll the inventory-table to a little bit right 
            let inventoryTable = document.querySelector('.inventory-table');

            if (inventoryTable) {
              inventoryTable.scrollLeft += 100;
            }
          },
        },
        {
          selector: '.create-a-new-customer',
          content: content.customerCreation,
        },
        {
          selector: '.select-a-customer',
          content: content.existingCustomer,
        },
        {
          selector: '.product',
          content: content.productSelection,
        },
        {
          selector: '.add-to-order-desktop',
          content: content.addItem,
        },
        {
          selector: '.order-table',
          content: content.orderTable,
        },
        {
          selector: '.tax-and-discount',
          content: content.taxDiscount,
        },
        {
          selector: '.order-summary',
          content: content.summary,
        },
        {
          selector: '.save-order',
          content: content.saveOrder,
        },
        {
          selector:'',
          content: '',
          action: () => {
            // scroll to top of the window
            window.scrollTo({
              top: 0
            })


            if(isBack){
              navigate("/order")
              setTimeout(()=>{         
                setCurrentStep((prev) => prev - 1)
              },
            [500])
            }else{
              navigate('/extensions')
              setTimeout(()=>{         
                setCurrentStep((prev) => prev + 1)
              },
            [500])
            }


          }
        },
        {
          selector: '',
          content: () => (
            <CustomTourContent
              content={content.extensionsWelcome}
              image="https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/Image%202.webp?alt=media&token=60321381-3775-480b-b3c9-68769a7e4d4f"
            />
          ),
        },
        {
          selector: '',
          content: '',
          action: () =>{
            if(isBack){
              navigate("/extensions")
              setTimeout(()=>{         
                setCurrentStep((prev) => prev - 1)
              },
            [500])
            }else{
              navigate('/extension/blindsEstimate')
              setTimeout(()=>{      
                setCurrentStep((prev) => prev + 1)
              },
            [500])
            }

   
          }
        },

        {
          selector: '.estimate-select-customer',
          content: content.estimateSelectCustomer,
        },
        {
          selector: '.estimate-select-order',
          content: content.estimateSelectOrder,
        },
        {
          selector: '.estimate-select-template',
          content: content.estimateSelectTemplate,
        },
        {
          selector: '.estimate-download',
          content: content.estimateDownload,
        },
        {
          selector: '.estimate-send-email',
          content: content.estimateSendEmail,
        },
      ];

      useEffect(() => {
        if(currentStep >= 0){
          if(isMobileView){
            // if(currentStep === 7){
            //   navigate("/");
            // }
          }
        }

      }, [currentStep])

      const handlePrevStep = () => {
        if(currentStep == steps.length -1){
          navigate("/")
          setCurrentStep(0)
        }else{
          setIsBack(true)
          setCurrentStep((prev) => prev - 1);
        }
   

      }

      const handleNextStep = () => {
        setIsBack(false)
        setCurrentStep((prev) => prev + 1);

      }

      const handleCompleteTour = () => {
  
   
      userTourCompleted({id:user.id}).then(()=>{
        toast.success("Yayyy you finished the tour")
        setIsTourOpen(false)
        setScrollLock(false)
        navigate("/")
      }).catch((error)=>{
        toast.error("Something went wrong, please try again")
      })
      }

      useEffect(()=>{

        if(scrollLock){

          document.body.style.overflow = "hidden"
        }else{
          document.body.style.overflow = "visible"
        }
      },[scrollLock])

  return (
    content ? (
    <Tour
      steps={steps}
      isOpen={isTourOpen}
      getCurrentStep={(step) => setCurrentStep(step)}
      onRequestClose={() => {
        // check if current step is the las step
        if(currentStep == steps.length -1){
          console.log("here")
          setIsTourOpen(false)
        }
      }}
      goToStep={currentStep}
      accentColor="#007bff"
      rounded={5}
      showNumber={true}
      disableInteraction={true}
      disableDotsNavigation={true}
      disableKeyboardNavigation={true}
      showCloseButton={false}
      showNavigation={false}
      showNumber={false}

      inViewThreshold={50} // Set threshold for element view
      lastStepNextButton={
        <>
        <button 
          className='bg-gray-300 w-32 h-16'
          onClick={()=>{
            handleCompleteTour()

          }}
          >
            Complete The Tour
          </button>
        
        </>
      }
      

      prevStep={handlePrevStep}
      nextStep={handleNextStep}


    />
    ) : null
  );
};

export default TourGuide;