import React, { useState, useEffect } from "react";
import {
  fetchStoreProducts,
  globalAlert,
  removeProductFromInventory,
} from "../../utils/globalFunctions";
import Skeleton from "react-loading-skeleton";
import { Button, Table, Pagination } from "flowbite-react";
import { updateStoreProduct } from "../../utils/globalFunctions";
import { useNavigate } from "react-router-dom";
import { updateStoreProductPrices } from "../../utils/firebase";
import {
  CubeIcon,
  CubeTransparentIcon,
  GlobeAltIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import StoreDashboardTemplate from "../../components/StoreDashboardTemplate";
import ExcelEditor from "../../components/ExcelEditor";
import { getManufacturerPriceListByProductID ,fetchSupplierName} from "../../utils/globalFunctions";
import { toast } from "react-toastify";

const ExcelView = ({ product_id, setOpenExcelLoader }) => {
  const [priceList, setPriceList] = useState(null);

  useEffect(() => {
    getManufacturerPriceListByProductID(product_id).then((res) => {
      setPriceList(res);
    });
  }, [product_id]);

  return priceList !== null ? (
    <div className="overflow-x-scroll">
      <p className="p-2">
        This is manufacturer's prices without discounts applied. Your manufacturer
        discounts only applied at your Order Entry form.
      </p>
      <ExcelEditor data={priceList} setPriceList={setPriceList} increase={0} />
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default function Products({ user }) {
  const [products, setProducts] = useState([]);
  const [storeProducts, setStoreProducts] = useState([]);
  const [displayProducts, setDisplayProducts] = useState([]);
  const [isStoreProducts, setIsStoreProducts] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [profitPercentage, setProfitPercentage] = useState(80);
  const [editPrice, setEditPrice] = useState("");
  const [fetchProducts, setFetchProducts] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [openExcelLoader, setOpenExcelLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8;
  const nav = useNavigate();

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "#ffffff");
    }
  }, []);

  useEffect(() => {
    const fetchProductsData = async () => {
      const data = await fetchStoreProducts(user.store_id, null);
      const storeProds = data.filter((product) => product.isStore === true);
      const globalProds = data.filter(
        (product) => product.isStore !== true || product.isStore === undefined
      );
      setStoreProducts(storeProds);
      setProducts(globalProds);
    };
    fetchProductsData();
  }, [fetchProducts, user.store_id]);

  useEffect(() => {
    const filteredProducts = filterProducts(
      isStoreProducts ? storeProducts : products,
      searchQuery
    );
    setDisplayProducts(filteredProducts);
    setCurrentPage(1);
  }, [isStoreProducts, storeProducts, products, searchQuery]);

  const filterProducts = (products, query) => {
    return products?.filter((product) =>
      product.product_title.toLowerCase().includes(query.toLowerCase())
    );
  };

  const handleUpdateProductPrice = (product) => {
    if (isNaN(product.store_product_price)) {
      alert("Store price must be a number");
      return;
    }
    product.store_product_price = parseFloat(
      product.store_product_price
    ).toFixed(2);

    updateStoreProduct(user.role, user.store_id, product).then((res) => {
      setEditPrice("");
      if (!res) {
        alert(
          "Something went wrong while updating the price, please report this to admin"
        );
      }
    });
  };

  const secondaryNavigation = [
    {
      name: "Store Products",
      href: "#",
      icon: CubeIcon,
      current: true,
      onClick: () => {
        nav("/store-products");
      },
    },
    {
      name: "Store Customizations",
      href: "#",
      className: "global-customizations",
      icon: CubeTransparentIcon,
      current: false,
      onClick: () => {
        nav("/store-product-customizations");
      },
    },
    {
      name: "Discover Products",
      href: "#",
      icon: GlobeAltIcon,
      className: "discover-products",
      current: false,
      onClick: () => {
        nav("/discover-new-products");
      },
    },
  ];

  // Define table columns based on the product type
  let columns = [
    { Header: "Product" },
    !isStoreProducts
      ? { Header: "Price" } :  null,

    { Header: "Store Price", labelLeft: "$" },
    { Header: "Availability" },
    { Header: "Category" },
    // Conditional column: Supplier or Manufacturer
    isStoreProducts
      ? { Header: "Supplier" }
      : { Header: "Manufacturer" },
    // Add "Stock Management" column for store products
    ...(isStoreProducts ? [{ Header: "Stock Management" }] : []),
    { Header: "Actions" },
  ];
  // remove nulls
  columns = columns.filter((column) => column !== null);



  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = displayProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const totalPages = Math.ceil(displayProducts.length / productsPerPage);

  console.log(currentProducts);
  return (
    <>
      <StoreDashboardTemplate secondaryNavigation={secondaryNavigation}>
        {openExcelLoader && (
          <div className="w-full h-full flex flex-col">
            <div className="sm:mt-4 mt-24 overflow-hidden sm:p-16">
              <button
                className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs ml-2 mr-2"
                onClick={() => {
                  setOpenExcelLoader(false);
                }}
              >
                Close
              </button>
              <ExcelView
                product_id={openExcelLoader}
                setOpenExcelLoader={setOpenExcelLoader}
              />
            </div>
          </div>
        )}

        {!openExcelLoader && (
          <>
            <div className="flex flex-col">
              {/* Header Section */}
              <div className="flex flex-row justify-between items-center px-6 py-4 bg-white shadow-sm">
                <div className="flex items-center">
                  <button
                    className={`mr-2 px-4 py-2 rounded ${
                      isStoreProducts
                        ? "bg-blue-600 text-white sm:text-base text-sm "
                        : "bg-gray-200 text-gray-700 sm:text-base text-sm "
                    }`}
                    onClick={() => setIsStoreProducts(true)}
                  >
                    Store Products
                  </button>
                  <button
                    className={`px-4 py-2 rounded ${
                      !isStoreProducts
                        ? "bg-blue-600 text-white sm:text-base text-sm "
                        : "bg-gray-200 text-gray-700 sm:text-base text-sm "
                    }`}
                    onClick={() => setIsStoreProducts(false)}
                  >
                    Global Products
                  </button>
                </div>
                {isStoreProducts && (
                  <button
                    className="flex ml-2 sm:text-base text-sm items-center bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded"
                    onClick={() => nav("/create-store-product")}
                  >
                    <PlusIcon className="h-5 w-5 mr-1" />
                    Create Product
                  </button>
                )}
              </div>

              {/* Filters Section */}
              <div className="flex flex-col sm:flex-row w-full justify-between items-center px-6 py-4 bg-gray-50">
                <input
                  type="text"
                  placeholder="Search by product title"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="border border-gray-300 w-full sm:w-64 h-10 rounded-lg mb-4 sm:mb-0 px-4 focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
                {
                  !isStoreProducts && (
                    <div className="flex items-center">
                    <div className="flex flex-col sm:flex-row items-center">
                      <label className="text-sm text-gray-700 mb-1 sm:mb-0 sm:mr-2">
                        Price Updater (profit %)
                      </label>
                      <input
                        type="text"
                        value={profitPercentage}
                        onChange={(e) => {
                          if (
                            e.target.value[0] !== "-" &&
                            isNaN(e.target.value)
                          ) {
                            globalAlert("Please enter a valid number");
                            return;
                          }
                          setProfitPercentage(e.target.value);
                        }}
                        className="border border-gray-300 w-24 rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-600"
                      />
                      <button
                        className="bg-blue-600 hover:bg-blue-700 h-8 px-4 rounded text-white text-sm ml-2 mt-2 sm:mt-0"
                        onClick={() => {
                          setUpdating(true);
                          toast.info(
                            "Updating prices, this may take a while..."
                          );
  
                          updateStoreProductPrices({
                            store_id: user.store_id,
                            profitPercentage,
                          }).then((res) => {
                            if (res) {
                              setFetchProducts(!fetchProducts);
                              toast.success("Prices updated successfully");
                            } else {
                              toast.error(
                                "Something went wrong while updating the prices, please report this to admin"
                              );
                            }
                            setUpdating(false);
                          });
                        }}
                      >
                        {updating ? "Updating..." : "Update"}
                      </button>
                    </div>
                  </div>
                  )
                }
           
              </div>

              {/* Products Table */}
              <div className="w-full">
                <div className="overflow-x-auto">
                  <Table className="min-w-full bg-white">
                    <Table.Head className="bg-gray-100">
                      {columns.map((column, idx) => (
                        <Table.HeadCell
                          key={idx}
                          className="whitespace-nowrap font-semibold text-gray-700 p-4 text-left"
                        >
                          {column.Header}
                        </Table.HeadCell>
                      ))}
                    </Table.Head>

                    <Table.Body className="divide-y">
                      {currentProducts.length > 0 ? (
                        currentProducts.map((p, index) => (
                          <Table.Row
                            className={
                              index % 2 === 0 ? "bg-white" : "bg-gray-50"
                            }
                            key={index}
                          >


                            {/* Title Cell */}
                            <Table.Cell className="p-4">
                              <p className="font-medium text-gray-800">
                                {p.product_title}
                              </p>
                            </Table.Cell>

                            {/* Price Cell */}
                            {
                              !isStoreProducts && (
                                <Table.Cell className="p-4">
                                {p.isPriceList ? (
                                  <p
                                    onClick={() => {
                                      setOpenExcelLoader(p.id);
                                    }}
                                    className="text-blue-600 cursor-pointer hover:underline"
                                  >
                                    {p?.priceList?.price_list_name}
                                  </p>
                                ) : (
                                  <p className="text-gray-700">
                                    {parseFloat(p.product_price).toFixed(2)} CAD
                                  </p>
                                )}
                              </Table.Cell>
                              )
                            }
                          

                            {/* Store Price Cell */}
                            {p.isPriceList ? (
                              <Table.Cell className="p-4">
                                <p
                                  className="text-blue-600 cursor-pointer hover:underline"
                                  onClick={() => {
                                    let priceListReference =
                                      p.storeProductPriceListRef;
                                    nav(
                                      "/priceListLoader/" +
                                        priceListReference.id
                                    );
                                  }}
                                >
                                  {p.storeProductPriceList.price_list_name}
                                </p>
                              </Table.Cell>
                            ) : (
                              <Table.Cell className="p-4">
                                {editPrice === p.id ? (
                                  <>
                                    <div className="relative rounded-md shadow-sm mb-2">
                                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <span className="text-gray-500 sm:text-sm">
                                          $
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        name="price"
                                        id="price"
                                        defaultValue={p.store_product_price}
                                        onChange={(e) => {
                                          let temp = [...displayProducts];
                                          let idx = temp.findIndex(
                                            (x) => x.id === p.id
                                          );
                                          temp[idx].store_product_price =
                                            e.target.value;
                                          setDisplayProducts(temp);
                                        }}
                                        className="block w-full rounded-md border-gray-300 pl-7 ppy-2 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        placeholder="0.00"
                                        aria-describedby="price-currency"
                                      />
                                    </div>
                                    <button
                                      className="bg-blue-600 w-full hover:bg-blue-700 text-white font-medium py-2 rounded"
                                      onClick={() => {
                                        setEditPrice("");
                                        handleUpdateProductPrice(p);
                                      }}
                                    >
                                      Save
                                    </button>
                                  </>
                                ) : (
                                  <div className="flex items-center">
                                    <p className="text-gray-700">
                                      {parseFloat(
                                        p.store_product_price
                                      ).toFixed(2)}{" "}
                                      CAD
                                    </p>
                                    <button
                                      onClick={() => {
                                        setEditPrice(p.id);
                                      }}
                                      className="ml-2 text-blue-600 hover:text-blue-800"
                                    >
                                      Edit
                                    </button>
                                  </div>
                                )}
                              </Table.Cell>
                            )}

                            {/* Availability Cell */}
                            <Table.Cell className="p-4">
                              <p
                                className={`${
                                  p.isAvailable
                                    ? "text-green-600"
                                    : "text-red-600"
                                } font-medium`}
                              >
                                {p.isAvailable ? "Available" : "Not Available"}
                              </p>
                            </Table.Cell>

                            {/* Category Cell */}
                            <Table.Cell className="p-4">
                              <p className="text-gray-700">
                                {p.product_category}
                              </p>
                            </Table.Cell>

                            {/* Supplier or Manufacturer Cell */}
                            {isStoreProducts ? (
                              <Table.Cell className="p-4">
                                <SupplierName supplierID={p.supplierID} />
                              </Table.Cell>
                            ) : (
                              <Table.Cell className="p-4">
                                <p className="text-gray-700">
                                  {p.creator === "Cachet Admin"
                                    ? "Cachet Blinds"
                                    : p.manufacturer_name}
                                </p>
                              </Table.Cell>
                            )}

                            {/* Stock Management Cell (Only for Store Products) */}
                            {isStoreProducts && (
                              <Table.Cell className="p-4">
                                <p
                                  className={`${
                                    p.isStock
                                      ? "text-green-600"
                                      : "text-red-600"
                                  } font-medium`}
                                >
                                  {p.isStock ? "Enabled" : "Disabled"}
                                </p>
                              </Table.Cell>
                            )}

                            {/* Actions Cell */}
                            <Table.Cell className="p-4">
                              <div

                              className="flex items-center"
                              
                              >



                              {/* {
                                isStoreProducts && (
                                  <button
                                  onClick={() => {
                                    nav(`/create-store-product/${p.id}`);
                                  }}
                                  className="bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium py-2 px-4 rounded mr-2"
                                  type="button"
                                >
                                  RFS
                                </button>
                                )
                              } */}
                              {
                                isStoreProducts && (
                                  <button
                                  onClick={() => {
                                    nav(`/create-store-product/${p.id}`);
                                  }}
                                  className="bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium py-2 px-4 rounded mr-2"
                                  type="button"
                                >
                                  Edit
                                </button>
                                )
                              }
                              <button
                                onClick={() => {
                                  removeProductFromInventory(
                                    user.store_id,
                                    p.id
                                  ).then((res) => {
                                    if (res) {
                                      setFetchProducts(!fetchProducts);
                                    } else {
                                      alert(
                                        "Something went wrong while removing the product, please report this to admin"
                                      );
                                    }
                                  });
                                }}
                                className="bg-red-600 hover:bg-red-700 text-white text-sm font-medium py-2 px-4 rounded"
                                type="button"
                              >
                                Remove
                              </button>

                              </div>
             
                            </Table.Cell>
                          </Table.Row>
                        ))
                      ) : (
                        <Table.Row>
                          <Table.Cell
                            colSpan={columns.length}
                            className="text-center p-4"
                          >
                            No products found.
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </StoreDashboardTemplate>
    </>
  );
}


const SupplierName = ({ supplierID }) => {
  const [supplierName, setSupplierName] = useState("");

  useEffect(() => { 
    fetchSupplierName(supplierID).then((res) => {
      setSupplierName(res);
    });
  }
  , [supplierID]);

  return (
    <div>
      <p
        className="text-gray-700"
      >{supplierName}</p>
    </div>
  );
}


