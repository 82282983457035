import React, { useEffect } from 'react'
import { useNavigate,useLocation } from 'react-router-dom'
import StripeCheckout from './stripeCheckout'

export default function Payment() {
    //get navigation state
    const nav = useNavigate()
    let location = useLocation();
    console.log(location)
    const selectedInvoices = location.state?.selectedInvoices;



    //get selected invoices from navigation state

    console.log(selectedInvoices)
  return (
    <div className='flex w-full justify-center'>      
      {
        selectedInvoices &&
        <StripeCheckout invoices={selectedInvoices}/>
      }

    </div>
  )
}
