import React, { useState, useEffect } from 'react';
import { updateManufacturer } from '../../utils/globalFunctions';
import { toast } from 'react-toastify';
import { Dialog } from '@headlessui/react';
import 'tailwindcss/tailwind.css';

// Importing Heroicons v2 icons
import {
  BellIcon,
  CreditCardIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import Quickbooks from './quickbooks_login';

const Settings = ({ manufacturer, user }) => {
  const [productionSheets, setProductionSheets] = useState([]);
  const [productionSheetOptions, setProductionSheetOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [keys, setKeys] = useState([]);
  const [selectedOptionsSettings, setSelectedOptionsSettings] = useState([]);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);
  const [newKeyName, setNewKeyName] = useState('');
  const [productionSheetTitle, setProductionSheetTitle] = useState('');
  const [newMaterialTypeName, setNewMaterialTypeName] = useState('');

  // New state variables for editing production sheets
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [sheetToEdit, setSheetToEdit] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [editSelectedOptions, setEditSelectedOptions] = useState([]);
  const [editSelectedOptionsSettings, setEditSelectedOptionsSettings] = useState([]);
  const [isMaterialTypeModalOpen, setIsMaterialTypeModalOpen] = useState(false);


  useEffect(() => {
    if (manufacturer) {
      const productionSheetKeys = manufacturer.productionSheetKeys || [];
      const categories = manufacturer.categories || [];
      const materialTypes = manufacturer.materialTypes || [];
      setMaterialTypes(materialTypes);
      setCategories(categories);
      setKeys(productionSheetKeys);
      const defaultOptions = [
        'Fabric Name',
        'Width',
        'Height',
        'Room Name',
        'Quantity',
        'Notes',
        'Matching Rule',
        'Cassette Cut',
        'Tube Cut',
        'Bottom Bar Cut',
        'Bottom Tube Cut',
        'Fabric Width Cut',
        'Fabric Height Cut',
        ...productionSheetKeys,
      ];
      setProductionSheetOptions(defaultOptions);
      setProductionSheets(manufacturer.productionSheets || []);
    }
  }, [manufacturer]);

  const handleSaveProductionSheets = async () => {
    try {
      const updatedManufacturer = {
        ...manufacturer,
        productionSheets,
        materialTypes,
        categories,
        productionSheetKeys: keys,
        id: user.manufacturerID,
      };
      await updateManufacturer(updatedManufacturer);
      toast.success('Settings saved successfully');
    } catch (error) {
      toast.error('Failed to save settings');
    }
  };

  const addCategory = () => {
    if (newCategoryName.trim() !== '') {
      setCategories([...categories, newCategoryName.trim()]);
      setNewCategoryName('');
      setIsCategoryModalOpen(false);
      toast.success('Category added');
    } else {
      toast.error('Category name cannot be empty');
    }
  };

  const addMaterialType = () => {
    if (newMaterialTypeName.trim() !== '') {
      setMaterialTypes([...materialTypes, newMaterialTypeName.trim()]);
      setNewMaterialTypeName('');
      setIsMaterialTypeModalOpen(false);
      toast.success('Material type name added');
    } else {
      toast.error('Material name cannot be empty');
    }
  };

  const addKey = () => {
    if (newKeyName.trim() !== '') {
      setKeys([...keys, newKeyName.trim()]);
      setNewKeyName('');
      setIsKeyModalOpen(false);
      toast.success('Key added');
    } else {
      toast.error('Key name cannot be empty');
    }
  };

  const createProductionSheet = () => {
    if (productionSheetTitle.trim() === '') {
      toast.error('Please enter a production sheet title');
      return;
    }
    const newSheet = {
      title: productionSheetTitle.trim(),
      options: selectedOptions,
      settings: selectedOptionsSettings,
    };
    setProductionSheets([...productionSheets, newSheet]);
    setSelectedOptions([]);
    setSelectedOptionsSettings([]);
    setProductionSheetTitle('');
    toast.success('Production sheet created');
  };

  const handleDeleteCategory = (category) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      const updatedCategories = categories.filter((item) => item !== category);
      setCategories(updatedCategories);
      toast.success('Category deleted');
    }
  };

  const handleDeleteMaterialTypeName = (m) => {
    if (window.confirm('Are you sure you want to delete this material type name?')) {
      const updatedMaterials = materialTypes.filter((item) => item !== m);
      setMaterialTypes(updatedMaterials);
      toast.success('Material name deleted, please don`t forget to save.');
    }
  };

  const handleDeleteKey = (key) => {
    if (window.confirm('Are you sure you want to delete this key?')) {
      const updatedKeys = keys.filter((item) => item !== key);
      setKeys(updatedKeys);
      toast.success('Key deleted');
    }
  };

  const handleDeleteProductionSheet = (sheetTitle) => {
    if (window.confirm('Are you sure you want to delete this production sheet?')) {
      const updatedSheets = productionSheets.filter((item) => item.title !== sheetTitle);
      setProductionSheets(updatedSheets);
      toast.success('Production sheet deleted');
    }
  };

  const openEditModal = (sheet) => {
    setSheetToEdit(sheet);
    setEditTitle(sheet.title);
    setEditSelectedOptions(sheet.options);
    setEditSelectedOptionsSettings(sheet.settings);
    setIsEditModalOpen(true);
  };

  const saveEditedSheet = () => {
    if (editTitle.trim() === '') {
      toast.error('Please enter a production sheet title');
      return;
    }
    const updatedSheet = {
      title: editTitle.trim(),
      options: editSelectedOptions,
      settings: editSelectedOptionsSettings,
    };
    setProductionSheets(
      productionSheets.map((sheet) => (sheet.title === sheetToEdit.title ? updatedSheet : sheet))
    );
    setIsEditModalOpen(false);
    toast.success('Production sheet updated');
  };

  return (
    <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      {/* <Quickbooks manufacturer={manufacturer} /> */}
      <h1 className="text-3xl font-bold leading-tight text-gray-900">Settings</h1>

      {/* Categories Section */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold text-gray-800">Categories</h2>
        <div className="mt-4 bg-white shadow sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {categories.map((category, index) => (
              <li key={index} className="flex items-center justify-between px-4 py-4 sm:px-6">
                <div className="flex items-center">
                  <span className="text-sm font-medium text-gray-900">{category}</span>
                </div>
                <div>
                  <button
                    onClick={() => handleDeleteCategory(category)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-4 flex">
          <button
            onClick={() => setIsCategoryModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Add Category
          </button>
        </div>
      </div>
      {/* Material Types Section */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold text-gray-800">Material Categories</h2>
        <div className="mt-4 bg-white shadow sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {materialTypes.map((m, index) => (
              <li key={index} className="flex items-center justify-between px-4 py-4 sm:px-6">
                <div className="flex items-center">
                  <span className="text-sm font-medium text-gray-900">{m}</span>
                </div>
                <div>
                  <button
                    onClick={() => handleDeleteMaterialTypeName(m)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-4 flex">
          <button
            onClick={() => setIsMaterialTypeModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Add Material Category
          </button>
        </div>
      </div>

      {/* Keys Section */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold text-gray-800">Keys</h2>
        <div className="mt-4 bg-white shadow sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {keys.map((key, index) => (
              <li key={index} className="flex items-center justify-between px-4 py-4 sm:px-6">
                <div className="flex items-center">
                  <span className="text-sm font-medium text-gray-900">{key}</span>
                </div>
                <div>
                  <button
                    onClick={() => handleDeleteKey(key)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-4 flex">
          <button
            onClick={() => setIsKeyModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Add Key
          </button>
        </div>
      </div>

      {/* Production Sheet Options */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold text-gray-800">Production Sheet Options</h2>
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {productionSheetOptions.map((option, index) => (
            <div key={index} className="flex items-center">
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                id={`option-${index}`}
                onChange={() => {
                  if (selectedOptions.includes(option)) {
                    setSelectedOptions(selectedOptions.filter((item) => item !== option));
                    setSelectedOptionsSettings(
                      selectedOptionsSettings.filter((item) => item.option !== option)
                    );
                  } else {
                    setSelectedOptions([...selectedOptions, option]);
                    setSelectedOptionsSettings([
                      ...selectedOptionsSettings,
                      {
                        option: option,
                        settings: {
                          fontSize: 12,
                          bold: false,
                          showKeys: true,
                        },
                      },
                    ]);
                  }
                }}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
              <label htmlFor={`option-${index}`} className="ml-2 block text-sm text-gray-700">
                {option}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Selected Options Settings */}
      {selectedOptions.length > 0 && (
        <div className="mt-10">
          <h2 className="text-xl font-semibold text-gray-800">Configure Production Sheet</h2>
          <div className="mt-4">
            <label htmlFor="productionSheetTitle" className="block text-sm font-medium text-gray-700">
              Production Sheet Title
            </label>
            <input
              type="text"
              id="productionSheetTitle"
              value={productionSheetTitle}
              onChange={(e) => setProductionSheetTitle(e.target.value)}
              className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="Enter title"
            />
          </div>
          <div className="mt-6">
            <h3 className="text-lg font-medium text-gray-900">Selected Options</h3>
            <div className="mt-4 space-y-4">
              {selectedOptions.map((option, index) => {
                const settings =
                  selectedOptionsSettings.find((item) => item.option === option)?.settings || {};
                return (
                  <div key={index} className="bg-white shadow sm:rounded-md p-4">
                    <h4 className="text-md font-medium text-gray-800">{option}</h4>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                      {/* Font Size */}
                      <div>
                        <label htmlFor={`fontSize-${index}`} className="block text-sm font-medium text-gray-700">
                          Font Size
                        </label>
                        <input
                          type="number"
                          id={`fontSize-${index}`}
                          value={settings.fontSize}
                          onChange={(e) => {
                            const updatedSettings = { ...settings, fontSize: e.target.value };
                            setSelectedOptionsSettings(
                              selectedOptionsSettings.map((item) =>
                                item.option === option ? { option, settings: updatedSettings } : item
                              )
                            );
                          }}
                          className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      {/* Bold */}
                      <div className="flex items-center mt-4 sm:mt-0">
                        <input
                          type="checkbox"
                          id={`bold-${index}`}
                          checked={settings.bold}
                          onChange={(e) => {
                            const updatedSettings = { ...settings, bold: e.target.checked };
                            setSelectedOptionsSettings(
                              selectedOptionsSettings.map((item) =>
                                item.option === option ? { option, settings: updatedSettings } : item
                              )
                            );
                          }}
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                        <label htmlFor={`bold-${index}`} className="ml-2 block text-sm text-gray-700">
                          Bold
                        </label>
                      </div>
                      {/* Show Keys */}
                      <div className="flex items-center mt-4 sm:mt-0">
                        <input
                          type="checkbox"
                          id={`showKeys-${index}`}
                          checked={settings.showKeys}
                          onChange={(e) => {
                            const updatedSettings = { ...settings, showKeys: e.target.checked };
                            setSelectedOptionsSettings(
                              selectedOptionsSettings.map((item) =>
                                item.option === option ? { option, settings: updatedSettings } : item
                              )
                            );
                          }}
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                        <label htmlFor={`showKeys-${index}`} className="ml-2 block text-sm text-gray-700">
                          Show Keys
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-6 flex space-x-4">
              <button
                onClick={() => {
                  setSelectedOptions([]);
                  setSelectedOptionsSettings([]);
                }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear
              </button>
              <button
                onClick={createProductionSheet}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Create Production Sheet
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Production Sheets List */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold text-gray-800">Production Sheets</h2>
        {productionSheets.length > 0 ? (
          <div className="mt-4 space-y-4">
            {productionSheets.map((sheet, index) => (
              <div key={index} className="bg-white shadow sm:rounded-md p-6">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium text-gray-900">{sheet.title}</h3>
                  <div className="flex space-x-4">
                    <button
                      onClick={() => openEditModal(sheet)}
                      className="text-blue-600 hover:text-blue-900 flex items-center"
                    >
                      <PencilIcon className="h-5 w-5 mr-1" />
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteProductionSheet(sheet.title)}
                      className="text-red-600 hover:text-red-900 flex items-center"
                    >
                      <XMarkIcon className="h-5 w-5 mr-1" />
                      Delete
                    </button>
                  </div>
                </div>
                <ul className="mt-4 space-y-2">
                  {sheet.options.map((option, idx) => (
                    <li key={idx} className="text-sm text-gray-700">
                      <span className="font-medium">{option}</span>
                      <ul className="ml-4 mt-1 text-xs text-gray-500">
                        <li>Font size: {sheet.settings.find((item) => item.option === option)?.settings.fontSize}</li>
                        <li>
                          Bold: {sheet.settings.find((item) => item.option === option)?.settings.bold ? 'Yes' : 'No'}
                        </li>
                        <li>
                          Show keys:{' '}
                          {sheet.settings.find((item) => item.option === option)?.settings.showKeys ? 'Yes' : 'No'}
                        </li>
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ) : (
          <p className="mt-4 text-sm text-gray-500">No production sheets found.</p>
        )}
      </div>

      {/* Save Button */}
      <div className="mt-10">
        <button
          onClick={handleSaveProductionSheets}
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
        >
          Save Settings
        </button>
      </div>

      {/* Modals */}
      {/* Add Category Modal */}
      <Dialog open={isCategoryModalOpen} onClose={() => setIsCategoryModalOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium text-gray-900">Add Category</Dialog.Title>
            <div className="mt-4">
              <label htmlFor="newCategoryName" className="block text-sm font-medium text-gray-700">
                Category Name
              </label>
              <input
                type="text"
                id="newCategoryName"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter category name"
              />
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setIsCategoryModalOpen(false)}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={addCategory}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
              >
                Add
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

            {/* Add Material Modal */}
            <Dialog open={isMaterialTypeModalOpen} onClose={() => setIsMaterialTypeModalOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium text-gray-900">Add Material</Dialog.Title>
            <div className="mt-4">
              <label htmlFor="newCategoryName" className="block text-sm font-medium text-gray-700">
                Material Name
              </label>
              <input
                type="text"
                id="newCategoryName"
                value={newMaterialTypeName}
                onChange={(e) => setNewMaterialTypeName(e.target.value)}
                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter category name"
              />
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setIsMaterialTypeModalOpen(false)}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={addMaterialType}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
              >
                Add
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Add Key Modal */}
      <Dialog open={isKeyModalOpen} onClose={() => setIsKeyModalOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium text-gray-900">Add Key</Dialog.Title>
            <div className="mt-4">
              <label htmlFor="newKeyName" className="block text-sm font-medium text-gray-700">
                Key Name
              </label>
              <input
                type="text"
                id="newKeyName"
                value={newKeyName}
                onChange={(e) => setNewKeyName(e.target.value)}
                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter key name"
              />
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setIsKeyModalOpen(false)}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={addKey}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
              >
                Add
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Edit Production Sheet Modal */}
      <Dialog open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} className="relative z-50">
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            <div className="relative bg-white rounded max-w-2xl mx-auto w-full p-6 overflow-y-auto max-h-screen">
              <Dialog.Title className="text-lg font-medium text-gray-900">Edit Production Sheet</Dialog.Title>
              {/* Modal content goes here */}
              {/* [Same as the create production sheet form, but using edit state variables] */}
              {/* ... */}
              <div className="mt-4">
                <label htmlFor="editTitle" className="block text-sm font-medium text-gray-700">
                  Production Sheet Title
                </label>
                <input
                  type="text"
                  id="editTitle"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  placeholder="Enter title"
                />
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-medium text-gray-900">Options</h3>
                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {productionSheetOptions.map((option, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={editSelectedOptions.includes(option)}
                        id={`edit-option-${index}`}
                        onChange={() => {
                          if (editSelectedOptions.includes(option)) {
                            setEditSelectedOptions(editSelectedOptions.filter((item) => item !== option));
                            setEditSelectedOptionsSettings(
                              editSelectedOptionsSettings.filter((item) => item.option !== option)
                            );
                          } else {
                            setEditSelectedOptions([...editSelectedOptions, option]);
                            setEditSelectedOptionsSettings([
                              ...editSelectedOptionsSettings,
                              {
                                option: option,
                                settings: {
                                  fontSize: 12,
                                  bold: false,
                                  showKeys: true,
                                },
                              },
                            ]);
                          }
                        }}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                      <label htmlFor={`edit-option-${index}`} className="ml-2 block text-sm text-gray-700">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              {/* Selected Options Settings */}
              {editSelectedOptions.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-gray-900">Configure Options</h3>
                  <div className="mt-4 space-y-4">
                    {editSelectedOptions.map((option, index) => {
                      const settings =
                        editSelectedOptionsSettings.find((item) => item.option === option)?.settings || {};
                      return (
                        <div key={index} className="bg-gray-50 border rounded-md p-4">
                          <h4 className="text-md font-medium text-gray-800">{option}</h4>
                          <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                            {/* Font Size */}
                            <div>
                              <label
                                htmlFor={`edit-fontSize-${index}`}
                                className="block text-sm font-medium text-gray-700"
                              >
                                Font Size
                              </label>
                              <input
                                type="number"
                                id={`edit-fontSize-${index}`}
                                value={settings.fontSize}
                                onChange={(e) => {
                                  const updatedSettings = { ...settings, fontSize: e.target.value };
                                  setEditSelectedOptionsSettings(
                                    editSelectedOptionsSettings.map((item) =>
                                      item.option === option ? { option, settings: updatedSettings } : item
                                    )
                                  );
                                }}
                                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                            {/* Bold */}
                            <div className="flex items-center mt-4 sm:mt-0">
                              <input
                                type="checkbox"
                                id={`edit-bold-${index}`}
                                checked={settings.bold}
                                onChange={(e) => {
                                  const updatedSettings = { ...settings, bold: e.target.checked };
                                  setEditSelectedOptionsSettings(
                                    editSelectedOptionsSettings.map((item) =>
                                      item.option === option ? { option, settings: updatedSettings } : item
                                    )
                                  );
                                }}
                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                              <label htmlFor={`edit-bold-${index}`} className="ml-2 block text-sm text-gray-700">
                                Bold
                              </label>
                            </div>
                            {/* Show Keys */}
                            <div className="flex items-center mt-4 sm:mt-0">
                              <input
                                type="checkbox"
                                id={`edit-showKeys-${index}`}
                                checked={settings.showKeys}
                                onChange={(e) => {
                                  const updatedSettings = { ...settings, showKeys: e.target.checked };
                                  setEditSelectedOptionsSettings(
                                    editSelectedOptionsSettings.map((item) =>
                                      item.option === option ? { option, settings: updatedSettings } : item
                                    )
                                  );
                                }}
                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                              <label htmlFor={`edit-showKeys-${index}`} className="ml-2 block text-sm text-gray-700">
                                Show Keys
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="mt-6 flex justify-end space-x-4">
                <button
                  onClick={() => setIsEditModalOpen(false)}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={saveEditedSheet}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Settings;