import React, { useState } from 'react';
import { EnvelopeIcon, PhoneIcon, MapPinIcon } from '@heroicons/react/24/solid';
import Footer from '../components/Footer';
import { contactUs } from '../utils/globalFunctions';
import { toast } from 'react-toastify';
import { sendEmail } from '../utils/firebase';

function ContactUsPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading,setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // TODO: Handle form submission, e.g., send data to your server or API
  //   console.log('Form Data:', formData);
  //   setSubmitted(true);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await contactUs(formData);
      if (result.success) {

        // Send email to the user
        await sendEmail({
          to: formData.email,
          subject: 'Thank you for contacting Supply Circle',
          text: `Hello ${formData.name},\n\nThank you for reaching out to us. We have received your message and will get back to you shortly.\n\nBest regards,\nSupply Circle Team`,
          html: `<p>Hello ${formData.name},</p><p>Thank you for reaching out to us. We have received your message and will get back to you shortly.</p><p>Best regards,<br>Supply Circle Team</p>`,
          sender: "Supply Circle",
          attachments: []
        }).then(() => {
          setSubmitted(true);
          toast.success('Your message has been sent successfully!');
          setLoading(false)
        })
      } else {
        // Handle the error accordingly
        console.error(result.error);
        toast.error('Failed to send your message. Please try again later.');
        setLoading(false)
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An unexpected error occurred.');
      setLoading(false)
    }
  };

  return (
    <div className="bg-white text-gray-900">
      {/* Header */}
      <header className="fixed w-full bg-white shadow z-50">
        <nav className="container mx-auto px-6 py-4 flex items-center justify-between">
          <div className="flex items-center">
            {/* Logo */}
            <img
              src="/logoBlack.png"
              alt="Supply Circle Logo"
              className="h-10 w-auto mr-2"
              onClick={
                ()=>{
                  window.location.href = "/"
                }
              }
            />

          </div>
          <div className="hidden md:flex items-center space-x-6">
            <a href="/" className="hover:text-gray-500">
              Home
            </a>
            <a href="/about" className="hover:text-gray-500">
              About
            </a>
            <a href="/contact" className="hover:text-gray-500">
              Contact
            </a>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="pt-24 bg-gray-50">
        <div className="container mx-auto px-6 py-20">
          <h1 className="text-5xl font-bold leading-tight text-center mb-12">
            Get in Touch with <span className="text-black">Supply Circle</span>
          </h1>
          <div className="flex flex-col lg:flex-row justify-between">
            {/* Contact Form */}
            <div className="w-full lg:w-1/2">
              {submitted ? (
                <div className="bg-green-100 text-green-800 p-4 rounded">
                  Thank you for reaching out! We'll get back to you shortly.
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto">
                  <div className="mb-6">
                    <label className="block text-gray-700 mb-2" htmlFor="name">
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-black"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-700 mb-2" htmlFor="email">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-black"
                      placeholder="you@example.com"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-700 mb-2" htmlFor="subject">
                      Subject
                    </label>
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-black"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-700 mb-2" htmlFor="message">
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="6"
                      className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-black"
                      placeholder="Your message..."
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  {!loading && <button
                    type="submit"
                    className="px-6 py-3 bg-black text-white text-lg rounded hover:bg-gray-800 transition duration-300"
                  >
                    Send Message
                  </button>}

                  {
                    loading &&
                    <button
                    className="px-6 py-3 bg-black text-white text-lg rounded hover:bg-gray-800 transition duration-300"
                  >
                    Sending...
                  </button>
                  }
                </form>
              )}
            </div>
            {/* Contact Information */}
            <div className="w-full lg:w-1/2 mt-12 lg:mt-0 lg:pl-12">
              <h2 className="text-3xl font-bold mb-6">Contact Information</h2>
              <p className="text-lg text-gray-700 mb-6">
                We'd love to hear from you! Whether you have a question about features, trials, pricing, or anything else, our team is ready to answer all your questions.
              </p>
              <div className="flex items-start mb-6">
                <MapPinIcon className="w-6 h-6 text-black mr-4" />
                <div>
                  <p className="text-lg font-semibold">Our Office</p>
                  <p className="text-gray-700">1234 Supply Circle Lane<br />Waterloo, ON, Canada</p>
                </div>
              </div>
              <div className="flex items-start mb-6">
                <PhoneIcon className="w-6 h-6 text-black mr-4" />
                <div>
                  <p className="text-lg font-semibold">Call Us</p>
                  <p className="text-gray-700">+1 (873) 355-0147</p>
                </div>
              </div>
              <div className="flex items-start">
                <EnvelopeIcon className="w-6 h-6 text-black mr-4" />
                <div>
                  <p className="text-lg font-semibold">Email Us</p>
                  <p className="text-gray-700">info@supplycircle.io</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        <Footer />
    </div>
  );
}

export default ContactUsPage;