import { useState, useEffect } from "react";
import ExtrasModal from "./ExtrasModal";
import axios from "axios";
import autoTable from "jspdf-autotable";

import { globalAlert, createInvoice } from "../utils/globalFunctions";
import { addressURL } from "../utils/globalFunctions";
import OrderNotes from "./OrderNotes";
import { globalSuccess } from "../utils/globalFunctions";
import { jsPDF } from "jspdf";
import { Button, Modal } from "flowbite-react";
import { Datepicker } from "flowbite-react";
import LabelPrinter from "./LabelPrinter";
import Orders from "./Orders";
export default function OrderListAdmin(props) {
  const [open, setOpen] = useState(false);
  const [newExtrasAdded, setNewExtrasAdded] = useState(false);
  const [total, setTotal] = useState(props.totalPrice);
  const [extras, setExtras] = useState(props.extras);
  const [orders, setOrders] = useState([]);
  const [orderNotes, setOrderNotes] = useState(null);
  const rules = props.rules;
  const [openModal, setOpenModal] = useState(false);
  const [widthChange, setWidthChange] = useState(null);
  const [dueDate, setDueDate] = useState(new Date().toLocaleDateString());
  const [tax, setTax] = useState(false); // boolean
  const [discount, setDiscount] = useState(0); // percentage
  const [additions, setAdditions] = useState([]);
  const [additionalName, setAdditionalName] = useState("");
  const [additionalQuantity, setAdditionalQuantity] = useState(0);
  const [additionalPrice, setAdditionalPrice] = useState(0);
  const [additionalDescription, setAdditionalDescription] = useState("");
  const [allIssues,setAllIssues] = useState([])

  const [heightChange, setHeightChange] = useState(null);

  useEffect(() => {
    setOrders(props.orders);
  }, [props.orders]);

  function generateInvoice(action) {
    const doc = new jsPDF();
    let localTotal = total - (discount * total) / 100;
    let additionsTotal = 0;

    //loop through additions to add price to localTotal
    additions.forEach((addition) => {
      localTotal += addition.price * addition.quantity;
      additionsTotal += addition.price * addition.quantity;
    });

    if (tax) {
      localTotal += (total * 13) / 100;
    }

    // Logo and Business Name (for demonstration, just using text)
    doc.setFontSize(20);
    doc.text("Cachet Blinds", 105, 20, { align: "center" });

    // INVOICE title
    doc.setFontSize(24);
    doc.setTextColor(255, 100, 0); // Orange
    doc.text("INVOICE", 105, 40, { align: "center" });

    // Invoice Details
    doc.setFontSize(12);
    doc.setTextColor(0); // Black
    doc.text("Invoice: #" + orders[0].customerID.toString(), 15, 60);
    doc.text("Date: " + new Date().toLocaleDateString(), 15, 70);
    doc.text(`Due date: ${dueDate}`, 145, 60);
    doc.text(`Total: $${localTotal.toFixed(2)}`, 145, 70);

    doc.line(
      15, // x-axis start
      80, // y-axis start
      195, // x-axis end
      80 // y-axis end
    );
    doc.setFontSize(10);
    doc.setTextColor(0);
    // Billing Details
    doc.text("Bill from:", 15, 90);
    doc.text("Cachet Blinds", 15, 100);
    doc.text("30 Manitou Dr., Kitchener", 15, 110);
    doc.text("info@cachetblinds.ca", 15, 120);

    doc.text("Bill to:", 145, 90);
    doc.text(orders[0].storename, 145, 100);

    doc.setFontSize(12);
    doc.setTextColor(0);
    // Item List Header
    doc.text("Item", 15, 140);
    doc.text("Size", 90, 140);
    doc.text("Quantity", 130, 140);
    doc.text("Total", 180, 140);

    doc.line(
      15, // x-axis start
      145, // y-axis start
      195, // x-axis end
      145 // y-axis end
    );

    // Sample items (this can be in a loop if dynamic)
    let yPos = 150;

    // Sample items (assuming they are in an array, this can be looped through)

    console.log(orders);
    const addItemToInvoice = (itemName, description, rate, quantity, total) => {
      const maxLineWidth = 100; // Max width for item description
      const lineHeight = 5; // Line height for text

      // Wrap text if it exceeds max width
      const wrappedText = doc.splitTextToSize(description, maxLineWidth);
      const numOfLines = wrappedText.length;

      // Check if adding the item will exceed page length
      if (yPos + numOfLines * lineHeight > 280) {
        // Assuming 280 as near bottom margin
        doc.addPage();
        yPos = 20; // Reset y position for new page
      }

      doc.setFontSize(10);
      doc.setTextColor(0);
      if (description.length < 4) doc.text(itemName, 15, yPos + 4);
      else doc.text(itemName, 15, yPos);
      doc.setFontSize(8);
      doc.setTextColor(
        100, // R
        100, // G
        100 // B
      );
      doc.text(wrappedText, 15, yPos + lineHeight); // This automatically handles multi-line
      doc.setFontSize(10);
      doc.setTextColor(0);
      doc.text(rate, 90, yPos + 4);
      doc.text(quantity, 135, yPos + 4);
      doc.text(total, 180, yPos + 4);

      doc.line(
        15,
        yPos + numOfLines * lineHeight + 5,
        195,
        yPos + numOfLines * lineHeight + 5,
        "S"
      );
      // Increase yPos by the height of the content added
      yPos += numOfLines * lineHeight + 10; // +10 for spacing between items
    };

    function descriptionBuilder(order) {
      let description = "";
      if (order.motor) {
        description += " Motorized | ";
      }
      if (order.spring) {
        description += " Spring | ";
      }
      if (order.wand) {
        description += " M.Wand | ";
      }
      if (order.chainLocation) {
        description += order.chainLocation + " chain | ";
      }
      if (order.barType) {
        description += order.barType + " bar | ";
      }
      if (order.covering) {
        description += order.covering + " headrail | ";
      }
      return description;
    }
    orders &&
      orders?.map((order) => {
        addItemToInvoice(
          order.fabric_name,
          descriptionBuilder(order),
          order.width + "cm" + " x " + order.height + "cm",
          order.quantity.toString(),
          "$" + order.price.toString()
        );
      });

    // add additions to items
    additions.forEach((addition) => {
      addItemToInvoice(
        addition.name,
        addition.description,
        "",
        addition.quantity.toString(),
        "$" + (addition.price * addition.quantity).toString()
      );
    });

    // items.forEach(item => {
    //     addItemToInvoice(item.name, item.description, item.size, item.qty.toString(), "$"+ item.total.toString());
    // });

    if (yPos > 250) {
      // Adjust as per your requirement
      doc.addPage();
      yPos = 20;
    }

    // Summary section
    doc.text("Subtotal:", 120, yPos + 10);
    doc.text("$" + total.toString(), 160, yPos + 10);

    if (additions.length > 0) {
      yPos = yPos + 10;
      doc.text("Additionals", 120, yPos + 10);
      doc.text(`$${additionsTotal}`, 160, yPos + 10);
    }

    doc.text("Discount", 120, yPos + 20);
    doc.text("-$" + ((discount * total) / 100).toFixed(2), 160, yPos + 20);

    doc.text("Tax", 120, yPos + 30);
    if (tax) {
      doc.text("$" + ((total * 13) / 100).toFixed(2), 160, yPos + 30);
    } else {
      doc.text("$0.00", 160, yPos + 30);
    }

    doc.line(
      120, // x-axis start
      yPos + 35, // y-axis start
      170, // x-axis end
      yPos + 35 // y-axis end
    );

    doc.text("Total", 120, yPos + 40);
    doc.text("$" + localTotal.toFixed(2), 160, yPos + 40);
    doc.setFontSize(12);
    doc.setTextColor(0);

    if (yPos > 250) {
      // Adjust as per your requirement
      doc.addPage();
      yPos = 20;
    }
    // // Payment Instructions
    doc.text("Terms & Conditions", 15, yPos + 50);
    doc.setFontSize(10);
    doc.setTextColor(
      100, // R
      100, // G
      100 // B
    );
    doc.text(
      "Please pay the amount by due date, contact us if you have any questions about the invoice",
      15,
      yPos + 55
    );

    // Save the PDF
    if (action === "download") doc.save("dealer-invoice.pdf");
    else return doc.output("datauristring");
  }





 

  const handleUpdateExtra = (extraID) => {
    axios
      .post(
        addressURL + "/api/updateExtrasStatus",
        {
          extraID,
          status: "Completed",
          index: props.index,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          extras.map((extra) => {
            if (extra.orderExtrasID === extraID) {
              extra.status = "Completed";
            }
          });
          setExtras([...extras]);
        } else {
          globalAlert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //     if (newExtrasAdded || props.isSearch) {
  //         getOrderTotal();
  //         axios.get(addressURL + `/api/getExtras?customerID=${props.customerID}`, {
  //             headers: {
  //                 "Content-Type": "application/json",
  //                 Accept: "application/json",
  //             },
  //             withCredentials: true,
  //         }).then((res) => {
  //             if (res.data.success) {

  //                 let extras = res.data.data;
  //                 setExtras(extras);
  //                 setNewExtrasAdded(false);
  //             }
  //         }
  //         ).catch((err) => {
  //             console.log(err);
  //         }
  //         );
  //     }
  // }, [newExtrasAdded, props.isSearch]);

  // useEffect(() => {
  //     if (props.isSearch) {
  //         getOrderTotal();
  //     }
  // }, [props.isSearch]);

  const shortenBlackOut = (input) => {
    // replace Blackout if exist in the string
    let output = input.replace("Blackout", "B.O");
    if (output === input) {
      output = input.replace("BLACKOUT", "B.O");
    }
    return output;
  };

  function convertTurkishCharacters(input) {
    const turkishCharacters = {
      ç: "c",
      ğ: "g",
      ı: "i",
      i: "i",
      ö: "o",
      ş: "s",
      ü: "u",
      Ç: "C",
      Ğ: "G",
      İ: "I",
      I: "I",
      Ö: "O",
      Ş: "S",
      Ü: "U",
    };

    return input.replace(
      /[çğıiöşüÇĞİIÖŞÜ]/g,
      (char) => turkishCharacters[char] || char
    );
  }

  const handleGeneratePdf = () => {
    //alert if order has no rule
    let hasNoRule = false;
    orders.forEach((order) => {
      if (!order.matchingRule) {
        hasNoRule = true;
      }
    });
    if (hasNoRule) {
      globalAlert("Please add rules for each order");
      return;
    }
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.text(`${orders[0].storename}(${orders[0].customerName}) `, 10, 10);
    // autoTable(doc, {
    //   tableWidth: 45,
    //   theme: "grid",

    //   tableStyle: {
    //     lineWidth: 0.1,
    //     lineColor: [0, 0, 0],
    //   },
    //   headStyles: {
    //     fillColor: [174, 214, 214],
    //     textColor: "black",
    //   },
    //   styles: {
    //     fontSize: 10,
    //     cellPadding: 1,
    //     textColor: "black",
    //     fontWeight: "bold",
    //     lineWidth: 0.3,
    //     lineColor: [0, 0, 0],
    //     overflow: "linebreak",
    //   },
    //   head: [["Symbol", "Meaning"]],
    //   body: [
    //     ["W", "Width"],
    //     ["H", "Height"],
    //     ["Qnt", "Quantity"],
    //     ["C.Cut", "Cassette Cut"],
    //     ["T.Cut", "Tube Cut"],
    //     ["B.Bar", "Bottom Bar"],
    //     ["F.Width", "Fabric Width"],
    //   ],
    // });

    autoTable(doc, {
      theme: "grid",
      alternateRowStyles: {
        fillColor: [242, 247, 255],
      },
      headStyles: {
        fillColor: [174, 214, 214],
        textColor: "black",
      },
      styles: {
        fontSize: 10,
        cellPadding: 1,
        textColor: "black",
        fontWeight: "bold",
        lineWidth: 0.3,
        lineColor: [0, 0, 0],
        overflow: "linebreak",
      },

      head: [
        [
          "No",
          "Fabric",
          "W",
          "H",
          // "C.Cut",
          // "T.Cut",
          // "B.Bar",
          // "B.Tube",
          // "F.Width",
          // "F.Height",
          "Chain",
          "R.Name",
          "B.Type",
          "Cassette",
          "Motor",
          "Spring",
          "M.Wand",
          "Qnt",
        ],
      ],
      body: orders.map((order, index) => {
        return [
          `${index + 1}`,
          `${shortenBlackOut(order.fabric_name)}`,
          `${order.width}`,
          `${order.height}`,
          // `${(
          //   order.width - JSON.parse(order.matchingRule.rule).cassetteCut
          // ).toFixed(2)}`,
          // `${(
          //   order.width - JSON.parse(order.matchingRule.rule).tubeCut
          // ).toFixed(2)}`,
          // `${(
          //   order.width - JSON.parse(order.matchingRule.rule).bottomBar
          // ).toFixed(2)}`,
          // `${(
          //   order.width - JSON.parse(order.matchingRule.rule).bottomTube
          // ).toFixed(2)}`,
          // `${(
          //   order.width - JSON.parse(order.matchingRule.rule).fabricWidth
          // ).toFixed(2)}`,
          // `${order.height * 2 + 30}`,
          `${order.chainLocation}`,
          `${order.room_name}`,
          `${order.barType ? order.barType : "none"}`,
          `${order.covering ? order.covering : "none"}`,
          `${order.motor ? "Yes" : "No"}`,
          `${order.spring ? "Yes" : "No"}`,
          `${order.wand ? "Yes" : "No"}`,
          `${order.quantity}`,
        ];
      }),
    });

    if (extras?.length > 0) {
      autoTable(doc, {
        alternateRowStyles: {
          fillColor: [137, 173, 173],
        },
        tableStyle: {
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
        },
        headStyles: {
          fillColor: [174, 214, 214],
          textColor: "black",
        },
        bodyStyles: {
          fontWeight: "bold",
          fontSize: 11,
          cellPadding: 1,
        },
        head: [["Remote Control", "Remote Control Charger"]],
        body: extras.map((extra) => {
          return [
            `${extra.remoteControl} units`,
            `${extra.remoteControlCharger} units`,
          ];
        }),
      });
    }

    if (orderNotes?.length > 0) {
      autoTable(doc, {
        columnStyles: {
          0: { columnWidth: 30 },
          2: {
            columnWidth: 40,
          },
        },

        headStyles: {
          fillColor: [174, 214, 214],
          textColor: "black",
        },
        styles: {
          fontSize: 10,
          cellPadding: 1,
          textColor: "black",
          fontWeight: "bold",
          lineWidth: 0.3,
          lineColor: [0, 0, 0],
        },
        head: [["Sender", "Note", "Created At"]],
        body: orderNotes.map((note) => {
          return [
            `${note.sender}`,
            `${convertTurkishCharacters(note.note)}`,
            `${new Date(note.createdAt).toLocaleDateString()}`,
          ];
        }),
      });
    }

    doc.save("dealer-order.pdf");
  };

  const handleUpdateOrderRule = (orderID, rule) => {
    //find the rule from rules by rulename
    let matchingRule = rules.find((r) => r.ruleName === rule);
    //find the order in orders and update the rule
    let newOrders = orders.map((order) => {
      if (order.orderID === orderID) {
        return {
          ...order,
          rule: matchingRule,
        };
      }
      return order;
    });
    setOrders(newOrders);
  };

  const handleDeleteOrder = (orderID) => {
    axios
      .post(
        addressURL + "/api/deleteOrder",
        {
          orderID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res.data.success);
        if (res.data.success) {
          let newOrders = orders.filter((order) => order.orderID !== orderID);
          setOrders(newOrders);
        } else {
          globalAlert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function getOrderWithGroupByCustomerID() {
    // let orderData = orders;
    try {
      const res = await axios.get(
        addressURL +
          `/api/getOrderByCustomerID?customerID=${orders[0].customerID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      );
      if (res.data.success) {
        console.log(res.data);
        if (res.data.data.length === 0) {
          //   setCustomerName(orderData[0].customerName);
          setOrders([]);
          setTotal(0);
          //   setDataUpdated(false);
          return;
        } else {
          if(res.data.data[0].orders[0].status === "Completed"){
            setOrders([]);
          }else{
            setOrders(res.data.data[0].orders);
            setTotal(res.data.data[0].totalPrice);
          }

          //   setDataUpdated(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleIssueInvoice = () => {
    const invoiceURIstring = generateInvoice("send");

    let invoiceID = props.orders[0].customerID;
    let storename = props.orders[0].storename;
    let storeEmail = props.orders[0].storeEmail;
    let customerName = props.orders[0].customerName;
    let invoiceURI = invoiceURIstring;
    let subtotal = total; //subtotal (before tax and discount)
    let tot = parseFloat(subtotal); //total (after)

    if (discount) {
      tot -= (tot * discount) / 100;
    }
    if (tax) {
      tot += (tot * 13) / 100;
    }

    //loop through the additions
    additions.forEach((addition) => {
      tot += addition.price * addition.quantity;
    });

    let data = {
      invoiceID,
      storename,
      storeEmail,
      customerName,
      total,
      dueDate,
      tax,
      discount,
      invoiceURI,
      orders,
      additions,
      subtotal,
      tot: Number(tot.toFixed(2)),
      isPaid: false,
      paidAmount: 0,
      createdAt: new Date().toLocaleDateString(),
    };

    createInvoice(data, invoiceID).then((res) => {
      if (res) {
        axios
          .post(
            addressURL + `/api/updateOrderStatus`,
            {
              customerID: props.orders[0].customerID,
              status: "Completed",
            },
            {
              // NOT MANUFACTURERS
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              withCredentials: true,
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.success) {
              globalSuccess("Invoice sent successfully");
              setOpenModal(false);
            } else {
              globalAlert("Something went wrong");
              setOpenModal(false);
            }
          });
      } else {
        globalAlert("Something went wrong");
      }
    });
  };
  console.log(orders);








  return (
    orders.length > 0 && (
      <>
        <div className="mt-8 sm:px-6 lg:px-8">
          <div className="flex ">
            <div>
              <h2 className="text-base font-medium text-gray-900">
                Order #{orders[0].customerID}
              </h2>
              <h2 className="text-base font-medium text-gray-900">
                {orders[0].storename}/{orders[0].customerName}
              </h2>
              <h2 className="text-base font-medium text-gray-900">
                <p>Last Update</p>
                <TimeComponent d={orders[orders.length - 1].createdAt} />

                <p>Customer Created</p>
                <TimeComponent d={orders[0].customerCreatedAt} />
              </h2>
              <LabelPrinter data={orders} />
            </div>
      
          </div>
          
          <div className="mt-2" />
          <div className="flex justify-start">
    
    
     
              {/* <Button className="mr-1 bg-blue-600" onClick={() => setOpenModal(true)}>Issue Invoice</Button> */}
              <Button  className="mr-1 bg-blue-600"   onClick={() => handleGeneratePdf()}>Generate PDF</Button>
       
     
          </div>

   
          <Orders
            orders={orders}
            products={props.products}
            getOrderWithGroupByCustomerID={getOrderWithGroupByCustomerID}
            handleDeleteOrder={handleDeleteOrder}
            userRole={"Admin"}

          />
          {extras?.length > 0 && (
            <div className="mt-4 flex flex-col">
              <p className="text-base font-medium text-gray-900 mb-2">
                {" "}
                Extras{" "}
              </p>
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block  py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className=" divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Remote Control
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Charger
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Hub
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Price
                          </th>
                        </tr>
                      </thead>
                      {extras?.length > 0 &&
                               extras?.map((extra, index) => {
                                return (
                                  <>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                      <tr className="divide-x divide-gray-200">
                                        <td className="px-4 py-4 whitespace-nowrap">
                                          <div className="flex items-center">
                                            <div>
                                              <div className="text-sm font-medium text-gray-900">
                                                {extra.remoteControl} unit ({extra.motor_brand})
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="px-4 py-4 whitespace-nowrap">
                                          <div className="text-sm text-gray-900">
                                            {extra.remoteControlCharger} unit
                                          </div>
                                        </td>
                                        <td className="px-4 py-4 whitespace-nowrap">
                                          <div className="text-sm text-gray-900">
                                            {extra.remoteControlHub} unit
                                          </div>
                                        </td>
                                        <td className="px-4 py-4 whitespace-nowrap">
                                          <div className="text-sm text-gray-900">
                                            $
                                            {extra.motor_brand === "novo" ? extra.remoteControl * 10 :
                                              extra.remoteControl * 30 +
                                              extra.remoteControlCharger * 25 +
                                              extra.remoteControlHub * 200
                                              }
                                          </div>
                                        </td>
                        
                                      </tr>
                                    </tbody>
                                  </>
                                );
                              })
                 }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <OrderNotes
            orderID={orders[0].customerID}
            setOrderNotes={setOrderNotes}
          />
          <div className="mt-2">
            <h2 className="text-base font-medium text-gray-900">
              Order Total: ${total}
            </h2>
          </div>
          <hr class="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />

          <ExtrasModal
            open={open}
            customerID={orders[0].customerID}
            setOpen={setOpen}
            setNewExtrasAdded={setNewExtrasAdded}
          />
                 <Modal show={openModal} onClose={() => setOpenModal(false)}>
              <Modal.Header>Invoice Generator</Modal.Header>
              <Modal.Body>
                <div className="space-y-2">
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Please select a due date for this invoice
                  </p>
                  <Datepicker
                    value={dueDate}
                    minDate={new Date()}
                    onSelectedDateChanged={(date) => {
                      setDueDate(date.toLocaleDateString());
                    }}
                  />
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Do you want to add tax?
                  </p>
                  <div className="flex items-center space-x-4">
                    <input
                      type="radio"
                      className="form-radio"
                      name="tax"
                      value="yes"
                      onClick={() => setTax(true)}
                    />
                    <label className="inline-flex items-center text-sm font-medium text-gray-700 dark:text-gray-200">
                      Yes
                    </label>
                    <input
                      type="radio"
                      className="form-radio"
                      name="tax"
                      value="no"
                      onClick={() => setTax(false)}
                    />
                    <label className="inline-flex items-center text-sm font-medium text-gray-700 dark:text-gray-200">
                      No
                    </label>
                  </div>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Add discount percentage (optional)
                  </p>
                  <div className="flex items-center space-x-4">
                    <input
                      type="number"
                      className="
                        w-full
                        px-4
                        py-2
                        text-base
                        placeholder-gray-500
                        border border-gray-300
                        rounded-md
                        focus:outline-none focus:ring-2 focus:ring-indigo-200
                        dark:bg-gray-700 dark:text-gray-300 dark:placeholder-gray-300 dark:border-gray-600
                    "
                      placeholder="Discount"
                      onChange={(e) => setDiscount(Number(e.target.value))}
                    />
                  </div>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Additionals
                  </p>
                  <div className="flex items-center space-x-4">
                    <input
                      type="text"
                      value={additionalName}
                      className="
                        w-full
                        px-4
                        py-2
                        text-base
                        placeholder-gray-500
                        border border-gray-300
                        rounded-md
                        focus:outline-none focus:ring-2 focus:ring-indigo-200
                        dark:bg-gray-700 dark:text-gray-300 dark:placeholder-gray-300 dark:border-gray-600
                    "
                      placeholder="Addition name"
                      onChange={(e) => setAdditionalName(e.target.value)}
                    />
                  </div>

                  <div className="flex items-center space-x-4">
                    <input
                      type="text"
                      value={additionalDescription}
                      className="
                        w-full
                        px-4
                        py-2
                        text-base
                        placeholder-gray-500
                        border border-gray-300
                        rounded-md
                        focus:outline-none focus:ring-2 focus:ring-indigo-200
                        dark:bg-gray-700 dark:text-gray-300 dark:placeholder-gray-300 dark:border-gray-600
                    "
                      placeholder="Addition description"
                      onChange={(e) => setAdditionalDescription(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center space-x-4">
                    <input
                      type="number"
                      className="
                        w-full
                        px-4
                        py-2
                        text-base
                        placeholder-gray-500
                        border border-gray-300
                        rounded-md
                        focus:outline-none focus:ring-2 focus:ring-indigo-200
                        dark:bg-gray-700 dark:text-gray-300 dark:placeholder-gray-300 dark:border-gray-600
                    "
                      placeholder="Quantity"
                      onChange={(e) =>
                        setAdditionalQuantity(Number(e.target.value))
                      }
                    />
                  </div>
                  <div className="flex items-center space-x-4">
                    <input
                      type="number"
                      className="
                        w-full
                        px-4
                        py-2
                        text-base
                        placeholder-gray-500
                        border border-gray-300
                        rounded-md
                        focus:outline-none focus:ring-2 focus:ring-indigo-200
                        dark:bg-gray-700 dark:text-gray-300 dark:placeholder-gray-300 dark:border-gray-600
                    "
                      placeholder="Price"
                      onChange={(e) =>
                        setAdditionalPrice(Number(e.target.value))
                      }
                    />
                  </div>
                  <div className="flex items-center space-x-4">
                    <button
                      className="
                        flex
                        items-center
                        justify-center
                        px-4
                        py-2
                        text-base
                        font-medium
                        text-white
                        bg-indigo-600
                        rounded-md
                        hover:bg-indigo-700
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                    "
                      onClick={() => {
                        setAdditions([
                          ...additions,
                          {
                            name: additionalName,
                            description: additionalDescription,
                            quantity: additionalQuantity,
                            price: additionalPrice,
                          },
                        ]);

                        setAdditionalName("");
                        setAdditionalDescription("");
                        setAdditionalPrice(0);
                        setAdditionalQuantity(0);
                      }}
                    >
                      Add
                    </button>
                  </div>
                  <div>
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Description
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm  font-semibold text-gray-500"
                          ></th>
                        </tr>
                      </thead>
                      {additions.length > 0 &&
                        additions.map((addition, index) => {
                          return (
                            <>
                              <tbody className="bg-white divide-y divide-gray-300">
                                <tr className="divide-x divide-gray-200">
                                  <td
                                    scope="col"
                                    className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    {addition.name}
                                  </td>
                                  <td
                                    scope="col"
                                    className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    {addition.description}
                                  </td>
                                  <td
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    {addition.price * addition.quantity}
                                  </td>
                                  <td
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    <button
                                      className="px-4 py-3.5 text-left text-xs  font-semibold text-red-500"
                                      onClick={() => {
                                        let newAdditions = additions.filter(
                                          (add, i) => i !== index
                                        );
                                        setAdditions(newAdditions);
                                      }}
                                    >
                                      delete
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          );
                        })}
                    </table>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => handleIssueInvoice(orders)}>
                  Issue Invoice
                </Button>
                <Button
                  color="gray"
                  onClick={() => generateInvoice("download")}
                >
                  Download
                </Button>
              </Modal.Footer>
            </Modal>
        </div>
      </>
    )
  );
}

function TimeComponent({ d }) {
  const date = new Date(d);

  const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
  const timeOptions = { hour: "2-digit", minute: "2-digit" };

  const dateString = date.toLocaleDateString([], dateOptions);
  const timeString = date.toLocaleTimeString([], timeOptions);

  const dateTimeString = `${dateString} ${timeString}`;

  return <div>{dateTimeString}</div>;
}
