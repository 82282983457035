import React from 'react'
import axios from 'axios'
import { addressURL } from '../utils/globalFunctions'
export default function OrderNotes(props) {
    const [orderNotes,setOrderNotes] = React.useState([])
    const [orderNote,setOrderNote] = React.useState('')
    const [orderNotesLoading,setOrderNotesLoading] = React.useState(true)

    React.useEffect(() => {

        getOrderNotes()
    },[])

    async function getOrderNotes() { //TODO: apply this pattern to every axios call and dont repeat yourself!!
        if(props.orderId?.lenghth === 0) return
        setOrderNotesLoading(true)
        try {
            const res = await axios.get(addressURL + `/api/getOrderNotes?customerID=${props.orderID}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                withCredentials: true,
            });
            setOrderNotes(res.data.data)
            props?.setOrderNotes(res.data.data)
        } catch (err) {
            console.log(err);
        } finally {
            setOrderNotesLoading(false)
        }
    }

    async function createOrderNote(){
        if(orderNote.length === 0) return
        try {
            const res = await axios.post(addressURL + `/api/createOrderNote`, {
                customerID: props.orderID,
                note: orderNote
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                withCredentials: true,
            });
            getOrderNotes()
            setOrderNote('')
        } catch (err) {
            console.log(err);
        } finally {
        }

    }



  return (
    <div className="mt-4 mb-4 sm:w-1/2 w-full m-auto min-h-[12rem]  justify-center border rounded-lg">
        <p  
        className="text-base font-medium text-gray-600 mb-2 text-left w-full ml-2 p-2 font-extrabold">
            Order Notes
        </p>  
        <hr 
        className='
            border-gray-300
            my-2
            w-full
        ' />

        <div
            style={
                {
                    maxHeight: "12rem",
                    overflowY: "scroll",
                    minHeight:"6rem"
                    }
                    }
            >
            {orderNotesLoading ? <p></p> : orderNotes.length > 0 ? orderNotes.map((note) => {
                return ( 
                    <div className="flex-col justify-between items-center border-b p-2 w-full">
                        <p className="text-sm font-medium text-gray-900 font-semibold">{note.sender}</p>
                        <p className="text-sm font-medium text-gray-900 ml-2">{note.note}</p>
                        <p className="text-xs font-medium text-gray-900 text-end">{new Date(note.createdAt).toLocaleDateString()}</p>
                    </div>
                )
            })
            : <p className="text-sm font-medium text-gray-900 font-semibold text-center mt-8">No notes yet</p>   
        }

            </div>




            <hr className='
                border-gray-300
                my-2
                w-full
            ' />

            

        <textarea
        className="w-full h-full p-2 focus:ring-transparent   "
        style={{
            border:"none",
        }}
        placeholder="Enter order notes here..."
        value={orderNote}
        onChange={(e) =>setOrderNote(e.target.value)}
        />

        <button
        className="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 w-full"
        onClick={() => createOrderNote()}
        >
            Add Note
        </button>

    </div>
    

  )
}
