import React, {useState, useEffect} from 'react'
import {saveNotificationTokenToUser, onMessageListener} from '../utils/firebase';


const Notification = () => {
    const [notification, setNotification] = useState({title: '', body: ''});
    const notify = () => {}

    function ToastDisplay() {
        return (
            <div  
   
            >
          

                <p><b>{notification?.title}</b></p>
                <p>{notification?.body}</p>
            </div>
        );
    };

    useEffect(() => {
        if (notification && notification.title && notification.body) {
            console.log('notification: ', notification);
            notify()
        }
    }, [notification])


    useEffect(() => {
        saveNotificationTokenToUser();

    }, []);

    onMessageListener()
        .then((payload) => {
            console.log('payload: ', payload);
            setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
        })
        .catch((err) => console.log('failed: ', err));


}

export default Notification