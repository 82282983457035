import React, { useEffect,useState } from 'react';
import { fetchStore,fetchUser } from '../utils/globalFunctions';


const Sender = ({ user }) => {
  const [store, setStore] = useState(null);
  const [u, setUser] = useState(null);
  useEffect(() => {
    fetchUser(user).then((data) => {
      console.log(data)
      setUser(data);
    })
  } , [user]);
  console.log(u)
  return (
    <div className="flex items-center">
      <div className="text-xs text-gray-500">
       
        {u?.email} ({u?.role === "admin" ? "Manufacturer" : "Store"})
      </div>
    </div>
  );
}

    

        
const MessageList = ({ messages,user,manufacturerID }) => {
  return (
    <div className="flex-1 p-2 overflow-y-auto">
      {messages.map((message) => (
        <div
          key={message.id}
          className="bg-gray-200 my-2 p-2 rounded"
        >
          

          
            <div className="text-gray-700">{message.message}</div>
            <div className="text-xs text-gray-500">
              
              <Sender user={message.senderID} />
              </div>
       
        </div>
      ))}
    </div>
  );
};

export default MessageList;