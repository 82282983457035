import React from 'react';
import ChatWindow from '../../components/ChatWindow';

const Chats = ({user}) => {
    return (
        <div className='mt-16'>
            <ChatWindow storeID={user.store_id} manufacturerID={user.manufacturer} user={user}/>
        </div>
    );
};

export default Chats;