import React, { useEffect } from 'react';
import { signOut } from "@firebase/auth";
import { auth } from '../../utils/firebase';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../utils/context';

export const handleLogout = (nav,setUser) => {




};
const SignOut = () => {
  const {setUser} = useUser()
    // Add your sign out logic here
    const navigate = useNavigate();

    useEffect(() => {
        handleLogout(navigate,setUser);

    });

    return (
      <>
      </>
    );
};

export default SignOut;