import React from 'react';

const Reports = () => {
    return (
        <div className="pt-12 flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">AI Reports</h1>
          </div>
        </div>
    );
};

export default Reports;