import Logout from "../../components/logout";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useUser } from "../../utils/context";

import { useState } from "react";

import { Disclosure, Menu, Transition } from "@headlessui/react";

import { Fragment } from "react";
import Orders from "./orders";
import Database from "./database";
import { Modal } from "flowbite-react";
import Button from "../../components/Button";
import {
  createFeedBack,
  globalAlert,
  globalSuccess,
} from "../../utils/globalFunctions";
import OrderEntries from "../store/OrderEntries";
import Dashboard from "./dashboard";
import CreateManufacturer from "./CreateManufacturer";
import Users from "./Users";
import NewsletterEditor from "./StoreNewsletter";



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


export default function Index(props) {
  const {user} = useUser();
  const [unseenNotificationCount, setUnseenNotificationCount] = useState(0);
  const [modal, setModal] = useState(false);

  return (
    <>
      <div className="flex justify-between items-center fixed top-2 right-2 z-30">
        {/* Profile dropdown */}
        <Menu as="div" className="relative ml-3">
          <div>
            <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span className="absolute -inset-1.5" />
              <span className="sr-only">Open user menu</span>
              <img
                className="h-10 w-10 rounded-full"
                src="https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/256x256/menu.png"
                alt=""
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute mr-2 right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => {
                      setModal(true);
                    }}
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 font-bold text-xs text-gray-700"
                    )}
                  >
                    Send Feedback
                  </a>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 font-bold text-xs text-gray-700"
                    )}
                  >
                    Dashboard
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/orders"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2  font-bold  text-xs text-gray-700"
                    )}
                  >
                    Orders
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/users"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2  font-bold  text-xs text-gray-700"
                    )}
                  >
                    Users
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/database"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2  font-bold  text-xs text-gray-700"
                    )}
                  >
                    Database
                  </a>
                )}
              </Menu.Item>


              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/o/orders"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2  font-bold  text-xs text-gray-700"
                    )}
                  >
                    Orders (Old version)
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>{({ active }) => <Logout />}</Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>

        <Modal show={modal} onClose={() => setModal(false)}>
          <Modal.Header>Submit a Feedback</Modal.Header>
          <Modal.Body>
            <div className="flex flex-col bg-white mt-2">
              <p className="mb-2 ">Your feedback</p>
              <textarea class="resize rounded-md" id="feedbackInput"></textarea>

              <Button
                color="gray"
                name={"Submit"}
                className="mt-2"
                onClick={async () => {
                  const feedback =
                    document.getElementById("feedbackInput").value;
                  console.log(feedback);

                  if (feedback.length)
                    try {
                      await createFeedBack(user.storeName, feedback);
                      globalSuccess("Feedback has succesfully submitted");
                      document.getElementById("feedbackInput").value = "";
                    } catch {
                      globalAlert(
                        "Something went wrong while submitting the feedback"
                      );
                    }
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>

        <Routes>
          {/* <Route path="/" element={<Dashboard />} /> */}
          <Route path="/" element={<Dashboard user={user} />} />
          <Route path="/orders" element={<Orders user={user} />} />
          <Route path="/database" element={<Database user={user} />} />
          <Route path="/users" element={<Users  />} />
       

          <Route path="/create-manufacturer" element={<CreateManufacturer user={user} />} />
          <Route
            path="/edit/order/:order_id"
            element={<OrderEntries user={user} />}
          />

          <Route
            path="*"
            element={
              <div className="flex justify-center items-center h-screen">
                <h1 className="text-4xl text-gray-600 font-bold m-auto">
                  404 Not Found
                </h1>
              </div>
            }
          />
        </Routes>

    </>
  );
}
