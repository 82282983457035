// src/extensions/calendarBooking/SetAvailability.jsx
import React, { useState, useEffect } from 'react';
import { db } from '../../utils/firebase';
import {
  doc,
  getDoc,
  setDoc,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  getDocs,
  Timestamp,
} from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { globalAlert, globalSuccess } from '../../utils/globalFunctions';

const daysOfWeekOptions = [
  { label: 'Sunday', value: 0 },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
];

const SetAvailability = ({ user }) => {
  // State for recurring availability
  const [availability, setAvailability] = useState({
    daysOfWeek: [],
    startTime: '09:00',
    endTime: '17:00',
    slotDuration: 60,
    limitPerSlot: 3,
  });

  // State for availability exceptions
  const [exceptions, setExceptions] = useState([]);

  // State for new exception inputs
  const [newException, setNewException] = useState({
    startDate: null,
    endDate: null,
  });

  // State for editing exceptions
  const [editingExceptionId, setEditingExceptionId] = useState(null);
  const [editingExceptionData, setEditingExceptionData] = useState({
    startDate: null,
    endDate: null,
  });

  // Fetch existing availability and exceptions on component mount
  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      const storeId = user.store_id;
      const storeRef = doc(db, 'Stores', storeId);
      const storeSnap = await getDoc(storeRef);

      if (storeSnap.exists()) {
        const data = storeSnap.data();
        if (data.availability) {
          setAvailability({
            daysOfWeek: data.availability.daysOfWeek.map(String), // Convert numbers to strings for checkboxes
            startTime: data.availability.startTime,
            endTime: data.availability.endTime,
            slotDuration: data.availability.slotDuration,
            limitPerSlot: data.availability.limitPerSlot,
          });
        }

        // Fetch exceptions
        const exceptionsRef = collection(db, 'Stores', storeId, 'availabilityExceptions');
        const exceptionsSnap = await getDocs(exceptionsRef);
        const fetchedExceptions = exceptionsSnap.docs.map(docSnap => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setExceptions(fetchedExceptions);
      }
    };

    fetchData();
  }, [user]);

  // Handle changes in recurring availability inputs
  const handleAvailabilityChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'daysOfWeek') {
      let updatedDays = [...availability.daysOfWeek];
      if (checked) {
        updatedDays.push(value);
      } else {
        updatedDays = updatedDays.filter(day => day !== value);
      }
      setAvailability(prev => ({ ...prev, daysOfWeek: updatedDays }));
    } else {
      setAvailability(prev => ({ ...prev, [name]: value }));
    }
  };

  // Handle adding a new exception
  const handleAddException = async () => {
    const { startDate, endDate } = newException;

    if (!startDate || !endDate) {
      globalAlert('Please select both start and end dates.');
      return;
    }

    if (endDate < startDate) {
      globalAlert('End date cannot be before start date.');
      return;
    }

    const storeId = user.store_id;
    const exceptionsRef = collection(db, 'Stores', storeId, 'availabilityExceptions');

    try {
      // Check for overlapping intervals
      const exceptionsSnap = await getDocs(exceptionsRef);
      const existingExceptions = exceptionsSnap.docs.map(docSnap => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));

      const hasOverlap = existingExceptions.some(exception => {
        const existingStart = new Date(exception.startDate);
        const existingEnd = new Date(exception.endDate);
        return (
          (startDate <= existingEnd) && (endDate >= existingStart)
        );
      });

      if (hasOverlap) {
        globalAlert('The selected date range overlaps with an existing exception.');
        return;
      }

      // Add the new exception
      await addDoc(exceptionsRef, {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        isClosed: true,
        createdAt: Timestamp.now(),
      });

      globalSuccess('Closed interval added successfully!');

      // Refresh exceptions
      const updatedExceptionsSnap = await getDocs(exceptionsRef);
      const updatedExceptions = updatedExceptionsSnap.docs.map(docSnap => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      setExceptions(updatedExceptions);

      // Reset new exception inputs
      setNewException({ startDate: null, endDate: null });
    } catch (error) {
      console.error('Error adding exception:', error);
      globalAlert('Failed to add exception.');
    }
  };

  // Handle removing an exception
  const handleRemoveException = async (id) => {
    const storeId = user.store_id;
    const exceptionRef = doc(db, 'Stores', storeId, 'availabilityExceptions', id);

    try {
      await deleteDoc(exceptionRef);
      globalSuccess('Closed interval removed successfully!');

      // Refresh exceptions
      const exceptionsRef = collection(db, 'Stores', storeId, 'availabilityExceptions');
      const exceptionsSnap = await getDocs(exceptionsRef);
      const updatedExceptions = exceptionsSnap.docs.map(docSnap => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      setExceptions(updatedExceptions);
    } catch (error) {
      console.error('Error removing exception:', error);
      globalAlert('Failed to remove exception.');
    }
  };

  // Handle initiating edit for an exception
  const handleEditException = (exception) => {
    setEditingExceptionId(exception.id);
    setEditingExceptionData({
      startDate: new Date(exception.startDate),
      endDate: new Date(exception.endDate),
    });
  };

  // Handle saving edited exception
  const handleSaveEditedException = async () => {
    const { startDate, endDate } = editingExceptionData;

    if (!startDate || !endDate) {
      globalAlert('Please select both start and end dates.');
      return;
    }

    if (endDate < startDate) {
      globalAlert('End date cannot be before start date.');
      return;
    }

    const storeId = user.store_id;
    const exceptionRef = doc(db, 'Stores', storeId, 'availabilityExceptions', editingExceptionId);
    const exceptionsRef = collection(db, 'Stores', storeId, 'availabilityExceptions');

    try {
      // Fetch existing exceptions excluding the one being edited
      const exceptionsSnap = await getDocs(exceptionsRef);
      const existingExceptions = exceptionsSnap.docs
        .filter(docSnap => docSnap.id !== editingExceptionId)
        .map(docSnap => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));

      // Check for overlapping intervals
      const hasOverlap = existingExceptions.some(exception => {
        const existingStart = new Date(exception.startDate);
        const existingEnd = new Date(exception.endDate);
        return (
          (startDate <= existingEnd) && (endDate >= existingStart)
        );
      });

      if (hasOverlap) {
        globalAlert('The selected date range overlaps with an existing exception.');
        return;
      }

      // Update the exception
      await updateDoc(exceptionRef, {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        updatedAt: Timestamp.now(),
      });

      globalSuccess('Closed interval updated successfully!');

      // Refresh exceptions
      const updatedExceptionsSnap = await getDocs(exceptionsRef);
      const updatedExceptions = updatedExceptionsSnap.docs.map(docSnap => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      setExceptions(updatedExceptions);

      // Reset editing state
      setEditingExceptionId(null);
      setEditingExceptionData({ startDate: null, endDate: null });
    } catch (error) {
      console.error('Error updating exception:', error);
      globalAlert('Failed to update exception.');
    }
  };

  // Utility function to format Date to YYYY-MM-DD
  const formatDate = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2); // Months are zero-indexed
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };

  // Handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload

    // Validate that at least one day is selected
    if (availability.daysOfWeek.length === 0) {
      globalAlert('Please select at least one day of the week.');
      return;
    }

    // Validate that end time is after start time
    const [startHour, startMinute] = availability.startTime.split(':').map(Number);
    const [endHour, endMinute] = availability.endTime.split(':').map(Number);
    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;

    if (endTotalMinutes <= startTotalMinutes) {
      globalAlert('End time must be after start time.');
      return;
    }

    // Validate slot duration and limit
    if (availability.slotDuration < 15) {
      globalAlert('Slot duration must be at least 15 minutes.');
      return;
    }

    if (availability.limitPerSlot < 1) {
      globalAlert('Limit per slot must be at least 1.');
      return;
    }

    const storeId = user.store_id;
    const storeRef = doc(db, 'Stores', storeId);

    try {
      // Save recurring availability
      await setDoc(
        storeRef,
        {
          availability: {
            daysOfWeek: availability.daysOfWeek.map(Number), // Convert strings to numbers
            startTime: availability.startTime,
            endTime: availability.endTime,
            slotDuration: Number(availability.slotDuration),
            limitPerSlot: Number(availability.limitPerSlot),
            updatedAt: Timestamp.now(),
          },
        },
        { merge: true }
      );

      globalSuccess('Recurring availability settings saved successfully!');
      // Optionally, you can refetch availability here if needed
    } catch (error) {
      console.error('Error setting availability:', error);
      globalAlert('Failed to save recurring availability settings.');
    }
  };

  return (
    <div className="flex flex-col max-w-3xl mx-auto p-6 bg-white shadow-md rounded mt-8">
      <h2 className="text-2xl text-center font-semibold mb-4">Set Availability</h2>
      <form onSubmit={handleFormSubmit}>
        {/* Recurring Availability */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Recurring Availability</h3>
          {/* Days of Week */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Days of Week:</label>
            <div className="flex flex-wrap gap-4">
              {daysOfWeekOptions.map((day) => (
                <label key={day.value} className="flex items-center">
                  <input
                    type="checkbox"
                    name="daysOfWeek"
                    value={day.value.toString()}
                    checked={availability.daysOfWeek.includes(day.value.toString())}
                    onChange={handleAvailabilityChange}
                    className="mr-2"
                  />
                  {day.label}
                </label>
              ))}
            </div>
            {availability.daysOfWeek.length === 0 && (
              <span className="text-red-500 text-sm">Please select at least one day.</span>
            )}
          </div>

          {/* Start and End Times */}
          <div className="flex gap-6 mb-4">
            <div className="flex-1">
              <label className="block text-gray-700 mb-2">Start Time:</label>
              <input
                type="time"
                name="startTime"
                value={availability.startTime}
                onChange={handleAvailabilityChange}
                className="mt-1 p-2 border rounded w-full"
                required
              />
            </div>
            <div className="flex-1">
              <label className="block text-gray-700 mb-2">End Time:</label>
              <input
                type="time"
                name="endTime"
                value={availability.endTime}
                onChange={handleAvailabilityChange}
                className="mt-1 p-2 border rounded w-full"
                required
              />
            </div>
          </div>

          {/* Slot Configuration */}
          <div className="flex gap-6 mb-4">
            <div className="flex-1">
              <label className="block text-gray-700 mb-2">Slot Duration (minutes):</label>
              <input
                type="number"
                name="slotDuration"
                value={availability.slotDuration}
                onChange={handleAvailabilityChange}
                className="mt-1 p-2 border rounded w-full"
                min="15"
                step="15"
                required
              />
            </div>
            <div className="flex-1">
              <label className="block text-gray-700 mb-2">Limit per Slot:</label>
              <input
                type="number"
                name="limitPerSlot"
                value={availability.limitPerSlot}
                onChange={handleAvailabilityChange}
                className="mt-1 p-2 border rounded w-full"
                min="1"
                required
              />
            </div>
          </div>
        </div>

        {/* Availability Exceptions */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Availability Exceptions</h3>
          {/* Add Exception */}
          <div className="mb-4 p-4 border rounded">
            <h4 className="font-medium mb-2">Add Closed Interval</h4>
            <div className="flex gap-6 mb-4">
              <div className="flex-1">
                <label className="block text-gray-700 mb-2">Start Date:</label>
                <DatePicker
                  selected={newException.startDate}
                  onChange={(date) => setNewException(prev => ({ ...prev, startDate: date }))}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select start date"
                  className="mt-1 p-2 border rounded w-full"
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 mb-2">End Date:</label>
                <DatePicker
                  selected={newException.endDate}
                  onChange={(date) => setNewException(prev => ({ ...prev, endDate: date }))}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select end date"
                  className="mt-1 p-2 border rounded w-full"
                  minDate={newException.startDate || undefined}
                />
              </div>
            </div>
            <button
              type="button"
              onClick={handleAddException}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              disabled={!newException.startDate || !newException.endDate}
            >
              Add Closed Interval
            </button>
          </div>

          {/* List of Exceptions */}
          {exceptions.length > 0 ? (
            <ul>
              {exceptions.map((exception) => (
                <li key={exception.id} className="mb-4 p-4 border rounded">
                  <div className="flex justify-between items-center">
                    <div>
                      <span className="font-medium">Closed Interval:</span> {exception.startDate} to {exception.endDate}
                    </div>
                    <div className="flex space-x-2">
                      <button
                        type="button"
                        onClick={() => handleEditException(exception)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={() => handleRemoveException(exception.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </div>
                  </div>

                  {/* Edit Exception Form */}
                  {editingExceptionId === exception.id && (
                    <div className="mt-4 p-4 border-t">
                      <h5 className="font-medium mb-2">Edit Closed Interval</h5>
                      <div className="flex gap-6 mb-4">
                        <div className="flex-1">
                          <label className="block text-gray-700 mb-2">Start Date:</label>
                          <DatePicker
                            selected={editingExceptionData.startDate}
                            onChange={(date) => setEditingExceptionData(prev => ({ ...prev, startDate: date }))}
                            dateFormat="yyyy-MM-dd"
                            className="mt-1 p-2 border rounded w-full"
                            required
                          />
                        </div>
                        <div className="flex-1">
                          <label className="block text-gray-700 mb-2">End Date:</label>
                          <DatePicker
                            selected={editingExceptionData.endDate}
                            onChange={(date) => setEditingExceptionData(prev => ({ ...prev, endDate: date }))}
                            dateFormat="yyyy-MM-dd"
                            className="mt-1 p-2 border rounded w-full"
                            required
                            minDate={editingExceptionData.startDate || undefined}
                          />
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <button
                          type="button"
                          onClick={handleSaveEditedException}
                          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                          disabled={!editingExceptionData.startDate || !editingExceptionData.endDate}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() => setEditingExceptionId(null)}
                          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>No closed intervals added yet.</p>
          )}
        </div>

        {/* Save Recurring Availability */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-3 rounded hover:bg-blue-600"
        >
          Save Recurring Availability
        </button>
      </form>
    </div>
  );
};

export default SetAvailability;