import React, { useState } from "react";
import { collection, doc, getDocs, updateDoc,setDoc, deleteDoc, query, where,deleteField } from "firebase/firestore";
import ReactJson from "react-json-view";
import { db } from "../../utils/firebase";

const FirebaseEditor = () => {
    const [collectionPath, setCollectionPath] = useState("");
    const [queries, setQueries] = useState([{ field: "", operator: "==", value: "" }]);
    const [data, setData] = useState(null);
    const [message, setMessage] = useState("");
  
    const handleQueryChange = (index, field, value) => {
      const newQueries = [...queries];
      newQueries[index][field] = value;
      setQueries(newQueries);
    };
  
    const handleAddQuery = () => {
      setQueries([...queries, { field: "", operator: "==", value: "" }]);
    };
  
    const handleQuery = async () => {
      try {
        const colRef = collection(db, collectionPath);
        const constraints = [];
  
        queries.forEach((query) => {
          if (query.field && query.value) {
            switch (query.operator) {
              case "==":
                constraints.push(where(query.field, "==", query.value));
                break;
              case ">":
                constraints.push(where(query.field, ">", query.value));
                break;
              case "<":
                constraints.push(where(query.field, "<", query.value));
                break;
              case "array-contains":
                constraints.push(where(query.field, "array-contains", query.value));
                break;
              // Add more cases as needed
              default:
                break;
            }
          }
        });
  
        const q = constraints.length > 0 ? query(colRef, ...constraints) : query(colRef);
        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() });
        });
        setData(results);
        setMessage("Data fetched successfully.");
      } catch (error) {
        setMessage(`Error fetching data: ${error.message}`);
      }
    };
  
    const handleJsonEdit = async (edit, id) => {
        try {
            const docRef = doc(db, collectionPath, id);
    
            if (edit.existing_value !== undefined && edit.new_value === undefined) {
                // Field is being deleted
                const fieldPath = edit.name; // edit.name is the path of the field being deleted
                await setDoc(docRef, {
                    [fieldPath]: deleteField(),
                }, { merge: true });
                setMessage(`Field "${fieldPath}" deleted successfully.`);
            } else if (edit.new_value !== undefined) {
                // Handle updates or additions (only if new_value is defined)
                const value = edit.new_value;
                const fieldPath = edit.name;
                await setDoc(docRef, {
                    [fieldPath]: value,
                }, { merge: true });
                setMessage(`Field "${fieldPath}" updated successfully.`);
            }
    
            // Refresh the data to reflect the deletion or update
            handleQuery();
        } catch (error) {
            setMessage(`Error updating document: ${error.message}`);
        }
    };
    const handleDelete = async (id) => {
      try {
        const docRef = doc(db, collectionPath, id);
        await deleteDoc(docRef);
        handleQuery(); // Refresh data after deletion
        setMessage("Document deleted successfully.");
      } catch (error) {
        setMessage(`Error deleting document: ${error.message}`);
      }
    };
  
    return (
      <div>
        <h1>Firebase Database Editor</h1>
        <div>
          <input
            type="text"
            placeholder="Collection Path"
            value={collectionPath}
            onChange={(e) => setCollectionPath(e.target.value)}
          />
          {queries.map((query, index) => (
            <div key={index}>
              <input
                type="text"
                placeholder="Field"
                value={query.field}
                onChange={(e) => handleQueryChange(index, "field", e.target.value)}
              />
              <select
                value={query.operator}
                onChange={(e) => handleQueryChange(index, "operator", e.target.value)}
              >
                <option value="==">==</option>
                <option value=">">{">"}</option>
                <option value="<">{"<"}</option>
                <option value="array-contains">array-contains</option>
              </select>
              <input
                type="text"
                placeholder="Value"
                value={query.value}
                onChange={(e) =>{
                        if(e.target.value === "true" || e.target.value === "false"){
                            handleQueryChange(index, "value", e.target.value === "true");
                        }else{
                    handleQueryChange(index, "value", e.target.value)}}
                }
              />
            </div>
          ))}
          <button onClick={handleAddQuery}>Add Query</button>
          <button onClick={handleQuery}>Fetch Data</button>
        </div>
        {message && <p>{message}</p>}
        {data && (
          <div>
            {data.map((doc) => (
              <div key={doc.id}>
                <h3>Document ID: {doc.id}</h3>
                <ReactJson
                  src={doc}
                  name={false}
                  collapsed={1}
                  enableClipboard={false}
                  onEdit={(edit) => handleJsonEdit(edit, doc.id)}
                  onDelete={(edit) => handleJsonEdit(edit, doc.id)}
                  onAdd={(edit) => handleJsonEdit(edit, doc.id)}
                  displayDataTypes={false}
                />
                <button onClick={() => handleDelete(doc.id)}>Delete Document</button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  
export default FirebaseEditor;

