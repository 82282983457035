import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getManufacturerDiscounts, deleteDiscount } from "../../utils/globalFunctions";
import TableComponent from "../../components/TableComponent";
import Skeleton from "react-loading-skeleton";

const Discounts = ({ manufacturer, user }) => {
  const [discounts, setDiscounts] = useState([]);
  const [filteredDiscounts, setFilteredDiscounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const rowsPerPage = 8;
  const navigate = useNavigate();

  useEffect(() => {
    fetchDiscounts();
  }, []);

  useEffect(() => {
    checkIsLastPage();
  }, [filteredDiscounts, currentPage]);

  const fetchDiscounts = () => {
    setIsLoading(true);
    getManufacturerDiscounts({ manufacturer: user.manufacturerID }).then((data) => {
      setDiscounts(data);
      setFilteredDiscounts(data);
      setIsLoading(false);
      setIsLastPage(data.length <= rowsPerPage);
    });
  };

  const handleDeleteDiscount = (discountId) => {
    deleteDiscount(discountId).then(() => {
      fetchDiscounts();
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = discounts.filter((discount) =>
      discount.code.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDiscounts(filtered);
    setCurrentPage(0);
    checkIsLastPage();
  };

  const checkIsLastPage = () => {
    setIsLastPage((currentPage + 1) * rowsPerPage >= filteredDiscounts.length);
  };

  const handleNextPage = () => {
    if (!isLastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const columns = [
    { Header: "Code", accessor: "code" },
    { Header: "Store", accessor: "storeName" },
    {
      Header:"Created At",
      accessor: "createdAt",
      Cell: ({ row }) => {
        return row.createdAt ? row.createdAt.toDate().toLocaleDateString() : <Skeleton />;
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div className="flex items-center">
          <button
            className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
            onClick={() => handleDeleteDiscount(row.id)}
          >
            Delete
          </button>
          <button
            className="bg-gray-700 ml-2 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
            onClick={() => navigate(`/edit-discount/${row.id}`)}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const paginatedData = filteredDiscounts.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  return (
    <>
      <div className="flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">Discounts</h1>
          <div className="flex mt-2">
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
              onClick={() => navigate("/create-discount")}
            >
              Create Discount
            </button>
          </div>
        </div>
      </div>

      <div className="pt-4 flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <input
            type="text"
            placeholder="Search by code"
            className="bg-white text-xs p-2 w-full mt-1 rounded border"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center mt-8">
        <div className="w-full overflow-x-auto">
          <div className="table-container">
            <div className="w-full mx-auto">
              <div className="flex justify-between mb-4">
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 0}
                >
                  Previous
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                  onClick={handleNextPage}
                  disabled={isLastPage}
                >
                  Next
                </button>
              </div>
              <TableComponent
                columns={columns}
                isLoading={isLoading}
                data={paginatedData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Discounts;