import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SearchBox.css";
import OrderListStore from "./OrderListStore";
import { groupByCustomerID } from "../utils/globalFunctions";
import OrderListAdmin from "./OrderListAdmin";
import { addressURL } from "../utils/globalFunctions";
const SearchBox = (props) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState({});
  const [timer, setTimer] = useState(null);
  const [orders,setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [newOrderEntered, setNewOrderEntered] = useState(false); // no need this
  const rules = props.rules;
  const fetchSearchResults = async (searchTerm) => {
    try {
      const response = await axios.get(
        addressURL +  `/api/search?query=${searchTerm}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      );
      const data = response.data;
      console.log(data);
      if(data.success){
        setOrders(data.data);
        // setResults(data.data);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

        
  useEffect(() => {

    axios
      .get(addressURL + `/api/price-list?fromWhere=allProducts`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        withCredentials: true,
      })
      .then((res) => {
        setProducts(res.data.result);

      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (query.trim().length > 1) {
      if (timer) clearTimeout(timer);
      const newTimer = setTimeout(() => {
        fetchSearchResults(query.trim());
      }, 500);
      setTimer(newTimer);
    } else {
      if (timer) clearTimeout(timer);
      setOrders([]);
    }
  }, [query]);

  
  return (
    <div className="search-box">
      <input
        type="text"
        className="search-input"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={props.user.role === "admin" ? "Search by customer name or  store name" : "Search by customer name"}
      />
      <div>
 
        {products.length > 0 && orders.length > 0 && (
            
          <>
            <p className="sm:pl-8 text-xl font-bold text-center mb-[-1rem] mt-4">Search Results</p>
            {orders.map((order,index) => {
              return (
                <div key={order.customerID}>
          {
            props.user.role === "admin" ? (
              <OrderListAdmin
              customerID={order.customerID}
              orders={order.orders}
              totalPrice={order.totalPrice}
              extras={order.extras}
              rules={rules}
              setNewOrderEntered={setNewOrderEntered}
              index={index}
              getOrderWithGroupByCustomerID={props.getOrderWithGroupByCustomerID}
              products={products}
            />
            ) : (
              <OrderListStore
              customerID={order.customerID}
              orders={order.orders}
              totalPrice={order.totalPrice}
              extras={order.extras}
              setNewOrderEntered={setNewOrderEntered}
              index={index}
              products={products}
            />
            )

          }
                
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
