import React, { useEffect } from 'react'
import { handleCalculateTotalOrder } from '../utils/globalFunctions'

export default function ShowTotalPrice({orderData}) {
    const [totalPrice, setTotalPrice] = React.useState(0)




    useEffect(() => {
       handleCalculateTotalOrder(orderData).then((res)=>{
            setTotalPrice(res)
       })
    }, [orderData])

  return (
    <div>
            <p>${totalPrice}</p>
    </div>
  )
}
