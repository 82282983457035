// App.js
import React, { useState, useEffect } from 'react';
import BlindsEstimate from '../extensions/blindsEstimate';
import CalendarBooking from '../extensions/calendarBooking';
import PriceExcelLoader from '../extensions/priceExcelLoder';


function ExtensionDetails({user,info}) {
  return (
    <div className='pt-16 w-screen flex justify-center'>

    <div className='sm:w-1/2 w-full '>

      {
        info === "blindsEstimate" ? <BlindsEstimate user={user}/>
        : info === "calendarBooking" ? <CalendarBooking user={user}/>
        : info === "priceLists" ? <PriceExcelLoader user={user}/>
        : <h1>Extension not found</h1>
      }


     



   





 
    </div>
  

    </div>
  );
}

export default ExtensionDetails;
