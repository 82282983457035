import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase'; // Assuming you have already initialized your Firebase app and imported the 'db' instance



const CreateSeller = ({user}) => {
    console.log(user)
    const [sellerName, setSellerName] = useState('');
    const [sellerEmail, setSellerEmail] = useState('');

    const handleNameChange = (e) => {
        setSellerName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setSellerEmail(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Add your logic to create a new seller here
        console.log('Seller Name:', sellerName);
        console.log('Seller Email:', sellerEmail);
    
        try {
            // Create a new document in the "register_queue" collection
            // const docRef = await addDoc(collection(db, 'register_queue'), {
            //     sellerName,
            //     sellerEmail,

            // });

            const sendMail = await addDoc(collection(db, 'mail'), {
                to: ['cihansa.9797@gmail.com'],
                message: {
                  subject: 'Hello from Firebase!',
                  text: 'This is the plaintext section of the email body.',
                  html: 'This is the <code>HTML</code> section of the email body.',
                }
            });
    
            console.log('Created Doc ID:', sendMail);
        } catch (error) {
            console.error('Error creating document:', error);
        }
    };

    return (
        <div className="max-w-md mx-auto">
            <h1 className="text-2xl font-bold mb-4">Invite Your Seller</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="sellerName" className="block mb-2">Seller Name</label>
                    <input
                        type="text"
                        id="sellerName"
                        value={sellerName}
                        onChange={handleNameChange}
                        className="border border-gray-300 rounded px-3 py-2 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="sellerEmail" className="block mb-2">Seller Email</label>
                    <input
                        type="email"
                        id="sellerEmail"
                        value={sellerEmail}
                        onChange={handleEmailChange}
                        className="border border-gray-300 rounded px-3 py-2 w-full"
                    />
                </div>
                <button type="submit" className="bg-indigo-500 text-white px-4 py-2 rounded">Invite</button>
            </form>
        </div>
    );
};

export default CreateSeller;

