// src/pages/admin/virtualConfigurator.jsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  fetchManufacturerMaterials,
  updateMaterialConfiguration,
} from '../../utils/globalFunctions'; // Adjust the import path as needed
import { Stage, Layer, Image, Transformer } from 'react-konva';
import useImage from 'use-image';
import { useUser } from '../../utils/context';
import PropTypes from 'prop-types';

/**
 * URLImage Component
 * Handles the rendering and interactions of individual materials on the canvas.
 */
const URLImage = React.forwardRef(
  (
    {
      material,
      canvasWidth,
      canvasHeight,
      isSelectedForManipulation,
      onSelectForManipulation,
      onChange,
    },
    ref
  ) => {
    const imageRef = ref;
    const [img] = useImage(material.image, 'Anonymous');

    /**
     * Handles the end of dragging.
     * Updates the material's configuration with new position percentages.
     */
    const handleDragEnd = (e) => {
      const node = e.target;
      const newAttrs = {
        ...material,
        config: {
          ...material.config,
          xPercent: node.x() / canvasWidth,
          yPercent: node.y() / canvasHeight,
        },
      };
      onChange(newAttrs);
    };

    /**
     * Handles the end of transformation (scaling, rotating).
     * Updates the material's configuration with new scale and rotation.
     */
    const handleTransformEnd = (e) => {
      const node = e.target;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      // Reset scaling to 1 to prevent cumulative scaling
      node.scaleX(1);
      node.scaleY(1);

      const newAttrs = {
        ...material,
        config: {
          ...material.config,
          xPercent: node.x() / canvasWidth,
          yPercent: node.y() / canvasHeight,
          scalePercentX: (material.config.scalePercentX || 1) * scaleX,
          scalePercentY: (material.config.scalePercentY || 1) * scaleY,
          rotation: node.rotation(),
        },
      };
      onChange(newAttrs);
    };

    return (
      <Image
        image={img}
        x={(material.config.xPercent || 0) * canvasWidth}
        y={(material.config.yPercent || 0) * canvasHeight}
        scaleX={material.config.scalePercentX || 1}
        scaleY={material.config.scalePercentY || 1}
        rotation={material.config.rotation || 0}
        draggable
        onClick={onSelectForManipulation} // Select for manipulation
        onTap={onSelectForManipulation}
        ref={imageRef}
        onDragEnd={handleDragEnd}
        onTransformEnd={handleTransformEnd}
      />
    );
  }
);

URLImage.displayName = 'URLImage';

URLImage.propTypes = {
  material: PropTypes.object.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  canvasHeight: PropTypes.number.isRequired,
  isSelectedForManipulation: PropTypes.bool.isRequired,
  onSelectForManipulation: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

/**
 * VirtualConfigurator Component
 * Main component handling the 2D configurator functionalities.
 */
export default function VirtualConfigurator() {
  const { user } = useUser();
  const [materials, setMaterials] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]); // Tracks materials displayed on the canvas
  const [selectedForManipulation, setSelectedForManipulation] = useState(null); // Tracks material selected for manipulation
  const stageRef = useRef();
  const transformerRef = useRef();
  const shapeRefs = useRef({});

  const ORIGINAL_WIDTH = 800;
  const ORIGINAL_HEIGHT = 600;
  const [canvasWidth, setCanvasWidth] = useState(ORIGINAL_WIDTH);
  const [canvasHeight, setCanvasHeight] = useState(ORIGINAL_HEIGHT);

  /**
   * Fetches materials from the backend when the component mounts.
   * Initializes selectedIds with materials that have existing configurations.
   */
  useEffect(() => {
    if (user?.manufacturerID) {
      fetchManufacturerMaterials(user.manufacturerID)
        .then((data) => {
          const sortedMaterials = data.sort(
            (a, b) => (a.config?.zIndex || 0) - (b.config?.zIndex || 0)
          );
          setMaterials(sortedMaterials);

          // Initialize selectedIds with materials that have existing configurations
          const initiallySelected = sortedMaterials
            .filter((mat) => mat.config && Object.keys(mat.config).length > 0)
            .map((mat) => mat.id);
          setSelectedIds(initiallySelected);
        })
        .catch((error) => {
          console.error('Error fetching materials:', error);
        });
    }
  }, [user?.manufacturerID]);

  /**
   * Handles window resizing to make the canvas responsive.
   */
  useEffect(() => {
    const handleResize = () => {
      const aspectRatio = ORIGINAL_WIDTH / ORIGINAL_HEIGHT;
      const containerWidth = Math.min(window.innerWidth * 0.9, ORIGINAL_WIDTH);
      const containerHeight = containerWidth / aspectRatio;

      setCanvasWidth(containerWidth);
      setCanvasHeight(containerHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * Manages the Transformer for the selected node.
   * Ensures that only the selected node is attached to the Transformer.
   */
  useEffect(() => {
    const transformer = transformerRef.current;
    if (transformer) {
      if (selectedForManipulation) {
        const node = shapeRefs.current[selectedForManipulation];
        if (node) {
          transformer.nodes([node]);
          transformer.getLayer().batchDraw();
        }
      } else {
        transformer.nodes([]);
        transformer.getLayer().batchDraw();
      }
    }
  }, [selectedForManipulation, materials, canvasWidth, canvasHeight]);

  /**
   * Handles selecting/deselecting materials from the left panel.
   * - Selecting adds the material to the canvas with a default or existing configuration.
   * - Deselecting removes the material from the canvas by clearing its configuration.
   */
  const handleSelectFromPanel = useCallback(
    (id) => {
      const material = materials.find((mat) => mat.id === id);
      if (!material) return;

      if (selectedIds.includes(id)) {
        // Deselect: Remove from selectedIds and clear configuration
        updateMaterialConfiguration(id, { config: {} })
          .then(() => {
            setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id));
            setMaterials((prevMaterials) =>
              prevMaterials.map((mat) => {
                if (mat.id === id) {
                  return { ...mat, config: {} };
                }
                return mat;
              })
            );

            // If the deselected material was selected for manipulation, deselect it
            if (selectedForManipulation === id) {
              setSelectedForManipulation(null);
            }
          })
          .catch((error) => {
            console.error(`Error deselecting material ${id}:`, error);
          });
      } else {
        // Select: Add to selectedIds and set default configuration if none exists
        const defaultConfig = {
          xPercent: 0.5, // Center position
          yPercent: 0.5,
          scalePercentX: 1,
          scalePercentY: 1,
          rotation: 0,
          zIndex: materials.length, // Place on top
        };

        const newConfig =
          material.config && Object.keys(material.config).length > 0
            ? material.config
            : defaultConfig;

        updateMaterialConfiguration(id, { config: newConfig })
          .then(() => {
            setSelectedIds((prev) => [...prev, id]);
            setMaterials((prevMaterials) =>
              prevMaterials.map((mat) => {
                if (mat.id === id) {
                  return { ...mat, config: newConfig };
                }
                return mat;
              })
            );
          })
          .catch((error) => {
            console.error(`Error selecting material ${id}:`, error);
          });
      }
    },
    [materials, selectedIds, selectedForManipulation]
  );

  /**
   * Deselects the material selected for manipulation when clicking outside.
   */
  const handleDeselectManipulation = useCallback((e) => {
    if (e.target === e.target.getStage()) {
      setSelectedForManipulation(null);
    }
  }, []);

  /**
   * Handles material configuration changes.
   * Updates the local state and persists the changes to the database.
   */
  const handleChange = useCallback(
    (newAttrs) => {
      setMaterials((prevMaterials) =>
        prevMaterials.map((mat) => (mat.id === newAttrs.id ? newAttrs : mat))
      );

      // Update the configuration in the database immediately
      updateMaterialConfiguration(newAttrs.id, { config: newAttrs.config }).catch((error) => {
        console.error(`Error updating material ${newAttrs.id}:`, error);
      });
    },
    []
  );

  /**
   * Brings selected materials forward in the z-index.
   * Updates both local state and persists changes to the database.
   */
  const handleBringForward = useCallback(() => {
    if (selectedIds.length === 0) return;
    const updatedMaterials = [...materials];

    selectedIds.forEach((id) => {
      const index = updatedMaterials.findIndex((mat) => mat.id === id);
      if (index < updatedMaterials.length - 1) {
        [updatedMaterials[index], updatedMaterials[index + 1]] = [
          updatedMaterials[index + 1],
          updatedMaterials[index],
        ];
        // Update zIndex accordingly
        updatedMaterials[index].config.zIndex = index;
        updatedMaterials[index + 1].config.zIndex = index + 1;
      }
    });

    setMaterials(updatedMaterials);

    // Update zIndex in the database
    updatedMaterials.forEach((mat) => {
      updateMaterialConfiguration(mat.id, { config: mat.config }).catch((error) => {
        console.error(`Error updating zIndex for material ${mat.id}:`, error);
      });
    });
  }, [materials, selectedIds]);

  /**
   * Sends selected materials backward in the z-index.
   * Updates both local state and persists changes to the database.
   */
  const handleSendBackward = useCallback(() => {
    if (selectedIds.length === 0) return;
    const updatedMaterials = [...materials];

    selectedIds.forEach((id) => {
      const index = updatedMaterials.findIndex((mat) => mat.id === id);
      if (index > 0) {
        [updatedMaterials[index], updatedMaterials[index - 1]] = [
          updatedMaterials[index - 1],
          updatedMaterials[index],
        ];
        // Update zIndex accordingly
        updatedMaterials[index].config.zIndex = index;
        updatedMaterials[index - 1].config.zIndex = index - 1;
      }
    });

    setMaterials(updatedMaterials);

    // Update zIndex in the database
    updatedMaterials.forEach((mat) => {
      updateMaterialConfiguration(mat.id, { config: mat.config }).catch((error) => {
        console.error(`Error updating zIndex for material ${mat.id}:`, error);
      });
    });
  }, [materials, selectedIds]);

  /**
   * Saves all configurations to the backend.
   * Updates zIndex based on the current order of materials.
   */
  const handleSave = useCallback(() => {
    const savePromises = materials.map((material, index) =>
      updateMaterialConfiguration(material.id, {
        config: {
          ...material.config,
          zIndex: index,
        },
        isSelected: selectedIds.includes(material.id),
      })
    );

    Promise.all(savePromises)
      .then(() => {
        alert('Configurations saved successfully!');
      })
      .catch((error) => {
        console.error('Error saving configurations:', error);
        alert('An error occurred while saving configurations.');
      });
  }, [materials, selectedIds]);

  /**
   * Deletes configurations of selected materials.
   * Removes them from the canvas without affecting the left panel.
   */
  const handleDelete = useCallback(() => {
    if (selectedIds.length === 0) return;

    const deletePromises = selectedIds.map((id) =>
      updateMaterialConfiguration(id, {
        config: {},
      })
    );

    Promise.all(deletePromises)
      .then(() => {
        setSelectedIds([]);
        setMaterials((prevMaterials) =>
          prevMaterials.map((mat) => {
            if (selectedIds.includes(mat.id)) {
              return { ...mat, config: {} };
            }
            return mat;
          })
        );

        // If any of the deleted materials were selected for manipulation, deselect them
        if (selectedIds.includes(selectedForManipulation)) {
          setSelectedForManipulation(null);
        }

        alert('Selected configurations deleted successfully!');
      })
      .catch((error) => {
        console.error('Error deleting configurations:', error);
        alert('An error occurred while deleting configurations.');
      });
  }, [selectedIds, selectedForManipulation]);

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Material Selection Panel */}
      <div className="lg:w-1/4 w-full bg-gray-100 p-4 overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">Select Materials</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {materials.map((material) => (
            <div
              key={material.id}
              className={`cursor-pointer border rounded p-2 flex flex-col items-center ${
                selectedIds.includes(material.id) ? 'border-blue-500' : 'border-transparent'
              }`}
              onClick={() => handleSelectFromPanel(material.id)}
            >
              <img
                src={material.image}
                alt={material.material_name}
                className="w-full h-20 object-cover mb-2"
              />
              <p className="text-center text-sm">{material.material_name}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Configurator Canvas */}
      <div className="lg:w-3/4 w-full p-4 flex flex-col">
        {/* Control Buttons */}
        <div className="mb-4 flex justify-end space-x-2">
          <button
            onClick={handleBringForward}
            disabled={selectedIds.length === 0}
            className={`px-4 py-2 rounded ${
              selectedIds.length > 0
                ? 'bg-blue-500 text-white hover:bg-blue-600'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Bring Forward
          </button>
          <button
            onClick={handleSendBackward}
            disabled={selectedIds.length === 0}
            className={`px-4 py-2 rounded ${
              selectedIds.length > 0
                ? 'bg-blue-500 text-white hover:bg-blue-600'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Send Backward
          </button>
          <button
            onClick={handleDelete}
            disabled={selectedIds.length === 0}
            className={`px-4 py-2 rounded ${
              selectedIds.length > 0
                ? 'bg-red-500 text-white hover:bg-red-600'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Delete Configuration
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 rounded bg-green-500 text-white hover:bg-green-600"
          >
            Save Configurations
          </button>
        </div>

        {/* Canvas Area */}
        <div className="border flex-grow">
          <Stage
            width={canvasWidth}
            height={canvasHeight}
            onMouseDown={handleDeselectManipulation}
            onTouchStart={handleDeselectManipulation}
            ref={stageRef}
          >
            <Layer>
              {/* Render only selected materials */}
              {materials
                .filter((mat) => selectedIds.includes(mat.id))
                .sort((a, b) => (a.config.zIndex || 0) - (b.config.zIndex || 0))
                .map((material) => (
                  <URLImage
                    key={material.id}
                    material={material}
                    canvasWidth={canvasWidth}
                    canvasHeight={canvasHeight}
                    isSelectedForManipulation={selectedForManipulation === material.id}
                    onSelectForManipulation={() => setSelectedForManipulation(material.id)}
                    onChange={handleChange}
                    ref={(node) => {
                      if (node) {
                        shapeRefs.current[material.id] = node;
                      }
                    }}
                  />
                ))}

              {/* Transformer for selected node */}
              <Transformer
                ref={transformerRef}
                rotateEnabled={true}
                enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                boundBoxFunc={(oldBox, newBox) => {
                  // Limit minimum size
                  if (newBox.width < 50 || newBox.height < 50) {
                    return oldBox;
                  }
                  return newBox;
                }}
                // Only allow transformer when a material is selected for manipulation
                visible={selectedForManipulation !== null}
              />
            </Layer>
          </Stage>
        </div>
      </div>
    </div>
  );
}

VirtualConfigurator.propTypes = {
  user: PropTypes.object,
};