import React from "react";
import { signOut } from 'firebase/auth';
import { useState, useMemo, useEffect } from "react";
import {addressURL} from '../utils/globalFunctions'
import { auth } from "../utils/firebase";
export const handleLogout = () => {
  signOut(auth).then(() => {
    localStorage.removeItem("user")
    window.location.replace("/");
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
};
export default function Logout() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleLogout();
    }
  }, [open]);

  return (
    <div className="w-full flex mt-2 mb-2 justify-center">
      <button
        className="bg-red-700 hover:bg-red-500 py-1 text-white text-[11px] font-light px-2 rounded"
        onClick={() => {
          setOpen(true);
        }}
      >
        Logout
      </button>
    </div>
  );
}
