import React, { useState, useEffect } from "react";
import {
  updateManufacturerVariation,
  fetchManufacturerProductVariations,
} from "../../utils/globalFunctions";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  Button,
  Card,
  Pagination,
  Spinner,
  TextInput,
} from "flowbite-react";

export default function ProductCustomizations({ user }) {
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const itemsPerPage = 5;

  // Fetch product variations on component mount
  useEffect(() => {
    fetchManufacturerProductVariations(user.manufacturerID).then((data) => {
      setProducts(data);
      setIsLoading(false);
    });
  }, [user.manufacturerID]);

  // Update displayed products when products, currentPage, or searchTerm changes
  useEffect(() => {
    const filteredProducts = products.filter((product) =>
      product.variation_title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedProducts = filteredProducts.slice(
      startIndex,
      startIndex + itemsPerPage
    );

    setDisplayedProducts(paginatedProducts);
  }, [products, currentPage, searchTerm]);

  // Update variation price
  const updateVariationPrice = (productIndex, variationIndex, newPrice) => {
    setProducts((currentProducts) => {
      const updatedProducts = [...currentProducts];
      const product = updatedProducts[productIndex];
      const variationItems = [...product.variationItems];
      const variation = { ...variationItems[variationIndex] };

      variation.variation_price = newPrice;
      variationItems[variationIndex] = variation;
      product.variationItems = variationItems;
      updatedProducts[productIndex] = product;

      return updatedProducts;
    });
  };

  // Update detail price
  const updateDetailPrice = (
    productIndex,
    variationIndex,
    detailIndex,
    detailItemIndex,
    newPrice
  ) => {
    setProducts((currentProducts) => {
      const updatedProducts = [...currentProducts];
      const product = updatedProducts[productIndex];
      const variationItems = [...product.variationItems];
      const variation = { ...variationItems[variationIndex] };
      const variationDetails = [...variation.variation_details];
      const variationDetail = { ...variationDetails[detailIndex] };
      const detailItems = [...variationDetail.variation_detail_items];
      const detailItem = { ...detailItems[detailItemIndex] };

      detailItem.variation_detail_price = newPrice;
      detailItems[detailItemIndex] = detailItem;
      variationDetail.variation_detail_items = detailItems;
      variationDetails[detailIndex] = variationDetail;
      variation.variation_details = variationDetails;
      variationItems[variationIndex] = variation;
      product.variationItems = variationItems;
      updatedProducts[productIndex] = product;

      return updatedProducts;
    });
  };

  // Handle save action
  const handleSave = () => {
    updateManufacturerVariation(products).then((res) => {
      if (res) {
        alert("Successfully updated");
      } else {
        alert("Error updating");
      }
    });
  };

  // Handle search input
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  // Calculate total pages
  const totalPages = Math.ceil(
    products.filter((product) =>
      product.variation_title.toLowerCase().includes(searchTerm.toLowerCase())
    ).length / itemsPerPage
  );

  return (
    <div className="container mx-auto p-4">
      {/* Header */}
      <div className="flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">Manufacturer Customizations</h1>
          <div className="flex mt-2">
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
              onClick={() => navigate("/products")}
            >
              Products
            </button>
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs ml-2"
              onClick={() => navigate("/customizations")}
            >
              Customizations
            </button>
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs ml-2"
              onClick={() => navigate("/create-product")}
            >
              Create Product
            </button>
          </div>
        </div>
      </div>

      {/* Search Bar */}
      <div className="flex justify-center mb-4 mt-8">
        <TextInput
          type="text"
          placeholder="Search Customizations"
          value={searchTerm}
          onChange={handleSearch}
          className="w-full max-w-md"
        />
      </div>

      {/* Save Button */}
      <div className="flex justify-end mb-4">
        <Button color="success" onClick={handleSave}>
          Save Changes
        </Button>
      </div>

      {/* Loading Spinner */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <Spinner size="xl" />
        </div>
      ) : (
        /* Product Customizations */
        <div className="grid grid-cols-1 gap-6">
          {displayedProducts.map((product, productIndex) => (
            <Card key={product.id} className="p-4">
              <h2 className="text-xl font-semibold mb-2">
                {product.variation_title}
              </h2>
              <Accordion collapseAll={true}>
                {product.variationItems.map((variationItem, variationIndex) => (
                  <Accordion.Panel key={variationItem.id}>
                    <Accordion.Title>
                      {variationItem.variation_name}
                    </Accordion.Title>
                    <Accordion.Content>
                      <div className="flex items-center mb-4">
                        <TextInput
                          type="number"
                          placeholder="Price"
                          value={variationItem.variation_price}
                          onChange={(e) =>
                            updateVariationPrice(
                              products.indexOf(product),
                              variationIndex,
                              e.target.value
                            )
                          }
                          className="w-32"
                          addon="$"
                        />
                      </div>
                      {/* Variation Details */}
                      {variationItem.variation_details.map(
                        (variationDetail, detailIndex) => (
                          <Accordion
                            key={variationDetail.id}
                            className="ml-4"
                            collapseAll={true}
                          >
                            <Accordion.Panel>
                              <Accordion.Title>
                                {variationDetail.variation_detail_title}
                              </Accordion.Title>
                              <Accordion.Content>
                                {variationDetail.variation_detail_items.map(
                                  (detailItem, detailItemIndex) => (
                                    <div
                                      key={detailItem.id}
                                      className="flex items-center mb-2"
                                    >
                                      <p className="text-sm flex-1">
                                        {detailItem.variation_detail_name}
                                      </p>
                                      <TextInput
                                        type="number"
                                        placeholder="Price"
                                        value={detailItem.variation_detail_price}
                                        onChange={(e) =>
                                          updateDetailPrice(
                                            products.indexOf(product),
                                            variationIndex,
                                            detailIndex,
                                            detailItemIndex,
                                            e.target.value
                                          )
                                        }
                                        className="w-28"
                                        addon="$"
                                      />
                                    </div>
                                  )
                                )}
                              </Accordion.Content>
                            </Accordion.Panel>
                          </Accordion>
                        )
                      )}
                    </Accordion.Content>
                  </Accordion.Panel>
                ))}
              </Accordion>
            </Card>
          ))}
        </div>
      )}

      {/* Pagination */}
      {!isLoading && totalPages > 1 && (
        <div className="flex justify-center mt-6">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  );
}