// userContext.js
import React, { createContext, useState, useContext,useEffect } from 'react';
import { auth, db } from './firebase';
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc,setDoc } from "firebase/firestore";

const UserContext = createContext();
const ManufacturerContext = createContext();

export const useUser = () => useContext(UserContext);
export const useManufacturer = () => useContext(ManufacturerContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);

    // useEffect(()=>{
    //     if(user){
    //         localStorage.setItem("user",JSON.stringify(user))
    //     }
    // },[user])


    return (
        <UserContext.Provider value={{ user, setUser, userLoading, setUserLoading}}>
            {children}
        </UserContext.Provider>
    );
};


export const ManufacturerProvider = ({ children }) => {
    const [manufacturer, setManufacturer] = useState(null);


    return (
        <ManufacturerContext.Provider value={{ manufacturer, setManufacturer}}>
            {children}
        </ManufacturerContext.Provider>
    );
};