import React from "react";
import {Hero} from "../LandingComponents/Hero";
import {PrimaryFeatures} from "../LandingComponents/PrimaryFeatures";
import {SecondaryFeatures} from "../LandingComponents/SecondaryFeatures";
import {CallToAction} from "../LandingComponents/CallToAction";
import {Reviews} from "../LandingComponents/Reviews";
import {Pricing} from "../LandingComponents/Pricing";
import {Faqs} from "../LandingComponents/Faqs";
import NavBar from "../LandingComponents/NavBar";
import { useNavigate } from "react-router-dom";

import { ArrowRightIcon, BoltIcon,CheckIcon,GlobeAltIcon,TruckIcon} from '@heroicons/react/24/solid';
import Footer from "../components/Footer";


export default function MainPage() {

  const navigate = useNavigate();
  return (
    <div className="bg-white text-gray-900">
      {/* Header */}
      <header className="fixed w-full bg-white shadow z-50">
        <nav className="container mx-auto px-6 py-4 flex items-center justify-between">
          <div className="flex items-center">
            {/* Logo */}
            <img
              src="/logoBlack.png"
              alt="Supply Circle Logo"
              className="h-10 w-auto mr-2"
              onClick={
                ()=>{
                  window.location.href = "/"
                }
              }
            />
            {/* <span className="text-2xl font-bold">Supply Circle</span> */}
          </div>
          <div className="hidden md:flex items-center space-x-6">
            <a href="#features" className="hover:text-gray-500">
              Features
            </a>
            <a 
              onClick={
                ()=>{
                  navigate("/about")
                }
              }
             className="hover:text-gray-500 cursor-pointer">
              About
            </a>
            <a href="#testimonials" className="hover:text-gray-500">
              Testimonials
            </a>
            <a

              onClick={
                ()=>{
                  navigate("/login")
                }
              }
              className="ml-4 px-4 py-2 bg-black text-white rounded hover:bg-gray-800 transition duration-300 cursor-pointer"
            >
             Login Account
            </a>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="sm:pt-24 pt-8 bg-gray-50 ">
        <div className="container mx-auto px-6 py-12 pb-36 flex flex-col-reverse lg:flex-row items-center">
          {/* Left Column */}
          <div className="w-full lg:w-1/2 mt-10 lg:mt-0">
            <h1 className="text-5xl font-bold leading-tight mb-6">
              Empowering <span className="text-black">Supply Chains</span> for the Modern World
            </h1>
            <p className="text-xl text-gray-700 mb-8">
              Seamlessly connect with manufacturers and streamline your procurement process with our cutting-edge B2B platform.
            </p>
            <a
            onClick={
              ()=>{
                navigate("/waitlist")
              }
            }
              className="inline-flex items-center px-6 py-3 bg-black text-white text-lg rounded hover:bg-gray-800 transition duration-300 cursor-pointer"
            >
              Get Started
              <ArrowRightIcon className="w-5 h-5 ml-2" />
            </a>
          </div>
          {/* Right Column */}
          <div className="w-full lg:w-1/2">
            <img
              src="/hero.svg"
              alt="Supply Chain Illustration"
              className="w-full"
            />
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="container mx-auto px-6 py-20">
        <h2 className="text-4xl font-bold text-center mb-12">
          Unmatched Features
        </h2>
        <div className="flex flex-wrap -mx-6">
          {/* Feature 1 */}
          <div className="w-full md:w-1/3 px-6 mb-12">
            <div className="bg-gray-100 rounded-lg shadow-md p-8 text-center">
              <BoltIcon className="w-16 h-16 mx-auto text-black mb-4" />
              <h3 className="text-2xl font-semibold mb-4">Interactive Estimations</h3>
              <p className="text-gray-700">
                Leverage our advanced tools to create precise estimates based on custom measurements and specifications.
              </p>
            </div>
          </div>
          {/* Feature 2 */}
          <div className="w-full md:w-1/3 px-6 mb-12">
            <div className="bg-gray-100 rounded-lg shadow-md p-8 text-center">
              <CheckIcon className="w-16 h-16 mx-auto text-black mb-4" />
              <h3 className="text-2xl font-semibold mb-4">Streamlined Ordering</h3>
              <p className="text-gray-700">
                Simplify your procurement process with seamless order management from start to finish.
              </p>
            </div>
          </div>
          {/* Feature 3 */}
          <div className="w-full md:w-1/3 px-6 mb-12">
            <div className="bg-gray-100 rounded-lg shadow-md p-8 text-center">
              <TruckIcon className="w-16 h-16 mx-auto text-black mb-4" />
              <h3 className="text-2xl font-semibold mb-4">In-House Delivery</h3>
              <p className="text-gray-700">
                Experience reliable, fast delivery managed by our dedicated logistics team, ensuring timely arrivals.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section id="about" className="bg-white">
        <div className="container mx-auto px-6 py-20 flex flex-col lg:flex-row items-center">
          {/* Left Column */}
          <div className="w-full lg:w-1/3">
            <img
              src="/delivery.svg"
              alt="About Us"
              className="w-full"
            />
          </div>
          {/* Right Column */}
          <div className="w-full lg:w-2/3 lg:pl-12 mt-10 lg:mt-0">
            <h2 className="text-4xl font-bold mb-6">Who We Are</h2>
            <p className="text-xl text-gray-700 mb-6">
              Supply Circle is revolutionizing the B2B supply chain by connecting sellers and contractors directly with manufacturers. Specializing in industries requiring custom estimates, we empower businesses to reduce manual work, minimize errors, and drive growth.
            </p>
            <a
              href="/about"
              className="inline-flex items-center px-6 py-3 bg-black text-white text-lg rounded hover:bg-gray-800 transition duration-300"
            >
              Learn More
              <ArrowRightIcon className="w-5 h-5 ml-2" />
            </a>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="bg-gray-50">
        <div className="container mx-auto px-6 py-20">
          <h2 className="text-4xl font-bold text-center mb-12">What Our Clients Say</h2>
          <div className="flex flex-wrap -mx-6">
            {/* Testimonial 1 */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-6 mb-12">
              <div className="bg-white rounded-lg shadow-md p-8">
                <p className="text-gray-700 mb-6">
                  "Supply Circle has dramatically improved our procurement process. The platform is intuitive and has saved us countless hours."
                </p>
                <div className="flex items-center">
                  <img
                    src="/man.svg"
                    alt="Client 1"
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <p className="font-semibold">Cansun S.</p>
                    <p className="text-gray-600 text-sm">Founder, Cachet Blinds</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Testimonial 2 */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-6 mb-12">
              <div className="bg-white rounded-lg shadow-md p-8">
                <p className="text-gray-700 mb-6">
                  "The interactive estimation tool is a game-changer. It allows us to provide clients with accurate quotes in real-time."
                </p>
                <div className="flex items-center">
                  <img
                    src="/women.svg"
                    alt="Client 2"
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <p className="font-semibold">Maria Rodriguez</p>
                    <p className="text-gray-600 text-sm">Owner, Ozzo Blinds</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Testimonial 3 */}
            <div className="w-full lg:w-1/3 px-6 mb-12">
              <div className="bg-white rounded-lg shadow-md p-8">
                <p className="text-gray-700 mb-6">
                  "Their in-house delivery service is reliable and efficient. We've seen a significant improvement in our project timelines."
                </p>
                <div className="flex items-center">
                  <img
                    src="/man.svg"
                    alt="Client 3"
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <p className="font-semibold">Michael Lee</p>
                    <p className="text-gray-600 text-sm">Contractor</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="bg-black">
        <div className="container mx-auto px-6 py-20 text-center">
          <h2 className="text-4xl font-bold text-white mb-6">
            Ready to Elevate Your Business?
          </h2>
          <p className="text-xl text-gray-300 mb-8">
            Join Supply Circle today and transform your supply chain management.
          </p>
          <a
     
            href="/waitlist"
            className="inline-flex items-center px-8 py-4 bg-white text-black text-lg rounded hover:bg-gray-200 transition duration-300"
          >
            Get Started Now
            <ArrowRightIcon className="w-5 h-5 ml-2" />
          </a>
        </div>
      </section>

      {/* Footer */}

      <Footer />
    </div>
  );

}

















