import React from 'react';

const WhiteLogo = ({width=100}) => {
    return (
        <div>
            <img src="./logoWhite.png" width={width} alt="Supply Cirlce" />
        </div>
    );
};


const BlackLogo = ({width=100}) => {
    return (
        <div>
            <img src="./logoBlack.png" width={width}  alt="Supply Circle" />
        </div>
    );
};


export { WhiteLogo, BlackLogo };