// src/pages/Route.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AdminDashboard from './admin';
import StoreDashboard from './store';
import TestDashboard from './superadmin';
import Login from './Login';
import Register from './Register';
import OnBoardingAdmin from './admin/onBoarding';
import OnBoarding from './OnBoarding';
import UpdatesComponent from '../components/UpdatesComponent';
import NotFound from './NotFound';
import DashboardRedirect from './DashboardRedirect'; // Import the component
import { useUser,useManufacturer } from '../utils/context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loadingGif from '../assets/Loader.gif';
import MainPage from './MainPage';
import CancelBooking from '../extensions/calendarBooking/CancelBooking';
import BookingView from '../extensions/calendarBooking/BookAppointment';
import SignOut from './admin/signOut';
import RegisterAdmin from './admin/onBoarding';
import OnBoardingStore from './OnBoarding';
import { useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../utils/firebase';
import { useState } from 'react'
import TourGuide from '../components/TourGuide';
import WholesalePortal from './WholesalePortal';
import BuyerDashboard from './WholesaleBuyer';
import WaitlistPage from './WaitlistPage';
import ContactUsPage from './ContactUs';
import AboutUsPage from './AboutUs';

const RouteWrapper = ({ user, children, requiredRole }) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (requiredRole && user.role !== requiredRole) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default function RouteComponent() {
  const { user, userLoading,setUser } = useUser();
  const {setManufacturer} = useManufacturer()
  const [localUserUpdate,setLocalUserUpdate] = useState(null)





  useEffect(()=>{
      if(user){
        const userDocRef = doc(db, "users", user.id); // Replace with your actual user document ID
        const unsubscribe =  onSnapshot(userDocRef, (doc) => {
  
          if (doc.exists()) {
            const userData = doc.data();
  
            setUser(
              {
                ...user,
                ...userData
              }
            )
            setLocalUserUpdate(userData)
  
          } else {
            console.log("User document does not exist");
          }
        });
        return () => unsubscribe();
      }
    
    
    },[])
    
  
    
    useEffect(()=>{
      if(localUserUpdate?.store_id){
        const storeDocRef = doc(db, "Stores", localUserUpdate.store_id); // Replace with your actual store document ID
        const unsubscribe = onSnapshot(storeDocRef, (doc) => {
          console.log(doc.data())
          if (doc.exists()) {
            const storeData = doc.data();
            console.log("Store document data:", storeData);
            setUser(
              {
                ...user,
                ...storeData
              }
            )
          } else {
            console.log("Store document does not exist");
          }
          return () => unsubscribe();
        });
    
      }
    },[localUserUpdate])
  
    useEffect(()=>{
      if(localUserUpdate?.manufacturerID){
        const storeDocRef = doc(db, "Manufacturers", localUserUpdate?.manufacturerID); // Replace with your actual store document ID
        const unsubscribe = onSnapshot(storeDocRef, (doc) => {
          console.log(doc.data())
          if (doc.exists()) {
            const storeData = doc.data();
            console.log("Store document data:", storeData);
            setManufacturer(storeData)
          } else {
            console.log("Store document does not exist");
          }
          return () => unsubscribe();
        });
    
      }
    },[localUserUpdate])

  if (userLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen w-full">
        <div className="flex flex-col items-center justify-center">
          <img src={loadingGif} alt="loading" className="success-img" />
        </div>
      </div>
    );
  }



  return (
    <>
      <Router>
        {/* <ToastContainer /> */}
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register/:id" element={<Register />} />
          <Route path="/register-admin/:manufacturerID" element={<RegisterAdmin />} />
          <Route path="/sign-out" element={<SignOut />} />
          <Route path="/calendar/:slug/:id" element={<BookingView />} />
          <Route path="/cancel-booking/:slug/:slugId" element={<CancelBooking />} />
          <Route path="/wholesale/my-orders" element={<BuyerDashboard />} />
          <Route path="/wholesale/:slug" element={<WholesalePortal />} />
          <Route path="/waitlist" element={<WaitlistPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/about" element={<AboutUsPage />} />

          {
             user && user?.role === 'store' && !user?.store_id &&
              <Route path="/" element={<OnBoarding user={user} />} />
          }


          {/* Root Route: Dashboard Redirect */}
          {
            !user && !userLoading &&
            <Route
            path="/"
            element={
              <MainPage />
            }
          />
          }

          {/* Root Route: Dashboard Redirect */}
          {
            user && 
            <>
            
        
            <Route
            path="/*"
            element={
              <RouteWrapper user={user}>
                <DashboardRedirect /> {/* Redirect based on role */}
              </RouteWrapper>
            }
          />
            </> 


          
          }







          {/* Catch-All Route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}