import React, { useState,useEffect } from 'react';
import ChatWindow from '../../components/ChatWindow';
import {fetchStore} from '../../utils/globalFunctions'


const Store = ({user}) => {
    const [store,setStore] = useState(null)
    useEffect(()=>{
        fetchStore(user).then((data)=>{
            setStore(data)
        })
    },[user])
    console.log(user)
    return (
        <div>
            {store?.storeName}
        </div>
    );
};

const Chats = ({manufacturer,user}) => {
  const [selectedUser, setSelectedUser] = useState(null);


  useEffect(()=>{
    if(manufacturer.subscribers.length > 0)
        setSelectedUser(manufacturer.subscribers[0])
  },[])
    

  return (
    <div className="h-screen lg:flex">
      <div className="w-full lg:w-1/4 lg:border-r border-gray-300 p-4">
        <h2 className="text-lg font-bold mb-4">Select a Store</h2>
        <ul className="flex lg:flex-col overflow-x-auto space-x-4 lg:space-x-0 lg:space-y-4">
          {manufacturer.subscribers.map((user) => (
            <li
              key={user}
              className={`p-2  h-10 cursor-pointer rounded whitespace-nowrap ${selectedUser === user ? 'bg-indigo-500 text-white' : 'bg-gray-100'}`}
              onClick={() => setSelectedUser(user)}
            >
              <Store user={user} />
            </li>
          ))}
        </ul>
      </div>
      <div className="w-full lg:w-3/4 p-4">
        <ChatWindow  storeID={selectedUser} manufacturerID={user.manufacturerID} user={user} />
      </div>
    </div>
  );
};

export default Chats;