import React from 'react';
import ImageUploader from '../../components/ImageUploader';
import { useState } from 'react';
import {createManufacturer, globalAlert, globalSuccess} from '../../utils/globalFunctions';
const CreateManufacturer = () => {
    const [photoUrl, setPhotoUrl] = useState('');
    const [manufacturer_name, setManufacturerName] = useState('');
    const [manufacturer_id, setManufacturerID] = useState('');
    const [metricSystem, setMetricSystem] = useState('cm');

    // Your code here

    console.log(photoUrl);


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(photoUrl, manufacturer_name, manufacturer_id);

        if(!photoUrl || !manufacturer_name || !manufacturer_id){    
            globalAlert('Please fill all fields');
            return;
        }
        
        let data = {
            displayName: manufacturer_name,
            logo: photoUrl,
            metricSystem: metricSystem ,
            id: manufacturer_id
        }

        createManufacturer(data).then((res) => {
            console.log(res);

            setPhotoUrl('');
            setManufacturerName('');
            setManufacturerID('');
            setMetricSystem('cm');

        }).catch((err) => {
            console.log(err);
        })





    }

    return (
        <div  > 
            <ImageUploader setDownloadURL={setPhotoUrl} downloadURL={photoUrl} />
            <div className='flex justify-center'>
                <div className='flex flex-col'>
                <input
                    type="text"
                    placeholder="Manufacturer Display Name"
                    className='mt-2 w-full'
                    value={manufacturer_name}
                    onChange={(e) => setManufacturerName(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Manufacturer Business Number" // cant be changed later.

                    className='mt-2 w-full'
                    value={manufacturer_id}
                    onChange={(e) => setManufacturerID(e.target.value)}
                />
                <select
                    className='mt-2 w-full'
                    value={metricSystem}
                    onChange={(e) => setMetricSystem(e.target.value)}
                >
                    <option value="cm">cm</option>
                    <option value="inch">inch</option>
                </select>
                <button

                className='mt-2 bg-blue-500 text-white p-2 rounded'
                onClick={handleSubmit}
                
                
                >Submit</button>
                </div>


            </div>




     
        </div>
    );
};

export default CreateManufacturer;