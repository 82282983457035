import React,{useEffect,useState} from 'react'
import { addressURL, globalAlert, globalSuccess } from '../../utils/globalFunctions';
import axios from 'axios'
export default function DealerCatalog() {

  const [catalog,setCatalog] = useState([]);
  const [noCatalogFound,setNoCatalogFound] = useState(false)
  const [loading,setLoading] = useState(true)
  const [value1,setValue1] = useState(0)
  const [value2,setValue2] = useState(0)
  const [op1,setOp1] = useState('+')
  const [op2,setOp2] = useState('+')
  const [trigger,setTrigger] = useState(false)
  const [trigger2,setTrigger2] = useState(false)
  const [dealerPrice, setDealerPrice] = useState(0)
  const [dealerExtras, setDealerExtras] = useState({})

  console.log(catalog)
  useEffect(() => {

    axios
      .get(addressURL + `/api/dealer/catalog`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        withCredentials: true,
      })
      .then((res) => {
        // setProducts(res.data.result);
        if(res.data.success){
            console.log(res.data?.catalog)
            //trigger rerender
            setCatalog(res.data?.catalog)
            
        }else{
            if(res.data?.message === "No catalog found"){
                console.log("No catalog found")
                setNoCatalogFound(true)
            }else{
                globalAlert("Something is wrong, please contact with developer. Code:#7327")
            }
        }
        setLoading(false)
      })
      .catch((err) => console.log(err));
  }, [trigger]);

  useEffect(() => {

    axios
      .get(addressURL + `/api/dealer/getDealerExtras`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        withCredentials: true,
      })
      .then((res) => {
        if(res.data.success){
            console.log(res.data?.data)
            //trigger rerenderres.data?.data[0].price
            if(res.data?.data.length === 0){
              setDealerExtras(
                {
                  motorized:0,
                  squared:0,
                  covered:0
                }
              )
            }else{
              setDealerExtras(res.data?.data[0]?.rule)
            }
            
        }else{
          globalAlert("Something is wrong, please contact with developer. Code:#7329")
        }
        setLoading(false)
      })
      .catch((err) => globalAlert("Something is wrong, please contact with developer. Code:#7330"));
  }, [trigger2]);

  const handleApply = () => {
    // build an equation string
    if((op1 === '*' && value1 === 0) || (op2 === '*' && value2 === 0) ||  (op2 === '*' && value1 === 0)  )
      return globalAlert(
        "You can't multiply by 0"
      )
    if((op1 === '/' && value1 === 0) || (op2 === '/' && value2 === 0) || ( op2 === '/' && value1 === 0)  )
      return globalAlert(
        "You can't divide by 0"
      )

    let equation = 'manufacturer_price' + op1 + value1 + op2 + value2
    console.log(equation)
    // send to backend
    axios
        .post(addressURL +
            `/api/dealer/applyPricing`, {
                equation: equation
            }, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                },
                withCredentials: true,
            })
            .then((res) => {
                if(res.data.success){
                    globalSuccess(res.data.message)
                    setTrigger(!trigger)
                }else{
                    globalAlert(res.data.message)
                }
            }
        )



}

  function handleRemove(id){
    console.log(id)
    axios
      .post(addressURL +
        `/api/dealer/removeFromCatalog`, {
            product_id: id
        }, {
        headers: {

            "Content-Type": "application/json",
            Accept: "application/json",
            },
            withCredentials: true,
        })
        .then((res) => {
            if(res.data.success){
                globalSuccess(res.data.message)
                setTrigger(!trigger)
            }else{
                globalAlert(res.data.message)
            }
        })
        .catch((err) => console.log(err));
  }



  function handleUpdate(id,val){

    if(val === 0){
      return
    }
   

    axios
      .post(addressURL +
        `/api/dealer/updateIndividualPrice`, {
            product_id: id,
            dealer_price: val,
        }, {
        headers: {

            "Content-Type": "application/json",
            Accept: "application/json",
            },
            withCredentials: true,
        })
        .then((res) => {
            if(res.data.success){
                globalSuccess(res.data.message)
                setTrigger(!trigger)
            }else{
                globalAlert(res.data.message)
            }
        })
        .catch((err) => console.log(err));
  }


  function handleUpdateExtras(){



    axios
      .post(addressURL +
        `/api/dealer/updateDealerExtras`, {
            dealerExtras: {
              motorized: dealerExtras.motorized?.length > 0 ? dealerExtras.motorized : "0",
              squared: dealerExtras.squared?.length > 0  ? dealerExtras.squared : "0",
              covered: dealerExtras.covered?.length > 0 ? dealerExtras.covered : "0",
            },
        }, {
        headers: {

            "Content-Type": "application/json",
            Accept: "application/json",
            },
            withCredentials: true,
        })
        .then((res) => {
            if(res.data.success){
                globalSuccess(res.data.message)
                setTrigger2(!trigger2)
            }else{
                globalAlert(res.data.message)
            }
        })
        .catch((err) => console.log(err));
  }
  if(noCatalogFound && !loading)
    return (
        <div>
            <div className="container m-auto">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center text-lg font-bold">Dealer Catalog</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="text-center">
                        <h3 className="text-sm mt-8">No catalog found</h3>
                        <button 
                            className="bg-gray-600 text-white p-2 mt-2 rounded"
                            onClick={() => {
                                window.location.href = "/dealers/catalog/add"
                            }}
                        >
                            Add Catalog
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )



    if(!loading)
        return (
            <div>
                <div className="row">
                    <div className="text-center">
                        <button 
                            className="bg-gray-600 text-white p-2 mt-2 rounded"
                            onClick={() => {
                                window.location.href = "/dealers/catalog/add"
                            }}
                        >
                            Discover Products
                        </button>
                    </div>
                </div>

        <div> 
          <div>
            
            <div className='md:ml-4 p-4  shadow-lg md:w-2/3 rounded'>
            <p className='font-bold text-base'>Pricing Logic</p>
                <div><a className='md:text-xl sm:font-bold  text-sm rounded p-2 border-2'> Dealer Price</a> <a className='md:text-xl sm:font-bold  text-sm rounded p-2 border-2'>=</a><a className='md:text-xl sm:font-bold  text-sm rounded p-2 border-2 ml-1'> Manufacturer Price</a>
                    <select onChange={(e)=>{
                      console.log(e.target.value)
                        setOp1(e.target.value)
                    }} className='ml-2 rounded'>
                    <option value={'+'}>+</option>
                    <option value={'-'} >-</option>
                    <option value={'*'}>*</option>
                    <option value={'/'}>/</option>
                </select> <input className='w-24 ml-1 rounded' onChange={
                    (e) => {
                        setValue1(e.target.value)
                    }
                } type='number' placeholder='0' /> 
                <select onChange={
                    (e) => {
                        setOp2(e.target.value)
                    }
                } className='ml-2 rounded'>
                    <option value={'+'}>+</option>
                    <option value={'-'} >-</option>
                    <option value={'*'}>*</option>
                    <option value={'/'}>/</option>
                </select> 
                <input className='w-24 ml-2 rounded' onChange={
                    (e) => {
                        setValue2(e.target.value)
                    }
                } type='number' placeholder='0' />
                <button onClick={handleApply} className='bg-gray-600 text-white p-2 mt-2 ml-2 rounded'>Apply</button>
                </div>
           
                


            </div>
          </div>



                            
        </div>   
        <div className='mt-8 ml-8 text-sm font-bold text-gray-600'>
          <p>
            Note: You can edit individual fabric's dealer price
          </p>
        </div>
        <div className='mt-8 ml-8 text-sm font-bold text-gray-600'>
          <p>
            Set Motorized Price
          </p>
          <div>
            <input 
            value={

              dealerExtras.motorized
            }
            onChange={(e) => {
              setDealerExtras(
                  {
                      ...dealerExtras,
                      motorized: e.target.value
                  }
                  
              )
          }}className='border-2 rounded p-2' type='number' placeholder='0' />
            <button onClick={handleUpdateExtras} className='bg-gray-600 text-white p-2 mt-2 ml-2 rounded'>Apply</button>
          </div>
          <p className='mt-2'>
            Set Squared Cassette Extra
          </p>
          <div>
            <input 
              value={
                dealerExtras.squared
              }
              onChange={(e) => {
                setDealerExtras(
                    {
                        ...dealerExtras,
                        squared: e.target.value
                    }
                    
                )
            }} className='border-2 rounded p-2' type='number' placeholder='0' />
            <button onClick={handleUpdateExtras} className='bg-gray-600 text-white p-2 mt-2 ml-2 rounded'>Apply</button>
          </div>
          <p className='mt-2'>
            Set Covered Cassette Extra
          </p>
          <div>
            <input  
              value={
                dealerExtras.covered
              }
            
            onChange={(e) => {
                setDealerExtras(
                    {
                        ...dealerExtras,
                        covered: e.target.value
                    }
                    
                )
            }} className='border-2 rounded p-2' type='number' placeholder='0' min={0} />
            <button onClick={handleUpdateExtras} className='bg-gray-600 text-white p-2 mt-2 ml-2 rounded'>Apply</button>
          </div>

        </div>
        
        <div className="px-4 sm:px-6 lg:px-8">     
        <div className=" flex flex-col">
          <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table  className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr className="divide-x divide-gray-200">
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Fabric Name
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Design
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Manufacturer Price
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                      >
                        Dealer Price
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {catalog.length > 0 &&
                      catalog.map((data,index) => (
                        <tr 
                          key={data.id} 
                          className="divide-x divide-gray-200"
                          >
                          <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                            <input
                              type="text"
                              
                              value={data.fabric_name}
                              className="text-sm sm:w-11/12 text-gray-500 border-none focus:ring-0 focus:border-none focus:outline-none"
                            />
                          </td>

                          <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                            <input
                              type="text"
                              
                              value={data.design_type}
                              className="text-sm sm:w-11/12 text-gray-500 border-none focus:ring-0 focus:border-none focus:outline-none"
                            />
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                            <input
                              type="text"
                              
                              value={data.manufacturer_price}
            
                              className="text-sm  sm:w-11/12 text-gray-500 border-none focus:ring-0 focus:border-none focus:outline-none"
                            />
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                            <input
                              type="text"
                              onChange={(e)=>{
                                setDealerPrice(e.target.value)
                                setCatalog(
                                  catalog.map((value)=>{
                                    if(value.product_id === data.product_id){
                                      value.dealer_price = e.target.value
                                    }
                                    return value
                                  })
                                )                             
                              }}
                              value={data.dealer_price}
                              
                             
                              onKeyDown={async (e) => {
                                if (e.key === "Enter") {
                                  handleUpdate(data.product_id,dealerPrice)
                                }
                              }}
                              
            
                              className="text-sm  sm:w-11/12 text-gray-500 border-none focus:ring-0 focus:border-none focus:outline-none"
                            />
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                            <button
                              onClick={() => handleUpdate(data.product_id,dealerPrice)}
                              className="text-sm text-gray-500 border-none focus:ring-0 focus:border-none focus:outline-none"
                            >
                              Update
                            </button>
                            <button
                              onClick={() => handleRemove(data.product_id)}
                              className="text-sm text-gray-500 border-none focus:ring-0 focus:border-none focus:outline-none ml-2"
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </div>
            </div>
        )
    
    return (
        <div>
            <div className="container m-auto">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center text-lg font-bold">Dealer Catalog</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="text-center">
                        <h3 className="text-sm mt-8">Loading...</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}
