import React, { useEffect, useState } from "react";
import { addressURL } from "../../utils/globalFunctions";
import axios from "axios";
import { useNavigate } from "react-router-dom";


export default function Details(props) {
  const [sellers, setSellers] = useState([]);

  const navigate = useNavigate(); 
  useEffect(() => {
    if (props.user.role === "admin") {
      //get all the sellers except admin.
      axios
        .get(addressURL + "/api/getAllSellers", {
          // NOT MANUFACTURERS
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        })
        .then((res) => {
          setSellers(res.data.sellers);
        })
        .catch((err) => console.log(err));
    }
  }, [props.user]);

  return (
    <div className="text-center m-4 sm:w-3/4 sm:m-auto">
    <h1 className="text-center text-lg text-gray-500 font-bold mb-8">
        You can click on the dealer name to see detailed page for your dealer
    </h1>

    <div className="grid grid-cols-3 gap-10">
        {sellers.length > 0 && sellers.map((seller, index) => {
            return (
                <div key={index} onClick={()=>{
                    navigate(`/dealers/${seller.storename}`)
                }} className="bg-gray-500 cursor-pointer p-4 rounded flex justify-center items-center">
                    <p className="text-white font-bold text-sm">{seller.storename}</p>
                </div>
            );
        })}
    </div>
</div>
  );
}

