import React, { useState } from "react";
import { globalAlert, globalSuccess } from "../utils/globalFunctions";
import axios from "axios";
import { addressURL } from "../utils/globalFunctions";
import { Button } from "flowbite-react";

function checkOrderCompatibility(order, issues, index) {
  const features = ["motor", "spring", "wand"];




  // Check for Vertical Sheer specific conditions
  if (order.design_type === "Vertical Sheer") {
    const verticalSheerIssues = features
      .filter((feature) => order[feature])
      .map((feature) => `${feature} set to Yes`);
    if (verticalSheerIssues.length > 0) {
      issues.push(
        `Item #${index + 1} cannot have ${verticalSheerIssues.join(
          ", "
        )} for vertical sheer`
      );
    }
  } else {
    // Check for conflicts between features
    for (let i = 0; i < features.length; i++) {
      if (order[features[i]]) {
        for (let j = i + 1; j < features.length; j++) {
          if (order[features[j]]) {
            issues.push(
              `Item #${index + 1} cannot have both ${features[i]} and ${
                features[j]
              } set to Yes`
            );
          }
        }
      }
    }
    // Check for chain location
    if (
      order.chainLocation !== "no" &&
      features.some((feature) => order[feature])
    ) {
      issues.push(
        `Item #${index + 1} cannot have ${features
          .filter((feature) => order[feature])
          .join(" or ")} set to Yes while chain location is set`
      );
    }
  }
}

export default function Orders({
  orders,
  getOrderWithGroupByCustomerID,
  products,
  handleDeleteOrder,
  userRole,
}) {
  console.log(orders);
  const [allIssues, setAllIssues] = useState([]);
  const [rerender,setrerender] = useState(0)


  
  const updateOrder = () => {
    //check the orders and find if there is an issue
    let issues = [];
    orders.forEach((order, index) => {
      if (order.design_type === "Vertical Sheer") {
        order.barType = null;
        order.covering = null;
        order.motor = 0;
        order.motor_type = null;
        order.spring = 0;
        order.wand = 0;

        if(order.chainLocation === "no" || order.chainLocation === "" )
          issues.push(`Item #${index + 1} chain location is not set`);

        // if(order.motor || order.spring || order.wand)
        //   issues.push(`Item #${index + 1} cannot have motor, spring or wand set to Yes for vertical sheer`);
      } else if (order.design_type === "Roller Shade") {
        order.barType = null;
        if(!order.covering)
          issues.push(`Item #${index + 1} hedrail clothing is not set`);
      } else {
        if (order.barType === null || order.barType === "")
          issues.push(`Item #${index + 1} bar type is not set`);
        if (order.covering === null || order.covering === "")
          issues.push(`Item #${index + 1} hedrail clothing is not set`);
        
      }
      console.log(order.motor_type)

      if(order.motor && !order.motor_type )
        issues.push(`Item #${index + 1} motor type is not set`);
      if (
        !order.motor &&
        !order.spring &&
        !order.wand &&
        order.chainLocation === "no"
      )
        issues.push(
          `Item #${
            index + 1
          } must have at least one of motor, spring, wand or chain location set to Yes`
        );

      if (order.width === null || order.width === "")
        issues.push(`Item #${index + 1} width is not set`);

      if (order.height === null || order.height === "")
        issues.push(`Item #${index + 1} height is not set`);

      checkOrderCompatibility(order, issues, index);
      console.log(issues);
    });

    //if there is an issue, alert the user
    if (issues.length > 0) {
      setAllIssues(issues);
      return;
    } else {
      setAllIssues([]);
      axios
        .post(
          addressURL + "/api/update-order",
          {
            customerID: orders[0].customerID,
            orders: orders,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.success) {
            globalSuccess("Succesfully updated the order");
            getOrderWithGroupByCustomerID();
          }
        })
        .catch((err) =>
          globalAlert(
            "There was an issue with updating orders, please try again. If issue persist, contact admin please"
          )
        );
    }
  };


  const processOrder = async () => {

    axios
      .post(
        addressURL + "/api/updateOrderStatus",
        {
          customerID: orders[0].customerID,
          status: "Processing",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          globalSuccess("Succesfully processed the order");
          getOrderWithGroupByCustomerID();
        }
      })
      .catch((err) =>
        globalAlert(
          "There was an issue with processing orders, please try again. If issue persist, contact admin please"
        )
      );
  };
  const completeOrder = async () => {
    axios
      .post(
        addressURL + "/api/updateOrderStatus",
        {
          customerID: orders[0].customerID,
          status: "Completed",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          globalSuccess("Succesfully completed the order");
          getOrderWithGroupByCustomerID();
        }
      })
      .catch((err) =>
        globalAlert(
          "There was an issue with completing orders, please try again. If issue persist, contact admin please"
        )
      );
  };

  const checkDisabled = () => {
    if (orders.length === 0) return true;
   
    let started = 0
    if(userRole === "Admin") return false;

    orders.forEach((order)=>{
      if(order.status === "Started" && userRole === "Store") started++;
    })
    console.log(started)
    if(started === 0) return true;
    return false;
  }
  return (
    <div className="">
      <div>
        <div className="flex mt-2">

          <Button  disabled={checkDisabled()} className="mr-1" onClick={() => updateOrder()}>
            Update Order
          </Button>
          {
            userRole === "Store" &&
            <Button className="mr-1" onClick={() => processOrder()}>
            Process Order
          </Button>
          }
          {
            userRole === "Admin" &&
            <Button onClick={() => completeOrder()}>Complete Order</Button>
          }
      
        </div>

        {allIssues.length > 0 && (
          <div
            className="bg-red-100 mt-4 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">
              There are issues with update order
            </strong>
            <ul>
              {allIssues.map((issue, index) => {
                return <li key={index}>{issue}</li>;
              })}
            </ul>
          </div>
        )}
      </div>
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-200">
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      No
                    </th>

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Fabric name
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Room name
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Width
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Height
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Motorized
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Motor Brand
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Spring
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      M.Wand
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Chain Placement
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Bar Type
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Headrail clothing
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Price
                    </th>

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Status
                    </th>
                    {userRole === "Admin" && (
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                      >
                        Rule
                      </th>
                    )}

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                    ></th>
                  </tr>
                </thead>
                {orders.length > 0 &&
                  orders?.map((order, index) => {
                    return (
                      <>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          <tr className="divide-x divide-gray-200">
                            <td className="px-4 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {index + 1}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <select
                                  defaultValue={order.design_type+":"+order.fabric_name}
                                  disabled={order.status !== "Started" && userRole === "Store"}
                                  onChange={async (e) => {
                     
                                    let design = e.target.value.split(":")[0]
                                    let fabric = e.target.value.split(":")[1]

                                    order.fabric_name = fabric
                                    order.design_type = design
                                    setrerender(!rerender)


                                  }}
                                >
                                  {products.map((val, index) => {
                                    let v = val.design_type + ":" + val.fabric_name
                                    return (
                                      <option
                                        key={index}
                                        value={v}
                                      >
                                        {val.fabric_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                              <input
                                className="shadow-sm  rounded-md appearance-none  border-gray-300 py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                                id="width"
                                type="text"
                                disabled={order.status !== "Started" && userRole === "Store"}
                                defaultValue={order.room_name}
                                onChange={(e) => {
                                  // check if value is in correct form
                         
                                  if (e.target.value !== order.room_name)
                                    order.room_name = e.target.value;
                                  else return;
                                }}
                                placeholder="Room Name"
                              />
                            </td>
                            <td className="px-4 py-4 ">
                              <div>
                                <input
                                  className="shadow-sm  rounded-md appearance-none  border-gray-300 w-24 py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                                  id="width"
                                  type="text"
                                  defaultValue={order.width}
                                  disabled={order.status !== "Started" && userRole === "Store"}
                                  onChange={(e) => {
                        
                                    // check if value is in correct form
                                    if (
                                      e.target.value.match(/^[0-9]*\.?[0-9]*$/)
                                    ) {
                                      if (e.target.value !== order.width)
                                        order.width = e.target.value;
                                      else return;
                                    } else {
                                      e.target.value = order.width;
                                      return globalAlert(
                                        "Number format is not correct"
                                      );
                                    }
                                  }}
                                  placeholder="Width"
                                />
                              </div>
                            </td>
                            <td className="px-4 py-4">
                              <div>
                                <input
                                  className="shadow-sm  rounded-md appearance-none  border-gray-300 w-24 py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                                  id="height"
                                  type="text"
                                  autoComplete="off"
                                  disabled={order.status !== "Started" && userRole === "Store"}
                                  defaultValue={order.height}
                                  onChange={(e) => {
                                    // check if value is in correct form
                                    if (
                                      e.target.value.match(/^[0-9]*\.?[0-9]*$/)
                                    ) {
                                      if (e.target.value !== order.height)
                                        order.height = e.target.value;
                                      else return;
                                    } else {
                                      e.target.value = order.height;
                                      return globalAlert(
                                        "Number format is not correct"
                                      );
                                    }
                                  }}
                                  placeholder="Height"
                                />
                              </div>
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                              <input
                                className="shadow-sm  rounded-md appearance-none  border-gray-300 w-24 py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                                id="quantity"
                                type="text"
                                disabled={order.status !== "Started" && userRole === "Store"}
                                autoComplete="off"
                                defaultValue={order.quantity}
                                onChange={(e) => {
                                  // check if value is in correct form
                                  if (e.target.value.match(/^[0-9]*$/)) {
                                    order.quantity = e.target.value;
                                  } else {
                                    e.target.value = order.quantity;
                                    return globalAlert(
                                      "You can only input numbers"
                                    );
                                  }
                                }}
                                placeholder="Quantity"
                              />
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                            {order.design_type !== "Vertical Sheer" &&
                              <select
                                className="shadow-sm  rounded-md appearance-none  border-gray-300 w-24 py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                                defaultValue={order.motor}
                                disabled={order.status !== "Started" && userRole === "Store"}
                                onChange={async (e) => {
                                  if (e.target.value !== order.motor) {
                                    order.motor = parseInt(e.target.value);
                                    console.log(e.target.value)
                                    setrerender(!rerender)
                                    if(order.motor === 0){
                                      order.motor_type = null;
                                    }
                                  } else return;
                                }}
                              >
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                              </select>}
                            </td>

                            <td className="px-4 py-4 whitespace-nowrap">
                            {order.design_type !== "Vertical Sheer" &&
                              order.motor !== 0 && <select
                                className="shadow-sm  rounded-md appearance-none  border-gray-300 w-24 py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                                defaultValue={order.motor_type}
                                disabled={order.status !== "Started" && userRole === "Store"}
                                onChange={async (e) => {
                                  if (e.target.value !== order.motor.type) {
                                    order.motor_type = e.target.value
                                  } else return;
                                }}
                              >
                                <option value="">Please select a motor</option>
                                <option value="novo">Novo</option>
                                <option value="louvolite">Louvolite</option>
                              </select>}
                            </td>

                            <td className="px-4 py-4 whitespace-nowrap">
                            {order.design_type !== "Vertical Sheer" &&
                              <select
                                className="shadow-sm  rounded-md appearance-none  border-gray-300 w-24 py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                                defaultValue={order.spring}
                                disabled={order.status !== "Started" && userRole === "Store"}
                                onChange={async (e) => {
                                  if (e.target.value !== order.spring) {
                                    order.spring = parseInt(e.target.value);
                                  } else return;
                                }}
                              >
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                              </select>
                            }
                            </td>

                            <td className="px-4 py-4 whitespace-nowrap">
                            {order.design_type !== "Vertical Sheer" &&
                              <select
                                className="shadow-sm  rounded-md appearance-none  border-gray-300 w-24 py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                                defaultValue={order.wand}
                                disabled={order.status !== "Started" && userRole === "Store"}
                                onChange={async (e) => {
                                  if (e.target.value !== order.wand) {
                                    order.wand = parseInt(e.target.value);
                                  } else return;
                                }}
                              >
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                              </select>
                  }
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                              {order.chainLocation && (
                                <select
                                  className="shadow-sm  rounded-md appearance-none  border-gray-300 w-24 py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                                  defaultValue={order.chainLocation}
                                  disabled={order.status !== "Started" && userRole === "Store"}
                                  onChange={async (e) => {
                                    if (
                                      e.target.value !== order.chainLocation
                                    ) {
                                      order.chainLocation = e.target.value;
                                    } else return;
                                  }}
                                >
                                  <option value={"right"}>Right</option>
                                  <option value={"left"}>Left</option>
                                  <option value={"no"}>No Chain</option>
                                </select>
                              )}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                              {order.design_type !== "Vertical Sheer" &&
                                order.design_type !== "Roller Shade" && (
                                  <select
                                    className="shadow-sm  rounded-md appearance-none  border-gray-300 py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                                    defaultValue={order.barType}
                                    disabled={order.status !== "Started" && userRole === "Store"}
                                    onChange={async (e) => {
                                      if (e.target.value !== order.barType) {
                                        order.barType = e.target.value;
                                      } else return;
                                    }}
                                  >
                                    <option value={null}>
                                      Please select bar type
                                    </option>
                                    <option value={"open"}>Open</option>
                                    <option value={"closed"}>Closed</option>
                                  </select>
                                )}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                              {order.design_type !== "Vertical Sheer" && (
                                <select
                                  className="shadow-sm  rounded-md appearance-none  border-gray-300  py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                                  defaultValue={order.covering}
                                  disabled={order.status !== "Started" && userRole === "Store"}
                                  onChange={async (e) => {
                                    if (e.target.value !== order.covering) {
                                      order.covering = e.target.value;
                                    } else return;
                                  }}
                                >
                                  <option value={null}>
                                    Please select hedrail type
                                  </option>
                                  <option value="curved square">
                                    curved square
                                  </option>
                                  <option value="uncovered round">
                                    uncovered round
                                  </option>
                                  <option value="covered round">
                                    covered round
                                  </option>
                                  <option value="covered square">
                                    covered square
                                  </option>
                                  <option value="uncovered square">
                                    uncovered square
                                  </option>
                                </select>
                              )}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                ${order.price}
                              </div>
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                {order.status}
                              </div>
                            </td>
                            {userRole === "Admin" && (
                              <td
                                className={`px-4 py-4 whitespace-nowrap ${
                                  !order?.matchingRule?.ruleName && "bg-red-300"
                                }`}
                              >
                                {order?.matchingRule?.ruleName ? (
                                  <div className="text-sm text-gray-900">
                                    {order?.matchingRule?.ruleName}
                                  </div>
                                ) : (
                                  <div className="text-sm text-white font-bold">
                                    No rule found
                                  </div>
                                )}
                              </td>
                            )}

                            <td className="px-4 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 flex">
                                <button
                                  type="button"
                                  disabled={order.status !== "Started" && userRole === "Store"}
                                  onClick={() => {
                                    handleDeleteOrder(order.orderID);
                                  }}
                                  className="inline-flex  ml-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </>
                    );
                  })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
