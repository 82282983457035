import React from 'react'

export default function StoreDashboardTemplate({  secondaryNavigation, children }) {
  return (



            <div className="mx-auto max-w-7xl pt-16 sm:pt-8 lg:flex lg:gap-x-16 lg:px-8">
              {secondaryNavigation && secondaryNavigation.length > 0 && (
                <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                  <nav className="flex-none px-4 sm:px-6 lg:px-0">
                    <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                      {secondaryNavigation.map((item) => (
                        <li key={item.name} className={item?.className ? item?.className : ""}>
                          <a
                          
                            onClick={item.onClick}
                            className={`group flex gap-x-3 cursor-pointer rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold ${item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50'}`}
                          >
                            <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </aside>
              )}

              <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
                       {/* className="mx-auto max-w-2xl   lg:mx-0 lg:max-w-none" */}
                <div>
                  {/* This is where the children will be rendered */}
                  {children}
                </div>
              </main>
            </div>
    
      
  )
}
