export const Zebra = [
    {
        "name": "Lift Style",
        "value": "Chain",
        "price": "0",
        "details": [
            {
                "name": "Location",
                "value": "Right",
                "price": "0"
            },
            {
                "name": "Location",
                "value": "Left",
                "price": "0"
            },
        ]
    },
    {
        "name": "Lift Style",
        "value": "Spring",
        "price": "30",
        "details": []
    },
    {
        "name": "Lift Style",
        "value": "Wand",
        "price": "0",
        "details": []
    },
    {
        "name": "Lift Style",
        "value": "Novo Motor",
        "price": "90",
        "details": [
            {
                "name": "Remote Controller",
                "value": "Add Controller",
                "price": "10"
            },
            {
                "name": "Remote Controller",
                "value": "No Controller",
                "price": "0"
            }
        ]
    },
    {
        "name": "Lift Style",
        "value": "Louvolite Motor",
        "price": "110",
        "details": [
            {
                "name": "Remote Controller",
                "value": "Add Controller",
                "price": "27"
            },
            {
                "name": "Remote Controller",
                "value": "No Controller",
                "price": "0"
            },
            {
                "name": "Charger",
                "value": "No Charger",
                "price": "0"
            },
            {
                "name": "Charger",
                "value": "Add Charger",
                "price": "20"
            },
            {
                "name": "Solar Panel",
                "value": "No Solar Panel",
                "price": "0"
            },
            {
                "name": "Solar Panel",
                "value": "Add Solar Panel",
                "price": "80"
            },
            {
                "name": "Hub",
                "value": "No Hub",
                "price": "0"
            },
            {
                "name": "Hub",
                "value": "Add Hub",
                "price": "200"
            }
        ]
    },
    {
        "name": "Cassette Style",
        "value": "Curved Square",
        "price": "0",
        "details": [
            {
                "name": "Covering",
                "value": "Uncovered",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "White",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Gray",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Black",
                "price": "0"
            },
        ]
    },
    {
        "name": "Cassette Style",
        "value": "Square",
        "price": "5",
        "details": [
            {
                "name": "Covering",
                "value": "Uncovered",
                "price": "0"
            },
            {
                "name": "Covering",
                "value": "Covered",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "White",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Gray",
                "price": "0"
            },
        ]
    },
    {
        "name": "Cassette Style",
        "value": "Round",
        "price": "0",
        "details": [
            {
                "name": "Covering",
                "value": "Uncovered",
                "price": "0"
            },
            {
                "name": "Covering",
                "value": "Covered",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "White",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Gray",
                "price": "0"
            },
        ]
    },
    {
        "name": "Cassette Style",
        "value": "Open Roll",
        "price": "0",
        "details": [
            {
                "name": "Cassette Color",
                "value": "White",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Gray",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Black",
                "price": "0"
            },
        ]
    },
    {
        "name": "Headrail Options",
        "value": "Standard Hedrail",
        "price": "0",
        "details": []
    },
    {
        "name": "Headrail Options",
        "value": "2 blinds on 1 Headrail",
        "description": "Please add left and right measurements to the notes",
        "price": "20",
        "details": []
    },
    {
        "name": "Bottom Bar",
        "value": "Closed Bar",
        "price": "0",
        "details": []
    },
    {
        "name": "Bottom Bar",
        "value": "Open Bar",
        "price": "0",
        "details": []
    }
]

export const Roller = [
    {
        "name": "Lift Style",
        "value": "Chain",
        "price": "0",
        "details": [
            {
                "name": "Location",
                "value": "Right",
                "price": "0"
            },
            {
                "name": "Location",
                "value": "Left",
                "price": "0"
            },
        ]
    },
    {
        "name": "Lift Style",
        "value": "Spring",
        "price": "30",
        "details": []
    },
    {
        "name": "Lift Style",
        "value": "Wand",
        "price": "0",
        "details": []
    },
    {
        "name": "Lift Style",
        "value": "Novo Motor",
        "price": "90",
        "details": [
            {
                "name": "Remote Controller",
                "value": "Add Controller",
                "price": "10"
            },
            {
                "name": "Remote Controller",
                "value": "No Controller",
                "price": "0"
            }
        ]
    },
    {
        "name": "Lift Style",
        "value": "Louvolite Motor",
        "price": "110",
        "details": [
            {
                "name": "Remote Controller",
                "value": "Add Controller",
                "price": "27"
            },
            {
                "name": "Remote Controller",
                "value": "No Controller",
                "price": "0"
            },
            {
                "name": "Charger",
                "value": "No Charger",
                "price": "0"
            },
            {
                "name": "Charger",
                "value": "Add Charger",
                "price": "20"
            },
            {
                "name": "Solar Panel",
                "value": "No Solar Panel",
                "price": "0"
            },
            {
                "name": "Solar Panel",
                "value": "Add Solar Panel",
                "price": "80"
            },
            {
                "name": "Hub",
                "value": "No Hub",
                "price": "0"
            },
            {
                "name": "Hub",
                "value": "Add Hub",
                "price": "200"
            }
        ]
    },
    {
        "name": "Cassette Style",
        "value": "Curved Square",
        "price": "0",
        "details": [
            {
                "name": "Covering",
                "value": "Uncovered",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "White",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Gray",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Black",
                "price": "0"
            },
        ]
    },
    {
        "name": "Cassette Style",
        "value": "Square",
        "price": "5",
        "details": [
            {
                "name": "Covering",
                "value": "Uncovered",
                "price": "0"
            },
            {
                "name": "Covering",
                "value": "Covered",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "White",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Gray",
                "price": "0"
            },
        ]
    },
    {
        "name": "Cassette Style",
        "value": "Round",
        "price": "0",
        "details": [
            {
                "name": "Covering",
                "value": "Uncovered",
                "price": "0"
            },
            {
                "name": "Covering",
                "value": "Covered",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "White",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Gray",
                "price": "0"
            },
        ]
    },
    {
        "name": "Cassette Style",
        "value": "Open Roll",
        "price": "0",
        "details": [
            {
                "name": "Cassette Color",
                "value": "White",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Gray",
                "price": "0"
            },
            {
                "name": "Cassette Color",
                "value": "Black",
                "price": "0"
            },
        ]
    },
    {
        "name": "Headrail Options",
        "value": "Standard Hedrail",
        "price": "0",
        "details": []
    },
    {
        "name": "Headrail Options",
        "value": "2 blinds on 1 Headrail",
        "description": "Please add left and right measurements to the notes",
        "price": "20",
        "details": []
    },
]
export const Vertical = [
    {
        "name": "Lift Style",
        "value": "Chain",
        "price": "0",
        "details": [
            {
                "name": "Location",
                "value": "Right",
                "price": "0"
            },
            {
                "name": "Location",
                "value": "Left",
                "price": "0"
            },
        ]
    },
    {
        "name": "Lift Style",
        "value": "Motorization",
        "price": "0",
        "details": [
            {
                "name": "Motor Type",
                "value": "AC motor",
                "price": "400"
            },
            {
                "name": "Motor Type",
                "value": "Battery motor",
                "price": "400"
            },
            {
                "name": "Hub",
                "value": "No Hub",
                "price": "0"
            },
            {
                "name": "Hub",
                "value": "Add Hub",
                "price": "200"
            }
        ]
    },
]