import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import { Button, Modal } from 'flowbite-react';
function App({data}) {
  const [openModal,setOpenModal] = useState(false)
  const  [chosenPDF,setChosenPDF] = useState(14)
  const  [emptySquares,setEmptySquares] = useState(0)

  // const [labelsData,setLabelsData]  = useState()

  const transformToArray = (flatArray,rowSize) => {
    const result = [];
    for (let i = 0; i < flatArray.length; i += rowSize) {
      const pair = [flatArray[i]];
  

      // loop for row size
      for (let j = 1; j < rowSize; j++) {
        if (!flatArray[i + j]) break;
        pair.push(flatArray[i + j]);
      }
    

 
      result.push(pair);
    }
    return result;
  }

  function deepCloneDATA(data){
    let clone = JSON.parse(JSON.stringify(data))
    return clone
  }

  
  // useEffect(()=>{
  //   if(data){
  //       const result =  transformToArray(data)
  //       setLabelsData(result)
  //   }},[data])


//   const labelsData = [
//     ["Label 1", "Label 2"],
//     ["Label 3", "Label 4"],
//     ["Label 5", "Label 6"],
//     ["Label 7", "Label 8"],
//     // ... (fill in other labels)
//   ];
const handleGeneratePdf = async () => {



  
  // Create a clone of the original PDF for use as a template

  let templatePdfDoc;
  let rowSize = 0
  if(chosenPDF === 30){
    const templatePdfBytes30 = await fetch("https://store.altrolabels.com/altro/templates/Die%208.5x11%20inch%202.625x1%20Portrait.pdf").then((res) => res.arrayBuffer());
    templatePdfDoc = await PDFDocument.load(templatePdfBytes30);
    rowSize = 3
  }else{
    const templatePdfBytes14 = await fetch("https://store.altrolabels.com/altro/templates/Die%208.5x11%20inch%204x1.33%20Portrait.pdf").then((res) => res.arrayBuffer());
    templatePdfDoc = await PDFDocument.load(templatePdfBytes14);
    rowSize = 2
  }


  
  // This will be our working document
  const pdfDoc = await PDFDocument.create();
  
  const [templatePage] = await pdfDoc.copyPages(templatePdfDoc, [0]);
  pdfDoc.addPage(templatePage);

  let maxLabelsPerPage;
  if(chosenPDF === 30){
    maxLabelsPerPage = 10
  }else{
    maxLabelsPerPage = 7
  }

  let noIndex = 1 - emptySquares

  console.log(data)
  let flatArray = deepCloneDATA(data.orders)
  // add emptySquares to the array
  for(let i = 0; i < emptySquares; i++){
    flatArray.unshift({})
  }
  console.log(flatArray)

  let newArray = []
  // before transforming to array, we need to duplicate the items that has more than 1 quantity
  flatArray.forEach((item)=>{
    newArray.push(item)
    if(item.quantity > 1){
      for(let i = 0; i < item.quantity - 1; i++){
        newArray.push(item)
      }
    }
  })

  console.log(newArray)


  const labelsData =  transformToArray(newArray,rowSize)
  console.log(labelsData)















  let pageCount = 1

  for (let rowIndex = 0; rowIndex < labelsData.length; rowIndex++) {
    const row = labelsData[rowIndex];
    const pageIndex = Math.floor(rowIndex / maxLabelsPerPage);
    let page;
  
    console.log(pageCount)
    if (pageIndex < pageCount) {
      page = pdfDoc.getPages()[pageIndex];
    } else {
      const [newTemplatePage] = await pdfDoc.copyPages(templatePdfDoc, [0]);
      pdfDoc.addPage(newTemplatePage);
      pageCount++;
      page = pdfDoc.getPages()[pageIndex];
    }

    // eslint-disable-next-line
    row.forEach((item, colIndex) => {
      // Recalculate rowIndex for multi-page support
      const adjustedRowIndex = rowIndex % maxLabelsPerPage;

      if(chosenPDF === 14){
        let x = 35 + colIndex * 290;
        let y = 645 - adjustedRowIndex * 95;
        page.drawText(item.customerName ? item.customerName : "" , { x, y, size: 11 });

        x = 90 + colIndex * 290;
        y = 705 - adjustedRowIndex * 95;
        page.drawText(item.room_name ? `(${noIndex}) ` + item.room_name : "" , { x, y, size: 13 });


        x = 90 + colIndex * 290;
        y = 685 - adjustedRowIndex * 95;
        page.drawText(item.fabric_name ? item.fabric_name : "", { x, y, size: 13 });

        x = 90 + colIndex * 290;
        y = 665 - adjustedRowIndex * 95;
        page.drawText((item.height &&item.width) ?  `${item.width}cm x ${item.height}cm` : "", { x, y, size: 13 });
      }else{
        let x = 30 + colIndex * 195;
        let y = 690 - adjustedRowIndex * 72;
        page.drawText(item.customerName ? item.customerName : "" , { x, y, size: 9 });
  
        x = 50 + colIndex * 195;
        y = 735 - adjustedRowIndex * 72;
        page.drawText(item.room_name ? `(${noIndex}) ` + item.room_name : "" , { x, y, size: 11 });
  
  
        x = 50 + colIndex * 195;
        y = 720 - adjustedRowIndex * 72;
        page.drawText(item.fabric_name ? item.fabric_name : "", { x, y, size: 11 });
  
        x = 50 + colIndex * 195;
        y = 707 - adjustedRowIndex * 72;
        page.drawText((item.height &&item.width) ?  `${item.width}cm x ${item.height}cm` : "", { x, y, size: 9 });
  
  
      }


      noIndex++
    });
  
  }

  const modifiedPdfBytes = await pdfDoc.save();
  const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'modified_labels.pdf';
  link.click();

  // clean the data
  flatArray = []
  




};



return (
  <div className='flex flex-col justify-start items-start'>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Invoice Generator</Modal.Header>
        <Modal.Body>
          <div className="space-y-2">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Which label template are you using?
            </p>
            <div className="flex items-center space-x-4">
                <input
                    type="radio"
                    className="form-radio"
                    name="check"
                    value="yes"
                    defaultChecked
                    onChange={()=>{
                      setChosenPDF(14)
                    }}


              
                />
                <label className="inline-flex items-center text-sm font-medium text-gray-700 dark:text-gray-200">
                    14 Labels
                </label>
                <input
                    type="radio"
                    className="form-radio"
                    name="check"
                    value="no"
                    onChange={()=>{
                      setChosenPDF(30)
                    }}



  
                />
                <label className="inline-flex items-center text-sm font-medium text-gray-700 dark:text-gray-200">
                    30 Labels
                </label>
            </div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                How many sticker has been used already for the current sheet?
            </p>
            <div className="flex items-center space-x-4">
                <input
                    type="number"
                    className="
                        w-full
                        px-4
                        py-2
                        text-base
                        placeholder-gray-500
                        border border-gray-300
                        rounded-md
                        focus:outline-none focus:ring-2 focus:ring-indigo-200
                        dark:bg-gray-700 dark:text-gray-300 dark:placeholder-gray-300 dark:border-gray-600
                    "
                    placeholder="Number"
                    onChange={
                      (e)=>{
                        setEmptySquares(e.target.value)
                      }
                    }
      
                />
            </div>

                


          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={handleGeneratePdf} >
            Download Labels
          </Button>
        </Modal.Footer>
      </Modal>
    <button className='p-4 ml-2 ' onClick={() => setOpenModal(true)}>Print Labels</button>
    <a href='https://store.altrolabels.com/ca/t/4-x-2-rectangle-labels' 
    className='text-xs'
    target="_blank" rel="noreferrer">Buy Labels</a>
  </div>
);

}

export default App;
