import React, { useEffect } from "react";
import axios from "axios";
import { addressURL,getCustomerName,getStoreName,getStoreOrderTotal } from "../../utils/globalFunctions";
import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  Bars3Icon,
  EllipsisHorizontalIcon,
  PlusSmallIcon,
} from "@heroicons/react/20/solid";
import { BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { globalSuccess, globalAlert } from "../../utils/globalFunctions";
import OrderEntries from "../store/OrderEntries";
const secondaryNavigation = [{ name: "Last 30 days", days:30, current: true },{ name: "60 days",days:60, href: "#", current: false },{ name: "90 days", days:90, href: "#", current: false },{ name: "All", days:9999, href: "#", current: false }];
const stats = [
  { name: "Total Cost", value: "$0", change: "", changeType: "" },
  { name: "Unpaid Balance", value: "$0", change: "", changeType: "" },
  { name: "Paid Amount", value: "$0", change: "", changeType: "" },
  { name: "Total Order", value: "0 pcs", change: "", changeType: "" },
];
const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Initialized: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
  Processing: "text-blue-700 bg-blue-50 ring-blue-600/10",
  "On Production": "text-pink-600 bg-pink-40 ring-pink-600/10",
  Completed: "text-yellow-700 bg-yellow-50 ring-yellow-600/10",
};
const days = [
  // {
  //   date: "Invoices",
  //   transactions: [
  //     {
  //       id: 3,
  //       amount: "$2,000.00",
  //       status: "Paid",
  //       tax: "$200.00",
  //       client: "Tuple",
  //       description: "Payment for order #12345",
  //       invoiceNumber: "12345",
  //       isInvoice: true,
  //       icon: ArrowUpCircleIcon,
  //     },
  //     {
  //       id: 4,
  //       amount: "$1,000.00",
  //       status: "Overdue",
  //       tax: "$100.00",
  //       client: "SavvyCal",
  //       description: "Payment for order #12346",
  //       invoiceNumber: "12346",
  //       isInvoice: true,
  //       icon: ArrowDownCircleIcon,
  //     },
  //   ],
  // },
];


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dealer(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [storename, setStoreName] = useState("");
  const [nav, setNav] = useState(secondaryNavigation);
  const [customerOrderOverview, setCustomerOrderOverview] = useState([]);
  const [allInvoices, setAllInvoices] = useState([
    {
      id: 1,
      date: "January 22, 2023",
      due: "January 29, 2023",
      amount: "$14,000.00",
      status: "Paid",
      invoiceNumber: "12345",
    },
    {
      id: 2,
      date: "January 23, 2023",
      due: "N/A",
      amount: "$7,600.00",
      status: "Overdue",
      invoiceNumber: "123"
    },
  ]);
  const [statistics, setStatistics] = useState(stats);
  const [ds, setDS] = useState(days);
  const [trigger,setTrigger] =useState(true)
  const [howManyDays,setHowManyDays] = useState(30)
  const [totalCost,setTotalCost] = useState(0)
  const navigate = useNavigate();
  const [orders,setOrders] = useState([])
  const [orderID, setOrderID] = useState("");
  useEffect(() => {
    var url = new URL(window.location.href);
    var pathname = decodeURIComponent(url.pathname); // decode the path
    var pathnames = pathname.split("/").filter(Boolean); // split path into segments
    var lastPath = pathnames[pathnames.length - 1]; // get the last segment

    // get store info
    // get 30, 60 and 90 day order volume, paid amount, unpaid balance, total cost
    getStoreOrderTotal(lastPath,howManyDays).then((res) => {
      setTotalCost(res.totalSum)
      setOrders(res.orders)
    });


    getStoreName({store_id:lastPath}).then((res) => {
      setStoreName(res);
    });
    

    // get store orders
    // get 8 orders per page

    // get store invoices
    //soon


  }, [howManyDays]);

  console.log(orders)


  if (orderID.length > 0)
    return <OrderEntries user={props.user} order_id={orderID} setOrderID={setOrderID} />


    return (
      <>
        <main>
          <div className="relative isolate overflow-hidden pt-4">

            {/* Secondary navigation */}
            <header className="pb-4 pt-6 sm:pb-6">
              <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                <h1 className="text-base font-semibold leading-7 text-gray-900">
                  {storename}
                </h1>
                <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                  {nav.map((item) => (
                    <a
                      key={item.name}
                      onClick={()=>{
                        setHowManyDays(item.days)
                        setTrigger(!trigger)
                        // current false
                        nav.forEach((item) => (item.current = false));
                        // current true
                        item.current = true;
                      }}
                      className={
                        item.current ? "text-indigo-600 cursor-pointer" : "text-gray-700 cursor-pointer"
                      }
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </header>

            {/* Stats */}
            <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
              <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              <div
                    
                    className={classNames( "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
                    )}
                  >
                    <dt className="text-sm font-medium leading-6 text-gray-500">
                     Total Order Amount
                    </dt>
           
                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    {totalCost}
                    </dd>
                  </div>
              </dl>
            </div>

            <div
              className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
              aria-hidden="true"
            >
              <div
                className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
                style={{
                  clipPath:
                    "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
                }}
              />
            </div>
          </div>
            {/* Recent client list*/}
            <div className="mx-auto py-16 max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                <div className="flex items-center justify-between">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Dealer Invoices
                  </h2>
                </div>

                <table className="mt-6 w-full border-t border-gray-200 divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Invoice #
                      </th>
                      <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Due
                      </th>
                      <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {allInvoices.length > 0 && allInvoices.map((invoice) => (
                      <tr key={invoice.id}>
                        <td className="py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {invoice.invoiceNumber}
                        </td>
                        <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                          {invoice.date}
                        </td>
                        <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                          {invoice.due}
                        </td>
                        <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                          {invoice.amount}
                        </td>
                        <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                          <span
                            className={classNames(
                              statuses[invoice.status],
                              "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                            )}
                          >
                            {invoice.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          <div className="space-y-16 py-8 xl:space-y-20">
            {/* Recent activity table */}


            {/* Recent client list*/}
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                <div className="flex items-center justify-between">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Dealer Orders
                  </h2>
                </div>
                <ul
                  role="list"
                  className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
                >
                  {orders.length > 0 && orders.map((client) => (
                    <li
                      key={client.id}
                      className="overflow-hidden rounded-xl border border-gray-200 shadow-sm bg-white p-6" 
                    >
            
                          <CustomerInfo customer_id={client.customer_id} store_id={client.store_id} order={client } user={props.user} setOrderID={setOrderID}/>
                       
               
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </main>
      </>
    );



  }
  

  function CustomerInfo({store_id, customer_id,order,setOrderID,user})  {

    const [customer, setCustomer] = useState('');



    useEffect(() => {
      getCustomerName({store_id,customer_id}).then((res) => {
        console.log(res)
        setCustomer(res);
      });

    }, [customer_id]);


 

    return (
      <div className="flex justify-between w-full">
           <div className="text-sm font-medium leading-6 text-gray-900">
            {customer}
          </div>
      
          <button
            onClick={() => {
              setOrderID(order.order_id);

            }}
            className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500"
          >
            View Order
          </button>

     
      </div>
    );
  }