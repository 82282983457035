import React, { useEffect, useState } from "react";
import { databaseMigrate, fetchStores, getProducts, getStoreData } from "../utils/globalFunctions";
import { storeProductSetup } from "../utils/firebase";
import Logout from "../components/logout";
import { useUser } from "../utils/context";


import {generateUniqueSlug} from "../utils/helperFunctions"
import { toast } from "react-toastify";
export default function OnBoarding({setIsOnBoarding }) {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [doItLater, setDoItLater] = useState(false);
  const [index, setIndex] = useState(0);
  const [storeName, setStoreName] = useState("");
  const [oldStoreName, setOldStoreName] = useState("");
  const [loading, setLoading] = useState(false);
  const [profitPercentage, setProfitPercentage] = useState(80);
  const [stores,setStores] = useState([])
  const {user, setUser} = useUser();
  


  function handleCompleteSetup() {
    storeProductSetup({userId:user.id,storeName: storeName.trim()}).then(
      (res) => {
        if(res){
          console.log(res)
          toast.success("Store setup successful")
          setUser({...user,...res})
          setLoading(false)
          window.location.reload()
        }else{
          toast.error("Something went wrong, please try again")
        }

      }
    );
  }






  return (
    <div className="w-screen bg-gray-100 flex-col overflow-hidden relative h-screen">
      <Logout />

      {loading ? (
        <div className="w-full h-full bg-black bg-opacity-50  flex justify-center items-center">
          <div className="sm:w-1/2 sm:h-1/2 bg-white rounded p-4 flex flex-col justify-center items-center">


            <p className="text-center text-xl text-gray-600 font-bold">
                We are setting up your store, this may take few minutes.
            </p>
            <div className="sm:w-1/2 sm:h-1/2 bg-white rounded  flex flex-col justify-center items-center">
            <img
                className="h-40"
                src="https://i.pinimg.com/originals/48/c8/b4/48c8b4eaac2a55362b524278a635943f.gif"
                alt="Your Icon"
            />
  
            
            </div>
          </div>
        </div>
      ) : (
        <>
              <h1
        className="text-center text-3xl font-bold mt-8
        "
      >
      Seller On Boarding
      </h1>

          {index === 0 && (
            <div className="w-full flex justify-center">
              <div className="w-full mx-auto overflow-y-scroll absolute top-36 bg-white  p-4 md:w-1/3 shadow-lg">
                <input
                  className="p-2 border-2 rounded focus:ring-0 w-full"
                  type="text"
                  id="storeName"
                  name="storeName"
                  placeholder="Enter your store name"
                  value={storeName}
                  onChange={(e) => {
                    setStoreName(e.target.value);
                  }}
                />
                <p className="w-full text-center">
                  <button
                    className=" bg-gray-300 mt-4 py-2 px-4 rounded"
                    onClick={() => {
                        if (storeName.trim().length === 0) {
                            return alert("Please enter your new store name")
                        }
                        setIndex(1);
                       


                    }}
                  >
                    Next
                  </button>
                </p>
              </div>
            </div>
          )}



          {index === 1 && (
            <div className="w-full flex justify-center">
              <div className="w-full mx-auto overflow-y-scroll absolute top-36 bg-white  p-4 md:w-1/3 shadow-lg">
               {/* <p>
                     Please enter your profit percentage(%), this will be added to the manufacturer product price to calculate your customer's total. You'll be able to update your own individual prices in your dashboard, generate invoices for your customers, sharable digital catalogs with free instant quote for your customers,  and even more features in the close future
               </p>
                <input
                  className="p-2 border-2 mt-5 rounded focus:ring-0 w-full"
                  type="text"
                  placeholder="Enter profit percentage(%)"
                  value={profitPercentage}
                  onChange={(e) => {
                    setProfitPercentage(e.target.value);
                  }}
                /> */}
                <p className="w-full text-center">
                  <button
                    className=" bg-gray-300 mt-4 py-2 px-4 rounded"
                    onClick={() => {
    
                      setLoading(true);
          

                      handleCompleteSetup();
                    }}
                  >
                    Start Selling
                  </button>
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
