import React,{useState,useEffect} from 'react'
import { getCustomers,getCustomerOrders,getOrder } from '../../utils/globalFunctions'
import ExcelLoader from '../../components/excelLoader'
import ExcelEditor from '../../components/ExcelEditor'
import PriceListView from '../../components/PriceListView'

export default function BlindsEstimate({user}) {
    const [name,setName] = useState('')
    const [uploaded,setUploaded] = useState(false)

    console.log(user)
  

  return (
    <div className='w-full'>
        <h1 className='text-2xl font-bold mt-4 mb-4 text-center'>Upload Price List</h1>
    <div className='sm:w-1/2 pl-4 pr-4 mx-auto'> 



    <input 


    type="text"
    placeholder="Price List Name"
    className='mb-4 w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm'
    onChange={(e) => {
        setName(e.target.value)
    }
    }
    />


{
  user.role === "admin" &&
  <ExcelLoader user={user} name={name} setUploaded={setUploaded} />
}


{/* <ExcelEditor data={data}/> */}

  <PriceListView user={user} uploaded={uploaded} setUploaded={setUploaded} />


  

    </div>
    </div>

  )
}
