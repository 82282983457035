import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  endBefore,
  limitToLast,
  getDoc,
  doc
} from "firebase/firestore";
import app from "../../utils/firebase"; // Import your Firestore configuration
import { getFirestore, startAfter, limit } from "firebase/firestore";
import ShowTotalPrice from "../../components/ShowTotalPrice";
import { Modal } from "flowbite-react";
import Messages from "../../components/Messages";
import ModalComponent from "../../components/ModalComponent";
import {
  deleteCreatedOrder,
  globalAlert,
  globalSuccess,
  processCreatedOrder,
  statusColor,
  updateStatusOrder,
  sendMessageWithAttachment,
  sendMessages,
  getAllGlobalVariations,
fetchStoreIdsByName,
getStoreData,
} from "../../utils/globalFunctions";
import { db } from "../../utils/firebase";
import StoreName from "../../components/StoreName";
import CustomerName from "../../components/CustomerName";
import { onSnapshot } from "firebase/firestore";
import CheckboxTable from "../../components/CheckboxTable";
import { Button, Checkbox, Table } from "flowbite-react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch/lite";
import TableComponent from "../../components/TableComponent";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import {
  getOrder,
  getGlobalRules,
  createGlobalRule,
} from "../../utils/globalFunctions";
import autoTable from "jspdf-autotable";
import { jsPDF } from "jspdf";
import {
  updateLastSeenUser,
  saveManufacturerCutRules,
} from "../../utils/globalFunctions";
import OrderEntries from "../store/OrderEntries";
import RuleEntryComponent from "../../components/RuleEntryComponent";
import LabelPrinter from "../../components/LabelPrinter";

const searchClient = algoliasearch(
  "G8KAJNNACO",
  "19a59bc05c5ad87988fb055629980009"
);

const convertToFraction = (decimal) => {
  const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
  const wholeNumber = Math.floor(decimal);
  const fraction = decimal - wholeNumber;
  const numerator = Math.round(fraction * 1000);
  const denominator = 1000;
  const divisor = gcd(numerator, denominator);
  return `${wholeNumber} ${numerator / divisor}/${denominator / divisor}`;
};

const OrderList = ({ user, manufacturer }) => {
  const [orders, setOrders] = useState([]);
  const [searchOrder, setSearchOrders] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [globalRules, setGloalRules] = useState([]);
  const [status, setStatus] = useState("Processing");
  const [searchTerm, setSearchTerm] = useState("");
  const [chatModal, setChatModal] = useState(false);
  const [chatOrderId, setChatOrderId] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [keyMap, setKeyMap] = useState({});
  const [isRuleMap, setIsRuleMap] = useState({});
  const [noRule, setNoRule] = useState(false);
  const [noRuleOrderID, setNoRuleOrderID] = useState("");
  const [noRuleOrders, setNoRuleOrders] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [lastDoc, setLastDoc] = useState(null); // State to keep track of the last document
  const [firstDoc, setFirstDoc] = useState(null); // State to keep track of the first document
  const [nextOrBack, setNextOrBack] = useState("none");
  const [isLastOrder, setIsLastOrder] = useState(false);
  const [chosenPDF, setChosenPDF] = useState(14);
  const [emptySquares, setEmptySquares] = useState(0);
  const [orderID, setOrderID] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false); // Track if we're in search mode
  const [searchPageCount, setSearchPageCount] = useState(0); // Track Algolia page number
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [tempOrderItems, setTempOrderItems] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");

  const navigate = useNavigate();

  const [sortOrder, setSortOrder] = useState("asc"); // State to track sort order

  useEffect(() => {

    getAllGlobalVariations(user.manufacturerID).then((data_) => {
      console.log(data_);
      let map = {};
      let map1 = {};
      let history = [];
      data_.forEach((item) => {  
        
        console.log("item", item);
        history.push(item);
        // check if the key is already in the map
        if (map[item.variation_title]) {
          // find the object in the history array
          let obj = history.find(
            (o) => o.variation_title === item.variation_title
          );
          // if the object is found, check the time createdAt and update the map with the latest object

          if (obj.isDeleted && !item.isDeleted) {
            console.log("item", item);

            map[item.variation_title] = item?.key
              ? item.key
              : "Customizations";
            map1[item.variation_title] = item?.isRule
              ? item.isRule
              : false;

            // remove the old object from the history array
            history = history.filter(
              (o) => o.variation_title !== item.variation_title
            );

            // add the new object to the history array
            history.push(item);
          }
        } else {
          if (!item?.key) {
            console.log("item", item);
          }

          map[item.variation_title] = item?.key
            ? item.key
            : item.variation_title;
          map1[item.variation_title] = item?.isRule ? item.isRule : false;
        }
      });
      setIsRuleMap(map1);
    })

  }, [user.manufacturerID]);

  useEffect(() => {
    if (manufacturer) {
      // Fetch stores
      manufacturer.subscribers.forEach((store) => {

       getStoreData({store_id:store}).then((data) => {
        setStores((prev) => [...prev, data]);
       })
      
      });

    }

  }, [manufacturer]);

  console.log("stores", stores);

  const handleSortOrderToggle = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    // Reset pagination
    setPageCount(0);
    setLastDoc(null);
    setFirstDoc(null);
    setNextOrBack("none");
  };

  const handlePrintOrder = (order) => {
    return handlePrintOrderPDFsTemp(order);
  };

  function computeLabelSpecs(userSpecs) {
    const inchToPoints = (inch) => inch * 72;
  
    // Parse dimensions from strings to numbers
    const labelWidthInch = parseFloat(userSpecs.Width.replace('"', ''));
    const labelHeightInch = parseFloat(userSpecs.Height.replace('"', ''));
  
    const sheetSizeParts = userSpecs['Sheet Size'].split('x').map(s => parseFloat(s.replace('"', '').trim()));
    const sheetWidthInch = sheetSizeParts[0];
    const sheetHeightInch = sheetSizeParts[1];
  
    const marginLeftRightInch = parseFloat(userSpecs['Margin (Left and Right)'].replace('"', ''));
    const marginTopBottomInch = parseFloat(userSpecs['Margin (Top and Bottom)'].replace('"', ''));
  
    const labelsPerSheet = parseInt(userSpecs['Labels per Sheet'], 10);
  
    // Compute margins per side
    const leftMarginInch = marginLeftRightInch / 2;
    const rightMarginInch = marginLeftRightInch / 2;
    const topMarginInch = marginTopBottomInch / 2;
    const bottomMarginInch = marginTopBottomInch / 2;
  
    // Convert dimensions to points
    const pageWidth = inchToPoints(sheetWidthInch);
    const pageHeight = inchToPoints(sheetHeightInch);
    const labelWidth = inchToPoints(labelWidthInch);
    const labelHeight = inchToPoints(labelHeightInch);
    const leftMargin = inchToPoints(leftMarginInch);
    const rightMargin = inchToPoints(rightMarginInch);
    const topMargin = inchToPoints(topMarginInch);
    const bottomMargin = inchToPoints(bottomMarginInch);
  
    // Calculate labels per row and column
    const availableWidth = sheetWidthInch - marginLeftRightInch;
    const labelsPerRow = Math.floor(availableWidth / labelWidthInch);
  
    const availableHeight = sheetHeightInch - marginTopBottomInch;
    const labelsPerColumn = Math.floor(availableHeight / labelHeightInch);
  
    // Recalculate gaps based on the exact labels per row and column
    const totalLabelWidth = labelsPerRow * labelWidthInch;
    const totalGapWidthInch = availableWidth - totalLabelWidth;
    const gapXInch = labelsPerRow > 1 ? totalGapWidthInch / (labelsPerRow - 1) : 0;
    const gapX = inchToPoints(gapXInch);
  
    const totalLabelHeight = labelsPerColumn * labelHeightInch;
    const totalGapHeightInch = availableHeight - totalLabelHeight;
    const gapYInch = labelsPerColumn > 1 ? totalGapHeightInch / (labelsPerColumn - 1) : 0;
    const gapY = inchToPoints(gapYInch);
  
    // Prepare labelSpecs
    const labelSpecs = {
      pageWidth: pageWidth,
      pageHeight: pageHeight,
      labelWidth: labelWidth,
      labelHeight: labelHeight,
      leftMargin: leftMargin,
      rightMargin: rightMargin,
      topMargin: topMargin,
      bottomMargin: bottomMargin,
      gapX: gapX,
      gapY: gapY,
      labelsPerRow: labelsPerRow,
      labelsPerColumn: labelsPerColumn,
      paddingX: 5, // You can adjust or make this user input as well
      paddingY: 5, // You can adjust or make this user input as well
    };
  
    return labelSpecs;
  }



  const handleGenerateLabel = async (data, orderInfo, labelType) => {
    // Define label specifications
    const labelSpecs = {
      pageWidth: 612,          // 8.5 inches * 72 points/inch
      pageHeight: 792,         // 11 inches * 72 points/inch
      labelWidth: 189,         // 2.625 inches * 72 points/inch
      labelHeight: 72,         // 1 inch * 72 points/inch
      leftMargin: 15.822,      // 0.21975 inches * 72 points/inch
      rightMargin: 15.822,     // Same as left margin
      topMargin: 36,           // 0.5 inches * 72 points/inch
      bottomMargin: 36,        // 0.5 inches * 72 points/inch
      gapX: 6.678,             // 0.09275 inches * 72 points/inch
      gapY: 0,                 // No vertical gap between labels
      paddingX: 5,             // Adjust as needed
      paddingY: 8,             // Slightly increased for more top padding
      labelsPerRow: 3,         // Number of labels per row
      labelsPerColumn: 10,     // Number of labels per column
      fontSize: 9,
      topSpace: 2.5,
      lineSpace: 2.75,
    };
  
    // Destructure label specifications
    const {
      pageWidth,
      pageHeight,
      labelWidth,
      labelHeight,
      leftMargin,
      rightMargin,
      topMargin,
      bottomMargin,
      gapX,
      gapY,
      paddingX,
      paddingY,
      labelsPerRow,
      labelsPerColumn,
    } = labelSpecs;
  
    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  
    // Prompt the user to enter the number of labels to skip
    const labelsToSkip = parseInt(prompt("How many labels would you like to skip?", "0"), 10) || 0;
  
    // Prepare the data array, expanding items based on quantity
    const flatArray = deepCloneDATA(data);
    const newArray = flatArray.reduce((acc, item) => {
      for (let i = 0; i < item.quantity; i++) {
        acc.push(item);
      }
      return acc;
    }, []);
  
    // Calculate total labels and labels per page
    const totalLabels = labelsToSkip + newArray.length;
    const labelsPerPage = labelsPerRow * labelsPerColumn;
    const totalPages = Math.ceil(totalLabels / labelsPerPage);
  
    // Generate pages and labels
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      const page = pdfDoc.addPage([pageWidth, pageHeight]);
  
      for (let labelIndex = 0; labelIndex < labelsPerPage; labelIndex++) {
        const globalLabelIndex = pageIndex * labelsPerPage + labelIndex;
  
        if (globalLabelIndex >= totalLabels) break;
  
        const actualIndex = globalLabelIndex - labelsToSkip;
  
        if (actualIndex >= 0 && actualIndex < newArray.length) {
          const item = newArray[actualIndex];
  
          // Calculate label position
          const row = Math.floor(labelIndex / labelsPerRow);
          const col = labelIndex % labelsPerRow;
  
          const x = leftMargin + col * (labelWidth + gapX);
          const y = pageHeight - topMargin - row * (labelHeight + gapY);
  
          // Draw label boundary
          // page.drawRectangle({
          //   x,
          //   y: y - labelHeight,
          //   width: labelWidth,
          //   height: labelHeight,
          //   borderColor: rgb(0, 0, 0),
          //   borderWidth: 0.5,
          // });
  
          // Extract location from customizations
          let location = "";
          for (const custom of item.customizations || []) {
            if (custom["Control"]) {
              for (const detail of custom["Control"].details || []) {
                if (detail?.key === "Location") {
                  location = detail.value;
                }
              }
            }
          }
  
          // Prepare label content
          const fontSize = labelSpecs.fontSize;
          const lineHeight = fontSize + labelSpecs.topSpace;
  
          const measurements = item.metricSystem === "inch"
            ? `${item.width} ${item.fractions?.width} x ${item.height} ${item.fractions?.height} inch`
            : `${item.width} x ${item.height} cm`;
  
          // Updated lines as per your requirements
          const lines = [
            // First line: Store name and item index
            `${orderInfo.store_name} - Item #${item.index}`,
            // Second line: Customer name
            orderInfo.customer_name,
            // Third line: Fabric name and location (if any)
            item.fabric_name
              ? `${item.fabric_name}${location ? ` (${location})` : ""}`
              : "",
            // Fourth line: Measurements and room name together
            `${measurements} - ${item.room_name || ""}`,
          ];
  
          // Center the text within the label
          lines.forEach((text, i) => {
            // Measure text width to center it
            const textWidth = font.widthOfTextAtSize(text, fontSize);
            const textX = x + (labelWidth - textWidth) / 2;
  
            page.drawText(text, {
              x: textX,
              y:
                y -
                paddingY -
                i * lineHeight -
                fontSize +
                labelSpecs.lineSpace,
              size: fontSize,
              font,
            });
          });
        }
      }
    }
  
    // Save and download the PDF
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "labels.pdf";
    link.click();
    setLoadingModal(false);
    setOpen(false);
  };




  const transformToArray = (flatArray, rowSize) => {
    const result = [];
    for (let i = 0; i < flatArray.length; i += rowSize) {
      const pair = [flatArray[i]];
      for (let j = 1; j < rowSize; j++) {
        if (!flatArray[i + j]) break;
        pair.push(flatArray[i + j]);
      }
      result.push(pair);
    }
    return result;
  };

  const deepCloneDATA = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  const handlePrintOrderPDFsTemp = (order, productionSheet) => {
    getGlobalRules(user.manufacturerID).then((res) => {
      if (res) {
        let globalRules = res;
        getOrder(order.order_id,user).then((data) => {
          let customHeads = new Set();
          let customer = order.customer_name;
          let store = order.store_name;
          let order_id = order.order_id.slice(-4);
          let orderItems = [];
          let noRule = false;
          let noRuleOrders = [];
          let orderNotes = [];

          getAllGlobalVariations(user.manufacturerID).then((data_) => {
            console.log(data);
            let error = 0;
            let history = [];
            let map = {};
            let map1 = {};
            data_.forEach((item) => {
              console.log("item", item);
              history.push(item);
              // check if the key is already in the map
              if (map[item.variation_title]) {
                // find the object in the history array
                let obj = history.find(
                  (o) => o.variation_title === item.variation_title
                );
                // if the object is found, check the time createdAt and update the map with the latest object

                if (obj.isDeleted && !item.isDeleted) {
                  console.log("item", item);

                  map[item.variation_title] = item?.key
                    ? item.key
                    : "Customizations";
                  map1[item.variation_title] = item?.isRule
                    ? item.isRule
                    : false;

                  // remove the old object from the history array
                  history = history.filter(
                    (o) => o.variation_title !== item.variation_title
                  );

                  // add the new object to the history array
                  history.push(item);
                }
              } else {
                if (!item?.key) {
                  console.log("item", item);
                }

                map[item.variation_title] = item?.key
                  ? item.key
                  : item.variation_title;
                map1[item.variation_title] = item?.isRule ? item.isRule : false;
              }
            });

            const keyAdditionMap = map;
            console.log("keyAdditionMap", keyAdditionMap);

            const isRuleAdditionMap = map1;

            const productionSheets = manufacturer.productionSheets;

            data?.orders.forEach((order, index) => {
              let orderItem = {
                fabric_name: order.selectedProduct.fabric_name,
                width: order.user_inputs.width,
                height: order.user_inputs.height,
                metricSystem: order.user_inputs.measurement_type,
                fractions: order.user_inputs?.fractions,
                room_name: order.user_inputs.room_name,
                quantity: order.quantity,
                note: order.notes,
              };

              let customizations = [];

              Object.keys(order.variants).forEach((key) => {
                let item = {};

                let k = keyAdditionMap[key];

                if (
                  handleAddDetailsTemp(order.variants[key].details).length > 0
                ) {
                  item = {
                    [k]: {
                      value: order.variants[key].value,
                      details: handleAddDetailsTemp(
                        order.variants[key].details
                      ),
                    },
                  };
                } else {
                  item = {
                    [k]: {
                      value: order.variants[key].value,
                    },
                  };
                }
                customizations.push(item);
                customHeads.add(k);
              });

              orderItem.customizations = customizations;

              let matchingRule = {};
              let keysLength = 0;
              Object.keys(order.variants).forEach((key) => {
                if (isRuleAdditionMap[key]) {
                  console.log("key2", key);
                  keysLength++;
                }
              });

              console.log("keysLength", keysLength);
              console.log("isRule", isRuleAdditionMap);
              if (!noRule) {
                for (let i = 0; i < globalRules.length; i++) {
                  if (
                    globalRules[i].product_type ===
                    order.selectedProduct.design_type
                  ) {
                    //firs criteria for the rule
                    let match = 0;
                    console.log("globalRules[i].rules", globalRules[i].rules);
                    Object.keys(globalRules[i].rules).forEach((key) => {
                      console.log("key", key);
                      console.log("key", order.variants[key]?.value.trim());
                      console.log("key", globalRules[i].rules[key].trim());
                      if (
                        order.variants[key]?.value.trim() ===
                        globalRules[i].rules[key].trim()
                      ) {
                        console.log("key3", key);
                        if (isRuleAdditionMap[key]) {
                          console.log("key4", key);
                          match++;
                        }
                      }
                    });

                    if (match === keysLength) {
                      matchingRule = globalRules[i];
                      break;
                    }
                  }
                }
              }

              if (Object.keys(matchingRule).length === 0) {
                noRule = true;
                console.log("no rule", order);
                noRuleOrders.push(order);
                setLoadingModal(false);
                setOpen(false);
                return;
              }

              orderItem.matchingRule = matchingRule;
              orderItem.index = index + 1;
              orderItems.push(orderItem);

              if (order.notes?.length > 0)
                orderNotes.push({ index: index, note: order.notes });
            });

            if (noRule) {
              setNoRuleOrders(noRuleOrders);
              setNoRule(true);
              return;
            }

            console.log("orderItems", orderItems);

   
            const labelSpecs = {
              pageWidth: 612,        // 8.5 inches * 72 points/inch
              pageHeight: 792,       // 11 inches * 72 points/inch
              labelWidth: 180,       // Adjust according to label size
              labelHeight: 72,       // Adjust according to label size
              marginX: 10,           // Space between labels horizontally
              marginY: 10,           // Space between labels vertically
              paddingX: 5,           // Padding inside labels horizontally
              paddingY: 15,          // Padding inside labels vertically
              labelsPerRow: 3,       // Number of labels per row
              labelsPerColumn: 10    // Number of labels per column
            };
            if (productionSheet === "label1") {
              return handleGenerateLabel(orderItems, data,"label1");
            }

            if (productionSheet === "label2") {
              return handleGenerateLabel(orderItems, data,"label2");
            }

            let orders_ = [];

 

            orderItems.forEach((order) => {
              let {
                cassetteCut,
                tubeCut,
                bottomBar,
                bottomTube,
                fabricWidth,
                fabricLength,
              } = handleCuttingRules(order);
              let matchingRule = order.matchingRule;
              let customizations = order.customizations;
              let roomName = order.room_name;
              let fabricName = order.fabric_name;
              let quantity = order.quantity;
              let note = order.note;
              let width = convertToManufacturerUnit(
                order,
                order.metricSystem
              ).width;
              let height = convertToManufacturerUnit(
                order,
                order.metricSystem
              ).height;
              let customs = {};

              console.log("customizations", customizations);

         
              for (let i = 0; i < customizations.length; i++) {
                let custom = customizations[i];
                let keys = Object.keys(custom);
                let key = keys[0]; // The key you're working with
                console.log("key", keys); // Debug: Check the key

                let value = custom[key].value;
                let details = custom[key].details;
                let stringBuilder = value;
                console.log(stringBuilder); // Debug: Check if value is correct


                let ops = productionSheet.options;
                console.log(ops)
                console.log(key)
                let index = ops.indexOf(key);
                console.log(index); 
                let isShowKey = true;
                if(index !== -1){
                  console.log(productionSheet.settings);
                  let setting = productionSheet.settings[index];
                  console.log(setting);
                  isShowKey = setting.settings.showKeys; // check if the key should be shown
                }
 
                console.log(isShowKey)

                if (details) {
                  stringBuilder = "\n" + value + "\n"; // Add a newline if there are details
                  for (let j = 0; j < details.length; j++) {
                    let detail = details[j];
                    if(isShowKey){

                    stringBuilder +=
                      " -" + detail.key + ": " + detail.value + "\n"; // Append details
                    }else{
                      stringBuilder +=
                      " -" + detail.value + "\n"; // Append details
                    }
                  }
                }

                // Initialize customs[key] if it doesn't exist, to avoid appending to undefined
                if (!customs[key]) {
                  customs[key] = ""; // Initialize as an empty string if not present
                }

                // Append the stringBuilder to customs[key]
                customs[key] += stringBuilder;
              }


              console.log("customs", customs); // Debug: Check the final customs object

              let o = {
                "Room Name": roomName,
                "Fabric Name": fabricName,
                Quantity: quantity,
                Width: width,
                Height: height,
                "Notes": note,
                "Cassette Cut": cassetteCut,
                "Tube Cut": tubeCut,
                "Bottom Bar Cut": bottomBar,
                "Bottom Tube Cut": bottomTube,
                "Fabric Width Cut": fabricWidth,
                "Fabric Height Cut": fabricLength,
                "Matching Rule": matchingRule.ruleName,
                ...customs,
              };
              orders_.push(o);
            });


            
            console.log("orders_", orders_);

            let options = productionSheet.options;
            console.log("options", options);
            let title = productionSheet.title;
            let settings = productionSheet.settings;

            let head = options;

            let body = [];
            let notFounds = [];
            orders_.forEach((order, index) => {
              let row = [];
              row.push(index + 1);

              head.forEach((key) => {
                let value = order[key];
                console.log("value", value);

                row.push(value);
              });
              body.push(row);
            });

            if (notFounds.length > 0) {
              console.log("notFounds", notFounds);
            }

            console.log("head", head);
            console.log("body", body);

            let customerName = order.customer_name;
            let storeName = order.store_name;
            let orderID = order.order_id.slice(-4);

            // Step 1: Filter out columns in 'head' where all corresponding 'body' entries are undefined
            let emptyColumns = [];
            let emptyHeads = [];

            let head_ = head.filter((item, index) => {
              index = index + 1; // Increment index by 1 to account for the 'No.' column
              let found = false;

              // Check if this column has any non-undefined entries in the body
              body.forEach((row) => {
                if (row[index] !== undefined && row[index] !== null) {
                  found = true;
                }
              });

              // If no defined values are found, mark this column as empty
              if (!found) {
                emptyColumns.push(index);
                emptyHeads.push(item);
              }

              return found; // Keep the column if it has defined values
            });

            // Step 2: Remove the corresponding columns from each row in the body
            body = body.map((row) => {
              return row.filter((item, index) => !emptyColumns.includes(index));
            });

            // remove the empty columns from the settings
            let settings_ = settings.filter(
              (item) => !emptyHeads.includes(item.option)
            );

            if(error){
              setLoadingModal(false);
              return
            }

            return handleGeneratePdfNewArtsTemp(
              [["Item #", ...head_]],
              body,
              settings_,
              title,
              storeName,
              customerName,
              orderID,
              orderNotes
            );
          });
        });
      }
    });

    // productionSheets.forEach((sheet) => {
    //   let options = sheet.options;
    //   let title = sheet.title;

    // });
  };

  const handleCuttingRules = (order) => {
    let width, height;
    let cassetteCut, tubeCut, bottomBar, bottomTube, fabricWidth, fabricLength;
    //todo custom functions to deduct the values(check fabricLength)
    if (manufacturer.metricSystem === "cm" && order.metricSystem === "inch") {
      width = inchesToCm(convertToDecimal(order.width, order.fractions?.width));
      height = inchesToCm(
        convertToDecimal(order.height, order.fractions?.height)
      );

      cassetteCut = (
        width - parseFloat(order.matchingRule.deductions.cassetteCut)
      ).toFixed(2);
      tubeCut = (
        width - parseFloat(order.matchingRule.deductions.tubeCut)
      ).toFixed(2);
      bottomBar = (
        width - parseFloat(order.matchingRule.deductions.bottomBar)
      ).toFixed(2);
      bottomTube = (
        width - parseFloat(order.matchingRule.deductions.bottomTube)
      ).toFixed(2);
      fabricWidth = (
        width - parseFloat(order.matchingRule.deductions.fabricWidth)
      ).toFixed(2);
      fabricLength = (
       2 *  height + 30 
      ).toFixed(2);
    }
    if (manufacturer.metricSystem === "inch" && order.metricSystem === "cm") {
      width = parseFloat(order.width);
      height = parseFloat(order.height);

      console.log("order", order);
      cassetteCut = convertToFraction(
        width -
          convertToDecimal(
            convertToFraction(order.matchingRule.deductions.cassetteCut).whole,
            convertToFraction(order.matchingRule.deductions.cassetteCut)
              .fraction
          )
      );
      tubeCut = convertToFraction(
        width - order.matchingRule.deductions.tubeCut
      );
      bottomBar = convertToFraction(
        width - order.matchingRule.deductions.bottomBar
      );
      bottomTube = convertToFraction(
        width - order.matchingRule.deductions.bottomTube
      );
      fabricWidth = convertToFraction(
        width - order.matchingRule.deductions.fabricWidth
      );

      fabricLength =
        height + parseFloat(order.matchingRule.deductions.fabricLength);

      fabricLength = convertToFraction(fabricLength);

      cassetteCut = cassetteCut.whole + " " + cassetteCut.fraction;
      tubeCut = tubeCut.whole + " " + tubeCut.fraction;
      bottomBar = bottomBar.whole + " " + bottomBar.fraction;
      bottomTube = bottomTube.whole + " " + bottomTube.fraction;
      fabricWidth = fabricWidth.whole + " " + fabricWidth.fraction;
      fabricLength = fabricLength.whole + " " + fabricLength.fraction;
    }

    if (manufacturer.metricSystem === "inch" && order.metricSystem === "inch") {
      width = convertToDecimal(order.width, order.fractions?.width);
      height = convertToDecimal(order.height, order.fractions?.height);

      console.log("1")
      let cassetteCutDecimal = convertToDecimal(
        convertToFraction(order.matchingRule.deductions.cassetteCut).whole,
        convertToFraction(order.matchingRule.deductions.cassetteCut).fraction
      );
      console.log("2")
      let tubeCutDecimal = convertToDecimal(
        convertToFraction(order.matchingRule.deductions.tubeCut).whole,
        convertToFraction(order.matchingRule.deductions.tubeCut).fraction
      );
      console.log("3")
      console.log(order.matchingRule)
      let bottomBarDecimal = convertToDecimal(
        convertToFraction(order.matchingRule.deductions.bottomBar).whole,
        convertToFraction(order.matchingRule.deductions.bottomBar).fraction
      );
      console.log("4")
      let bottomTubeDecimal = convertToDecimal(
        convertToFraction(order.matchingRule.deductions.bottomTube).whole,
        convertToFraction(order.matchingRule.deductions.bottomTube).fraction
      );
      console.log("5")
      let fabricWidthDecimal = convertToDecimal(
        convertToFraction(order.matchingRule.deductions.fabricWidth).whole,
        convertToFraction(order.matchingRule.deductions.fabricWidth).fraction
      );
      console.log("6")
      let fabricLengthDecimal = convertToDecimal(
        convertToFraction(order.matchingRule.deductions.fabricLength).whole,
        convertToFraction(order.matchingRule.deductions.fabricLength).fraction
      );
      console.log("7")

      cassetteCut = convertToFraction(width - cassetteCutDecimal);
      tubeCut = convertToFraction(width - tubeCutDecimal);
      bottomBar = convertToFraction(width - bottomBarDecimal);
      bottomTube = convertToFraction(width - bottomTubeDecimal);
      fabricWidth = convertToFraction(width - fabricWidthDecimal);
      fabricLength = convertToFraction(height + fabricLengthDecimal);

      cassetteCut = cassetteCut.whole + " " + cassetteCut.fraction;
      tubeCut = tubeCut.whole + " " + tubeCut.fraction;
      bottomBar = bottomBar.whole + " " + bottomBar.fraction;
      bottomTube = bottomTube.whole + " " + bottomTube.fraction;
      fabricWidth = fabricWidth.whole + " " + fabricWidth.fraction;
      fabricLength = fabricLength.whole + " " + fabricLength.fraction;

      if (order.matchingRule.deductions.cassetteCut === "none") {
        cassetteCut = "N/A";
      }
      if (order.matchingRule.deductions.tubeCut === "none") {
        tubeCut = "N/A";
      }
      if (order.matchingRule.deductions.bottomBar === "none") {
        bottomBar = "N/A";
      }
      if (order.matchingRule.deductions.bottomTube === "none") {
        bottomTube = "N/A";
      }
      if (order.matchingRule.deductions.fabricWidth === "none") {
        fabricWidth = "N/A";
      }
      if (order.matchingRule.deductions.fabricLength === "none") {
        fabricLength = "N/A";
      }
    }

    if (manufacturer.metricSystem === "cm" && order.metricSystem === "cm") {
      //only cachet admin for now
      width = parseFloat(order.width);
      height = parseFloat(order.height);

      cassetteCut = (
        width - parseFloat(order.matchingRule.deductions.cassetteCut)
      ).toFixed(2);
      tubeCut = (
        width - parseFloat(order.matchingRule.deductions.tubeCut)
      ).toFixed(2);
      bottomBar = (
        width - parseFloat(order.matchingRule.deductions.bottomBar)
      ).toFixed(2);
      bottomTube = (
        width - parseFloat(order.matchingRule.deductions.bottomTube)
      ).toFixed(2);
      fabricWidth = (
        width - parseFloat(order.matchingRule.deductions.fabricWidth)
      ).toFixed(2);
      fabricLength = (height * 2 + 30).toFixed(2); // TODO:: custom logics for each businesses

      if (order.matchingRule.deductions.cassetteCut === "none") {
        cassetteCut = "N/A";
      }
      if (order.matchingRule.deductions.tubeCut === "none") {
        tubeCut = "N/A";
      }
      if (order.matchingRule.deductions.bottomBar === "none") {
        bottomBar = "N/A";
      }
      if (order.matchingRule.deductions.bottomTube === "none") {
        bottomTube = "N/A";
      }
      if (order.matchingRule.deductions.fabricWidth === "none") {
        fabricWidth = "N/A";
      }
      if (order.matchingRule.deductions.fabricLength === "none") {
        fabricLength = "N/A";
      }
    }

    return {
      cassetteCut,
      tubeCut,
      bottomBar,
      bottomTube,
      fabricWidth,
      fabricLength,
    };
  };

  const convertToDecimal = (whole, fraction) => {
    // Convert the whole part to a number
    const wholeNumber = parseFloat(whole);

    // If fraction is a valid fraction string (like "1/4"), split and calculate it
    if (fraction && fraction.includes("/")) {
      const [numerator, denominator] = fraction.split("/").map(Number);
      return wholeNumber + numerator / denominator;
    }

    // If no fraction or fraction is not a valid format, just return the whole number
    return wholeNumber;
  };

  const inchesToCm = (inches) => {
    return inches * 2.54;
  };

  const cmToInches = (cm) => {
    return cm / 2.54;
  };

  const convertToFraction = (decimal) => {
    console.log("decimal1", decimal);
    if (decimal === "none") {
      return {
        whole: 0,
        fraction: 0,
      };
    }

    console.log("decimal3", decimal);
    const wholeNumber = Math.floor(decimal);
    const fraction = decimal - wholeNumber;
    const closestFraction = convertToClosestFraction(fraction, 16); // Using 16 as the maximum denominator

    return {
      whole: wholeNumber,
      fraction: closestFraction,
    };
  };

  const convertToClosestFraction = (fraction, maxDenominator = 16) => {
    const denominator = maxDenominator;
    const numerator = Math.round(fraction * denominator);

    // If the numerator is 0 or equal to the denominator, adjust the fraction
    if (numerator === 0) {
      return "";
    } else if (numerator === denominator) {
      return `${numerator / denominator}`;
    } else {
      // Reduce the fraction to its simplest form
      const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
      const divisor = gcd(numerator, denominator);
      return `${numerator / divisor}/${denominator / divisor}`;
    }
  };

  let convertToManufacturerUnit = (order, unit) => {
    if (manufacturer.metricSystem === "cm" && unit === "inch") {
      // inch to cm
      let width = parseFloat(order.width);
      let height = parseFloat(order.height);
      let heightFraction = order?.fractions?.height
        ? order?.fractions?.height
        : 0;
      let widthFraction = order?.fractions?.width ? order?.fractions?.width : 0;

      let decimalWidth = convertToDecimal(width, widthFraction);
      let decimalHeight = convertToDecimal(height, heightFraction);

      let cmWidth = inchesToCm(decimalWidth);
      let cmHeight = inchesToCm(decimalHeight);

      return {
        width: cmWidth.toFixed(2),
        height: cmHeight.toFixed(2),
      };
    }
    if (manufacturer.metricSystem === "inch" && unit === "cm") {
      // cm to inch
      let width = parseFloat(order.width);
      let height = parseFloat(order.height);

      let inchWidth = convertToFraction(width);
      let inchHeight = convertToFraction(height);

      return {
        width: inchWidth.whole + " " + inchWidth.fraction,
        height: inchHeight.whole + " " + inchHeight.fraction,
      };
    }
    if (manufacturer.metricSystem === "cm" && unit === "cm") {
      let width = parseFloat(order.width);
      let height = parseFloat(order.height);

      return {
        width: width.toFixed(2),
        height: height.toFixed(2),
      };
    }

    if (manufacturer.metricSystem === "inch" && unit === "inch") {
      return {
        width: order.width + " " + order?.fractions?.width,
        height: order.height + " " + order?.fractions?.height,
      };
    }
  };

  const handleGeneratePdfNewArtsTemp = (
    head,
    body,
    settings,
    title,
    storeName,
    customerName,
    orderID,
    orderNotes
  ) => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Header
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(40);
    doc.text(title, pageWidth / 2, 15, { align: "center" });

    // Store and Customer Info
    doc.setFontSize(14);
    doc.setFont("helvetica", "normal");
    doc.text(`${storeName} (${customerName})`, pageWidth / 2, 25, {
      align: "center",
    });

    // Order ID and Date
    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    doc.text(`Order ID: ${orderID}`, 10, 35);
    const date = new Date().toLocaleDateString();
    doc.text(`Date: ${date}`, pageWidth - 30, 35);

    // Separator Line
    doc.line(10, 38, pageWidth - 10, 38);

    // Column styles based on the settings
    let columnStyles = {};
    settings.forEach((setting) => {
      let index = head[0].indexOf(setting.option);
      columnStyles[index] = {
        cellWidth: "auto",
        valign: "middle",
        fontStyle: setting.settings.bold ? "bold" : "normal",
        fontSize: parseInt(setting.settings.fontSize),
      };
    });

    // AutoTable configuration with head and body included
    autoTable(doc, {
      startY: 40,
      theme: "grid",
      headStyles: {
        fillColor: [230, 230, 230],
        textColor: [0, 0, 0],
        fontSize: 8,
        fontStyle: "bold",
      },
      alternateRowStyles: {
        fillColor: [242, 247, 255],
      },
      styles: {
        fontSize: 9,
        textColor: [60, 60, 60],
        lineColor: [200, 200, 200],
        lineWidth: 0.5,
        cellPadding: 1,
        overflow: "linebreak", // Ensure text wraps to the next line
        valign: "middle",
        minCellHeight: 10, // Adjust minimum cell height to accommodate larger text
      },
      columnStyles: {
        0: { cellWidth: 10, halign: "center", valign: "middle" }, // First column (e.g., Room Name)
        ...columnStyles, // Additional columns based on settings
      },
      head: head, // Ensure that the head is passed to autoTable
      body: body, // Ensure that the body data is passed to autoTable
      bodyStyles: {
        pageBreak: "auto", // Prevents cells from awkwardly splitting between pages
        overflow: "linebreak", // Text will wrap instead of breaking across pages
      },
      margin: { top: 40, left: 10, right: 10 },
      tableWidth: "auto",
      showHead: "everyPage", // Ensure the head is displayed on every page
      rowPageBreak: "avoid", // Prevent rows from splitting across pages
      didDrawPage: (data) => {
        // Footer on every page
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        doc.setFont("helvetica", "italic");
        doc.text(
          `Page ${
            doc.internal.getCurrentPageInfo().pageNumber
          } of ${pageCount}`,
          pageWidth - 30,
          pageHeight - 10
        );
      },
    });

// // Add notes to the end of the document
//     if (orderNotes.length > 0) {
//       doc.addPage();

//       const pageWidth = doc.internal.pageSize.getWidth();
//       const pageHeight = doc.internal.pageSize.getHeight();
//       const marginLeft = 10;
//       const marginRight = 10;
//       const marginTop = 15;
//       const marginBottom = 15;
//       const textWidth = pageWidth - marginLeft - marginRight;

//       doc.setFontSize(13);
//       doc.setFont("helvetica", "bold");
//       doc.text("Order Notes", pageWidth / 2, marginTop, { align: "center" });

//       doc.setFontSize(10);
//       doc.setFont("helvetica", "normal");

//       let yPosition = marginTop + 10; // Starting y position after the title
//       const lineHeight = 5; // Adjust based on your font size and spacing needs

//       orderNotes.forEach((note) => {
//         const text = `Order Item #${note.index + 1}: ${note.note}`;
//         const textLines = doc.splitTextToSize(text, textWidth);
//         const requiredHeight = lineHeight * textLines.length;

//         // Check if adding this text would exceed the page height
//         if (yPosition + requiredHeight + marginBottom > pageHeight) {
//           doc.addPage();
//           yPosition = marginTop; // Reset yPosition for new page
//         }

//         doc.text(textLines, marginLeft, yPosition);
//         yPosition += requiredHeight + lineHeight; // Move down for the next note
//       });
//     }

    // Save the PDF
    doc.save(`${customerName}.pdf`);
    setLoadingModal(false);
    setOpen(false);
  };

  const columns = [
    { Header: "Order ID", accessor: "orderID" },
    { Header: "Store Name", accessor: "store_name" },
    { Header: "Customer Name", accessor: "customer_name" },
    { Header: "Status", accessor: "customStatus" },
    { Header: "Total", accessor: "total" },
    {
      Header: "Created At",
      accessor: "created_at",
      HeaderIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4 ml-2 cursor-pointer"
          onClick={handleSortOrderToggle}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d={`M${
              sortOrder === "asc"
                ? "7 14l5-5m0 0l5 5m-5-5v12"
                : "17 10l-5 5m0 0l-5-5m5 5V3"
            }`}
          />
        </svg>
      ),
    },
    { Header: "Actions", accessor: "customActions" },
  ];
  const listenToChangesWithCondition = () => {
    let q = null;

    if (nextOrBack === "next") {
      if(selectedStore !== ""){
        q = query(
          collection(db, "Orders"),
          where("is_deleted", "==", false),
          where("status", "==", status),
          where("store_id", "===", selectedStore),
          where("manufacturers", "array-contains", user.manufacturerID),
          orderBy("created_at", sortOrder),
          startAfter(lastDoc),
          limit(8)
        );
      }else{
        q = query(
          collection(db, "Orders"),
          where("is_deleted", "==", false),
          where("status", "==", status),
          where("manufacturers", "array-contains", user.manufacturerID),
          orderBy("created_at", sortOrder),
          startAfter(lastDoc),
          limit(8)
        );
      }
    } else if (nextOrBack === "back") {
      if(selectedStore !==  ""){
        q = query(
          collection(db, "Orders"),
          where("is_deleted", "==", false),
          where("status", "==", status),
          where("store_id","==",selectedStore),
          where("manufacturers", "array-contains", user.manufacturerID),
          orderBy("created_at", sortOrder),
          endBefore(firstDoc),
          limitToLast(8)
        );
      }else{
        q = query(
          collection(db, "Orders"),
          where("is_deleted", "==", false),
          where("status", "==", status),
          where("manufacturers", "array-contains", user.manufacturerID),
          orderBy("created_at", sortOrder),
          endBefore(firstDoc),
          limitToLast(8)
        );
      }

    } else {
      if(selectedStore !== ""){
        q = query(
          collection(db, "Orders"),
          where("is_deleted", "==", false),
          where("status", "==", status),
          where("store_id", "==", selectedStore),
          where("manufacturers", "array-contains", user.manufacturerID),
          orderBy("created_at", sortOrder),
          limit(8)
        );
      }else{
        q = query(
          collection(db, "Orders"),
          where("is_deleted", "==", false),
          where("status", "==", status),
          where("manufacturers", "array-contains", user.manufacturerID),
          orderBy("created_at", sortOrder),
          limit(8)
        );
      }

    }

    

    const unsubscribeOrders = onSnapshot(q, (querySnapshot) => {
      const documents = querySnapshot.docs;

      if (documents.length > 0) {
        setLastDoc(documents[documents.length - 1]);
        setFirstDoc(documents[0]);

        if (documents.length < 8) {
          setIsLastOrder(true);
        } else {
          setIsLastOrder(false);
        }

        const ordersData = documents.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            orderID: doc.id.slice(-4).toUpperCase(),
            ...data,
            created_at: data.created_at.toDate().toLocaleDateString(),
            customStatus: (
              <p
                className={`${statusColor[data.status]} p-2 rounded text-center font-bold text-xs text-white`}
              >
                {data.status}
              </p>
            ),
            customActions: (
              <div className="flex justify-center">
                {data.status === "Created" && (
                  <Button
                    size={"xs"}
                    onClick={() => handleUpdateStatusOrder(doc.id, "Processing")}
                    className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Process
                  </Button>
                )}
                {data.status === "Processing" && (
                  <Button
                    size={"xs"}
                    onClick={() => handleUpdateStatusOrder(doc.id, "In Production")}
                    className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Start Production
                  </Button>
                )}
                {data.status === "In Production" && (
                  <Button
                    size={"xs"}
                    onClick={() =>
                      handleUpdateStatusOrder(doc.id, "Production Completed")
                    }
                    className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Complete Production
                  </Button>
                )}
                <Button
                  size={"xs"}
                  onClick={() => setOrderID(doc.id)}
                  className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                >
                  {/* View Details Button */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 hover:cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </Button>
                <Button
                  size={"xs"}
                  onClick={() => {
                    setOpen(true);
                    setSelectedOrder(data);
                  }}
                  className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                >
                  {/* Print Button */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
                    />
                  </svg>
                </Button>
              </div>
            ),
          };
        });

        setOrders(ordersData);
      } else {
        setOrders([]); // Update orders to empty array when no documents are returned
        setIsLastOrder(true);
      }
      setIsLoading(false);
    });

    return () => unsubscribeOrders();
  };

  useEffect(() => {
    if (!isSearchMode && searchTerm.trim().length === 0) {
      const unsubscribe = listenToChangesWithCondition();
      return () => unsubscribe();
    }
  }, [status, pageCount, sortOrder, isSearchMode,selectedStore]);


  const handleSearch = async (page = 0) => {
    if (searchTerm.trim().length > 0) {
      setIsLoading(true);
      setIsSearchMode(true);
      setSearchPageCount(page);
  
      try {
        // Search in both customers and stores indices
        const storeIndex = searchClient.initIndex("Stores");
        const customerIndex = searchClient.initIndex("Customers");
  
        // Perform searches for store names and customer names (without pagination)
        const storeResults = await storeIndex.search(searchTerm, {
          hitsPerPage: 10000, // Retrieve all store results
        });
  
        const customerResults = await customerIndex.search(searchTerm, {
          hitsPerPage: 10000, // Retrieve all customer results
        });
  
        // Combine store and customer results
        const storeHits = storeResults.hits.map((hit) => hit.objectID); // Extract store IDs
        const customerHits = customerResults.hits.map((hit) => hit.objectID); // Extract customer IDs
  
        console.log("storeHits", storeHits);
        console.log("customerHits", customerHits);
        // Combine store and customer IDs in the filters array
        const filtersArray = [
          ...storeHits.map((id) => `store_id:${id}`),
          ...customerHits.map((id) => `customer_id:${id}`),
        ];
  
        const combinedFilters = filtersArray.length > 0 ? filtersArray.join(' OR ') : null;
  
        if (!combinedFilters) {
          setOrders([]); // No results if there are no matching stores or customers
          return;
        }
  
        // Use the store and customer IDs to search for related orders
        const orderIndex = searchClient.initIndex("Orders");


        // No search term is needed here, only filter by store and customer IDs
        const orderResults = await orderIndex.search("", {
          filters: `is_deleted=0 AND (${combinedFilters}) AND status:"${status}"`, // Quotes added around status
          facetFilters: [
            `manufacturers:${user.manufacturerID}`,
          ],
          page: page,
          hitsPerPage: 8,
        });
        console.log("orderResults", orderResults);
        const { hits, nbPages } = orderResults;
  
        if (nbPages - 1 === page) {
          setIsLastOrder(true);
        } else {
          setIsLastOrder(false);
        }
  
        // Fetch the actual orders from Firestore
        const newOrders = await Promise.all(
          hits.map(async (hit) => {
            const orderDoc = await getDoc(doc(db, "Orders", hit.objectID)); // Fetch order from Firestore
            const orderData = orderDoc.exists() ? orderDoc.data() : null;
  
            if (orderData) {
              let createdAtDate = "N/A";
  
              if (orderData.created_at) {
                if (typeof orderData.created_at === "object" && orderData.created_at.seconds) {
                  createdAtDate = new Date(orderData.created_at.seconds * 1000).toLocaleDateString();
                } else if (!isNaN(Date.parse(orderData.created_at))) {
                  createdAtDate = new Date(orderData.created_at).toLocaleDateString();
                } else {
                  createdAtDate = new Date(parseInt(orderData.created_at)).toLocaleDateString();
                }
              }
  
              return {
                id: hit.objectID,
                orderID: hit.objectID.slice(-4).toUpperCase(),
                ...orderData, // Add Firestore order data here
                created_at: createdAtDate,
                customStatus: (
                  <p
                    className={`${
                      statusColor[orderData.status]
                    } p-2 rounded text-center font-bold text-xs text-white`}
                  >
                    {orderData.status}
                  </p>
                ),
                customActions: (
                  <div className="flex justify-center">
                    {hit.status === "Created" && (
                      <Button
                        size={"xs"}
                        onClick={() =>
                          handleUpdateStatusOrder(hit.objectID, "Processing")
                        }
                        className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                      >
                        Process
                      </Button>
                    )}
                    {hit.status === "Processing" && (
                      <Button
                        size={"xs"}
                        onClick={() =>
                          handleUpdateStatusOrder(hit.objectID, "In Production")
                        }
                        className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                      >
                        Start Production
                      </Button>
                    )}
                    {hit.status === "In Production" && (
                      <Button
                        size={"xs"}
                        onClick={() =>
                          handleUpdateStatusOrder(
                            hit.objectID,
                            "Production Completed"
                          )
                        }
                        className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                      >
                        Complete Production
                      </Button>
                    )}
                    <Button
                      size={"xs"}
                      onClick={() => setOrderID(hit.objectID)}
                      className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 hover:cursor-pointer"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    </Button>
                    <Button
                      size={"xs"}
                      onClick={() => handlePrintOrder(hit)}
                      className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
                        />
                      </svg>
                    </Button>
                  </div>
                ),
              };
            }
  
            return null; // If no order data found
          })
        );
  
        // Filter out any null values from the orders array
        setOrders(newOrders.filter((order) => order !== null));
      } catch (error) {
        console.error("Search failed:", error);
        globalAlert("Search failed, please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   console.log("searchTerm", searchTerm);
  //   handleSearch(searchTerm);
  //   if (isSearchMode) {
   
  //   }
  // }, [searchTerm, isSearchMode, searchPageCount]);

  const handleUpdateStatusOrder = (id, s) => {
    updateStatusOrder(id, s).then((res) => {
      if (res) {
        // Optionally handle success
      }
    });
  };
  const handleAddDetailsTemp = (details) => {
    try {
      console.log("details", details);
      
      if (Object.keys(details).length === 0) return "";
  
      const sortedKeys = Object.keys(details).sort((a, b) => a.localeCompare(b));
  
      const newDetails = sortedKeys.map((key) => ({
        key: key,
        value: details[key]?.value || null,
      }));
  
      return newDetails;
    } catch (error) {
      console.error("Error in handleAddDetailsTemp:", error);
      return [];
    }
  };

  if (orderID.length > 0)
    return (
      <OrderEntries user={user} order_id={orderID} setOrderID={setOrderID} />
    );

  if (noRule) {
    return (
      <RuleEntryComponent
        noRule={noRule}
        setNoRule={setNoRule}
        noRuleOrders={noRuleOrders}
        manufacturer={manufacturer}
        setNoRuleOrders={setNoRuleOrders}
        isRuleMap={isRuleMap}
        user={user}
        saveManufacturerCutRules={saveManufacturerCutRules}
      />
    );
  }

  return (
    <>
      <div className=" flex flex-row justify-center">
        <ModalComponent
          open={open}
          setOpen={setOpen}
          title={loadingModal ? "Please wait..." : "Select Production Sheet"}
          children={
            <div className="flex flex-col">
              {loadingModal ? (
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                </div>
              ) : (
                <>
                  {manufacturer?.productionSheets?.map((sheet) => (
                    <Button
                      size={"xs"}
                      onClick={() => {
                        setLoadingModal(true);
                        handlePrintOrderPDFsTemp(selectedOrder, sheet);
                      }}
                      className="bg-gray-400 mr-1 mt-2 hover:bg-gray-600 text-sm text-white font-bold py-2 px-4 rounded"
                    >
                      {sheet.title}
                    </Button>
                  ))}
                  <Button
                    size={"xs"}
                    onClick={() => {
                      handlePrintOrderPDFsTemp(selectedOrder, "label1");
                    }}
                    className="bg-gray-400 mr-1 mt-2 hover:bg-gray-600 text-sm text-white font-bold py-2 px-4 rounded"
                  >
                    Generate Labels (3 * 10)
                  </Button>
                  <Button
                    size={"xs"}
                    onClick={() => {
                      handlePrintOrderPDFsTemp(selectedOrder, "label2");
                    }}
                    className="bg-gray-400 mr-1 mt-2 hover:bg-gray-600 text-sm text-white font-bold py-2 px-4 rounded"
                  >
                    Generate Labels (2 * 7)
                  </Button>
                </>
              )}
            </div>
          }
        />
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">{status} Orders</h1>
          <div className="flex">
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                setNextOrBack("none");
                setPageCount(0);
              }}
              className="bg-white text-xs p-2 w-1/2 mt-1 rounded border"
            >
              <option value="Processing">Processing</option>
              <option value="In Production">In Production</option>
              <option value="Production Completed">Production Completed</option>
            </select>

            <div className="flex">

            <select
              value={selectedStore}
              onChange={(e) => {
                setSelectedStore(e.target.value);
              }}
              className="bg-white text-xs p-2 ml-2 mt-1 rounded border"
            >
              <option value="">Filter by store</option>
              {
                stores.map((store) => {
                  return <option value={store.store_id}>{store.storeName}</option>
                }
                )
              }
           
            </select>
              {/* <input
                type="text"
                placeholder="Search by customer name or store name"
                className="bg-white ml-2 text-xs p-2 w-1/2 mt-1 rounded border"
                value={searchTerm}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchTerm(value);

                  if (value.trim() === "") {
                    // Reset to Firestore mode when search term is cleared
                    setIsSearchMode(false);
                    setSearchPageCount(0);
                    setPageCount(0);
                    setNextOrBack("none");
                    listenToChangesWithCondition(); // Reload Firestore data
                  }
                }}
              /> */}

              {/* <Button
                size={"xs"}
                onClick={() => {
                  handleSearch(); // Execute search only when the button is clicked
                  setNextOrBack("none");
                  setPageCount(0);
                }}
                className="bg-gray-400 ml-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
              >
                Search
              </Button> */}
            </div>
          </div>
        </div>
      </div>

      <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center">
        <div className="w-full overflow-x-auto">
          <div className="flex justify-between items-center mb-2">
            <p className="text-gray-700 dark:text-gray-400 text-sm ml-1">
              Page <a className="font-bold">{pageCount + 1}</a>
            </p>

            <div>
              <button
                disabled={
                  (isSearchMode && searchPageCount === 0) ||
                  (!isSearchMode && pageCount === 0) ||
                  isLoading
                }
                onClick={() => {
                  if (isSearchMode && searchPageCount > 0) {
                    // In search mode, decrease the search page count
                    handleSearch(searchPageCount - 1);
                  } else if (!isSearchMode && pageCount > 0) {
                    // In Firestore mode, go back to the previous page
                    setNextOrBack("back");
                    setPageCount(pageCount - 1);
                  }
                }}
                className="mr-1 hover:text-black cursor-pointer text-gray-800 font-bold py-2 px-4 rounded"
              >
                Previous
              </button>

              <button
                disabled={isLastOrder || isLoading} // Disable when it's the last page or loading
                onClick={() => {
                  if (isSearchMode) {
                    // In search mode, increase the search page count
                    handleSearch(searchPageCount + 1);
                  } else {
                    // In Firestore mode, go to the next page
                    setNextOrBack("next");
                    setPageCount(pageCount + 1);
                  }
                }}
                className="mr-1 hover:text-black cursor-pointer text-gray-800 font-bold py-2 px-4 rounded"
              >
                Next
              </button>
            </div>
          </div>
          <div className="table-container">
            <div className="w-full mx-auto">
              <TableComponent
                columns={columns}
                data={orders}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderList;
