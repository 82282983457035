import React, { useState, useEffect } from "react";
import { fetchStoreProducts } from "../../utils/globalFunctions";
import Skeleton from "react-loading-skeleton";
import { Button, Checkbox, Table } from "flowbite-react";
import { addStoreProduct,addAllStoreProducts } from "../../utils/globalFunctions";
import { useNavigate } from "react-router-dom";
import { getMissingProducts } from "../../utils/firebase";
import {  Modal } from "flowbite-react";
import { useSnackbar } from "react-simple-snackbar";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import { FingerPrintIcon } from "@heroicons/react/20/solid";
import { BellIcon } from "@heroicons/react/20/solid";
import StoreDashboardTemplate from "../../components/StoreDashboardTemplate";
import { CubeIcon,CubeTransparentIcon,GlobeAltIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
export default function DiscoverNewProducts({ user }) {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [doItLater, setDoItLater] = useState(false);
  const [addingProduct, setAddingProduct] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar({
    position: 'top-center',
    style: {


      color: 'white',
      backgroundColor: '#2c323b',
      fontSize: '16px',
      textAlign: 'center',

      borderRadius: '8px',
      opacity:'1',
      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',


      
   
      fontWeight:'500'



    },

  })
  const [storeName, setStoreName] = useState("");
  const [oldStoreName, setOldStoreName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [profitPercentage, setProfitPercentage] = useState(80);
  const [editPrice, setEditPrice] = useState(null);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  const secondaryNavigation = [
    { name: 'Store Products', href: '#', icon: CubeIcon, current: false,
    onClick: ()=> {
      nav("/store-products");
    }},
    { name: 'Global Customizations', href: '#', icon: CubeTransparentIcon, current: false, onClick: ()=> {
      nav("/store-product-customizations");
    } },
    { name: 'Discover Products', href: '#', icon: GlobeAltIcon, current: true,
    onClick: ()=> {
      nav("/discover-new-products");
    }},
    // { name: 'Plan', href: '#', icon: CubeIcon, current: false },
    // { name: 'Billing', href: '#', icon: CreditCardIcon, current: false },
    // { name: 'Team members', href: '#', icon: UsersIcon, current: false },
  ];
  useEffect(() => {
    getMissingProducts({storeId:user.store_id,manufacturerId:user.manufacturer}).then(({data}) => {
        console.log(data)
      setProducts(data);
      setIsLoading(false);
    }).catch((err)=>{
        console.log(err)
    })
  }, []);
  console.log(products);

  const columns = React.useMemo(
    () => [
      {
        Header: "Image",
        // HeaderIcon: <HashtagIcon width={10} />,
      },
      {
        Header: "Title",
      },

      {
        Header: "Manufacturer Price",
      },



      {
        Header: "Availability",
      },
      {
        Header: "Category",
      },
      {
        Header: "Manufacturer",
      },
      {
        Header: "Actions",
      },
    ],
    []
  );


  if(loading){
    return <div className="flex justify-center items-center h-screen">
      <p>Products are loading, this may take few minutes. Please do not close, or change this page.</p>
    </div>
  }


  return (
 

    <StoreDashboardTemplate
      
    secondaryNavigation={secondaryNavigation}
  >
     <>


      <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center ">
        <div className="w-full overflow-x-auto">
          <div className="table-container">
            <div className="w-full mx-auto">
              {/* <button
                className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded mb-4"
                onClick={() => {
                  setLoading(true);
                  addAllStoreProducts({
                    storeId: user.store_id,
                    storeProducts: products,
                    profitPercentage: profitPercentage,
                  }).then((data) => {
                    if(data){
                      console.log(data);
                      // remove it from the current products
                      setProducts([]);
                      // setEditPrice(null);
                      setLoading(false);
                      openSnackbar("Products added to inventory")
                      
                    }else{
                      setLoading(false);
                      openSnackbar("There was an error")
                    }
                  });
                }}
              >
                Add All to Inventory
              </button> */}

              <Table className="mb-4 border" hoverable>
                <Table.Head>
                  {columns.map((column) => (
                    <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white  p-4">
                      {
                        <div className="h-4">
                          {!isLoading && (
                            <a className="flex">
                              {column.HeaderIcon}
                              {column.Header}
                            </a>
                          )}
                        </div>
                      }
                    </Table.HeadCell>
                  ))}
                </Table.Head>
                <Table.Body className="divide-y ">
                  {isLoading ? (
                    Array.from({ length: 8 }).map((_, index) => (
                      <Table.Row key={index}>
                        {columns.map((column, colIndex) => (
                          <Table.Cell className="p-8 " key={colIndex}>
                            <Skeleton className="sm:w-full w-32" count={1} />
                          </Table.Cell>
                        ))}
                  
                      </Table.Row>
                    ))
                  ) : (
                    <>
                      {products?.map((p, index) => (
                        <Table.Row className={"bg-white"}>
                          <Table.Cell className=" p-4   ">
                          <Skeleton width={60} height={60} count={1} enableAnimation />
                          </Table.Cell>

                          <Table.Cell className="p-4">
                            <p className="">{p.product_title}</p>
                          </Table.Cell>

                          <Table.Cell className="p-4">

                         { p.isPriceList ? (
                                  <div className="flex items-center">
                                    <p className="text-indigo-600">{p?.priceList?.price_list_name}</p>
                                  </div>
                                ) : (
                                  <div className="flex items-center">
                                  <p className="">
                              {parseFloat(p.product_price).toFixed(2)}CAD
                            </p>
                                  </div>
                                )}

                  
                          </Table.Cell>
                   
                          <Table.Cell className="p-4">
                            <p className="">
                              {p.isAvailable ? (
                                <a className="text-green-600">Available</a>
                              ) : (
                                <a className="text-red-600">Not Available</a>
                              )}
                            </p>
                          </Table.Cell>
                          <Table.Cell className="p-4">
                            <p className="">{p.product_category}</p>
                          </Table.Cell>
                          <Table.Cell className="p-4">
                            <p className="">{p.manufacturer_name}</p>
                          </Table.Cell>
                          <Table.Cell className="p-4">
                            <button
                            disabled={addingProduct}
                              className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                              onClick={() => {
                                setAddingProduct(p.id);
                                addStoreProduct({
                                  storeId: user.store_id,
                                  storeProduct: p,
                                  profitPercentage: profitPercentage,
                              }).then((data) => {
                                if(data){
                                  console.log(data);
                                  // remove it from the current products
                                  setProducts(products.filter((pr) => pr.id !== p.id));
                                  // setEditPrice(null);
                                  toast.success("Product added to inventory")
                                  setAddingProduct(false);
                                }else{
                                  toast.error("There was an error")
                                  setAddingProduct(false);
                                }
                                  
                              });
                              }}
                            >
                              {addingProduct === p.id ? "Adding..." : "Add to Inventory"}
                            </button>
                    
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </>
                  )}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
{/* 
    <Modal show={editPrice}>
        <Modal.Body>
            <p>
                Add new products to your inventory  and decide on your profits. We will re-calculate the price for your store-front product. Please enter
                the profit percentage below for this product.
            </p>
            <input
                type="number"
                value={profitPercentage}
                onChange={(e) => {
                    setProfitPercentage(e.target.value);
                }}
                className="bg-gray-200 p-2 rounded w-full mt-2"
            />
            <button
                className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded mt-2"
                onClick={() => {
                    addStoreProduct({
                        storeId: user.store_id,
                        storeProduct: editPrice,
                        profitPercentage: profitPercentage,
                    }).then((data) => {
                      if(data){
                        console.log(data);
                        // remove it from the current products
                        setProducts(products.filter((p) => p.id !== editPrice.id));
                        setEditPrice(null);
                        openSnackbar("Product added to inventory")
                      }else{
                        openSnackbar("There was an error")
                      }
                        
                    });
        
                }}
            >
                Submit
            </button>
            <button
                className="bg-gray-500 ml-2 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded mt-2"
                onClick={() => {
                    setEditPrice(null);
                }}
            >
                Cancel
            </button>


        </Modal.Body>
    </Modal> */}
    </>
  
  </StoreDashboardTemplate>
  );
}
