// src/components/Users.js
import React, { useState,useEffect } from 'react';
import { getAllUsers } from '../../utils/globalFunctions';
import { deleteUser } from '../../utils/globalFunctions';

const Users = () => {
  const [users, setUsers] = useState([
    { id: 1,email: 'johndoe@example.com', role: 'Admin' },
    { id: 2,  email: 'janesmith@example.com', role: 'User' },
    // Add more users here
  ]);

  const handleDeleteUser = (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      deleteUser(userId).then((res)=>{
        if(res){
          setUsers(users.filter(user => user.id !== userId));
          alert("User deleted successfully");
        }
      })


    }
  };


  useEffect(() => {
    getAllUsers().then((data) => {
      setUsers(data);
    });
  },[]);


  return (
    <div className="min-h-screen bg-gray-100 py-10 px-4">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Users</h1>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-200">
              <tr>
              <th className="py-3 px-5 text-left text-gray-700 font-semibold uppercase tracking-wider">
                  #
                </th>
                <th className="py-3 px-5 text-left text-gray-700 font-semibold uppercase tracking-wider">
                  id
                </th>
                <th className="py-3 px-5 text-left text-gray-700 font-semibold uppercase tracking-wider">
                  Email
                </th>
                <th className="py-3 px-5 text-left text-gray-700 font-semibold uppercase tracking-wider">
                  Role
                </th>
                <th className="py-3 px-5 text-left text-gray-700 font-semibold uppercase tracking-wider">
                  Created At
                </th>
                <th className="py-3 px-5 text-center text-gray-700 font-semibold uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {users.length > 0 ? (
                users.map((user,index) => (
                  <tr key={user.id} className="border-b hover:bg-gray-50">
                    <td className="py-4 px-5 text-gray-700">{index+1}</td>
                    <td className="py-4 px-5 text-gray-700">{user.id}</td>
                    <td className="py-4 px-5 text-gray-700">{user.email}</td>
                    <td className="py-4 px-5 text-gray-700">{user.role}</td>
                    <td className="py-4 px-5 text-gray-700">
                      {new Date(user?.createdAt?.seconds * 1000).toLocaleDateString()}
                    </td>
                    <td className="py-4 px-5 text-center">
                      <button
                        onClick={() => handleDeleteUser(user.id)}
                        className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded transition duration-200"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="py-4 px-5 text-center text-gray-500">
                    No users found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Users;