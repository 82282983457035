// src/pages/MaterialEdit.js
import React, { useState, useEffect } from "react";
import {
  fetchMaterial,
  updateMaterial,
  fetchSuppliers,
  getPriceList,
} from "../../utils/globalFunctions";
import { Button, Checkbox, Modal } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { useUser } from "../../utils/context";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ConnectMaterialsToProducts from "../../components/ConnectMaterialsToProducts";
import ImageUploader from "../../components/ImageUploader";

export default function MaterialEdit({ manufacturer }) {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useUser();
  const { id } = useParams();
  const [openSnackbar] = useSnackbar({
    position: "top-center",
    style: {
      color: "white",
      backgroundColor: "#2c323b",
      fontSize: "16px",
      textAlign: "center",
      borderRadius: "8px",
      opacity: "1",
      boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
      fontWeight: "500",
    },
  });

  // Material fields
  const [materialName, setMaterialName] = useState("");
  const [tag, setTag] = useState("");
  const [inventoryType, setInventoryType] = useState("");
  const [materialCategory, setMaterialCategory] = useState("");
  const [stockManagement, setStockManagement] = useState(false);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [length, setLength] = useState("");
  const [units, setUnits] = useState("");
  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [showScanner, setShowScanner] = useState(false);
  const [disconnect, setDisconnect] = useState(false);
  const [connect, setConnect] = useState(false);
  const [material, setMaterial] = useState({});

  const [description, setDescription] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");

  // New fields
  const [cost, setCost] = useState(0);
  const [wholesaleEnabled, setWholesaleEnabled] = useState(false);
  const [wholesalePrice, setWholesalePrice] = useState(0);
  const [isPriceList, setIsPriceList] = useState(false);
  const [isLimited, setIsLimited] = useState(false);
  const [minQuantity, setMinQuantity] = useState(0);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [minLength, setMinLength] = useState(0);
  const [maxLength, setMaxLength] = useState(0);
  const [minWidth, setMinWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);
  const [minHeight, setMinHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [metricType,setMetricType] = useState("");
  const [metricTypeStock,setMetricTypeStock] = useState("");
  const [priceList, setPriceList] = useState([]);
  const [selectedPriceList, setSelectedPriceList] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      openSnackbar("Material not found");
      navigate("/materials");
    }

    // Fetch suppliers
    fetchSuppliers(user.manufacturerID).then((suppliers) => {
      setSupplierList(suppliers);
    });

    // Fetch price lists
    getPriceList("dnLLP341yLe4HvKOXd8X", user.manufacturerID).then((data) => {
      setPriceList(data);
    });

    // After suppliers and price lists are loaded, fetch material data
    fetchMaterial(id).then((data) => {
      if (data) {
        setMaterial(data);
        setDownloadUrl(data.images ? data.images[0] : "");
        setMaterialFields(data);
        setIsLoading(false);
      } else {
        openSnackbar("Material not found");
        navigate("/materials");
      }
    });
  }, [id, user.manufacturerID]);

  const handleUpdateMaterial = async () => {
    // Validate input data
    if (!materialName || !inventoryType || !materialCategory) {
      alert("Please enter valid material details");
      return;
    }

    if (stockManagement && inventoryType === "AREA") {
      if (!width || !height) {
        alert("Please enter valid area dimensions");
        return;
      }
    }

    if (stockManagement && inventoryType === "LENGTH") {
      if (!length) {
        alert("Please enter valid length");
        return;
      }
    }

    if (stockManagement) {
      if (!units) {
        alert("Please enter valid units");
        return;
      }
    }

    // cost cannot be empty
    if (!cost) {
      alert("Please enter a valid cost");
      return;
    }

    if((inventoryType === "LENGHT" || inventoryType === "AREA") && !metricType){
      alert("Please select metric type");
      return;
    }

    // Use existing tag or generate a new one if not provided
    const materialTag = tag || generateUniqueTag();

    const updatedMaterial = {
      material_name: materialName,
      material_name_lowercase: materialName.toLowerCase(),
      tag: materialTag,
      unitType: inventoryType,
      materialCategory: materialCategory,
      description: description,
      manufacturerID: user.manufacturerID,
      creatorID: user.id,
      supplierID: selectedSupplier,
      isDeleted: false,
      cost: parseFloat(cost),
      isAvailable: true,
      images: downloadUrl ? [downloadUrl] : [],
      stockManagementEnabled: stockManagement,
      stockMetaData: stockManagement
        ? {
            metricType:metricTypeStock,
            Width:
              inventoryType === "AREA" ? parseFloat(width) || null : null,
            Height:
              inventoryType === "AREA" ? parseFloat(height) || null : null,
            Length:
              inventoryType === "LENGTH" ? parseFloat(length) || null : null,
            Units: parseFloat(units) || null,
            AvailableUnits: parseFloat(units) || null,
            AvailableLength:
              inventoryType === "LENGTH" ? parseFloat(length) : null,
            AvailableWidth:
              inventoryType === "AREA" ? parseFloat(width) : null,
            AvailableHeight:
              inventoryType === "AREA" ? parseFloat(height) : null,
            AvailableArea:
              parseFloat(width) && parseFloat(height)
                ? parseFloat(width) * parseFloat(height)
                : null,
          }
        : null,
      wholesaleEnabled: wholesaleEnabled,
      wholesaleMetaData: wholesaleEnabled
        ? {
            metricType:metricType,
            minQuantity: parseInt(minQuantity),
            minLength:
              inventoryType === "LENGTH" ? parseFloat(minLength) : null,
            minWidth:
              inventoryType === "AREA" ? parseFloat(minWidth) : null,
            minHeight:
              inventoryType === "AREA" ? parseFloat(minHeight) : null,
            price: isPriceList ? null : parseFloat(wholesalePrice),
            isPriceList: isPriceList,
            priceList: isPriceList ? selectedPriceList.ref : null,
            isLimited: isLimited,
            maxQuantity: parseFloat(maxQuantity) || null,
            maxLength:
              inventoryType === "LENGTH" ? parseFloat(maxLength) : null,
            maxWidth:
              inventoryType === "AREA" ? parseFloat(maxWidth) : null,
            maxHeight:
              inventoryType === "AREA" ? parseFloat(maxHeight) : null,
          }
        : null,
    };

    try {
      const res = await updateMaterial(id, updatedMaterial);
      if (res) {
        openSnackbar("Material Updated Successfully");
        navigate("/materials");
      } else {
        openSnackbar("Something went wrong, please report this to admin");
      }
    } catch (error) {
      console.error("Error updating material:", error);
      openSnackbar("An error occurred while updating the material");
    }
  };

  const setMaterialFields = (data) => {
    setMaterialName(data.material_name || "");
    setDescription(data.description || "");
    setTag(data.tag || "");
    setInventoryType(data.unitType || "");
    setMetricType(data?.wholesaleMetaData?.metricType || "");
    setMetricTypeStock(data?.stockMetaData?.metricType || "");
    setMaterialCategory(data.materialCategory || "");
    setStockManagement(data.stockManagementEnabled || false);
    setWidth(
      data.stockMetaData && data.stockMetaData.Width
        ? data.stockMetaData.Width.toString()
        : ""
    );
    setHeight(
      data.stockMetaData && data.stockMetaData.Height
        ? data.stockMetaData.Height.toString()
        : ""
    );
    setLength(
      data.stockMetaData && data.stockMetaData.Length
        ? data.stockMetaData.Length.toString()
        : ""
    );
    setUnits(
      data.stockMetaData && data.stockMetaData.Units
        ? data.stockMetaData.Units.toString()
        : ""
    );
    setSelectedSupplier(data.supplierID ? data.supplierID.toString() : "");
    setCost(data.cost ? data.cost.toString() : "0");
    setWholesaleEnabled(data.wholesaleEnabled || false);

    // Set wholesale meta data
    if (data.wholesaleMetaData) {
      const wm = data.wholesaleMetaData;
      setMinQuantity(wm.minQuantity ? wm.minQuantity.toString() : "0");
      setMaxQuantity(wm.maxQuantity ? wm.maxQuantity.toString() : "0");
      setMinLength(wm.minLength ? wm.minLength.toString() : "0");
      setMaxLength(wm.maxLength ? wm.maxLength.toString() : "0");
      setMinWidth(wm.minWidth ? wm.minWidth.toString() : "0");
      setMaxWidth(wm.maxWidth ? wm.maxWidth.toString() : "0");
      setMinHeight(wm.minHeight ? wm.minHeight.toString() : "0");
      setMaxHeight(wm.maxHeight ? wm.maxHeight.toString() : "0");
      setWholesalePrice(wm.price ? wm.price.toString() : "0");
      setIsPriceList(wm.isPriceList || false);
      setIsLimited(wm.isLimited || false);

      if (wm.priceList && priceList.length > 0) {
        const priceListId = wm.priceList.id; // Extract ID from Firestore ref
        const selected = priceList.find((pl) => pl.id === priceListId);
        setSelectedPriceList(selected || "");
      }
    }
  };

  // Function to generate a unique tag (e.g., UUID)
  const generateUniqueTag = () => {
    return `TAG-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  };

  if (isLoading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  return (
    <>
      <div className="pt-12 flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">Edit Material</h1>
          <div className="flex mt-2">
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
              onClick={() => {
                navigate("/materials");
              }}
            >
              Materials
            </button>
          </div>
        </div>
      </div>

      <div className="sm:w-1/2 w-full border mx-auto min-h-[500px] mt-8 rounded-lg">
        <h2 className="text-xl font-bold mb-4 text-center mt-2">
          Update Material
        </h2>
        <div className="md:pl-24 md:pr-24 pl-8 pr-8">
          <ImageUploader
            downloadURL={downloadUrl}
            setDownloadURL={setDownloadUrl}
          />
          <input
            className="border p-2 mb-2 w-full"
            placeholder="Material Name"
            value={materialName}
            onChange={(e) => setMaterialName(e.target.value)}
          />

          <div className="mb-2">
            <input
              className="border p-2 w-full"
              placeholder="Tag"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs mt-2"
              onClick={() => setShowScanner(true)}
            >
              Scan Barcode
            </button>
          </div>

          <textarea className="w-full" onChange={
            (e) => setDescription(e.target.value)
          } value={description} placeholder="Description" rows={4} />




          {/* Barcode Scanner Modal */}
          <Modal show={showScanner} onClose={() => setShowScanner(false)}>
            <Modal.Header>Scan Barcode</Modal.Header>
            <Modal.Body>
              <BarcodeScannerComponent
                width={500}
                height={500}
                onUpdate={(err, result) => {
                  if (result) {
                    setTag(result.text);
                    setShowScanner(false);
                  } else if (err) {
                    console.error(err);
                  }
                }}
              />
            </Modal.Body>
          </Modal>

          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="cost"
          >
            Cost
          </label>
          <input
            className="border p-2 mb-2 w-full"
            placeholder="Cost"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            type="number"
          />

          <select
            className="border p-2 mb-2 w-full"
            value={inventoryType}
            onChange={(e) => setInventoryType(e.target.value)}
          >
            <option value="">Select Type</option>
            <option value="AREA">Area</option>
            <option value="LENGTH">Length</option>
            <option value="UNIT">Unit</option>
            <option value="BUNDLE">Bundle</option>
            <option value="BOX">Box</option>
          </select>


          <select
            className="border p-2 mb-2 w-full"
            value={materialCategory}
            onChange={(e) => setMaterialCategory(e.target.value)}
          >
            <option value="">Select Material Category</option>
            {manufacturer?.materialTypes?.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>

          <select
            className="border p-2 mb-2 w-full"
            value={selectedSupplier}
            onChange={(e) => {
              setSelectedSupplier(e.target.value);
            }}
          >
            <option value="">Select Supplier</option>
            {supplierList.map((supplier) => (
              <option key={supplier.id} value={supplier.id.toString()}>
                {supplier.supplierName}
              </option>
            ))}
          </select>

          <div className="flex items-center mb-2">
            <Checkbox
              checked={stockManagement}
              className="bg-gray-500"
              onChange={(e) => setStockManagement(e.target.checked)}
            />
            <label className="ml-2">Enable Stock Management</label>
          </div>

          {
  stockManagement &&
  
    ( inventoryType === "LENGTH" || inventoryType === "AREA") &&
     <select
       className="border p-2 mb-2 w-full"
       value={metricTypeStock}
       onChange={(e) => setMetricTypeStock(e.target.value)}
     >
       <option value="">Select Metric Type</option>
       <option value="cm">cm</option>
       <option value="m">m</option>
       <option value="inch">inch</option>
       <option value="ft">ft</option>
       <option value="yard">yd</option>
   </select>
   
   
}
          {stockManagement && inventoryType === "AREA" && (
            <>
              <input
                className="border p-2 mb-2 w-full"
                placeholder="Width"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                type="number"
              />
              <input
                className="border p-2 mb-2 w-full"
                placeholder="Height"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                type="number"
              />
            </>
          )}

          {stockManagement && inventoryType === "LENGTH" && (
            <input
              className="border p-2 mb-2 w-full"
              placeholder="Length"
              value={length}
              onChange={(e) => setLength(e.target.value)}
              type="number"
            />
          )}

          {stockManagement && (
            <input
              className="border p-2 mb-2 w-full"
              placeholder="Units"
              value={units}
              onChange={(e) => setUnits(e.target.value)}
              type="number"
            />
          )}

          <div className="flex items-center mb-2">
            <Checkbox
              checked={wholesaleEnabled}
              className="bg-gray-500"
              onChange={(e) => setWholesaleEnabled(e.target.checked)}
            />
            <label className="ml-2">Enable Wholesale</label>
          </div>

          {
            wholesaleEnabled &&
 ( inventoryType === "LENGTH" || inventoryType === "AREA") &&
  <select
    className="border p-2 mb-2 w-full"
    value={metricType}
    onChange={(e) => setMetricType(e.target.value)}
  >
    <option value="">Select Metric Type</option>
    <option value="cm">cm</option>
    <option value="inch">inch</option>
    <option value="ft">ft</option>
    <option value="yard">yd</option>
</select>
}

          {wholesaleEnabled && (
            <>
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="minQuantity"
              >
                Min Order Quantity
              </label>
              <input
                className="border p-2 mb-2 w-full"
                placeholder="Min Quantity"
                value={minQuantity}
                onChange={(e) => setMinQuantity(e.target.value)}
                type="number"
              />

              {inventoryType === "LENGTH" && (
                <>
                  <label className="block text-sm font-medium text-gray-700">
                    Min Allowed Length
                  </label>
                  <input
                    className="border p-2 mb-2 w-full"
                    placeholder="Min Length"
                    value={minLength}
                    onChange={(e) => setMinLength(e.target.value)}
                    type="number"
                  />
                </>
              )}

              {inventoryType === "AREA" && (
                <>
                  <label className="block text-sm font-medium text-gray-700">
                    Min Allowed Width
                  </label>
                  <input
                    className="border p-2 mb-2 w-full"
                    placeholder="Min Width"
                    value={minWidth}
                    onChange={(e) => setMinWidth(e.target.value)}
                    type="number"
                  />
                  <label className="block text-sm font-medium text-gray-700">
                    Min Allowed Height
                  </label>
                  <input
                    className="border p-2 mb-2 w-full"
                    placeholder="Min Height"
                    value={minHeight}
                    onChange={(e) => setMinHeight(e.target.value)}
                    type="number"
                  />
                </>
              )}

              <div className="flex items-center mb-2">
                <Checkbox
                  checked={isPriceList}
                  className="bg-gray-500"
                  onChange={(e) => setIsPriceList(e.target.checked)}
                />
                <label className="ml-2">Use Price List</label>
              </div>

              {isPriceList && (
                <>
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="priceList"
                  >
                    Wholesale Price List
                  </label>

                  <select
                    className="border p-2 mb-2 w-full mt-2"
                    value={selectedPriceList?.id || ""}
                    onChange={(e) => {
                      const selected = priceList.find(
                        (price) => price.id === e.target.value
                      );
                      setSelectedPriceList(selected || "");
                    }}
                  >
                    <option value="">Select Price List</option>
                    {
                priceList.map(
                    (price,index) => (
                        price.type === inventoryType &&
                        <option key={index} value={price.id}>{price.price_list_name}</option>
                    )
                )
            }
                  </select>
                </>
              )}

              {!isPriceList && (
                <>
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="wholesalePrice"
                  >
                    Wholesale Price
                  </label>
                  <input
                    className="border p-2 mb-2 w-full"
                    placeholder="Wholesale Price"
                    value={wholesalePrice}
                    onChange={(e) => setWholesalePrice(e.target.value)}
                    type="number"
                  />
                </>
              )}

              <div className="flex items-center mb-2">
                <Checkbox
                  checked={isLimited}
                  className="bg-gray-500"
                  onChange={(e) => setIsLimited(e.target.checked)}
                />
                <label className="ml-2">Set Limits</label>
              </div>

              {isLimited && (
                <>
                  <label className="block text-sm font-medium text-gray-700">
                    Max Allowed Quantity
                  </label>

                  <input
                    className="border p-2 mb-2 w-full"
                    placeholder="Max Quantity"
                    value={maxQuantity}
                    onChange={(e) => setMaxQuantity(e.target.value)}
                    type="number"
                  />

                  {inventoryType === "LENGTH" && (
                    <>
                      <label className="block text-sm font-medium text-gray-700">
                        Max Allowed Length
                      </label>

                      <input
                        className="border p-2 mb-2 w-full"
                        placeholder="Max Length"
                        value={maxLength}
                        onChange={(e) => setMaxLength(e.target.value)}
                        type="number"
                      />
                    </>
                  )}

                  {inventoryType === "AREA" && (
                    <>
                      <label className="block text-sm font-medium text-gray-700">
                        Max Allowed Width
                      </label>

                      <input
                        className="border p-2 mb-2 w-full"
                        placeholder="Max Width"
                        value={maxWidth}
                        onChange={(e) => setMaxWidth(e.target.value)}
                        type="number"
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Max Allowed Height
                      </label>
                      <input
                        className="border p-2 mb-2 w-full"
                        placeholder="Max Height"
                        value={maxHeight}
                        onChange={(e) => setMaxHeight(e.target.value)}
                        type="number"
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}

          <div className="flex w-full justify-center">
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mb-2 mt-2"
              onClick={handleUpdateMaterial}
            >
              Update Material
            </button>

            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mb-2 mt-2 ml-2"
              onClick={() => setDisconnect(true)}
            >
              Disconnect
            </button>

            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mb-2 mt-2 ml-2"
              onClick={() => setConnect(true)}
            >
              Connect
            </button>
          </div>
        </div>
      </div>

      {connect && (
        <>
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50"></div>
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded shadow-lg z-50 w-11/12 max-w-4xl max-h-screen overflow-hidden">
            <div className="h-full w-full flex flex-col items-center justify-start overflow-y-auto max-h-[calc(100vh-4rem)]">
              <ConnectMaterialsToProducts
                user={user}
                manufacturer={manufacturer}
                material={material} // Pass the material for editing
                isDisconnect={false}
                setOpen={setConnect}
              />
            </div>
          </div>
        </>
      )}

      {disconnect && (
        <>
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50"></div>
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded shadow-lg z-50 w-11/12 max-w-4xl max-h-screen overflow-hidden">
            <div className="h-full w-full flex flex-col items-center justify-start overflow-y-auto max-h-[calc(100vh-4rem)]">
              <ConnectMaterialsToProducts
                user={user}
                manufacturer={manufacturer}
                material={material} // Pass the material for editing
                isDisconnect={true}
                setOpen={setDisconnect}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}