import React, { useEffect } from 'react';
import SetAvailability from './SetAvailability';
import BookAppointment from './BookAppointment';
import DealerBookingsView from './DealerBookingsView';
import { useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { updateStoreSlug } from '../../utils/globalFunctions';
import { generateSlug } from '../../utils/helperFunctions';
const Index = ({user}) => {
    const [view, setView] = useState('dealerBookingsView');


    useEffect(() => {
        
        if(!user?.storeSlug){

            updateStoreSlug({
                store_id: user.store_id,
                storeName: user.storeName
            })



        }else{
            if(generateSlug(user.storeName) !== user.storeSlug){ // if the store name has been updated
                updateStoreSlug({
                    store_id: user.store_id,
                    storeName: user.storeName
                })
            }
        }


    }, [])

    if(user?.storeSlug){

        return (
            <div className=''>
                
    
    
                <div className="flex justify-center mt-4">
                    <button onClick={() => setView('dealerBookingsView')} className={`px-4 py-2 ${view === 'dealerBookingsView' ? 'bg-blue-500' : 'bg-blue-300'} text-white`}>Dealer Bookings View</button>
                    <button onClick={() => setView('setAvailability')} className={`px-4 py-2 ${view === 'setAvailability' ? 'bg-blue-500' : 'bg-blue-300'} text-white`}>Set Availability</button>
                    <button onClick={() => setView('bookAppointment')} className={`px-4 py-2 ${view === 'bookAppointment' ? 'bg-blue-500' : 'bg-blue-300'} text-white`}>Book Appointment</button>
                </div>
    
                <div className='flex pl-4 pr-4'>
                    <div className='mt-8'>
                        
                    <p className='text-sm font-semibold'>Your store's calendar public link, you can share this link with your potential customers</p>
                    <div className='flex items-center gap-x-2 mt-4'>
                    <a className="text-xs font-bold text-gray-500 " href={`https://
                    supplycircle.io/${encodeURI(user.storeName)}/book-appointment`}>
                        https://supplycircle.io/calendar/{encodeURI(user.storeSlug)}/{user.storeSlugId}
                    </a>
                    <CopyToClipboard
                   
                   text={`https://supplycircle.io/calendar/${encodeURI(user.storeSlug)}/${user.storeSlugId}`}
                   onCopy={() => alert('Copied to clipboard')}
                   >
    
    
                    <button className="bg-blue-500 hover:bg-blue-600 p-2 rounded text-white text-xs ml-2 ">
                        
                        Copy Link
                    </button>
             
       
                    </CopyToClipboard>
                    </div>
    
    
                    </div>
          
    
    
    
           
                 
    
                </div>
    
    
                {
                    view === 'setAvailability' ? <SetAvailability user={user} setView={setView}/>
                    : view === 'bookAppointment' ? <>
                    <p className='text-sm font-semibold mt-8 pl-4 pr-4'>This is preview of your calendar</p>
                    <BookAppointment user={user} setView={setView}/>
                    </>
                    : view === 'dealerBookingsView' ? <DealerBookingsView user={user} setView={setView}/>
                    : null
                }
    
    
    
    
    
            </div>
        );
    }else{
        return <div>Loading...</div>
    }
    

}

export default Index;