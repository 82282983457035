import React, { useState, useEffect } from "react";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import ShowTotalPrice from "../../components/ShowTotalPrice";
import {  Modal } from "flowbite-react";
import Messages from "../../components/Messages";
import {
  deleteCreatedOrder,
  globalAlert,
  globalSuccess,
  processCreatedOrder,
  statusColor,
  updateStatusOrder,
  sendMessageWithAttachment,
  sendMessages
} from "../../utils/globalFunctions";
import { db } from "../../utils/firebase";
import { onSnapshot } from "firebase/firestore";
import CheckboxTable from "../../components/CheckboxTable";
import { Button, Checkbox, Table } from "flowbite-react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch/lite";
import { getOrder, getGlobalRules } from "../../utils/globalFunctions";
import autoTable from "jspdf-autotable";
import { jsPDF } from "jspdf";
import OrderEntries from "../store/OrderEntries";

const searchClient = algoliasearch(
  "G8KAJNNACO",
  "19a59bc05c5ad87988fb055629980009"
);

const Orders = ({ user }) => {
  const [orders, setOrders] = useState([]);
  const [searchOrder, setSearchOrders] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [globalRules, setGloalRules] = useState([]);
  const [status, setStatus] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [chatModal,setChatModal] = useState(false)
  const [chatOrderId,setChatOrderId] = useState("")
  const [attachment, setAttachment] = useState(null);
  const [orderID,setOrderID] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    getGlobalRules().then((res) => {
      if (res) {
        setGloalRules(res);
      }
    });
  }, []);

  const handleSearch = () => {
    // if (searchTerm?.length > 0) {
    //   const index = searchClient.initIndex("cachetsearch");
    //   index.search(searchTerm).then(({ hits }) => {
    //     console.log(hits);
    //     setSearchOrders([]);
    //     hits.forEach((hit) => {
    //       getOrder(hit.objectID).then((res) => {
    //         console.log(res);
    //         if (res) {
    //           setSearchOrders((orders) => [...orders, res]);
    //         }
    //       });
    //     });
    //   });
    // } else {
    //   setStatus(status);
    // }
  };

  const sendMessage = () => {

    const messageInput = document.getElementById('messageInput')

    
    const message = messageInput.value

    if(message.length === 0 && !attachment){
      return
    }

    if(attachment){
      sendMessageWithAttachment(chatOrderId,message,user.storeName,attachment).then((res)=>{
        if(res){
          messageInput.value = ""
          setAttachment(null)
        }
      })
      return
    }else{
      sendMessages(chatOrderId,message,user.storeName,"").then((res)=>{
        if(res){
          messageInput.value = ""
        }
      })
    }

 


  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Order ID",
        // HeaderIcon: <HashtagIcon width={10} />,
      },
      {
        Header: "Store Name",
      },
      {
        Header: "Customer Name",
      },

      {
        Header: "Status",
      },
      {
        Header: "Total",
        labelLeft: "$",
      },
      {
        Header: "Created At",
      },
    ],
    []
  );
  const listenToChangesWithCondition = () => {
    // Create a query against the collection.
    let q = query(
      collection(db, "Orders"),
      where("status", "==", status),

      orderBy("created_at", "desc") // Then orderBy 'created_at'
    );

    if (status === "All") {
      q = query(
        collection(db, "Orders"),
        orderBy("created_at", "desc") // Then orderBy 'created_at'
      );
    }
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          console.log("New doc added: ", change.doc.data());
        }
        if (change.type === "modified") {
          console.log("Doc modified: ", change.doc.data());
        }
        if (change.type === "removed") {
          console.log("Doc removed: ", change.doc.data());
        }
      });

      // If you still need to handle the complete set
      const documents = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // each document has a orders array with order items and each order item has selectedProduct object that tells the manufacturer name, so we should filter out the manufacturer orders here

      console.log("Current documents in query: ", documents);
      setOrders(documents);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = listenToChangesWithCondition(); // or listenToDocument(docId)

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, [status]);

  const handleUpdateStatusOrder = (id, s) => {
    updateStatusOrder(id, s).then((res) => {
      if (res) {
      }
    });
  };


  const handleFileChange = (event) => {
    setAttachment(event.target.files[0]);
};

  function convertTurkishCharacters(input) {
    const turkishCharacters = {
      ç: "c",
      ğ: "g",
      ı: "i",
      i: "i",
      ö: "o",
      ş: "s",
      ü: "u",
      Ç: "C",
      Ğ: "G",
      İ: "I",
      I: "I",
      Ö: "O",
      Ş: "S",
      Ü: "U",
    };

    return input.replace(
      /[çğıiöşüÇĞİIÖŞÜ]/g,
      (char) => turkishCharacters[char] || char
    );
  }
  const handleGeneratePdf = (data) => {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.text(`${data.store}(${data.customer}) `, 10, 10);
    autoTable(doc, {
      tableWidth: 45,
      theme: "grid",

      tableStyle: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: [174, 214, 214],
        textColor: "black",
      },
      styles: {
        fontSize: 10,
        cellPadding: 1,
        textColor: "black",
        fontWeight: "bold",
        lineWidth: 0.3,
        lineColor: [0, 0, 0],
        overflow: "linebreak",
      },
      head: [["Symbol", "Meaning"]],
      body: [
        ["W", "Width"],
        ["H", "Height"],
        ["Qnt", "Quantity"],
        ["C.Cut", "Cassette Cut"],
        ["T.Cut", "Tube Cut"],
        ["B.Bar", "Bottom Bar"],
        ["F.Width", "Fabric Width"],
      ],
    });

    autoTable(doc, {
      theme: "grid",
      alternateRowStyles: {
        fillColor: [242, 247, 255],
      },
      headStyles: {
        fillColor: [174, 214, 214],
        textColor: "black",
      },
      styles: {
        fontSize: 10,
        cellPadding: 1,
        textColor: "black",
        fontWeight: "bold",
        lineWidth: 0.3,
        lineColor: [0, 0, 0],
        overflow: "linebreak",
      },

      head: [
        [
          "No",
          "Fabric",
          "W",
          "H",
          "C.Cut",
          "T.Cut",
          "B.Bar",
          "B.Tube",
          "F.Width",
          "F.Height",
          "R.Name",
          "Lift",
          "Cassette",
          "B.Type",
          "Qnt",
        ],
      ],
      body: data.orderItems.map((order, index) => {
        return [
          `${index + 1}`,
          `${order.fabric_name}`,
          `${order.width}`,
          `${order.height}`,
          `${(order.width - order.matchingRule.deductions.cassetteCut).toFixed(
            2
          )}`,
          `${(order.width - order.matchingRule.deductions.tubeCut).toFixed(2)}`,
          `${(order.width - order.matchingRule.deductions.bottomBar).toFixed(
            2
          )}`,
          `${(order.width - order.matchingRule.deductions.bottomTube).toFixed(
            2
          )}`,
          `${(order.width - order.matchingRule.deductions.fabricWidth).toFixed(
            2
          )}`,
          `${order.height * 2 + 30}`,
          `${order.room_name}`,
          `${order.liftStyle}`,
          `${order.cassetteStyle}`,
          `${order.bottomBar}`,
          `${order.quantity}`,
        ];
      }),
    });

    if (data.orderNotes?.length > 0) {
      autoTable(doc, {
        columnStyles: {
          0: { columnWidth: 10 },
  
        },

        headStyles: {
          fillColor: [174, 214, 214],
          textColor: "black",
        },
        styles: {
          fontSize: 10,
          cellPadding: 1,
          textColor: "black",
          fontWeight: "bold",
          lineWidth: 0.3,
          lineColor: [0, 0, 0],
        },
        head: [["No", "Note"]],
        body: data.orderNotes.map((note,index) => {
          return [
            `${index+1}`,
            `${convertTurkishCharacters(note)}`
          ];
        }),
      });
    }

    doc.save("cachet-blinds-price-list.pdf");
  };

  const handleAddDetails = (details) => {
    let newDetails = [];
    if (Object.keys(details).length === 0) return "";

    Object.keys(details).forEach((key) => {
      newDetails.push(details[key].value);
    });
    return newDetails.join(",");
  };

  const handlePrintOrder = (order) => {
    let customer = order.customer_name;
    let store = order.store_name;
    let order_id = order.order_id.slice(-4);
    // let createdAt = order.created_at.toDate().toLocaleDateString()
    let orders = order.orders;
    let orderItems = [];
    let noRule = false;

    let orderNotes = [];
    orders.forEach((order) => {
      let orderItem = {
        fabric_name: order.selectedProduct.fabric_name,
        width: order.user_inputs.width,
        height: order.user_inputs.height,
        room_name: order.user_inputs.room_name,
        quantity: order.quantity,
      };
      Object.keys(order.variants).forEach((key) => {
        if (key === "Bottom Bar") {
          if (handleAddDetails(order.variants[key].details).length > 0)
            orderItem["bottomBar"] =
              order.variants[key].value +
              `(${handleAddDetails(order.variants[key].details)})`;
          else orderItem["bottomBar"] = order.variants[key].value;
        }
        if (key === "Cassette Style") {
          if (handleAddDetails(order.variants[key].details).length > 0)
            orderItem["cassetteStyle"] =
              order.variants[key].value +
              `(${handleAddDetails(order.variants[key].details)})`;
          else orderItem["cassetteStyle"] = order.variants[key].value;
        }
        if (key === "Lift Style") {
          if (handleAddDetails(order.variants[key].details).length > 0)
            orderItem["liftStyle"] =
              order.variants[key].value +
              `(${handleAddDetails(order.variants[key].details)})`;
          else orderItem["liftStyle"] = order.variants[key].value;
        }
        if (key === "Hedrail Options") {
          if (handleAddDetails(order.variants[key].details).length > 0)
            orderItem["hedrailOption"] =
              order.variants[key].value +
              `(${handleAddDetails(order.variants[key].details)})`;
          else orderItem["hedrailOption"] = order.variants[key].value;
        }

        orderItem[key] = order.variants[key].value;
      });
      orderItems.push(orderItem);
      let matchingRule = {};
      // match the rule

      for (let i = 0; i < globalRules.length; i++) {
        if (globalRules[i].product_type === order.selectedProduct.design_type) {
          let match = 0;
          Object.keys(globalRules[i].rules).forEach((key) => {
            console.log(key);
            if (
              order.variants[key]?.value.trim() ===
              globalRules[i].rules[key].trim()
            ) {
              match++;
            }
          });
          if (match === Object.keys(order.variants).length) {
            matchingRule = globalRules[i];
            break;
          }
        }
      }

      console.log(matchingRule);
      if (Object.keys(matchingRule).length === 0) {
        noRule = true;
        return;
      }

      orderItem.matchingRule = matchingRule;
      if(order.notes?.length > 0)
        orderNotes.push(order.notes)
    });

    if (noRule) return globalAlert("No rule found for this order");

    console.log(orderItems);
    handleGeneratePdf({ customer, store, order_id, orderItems,orderNotes });
  };

  const handleDeleteOrder = (id) =>{
    deleteCreatedOrder(id).then((res)=>{
       if(res){
         globalSuccess(
           "Order Deleted",
         )

       }else{
         globalAlert(
           "Error Deleting Order",
         )
       }

    })

 }

 if(orderID.length >0)
  return <OrderEntries 
  user={user}
  order_id={orderID}
  setOrderID={setOrderID}
  />

  return (
    <>
      <div className="pt-32 flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">{status} Orders</h1>

          <div className=" flex">
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              className="bg-white text-xs p-2 w-1/2 mt-1 rounded border"
            >
              <option value="All">All</option>
            </select>

            <input
              type="text"
              placeholder="Search by customer name or store name"
              className="bg-white ml-2 text-xs p-2 w-1/2 mt-1 rounded border"
              value={searchTerm}
              onChange={(e) => {
                if (e.target.value === "") {
                  setSearchOrders([]);
                }
                setSearchTerm(e.target.value);
              }}
            />

            <Button
              size={"xs"}
              onClick={() => {
                handleSearch();
              }}
              className="bg-gray-400 ml-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center mt-8">
        <div className="w-full overflow-x-auto">
          <div className="table-container">
            <div className="w-full mx-auto">
              <Table className="mb-4  border" hoverable>
                <Table.Head>
                  {columns.map((column) => (
                    <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width">
                      <a className="flex">
                        {column.HeaderIcon}
                        {column.Header}
                      </a>
                    </Table.HeadCell>
                  ))}
                  <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width"></Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y ">
                  {isLoading ? (
                    Array.from({ length: 3 }).map((_, index) => (
                      <Table.Row key={index}>
                        <Table.Cell className="p-4">
                          <Skeleton count={1} />
                        </Table.Cell>
                        {columns.map((column, colIndex) => (
                          <Table.Cell className="p-6" key={colIndex}>
                            <Skeleton width={125} count={1} />
                          </Table.Cell>
                        ))}
                        <Table.Cell className="p-4">
                          <Skeleton width={125} count={1} />
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <>
                      {searchOrder?.length > 0
                        ? searchOrder.map((d) => (
                            <Table.Row className={"bg-white"}>
                              <Table.Cell className="p-6">
                                {d.order_id.slice(-4)}
                              </Table.Cell>
                              <Table.Cell className="p-6">
                                {d.store_name}
                              </Table.Cell>
                              <Table.Cell className="p-6">
                                {d.customer_name}
                              </Table.Cell>
                              <Table.Cell className={"p-6 text-white "}>
                                <p
                                  className={
                                    statusColor[d.status] +
                                    " p-2 rounded text-center font-bold text-xs"
                                  }
                                >
                                  {d.status}
                                </p>
                              </Table.Cell>
      
                              <Table.Cell className="p-6">
                                <ShowTotalPrice orderData={d.orders} />
                              </Table.Cell>

                              <Table.Cell className="p-4">
                                {d.created_at.toDate().toLocaleDateString()}
                              </Table.Cell>
                              <Table.Cell className="p-2">
                              <div className="flex justify-center">
                   

                   <Button
                     size={"xs"}
                     onClick={() => {
                       handleDeleteOrder(d.id)
                     }}
                     className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                   >
                     Delete
                   </Button>


                   <Button
                     size={"xs"}
                     onClick={() => {
                       handleUpdateStatusOrder(
                         d.order_id,
                         "Processing"
                       );
                     }}
                     className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                   >
                     Process
                   </Button>

              
                   <Button
                     size={"xs"}
                     onClick={() => {
                       handleUpdateStatusOrder(
                         d.order_id,
                         "In Production"
                       );
                     }}
                     className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                   >
                     Start Production
                   </Button>

         
                   <Button
                     size={"xs"}
                     onClick={() => {
                       handleUpdateStatusOrder(
                         d.order_id,
                         "Production Completed"
                       );
                     }}
                     className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                   >
                     Complete Production
                   </Button>
        

                 <Button
                   size={"xs"}
                   onClick={() => {
                    setOrderID(
                      d.order_id
                    )
                   }}
                   className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                 >
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     strokeWidth={1.5}
                     stroke="currentColor"
                     className="w-4 h-4 hover:cursor-pointer"
                   >
                     <path
                       strokeLinecap="round"
                       strokeLinejoin="round"
                       d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                     />
                     <path
                       strokeLinecap="round"
                       strokeLinejoin="round"
                       d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                     />
                   </svg>
                 </Button>

                 <Button
                   size={"xs"}
                   onClick={() => {
                     handlePrintOrder(d);
                   }}
                   className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                 >
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     strokeWidth={1.5}
                     stroke="currentColor"
                     className="w-4 h-4"
                   >
                     <path
                       strokeLinecap="round"
                       strokeLinejoin="round"
                       d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
                     />
                   </svg>
                 </Button>

                 <Button
                   size={"xs"}
         
                   onClick={() => {

                     setChatModal(true)
                     setChatOrderId(d.order_id)
                   
                   }}
                   className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                 >
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
<path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
</svg>


                 </Button>
               </div>
                              </Table.Cell>
                            </Table.Row>
                          ))
                        : orders.map((d) => (
                            <Table.Row className={"bg-white"}>
                              <Table.Cell className="p-6">
                                {d.order_id.slice(-4)}
                              </Table.Cell>
                              <Table.Cell className="p-6">
                                {d.store_name}
                              </Table.Cell>
                              <Table.Cell className="p-6">
                                {d.customer_name}
                              </Table.Cell>
                              <Table.Cell className={"p-6 text-white "}>
                                <p
                                  className={
                                    statusColor[d.status] +
                                    " p-2 rounded text-center font-bold text-xs"
                                  }
                                >
                                  {d.status}
                                </p>
                              </Table.Cell>
                              <Table.Cell className="p-6">
                                <ShowTotalPrice orderData={d.orders} />
                              </Table.Cell>

                              <Table.Cell className="p-4">
                                {d.created_at.toDate().toLocaleDateString()}
                              </Table.Cell>
                              <Table.Cell className="p-2">
                                <div className="flex justify-center">
                   

                                    <Button
                                      size={"xs"}
                                      onClick={() => {
                                        handleDeleteOrder(d.id)
                                      }}
                                      className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                                    >
                                      Delete
                                    </Button>


                                    <Button
                                      size={"xs"}
                                      onClick={() => {
                                        handleUpdateStatusOrder(
                                          d.order_id,
                                          "Processing"
                                        );
                                      }}
                                      className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                                    >
                                      Process
                                    </Button>
                 
                               
                                    <Button
                                      size={"xs"}
                                      onClick={() => {
                                        handleUpdateStatusOrder(
                                          d.order_id,
                                          "In Production"
                                        );
                                      }}
                                      className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                                    >
                                      Start Production
                                    </Button>
                 
                          
                                    <Button
                                      size={"xs"}
                                      onClick={() => {
                                        handleUpdateStatusOrder(
                                          d.order_id,
                                          "Production Completed"
                                        );
                                      }}
                                      className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                                    >
                                      Complete Production
                                    </Button>
                         

                                  <Button
                                    size={"xs"}
                                    onClick={() => {
                                      setOrderID(
                                        d.order_id
                                      )
                                    }}
                                    className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-4 h-4 hover:cursor-pointer"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                      />
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                      />
                                    </svg>
                                  </Button>

                                  <Button
                                    size={"xs"}
                                    onClick={() => {
                                      handlePrintOrder(d);
                                    }}
                                    className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-4 h-4"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
                                      />
                                    </svg>
                                  </Button>

                                  <Button
                                    size={"xs"}
                          
                                    onClick={() => {

                                      setChatModal(true)
                                      setChatOrderId(d.order_id)
                                    
                                    }}
                                    className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                                  >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
          </svg>


                                  </Button>
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                    </>
                  )}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={chatModal && setChatOrderId?.length > 0} onClose={() => setChatModal(false)}>
              <Modal.Header>Order #{chatOrderId.slice(-4)} Support</Modal.Header>
              <Modal.Body>
                <div className="h-96 "> 
                  <Messages order_id={chatOrderId} user={user} />
                          
           





                </div>
                <div className='flex bg-white mt-2'>
                <input
                  type="text"
                  className="border border-gray-300 p-2 w-full mr-2 rounded-md"
                  placeholder="Enter your message here"
                  id='messageInput'
                  onKeyDown={
                    (e)=>{
                      if(e.keyCode === 13){
                        console.log("here")
                   sendMessage()
                      }
                    }
                  }
                />
    
                <Button
                  color="gray"
                  onClick={() => sendMessage()}
                >
                  Send
                </Button>
          
                </div>
         
                <input
                  type="file"
                  id="uploadImage"
                  className="mt-2 border rounded-md"

                  
                  accept=".jpg,.jpeg,.png,.HEIC"
                  multiple={false}
                    onChange={handleFileChange} 
                />
              </Modal.Body>
  
    </Modal>
    </>
  );
};

export default Orders;
