import React, { useState, useEffect,useRef } from 'react';

import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../utils/firebase';

const Messages = ({ order_id ,user}) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      };
      useEffect(() => {
        scrollToBottom();
      }, [messages]); // Dependency on the messages array
            
    const listenToMessages = () => {
        if (order_id?.length === 0) return;

        const messagesRef = collection(db, 'Conversations', order_id, 'Messages');
        const q = query(messagesRef, orderBy('createdAt'));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const fetchedMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setMessages(fetchedMessages);
        });

        return unsubscribe;
    };

    useEffect(() => {
        const unsubscribe = listenToMessages();
        return () => unsubscribe();
    }, [order_id]);

    return (
        <div className="chat-container">
          <div className="messages">
                {messages && messages.length ? messages.map((message) => (
                    <div key={message.id} className={`message ${message.sender_name === user.username ? 'sender' : 'receiver'}`}>
                       <p className="text-sm font-bold text-gray-700">{message.sender_name}</p>
                       <p className="message-content text-sm ml-1">{message.content}</p>
                        <p className="message-timestamp text-xs text-right mb-1">{message.createdAt?.toDate().toLocaleDateString()}</p>
                        {
                            message.attachment.url?.length > 0 &&
                            <div className="message-attachment">
                                <a href={message.attachment.url} target="_blank">
                                <img className='sm:w-72' src={message.attachment.url} alt="attachment" />
                                </a>
                            </div>

                        }
                   
                    </div>
                ))
                    :

                    <div className='h-full flex flex-col justify-center'>  
         <p className="text-center  text-sm text-gray-700">No messages yet</p>
                    </div>
           
                }
                <div ref={messagesEndRef} />
            </div>
        </div>
    );
};

export default Messages;
