import React, { useState, useEffect, useMemo } from "react";
import {
  fetchManufacturerMaterials,
  deleteMaterial,
  fetchSupplierName,
} from "../../utils/globalFunctions";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../components/TableComponent";

export default function Materials({ user }) {
  const [materials, setMaterials] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const navigate = useNavigate();

  const rowsPerPage = 6;

  // Fetch materials
  useEffect(() => {
    fetchManufacturerMaterials(user.manufacturerID).then(async (data) => {
      // Fetch supplier names
      const materialsWithSupplierNames = await Promise.all(
        data.map(async (material) => {
          const supplierName = await fetchSupplierName(material.supplierID);
          return { ...material, supplierName: supplierName || "N/A" };
        })
      );

      setMaterials(materialsWithSupplierNames);
      setFilteredMaterials(materialsWithSupplierNames);
      setIsLoading(false);
      setIsLastPage(materialsWithSupplierNames.length <= rowsPerPage);
    });
  }, [user.manufacturerID]);

  // Update last page status when filteredMaterials or currentPage changes
  useEffect(() => {
    checkIsLastPage();
  }, [filteredMaterials, currentPage]);

  // Handle deleting a material
  const handleDeleteMaterial = (material) => {
    deleteMaterial(material.id).then((res) => {
      if (res) {
        setMaterials((prevMaterials) =>
          prevMaterials.filter((mat) => mat.id !== material.id)
        );
        setFilteredMaterials((prevMaterials) =>
          prevMaterials.filter((mat) => mat.id !== material.id)
        );
      } else {
        alert("Error deleting material. Please try again.");
      }
    });
  };

  // Handle search input changes
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = materials.filter((material) =>
      material.material_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredMaterials(filtered);
    setCurrentPage(0);
  };

  // Check if the current page is the last page
  const checkIsLastPage = () => {
    setIsLastPage(
      (currentPage + 1) * rowsPerPage >= filteredMaterials.length
    );
  };

  // Navigate to the next page
  const handleNextPage = () => {
    if (!isLastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Navigate to the previous page
  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Define table columns
  const columns = [
    { Header: "Tag", accessor: "tag" },
    { Header: "Material Name", accessor: "material_name" },
    { Header: "Material Category", accessor: "materialCategory" },
    { Header: "Stocks",     
    Cell: ({ row }) => (
      <>
       {
        row.stockManagementEnabled === true ?
        <div>
          <p>Enabled</p>
        </div>
        : <p>Disabled</p>
      
       }

    
      </>
    ), },
    { Header: "Supplier", accessor: "supplierName" },
    { Header: "Unit Type", accessor: "unitType" },
    { Header: "Dimension", 
    Cell: ({ row }) => (
      console.log(row),
      <>

      {
       row.stockManagementEnabled  &&  row.unitType === 'AREA' ? 
        <div>
          <p>Units: {row.stockMetaData?.Units}</p>
          <p>Width: {row.stockMetaData?.Width} {row.stockMetaData?.metricType}</p>
          <p>Height: {row.stockMetaData?.Height} {row.stockMetaData?.metricType}</p>
        </div>
        : row.stockManagementEnabled  && row.unitType === 'LENGTH' ? 
        <div>
          <p>Units: {row.stockMetaData?.Units}</p>
          <p>Length: {row?.stockMetaData?.Length} {row.stockMetaData?.metricType}</p>
        </div>
        : row.stockManagementEnabled  && row.unitType === 'UNIT' ? 
        <div>
          <p>Units: {row?.stockMetaData?.Units}</p>
        </div>
        : "N/A"
      }
      </>
    ),
    },
    { Header: "Actions", accessor: "actions" },
  ];

  // Format materials data before rendering
  const formattedMaterials = useMemo(() => {
    return filteredMaterials.map((material) => {
      // Determine dimensions based on type and stockManagement
      let dimensions = "N/A";
      if (material.stockManagement) {
        switch (material.type) {
          case "AREA":
            dimensions = `Area: ${material.Area || "N/A"}, Width: ${
              material.Width || "N/A"
            }, Height: ${material.Height || "N/A"}`;
            break;
          case "LENGTH":
            dimensions = `Length: ${material.Length || "N/A"}`;
            break;
          case "UNIT":
            dimensions = `Units: ${material.Units || "N/A"}`;
            break;
          default:
            dimensions = "N/A";
        }
      }

      return {
        ...material,
        stockManagement: material.stockManagement ? "Enabled" : "Disabled",
        dimensions: dimensions,
        actions: (
          <div className="flex items-center">
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded ml-2"
              onClick={() => handleDeleteMaterial(material)}
            >
              Delete
            </button>
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded ml-2"
              onClick={() => navigate(`/edit/material/${material.id}`)}
            >
              View
            </button>
          </div>
        ),
      };
    });
  }, [filteredMaterials, navigate]);

  // Get paginated data
  const paginatedData = useMemo(() => {
    const startIndex = currentPage * rowsPerPage;
    return formattedMaterials.slice(startIndex, startIndex + rowsPerPage);
  }, [formattedMaterials, currentPage]);

  return (
    <>
      <div className="flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">Manufacturer Materials</h1>
          <div className="flex mt-2">
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
              onClick={() => navigate("/materials")}
            >
              Materials
            </button>
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs ml-2"
              onClick={() => navigate("/virtual-configurator")}
            >
              Virtual Configurator
            </button>
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs ml-2"
              onClick={() => navigate("/create-material")}
            >
              Create Material
            </button>
          </div>
        </div>
      </div>

      <div className="pt-4 flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <input
            type="text"
            placeholder="Search by material name"
            className="bg-white text-xs p-2 w-full mt-1 rounded border"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center mt-8">
        <div className="w-full overflow-x-auto">
          <div className="table-container">
            <div className="w-full mx-auto">
              <div className="flex justify-between mb-4">
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 0}
                >
                  Previous
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                  onClick={handleNextPage}
                  disabled={isLastPage}
                >
                  Next
                </button>
              </div>
              <TableComponent
                columns={columns}
                isLoading={isLoading}
                data={paginatedData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}