import React, { useEffect, useState } from "react";

import { useParams } from 'react-router-dom';
import { fetchManufacturerProduct } from "../../utils/globalFunctions";

const ManufacturerProduct = ({user,manufacturer}) => {
    const [product,setProduct] = useState(null)
    const [sellers,setSellers] = useState(null)


    const { id } = useParams();
    console.log(id)

    useEffect(()=>{
        if(manufacturer){
            fetchManufacturerProduct(id).then((data)=>{
                console.log(data)
                setProduct(data)
            })



        }
    },[manufacturer])
    console.log(product)
    console.log(sellers)
    return (
        <div>
            {/* Your code here */}
        </div>
    );
};

export default ManufacturerProduct;