import { useState, useEffect } from "react";
import ExtrasModal from "./ExtrasModal";
import axios from "axios";
import { globalAlert, globalSuccess } from "../utils/globalFunctions";
import { addressURL } from "../utils/globalFunctions";
import OrderNotes from "./OrderNotes";
import ComboBox from "./ComboBox";
import autoTable from 'jspdf-autotable'
import { jsPDF } from "jspdf";
import { Button } from 'flowbite-react';
import Orders from "./Orders";
export default function OrderListStore(props) {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [extras, setExtras] = useState(props.extras || []);
  const [newExtrasAdded, setNewExtrasAdded] = useState(false);
  const [total, setTotal] = useState(props.totalPrice);
  const [orders, setOrders] = useState(props.orders || []);
  const [customerName, setCustomerName] = useState("");
  const [disbaleButton,setDisableButton] = useState(false)
  const [widthChange, setWidthChange] = useState(null);
  const [orderNotes, setOrderNotes] = useState([]);

  const [heightChange, setHeightChange] = useState(null);

  const [dataUpdated, setDataUpdated] = useState(false);
  console.log(props.orders);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (props.totalPrice) {
      setTotal(props.totalPrice);
    } else {
      setTotal(0);
    }
  }, [props.totalPrice]);



  const shortenBlackOut = (input) => {
    // replace Blackout if exist in the string
    let output = input.replace("Blackout", "B.O");
    if(output === input){
        output = input.replace("BLACKOUT", "B.O");
    }
    return output
}

function convertTurkishCharacters(input) {
    const turkishCharacters = {
      'ç': 'c',
      'ğ': 'g',
      'ı': 'i',
      'i': 'i',
      'ö': 'o',
      'ş': 's',
      'ü': 'u',
      'Ç': 'C',
      'Ğ': 'G',
      'İ': 'I',
      'I': 'I',
      'Ö': 'O',
      'Ş': 'S',
      'Ü': 'U'
    };
  
    return input.replace(/[çğıiöşüÇĞİIÖŞÜ]/g, char => turkishCharacters[char] || char);
  }


  const handleGeneratePdf = () => {
        

    const doc = new jsPDF({
        orientation: 'landscape'
    }
    );
    doc.text(`${orders[0].customerName}`, 10, 10);


    autoTable(doc, { 
        theme:"grid",
        alternateRowStyles:
        {
            fillColor: [242, 247, 255]
        },
        headStyles:
        {
            fillColor: [174, 214, 214],
            textColor:"black"
        },
        styles:{
            fontSize: 10,
            cellPadding: 1,
            textColor:"black",
            fontWeight:"bold",
            lineWidth: 0.3,
            lineColor: [0, 0, 0],
            overflow: "linebreak"
        },



        head: [['No','Fabric','R.Name','W','H','Qnt','Chain','B.Type','Cassette','Motor','Spring','M.Wand','Price']],
          body:       orders.map((order,index) => {
            return [
                  `${index+1}`,   
                  `${shortenBlackOut(order.fabric_name)}`,
                  `${order.room_name}`,
                  `${order.width}`,
                  `${order.height}`,
                  `${order.quantity}`,
                  `${order.chainLocation}`,
                  `${order.barType}`,
                  `${order.covering}`,
                  `${order.motor ? "Yes" : "No"}`,
                  `${order.spring ? "Yes" : "No"}`,
                  `${order.wand ? "Yes" : "No"}`,
                  `${order.price}`
            ]
        }
        ),
   
          


       })

    if(extras?.length > 0){
        autoTable(doc, { 
            alternateRowStyles:
            {
                fillColor: [137, 173, 173]
            },
            tableStyle: {
                lineWidth: 0.1,
                lineColor: [0, 0, 0]
            },
            headStyles:
            {
                fillColor: [174, 214, 214],
                textColor:"black"
            },
            bodyStyles:{
                fontWeight:"bold",
                fontSize: 11,
                cellPadding: 1,
            }
            ,
            head: [['Remote Control','Remote Control Charger']],
              body: extras.map((extra) => {
                    return [
                        `${extra.remoteControl} units`,
                        `${extra.remoteControlCharger} units`
                    ]
                }
              ),
  
           })
    }



    if(orderNotes?.length > 0){
        autoTable(doc, { 
            
            
            columnStyles:{
                0:{columnWidth: 30},
                2:{
                    columnWidth: 40,
                }
            },

            headStyles:
            {
                fillColor: [174, 214, 214],
                textColor:"black"
            },
            styles:{
                fontSize: 10,
                cellPadding: 1,
                textColor:"black",
                fontWeight:"bold",
                lineWidth: 0.3,
                lineColor: [0, 0, 0],
            },
            head: [
                ['Sender','Note','Created At']
            ],
            body: 
                orderNotes.map((note) => {
                    return [
                        `${note.sender}`,
                        `${convertTurkishCharacters(note.note)}`,
                        `${new Date(note.createdAt).toLocaleDateString()}`
                    ]
                }
                ),
  
           })

    }
 


    doc.save('cachet-blinds-price-list.pdf')

    };



  useEffect(() => {
    if (props.orders.length > 0) {
      setOrders(props.orders);
    }
  }, [props.orders]);

  // useEffect(() => {
  //   if (props.isSearch) {
  //     getOrderTotal();
  //   }
  // }, [props.isSearch]);

  async function getOrderWithGroupByCustomerID() {
    let orderData = orders;
    try {
      const res = await axios.get(
        addressURL +
          `/api/getOrderByCustomerID?customerID=${orders[0].customerID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      );
      if (res.data.success) {
        if (res.data.data.length === 0) {
          setCustomerName(orderData[0].customerName);
          setOrders([]);
          setTotal(0);
          setDataUpdated(false);
          return;
        } else {
          setOrders(res.data.data[0].orders);
          setExtras(res.data.data[0].extras)
          setTotal(res.data.data[0].totalPrice);
          setDataUpdated(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (dataUpdated) {
      getOrderWithGroupByCustomerID();
    }
  }, [dataUpdated]);

  useEffect(() => {
    if (newExtrasAdded ) {
      getOrderWithGroupByCustomerID()
      setNewExtrasAdded(false);
    }
  }, [newExtrasAdded]);

  const handleDeleteOrder = (orderID) => {
    axios
      .post(
        addressURL + "/api/deleteOrder",
        {
          orderID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res.data.success);
        if (res.data.success) {
          let newOrders = orders.filter((order) => order.orderID !== orderID);
          setOrders(newOrders);
        } else {
          globalAlert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteExtras = (extraID) => {
    axios
      .post(
        addressURL + "/api/deleteExtras",
        {
          extraID,
          index: props.index, // clear mem cache in server
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          setNewExtrasAdded(true);
        } else {
          globalAlert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <>
      {orders?.length > 0 ? (
        <div className="mt-8 sm:px-6 lg:px-8">
          <div>
            <h2 className="text-base font-medium text-gray-900">
              Order #{orders[0].customerID}
            </h2>
            <h2 className="text-base font-medium text-gray-900">
              Customer Name: {orders[0].customerName}
            </h2>
            {/* <button 
            disabled={disbaleButton}
              onClick={(e) => {
                // call sendinvoice api
                e.preventDefault()
                axios
                  .post(
                    addressURL + "/api/veem/sendInvoice",
                    {
                      orderID: orders[0].customerID,
                      totalPrice: total,
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                      },
                      withCredentials: true,
                    }
                  )
                  .then((res) => {
                    if (res.data.success) {
                      setDisableButton(true)
                      globalSuccess("Invoice has been sent to your email to be paid.");
                    } else {
                      globalAlert(res.data.message);
                    }
                  }
                  )
                  .catch((err) => {
                    
                    console.log(err);
                  }
                  );

              }}
              className="inline-flex items-center px-4 py-2 mt-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Pay now
              </button> */}
          </div>
          <div className="ml-auto flex">
                    <Button onClick={() => handleGeneratePdf()}>Generate PDF</Button>
                    {/* <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => handleMatchRules()}
                    >
                     Match Rules
                    </button> */}
                </div>
   

                  
          <Orders
            orders={orders}
            products={props.products}
            getOrderWithGroupByCustomerID={getOrderWithGroupByCustomerID}
            handleDeleteOrder={handleDeleteOrder}
            userRole={"Store"}
          />

          <div className="mt-4 flex flex-col">
            <p className="text-base font-medium text-gray-900 mb-2"> Extras </p>
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block  py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className=" divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Remote Control
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Charger
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Hub
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Solar Panel
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {extras?.map((extra, index) => {
                      return (
                        <>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            <tr className="divide-x divide-gray-200">
                              <td className="px-4 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div>
                                    <div className="text-sm font-medium text-gray-900">
                                      {extra.remoteControl} unit ({extra.motor_brand})
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                  {extra.remoteControlCharger} unit
                                </div>
                              </td>
                              <td className="px-4 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                  {extra.remoteControlHub} unit
                                </div>
                              </td>
                              <td className="px-4 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                  {extra.remoteControlSolarPanel} unit
                                </div>
                              </td>
                              <td className="px-4 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                  $
                                  {extra.motor_brand === "novo" ? extra.remoteControl * 10 :
                                    extra.remoteControl * 30 +
                                    extra.remoteControlCharger * 25 +
                                    extra.remoteControlHub * 200 + extra.remoteControlSolarPanel * 200
                                    }
                                </div>
                              </td>
                              <td className="px-4 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 flex">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleDeleteExtras(extra.orderExtrasID)
                                    }
                                    className="inline-flex  ml-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 mb-4 w-full flex justify-center">
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Add Extras
            </button>
          </div>

          <OrderNotes
            orderID={orders[0].customerID}
            setOrderNotes={(notes) => {
              setOrderNotes(notes);
            }}
          />
          <div className="mt-2">
            <h2 className="text-base font-medium text-gray-900">
              Manufacturer Price: ${total}
            </h2>
          </div>
          <hr class="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />

          <ExtrasModal
            open={open}
            customerID={orders[0].customerID}
            setOpen={setOpen}
            setNewExtrasAdded={setNewExtrasAdded}
            index={props.index}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-96">
          <p className="text-base font-medium text-gray-900 mb-2 ">
            {" "}
            You deleted all the orders for {customerName}
          </p>
        </div>
      )}
    </>
  );
}
