import React, { useEffect } from 'react'
import { handleCalculateItemTotal, handleCalculateTotalOrder } from '../utils/globalFunctions'

export default function ShowSubItemTotal({orderItem=undefined,discounts=undefined, isCustomer=false}) {
    const [totalPrice, setTotalPrice] = React.useState(0)


    useEffect(() => {
      console.log(orderItem)
      console.log(discounts)
      if(orderItem === undefined || discounts === undefined){
        return
      }
       handleCalculateItemTotal(orderItem,discounts).then((res)=>{
         if(isCustomer){
          setTotalPrice(res[1])
         }else{
          setTotalPrice(res[0])
         }
            
       })
    }, [orderItem,discounts])

  return (
    <div>
            <p>${totalPrice}</p>
    </div>
  )
}
