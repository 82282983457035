import React from 'react'

export default function Dealers() {
    return (
        <div className="flex flex-col  min-h-[90vh] w-screen">
          <div
            className="m-auto cursor-pointer p-4 bg-gray-600 w-36 rounded-md hover:bg-gray-700"
            onClick={() => {
              window.location.href = "/dealers/details";
            }}
          >
            <p className="text-2xl text-white text-center">Details</p>
          </div>
          <div
            className="m-auto cursor-pointer p-4 bg-gray-600 w-36 rounded-md hover:bg-gray-700 mt-[-60px]"
            onClick={() => {
              window.location.href = "/dealers/active-orders";
            }}
          >
            
            <p className="text-2xl text-white text-center">Active Orders</p>
          </div>
          <div
            className="m-auto cursor-pointer p-4 bg-gray-600 w-36 rounded-md hover:bg-gray-700 mt-[-60px]"
            onClick={() => {
              window.location.href = "/dealers/completed-orders";
            }}
          >
            
            <p className="text-2xl text-white text-center">Completed Orders</p>
          </div>
          <div
            className="m-auto cursor-pointer p-4 bg-gray-600 w-36 rounded-md hover:bg-gray-700 mt-[-60px]"
            onClick={() => {
              window.location.href = "/dealers/notify";
            }}
          >
            
            <p className="text-2xl text-white text-center">Notify</p>
          </div>
        </div>
      );
}
