import React from "react"


export default function Footer(){
    return(
        <>
      {/* Footer */}
      <footer className="bg-gray-900">
        <div className="container mx-auto px-6 py-12">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 mb-6">
              <img
                src="/logoWhite.png"
                alt="Supply Circle Logo"
                className="h-8 w-auto mb-4"
              />
              <p className="text-gray-500">
                Revolutionizing supply chains by connecting you directly with manufacturers.
              </p>
            </div>
            <div className="w-full md:w-1/3 mb-6">
              <h3 className="text-white text-xl font-semibold mb-4">Company</h3>
              <ul>
                <li className="mb-2">
                  <a href="/about" className="text-gray-500 hover:text-white">
                    About Us
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/contact" className="text-gray-500 hover:text-white">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/3 mb-6">
              <h3 className="text-white text-xl font-semibold mb-4">Legal</h3>
              <ul>
                <li className="mb-2">
                  <a href="/privacy" className="text-gray-500 hover:text-white">
                    Privacy Policy
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/terms" className="text-gray-500 hover:text-white">
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-8 text-center text-gray-500">
            © 2024 Supply Circle. All rights reserved.
          </div>
        </div>
      </footer>
        
        </>
    )
}