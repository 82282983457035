// src/utils/generateSlug.js
import slugify from 'slugify';
import { nanoid } from 'nanoid';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import { storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const uploadImage = async (file) => {
  try {
    // Create a unique filename using timestamp
    const timestamp = Date.now();
    const storageRef = ref(storage, `images/${timestamp}_${file.name}`);

    // Upload the file to the specified path
    const snapshot = await uploadBytes(storageRef, file);

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);

    console.log('File available at', downloadURL);

    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const generateSlug = (storeName) => {
  const baseSlug = slugify(storeName, { lower: true, strict: true });
  return baseSlug;
};

export const generateUniqueId = (size=4) => {
  const id = nanoid(size); // Generates a 4-character unique ID
  return id;
};


export const handleSignOut = () => {
  signOut(auth).then(() => {
    // Sign-out successful.
    window.location = "/"
  }).catch((error) => {
    // An error happened.
  });
}


