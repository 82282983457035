import React, { useState, useEffect, Fragment } from "react";
import {
  fetchStoreVariations,
  fetchSuppliers,
  createStoreProduct,
  createStoreCategory,
  getStorePriceLists,
  createSupplier,
} from "../../utils/globalFunctions";
import { Checkbox } from "flowbite-react";
import { useSnackbar } from "react-simple-snackbar";
import ExcelLoader from "../../components/excelLoader";
import { useUser } from "../../utils/context";
import { Dialog, Transition } from "@headlessui/react";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import { serverTimestamp } from "firebase/firestore";

export default function CreateProduct() {
  const [products, setProducts] = useState([]);
  const [selectedCustomizations, setSelectedCustomizations] = useState([]);
  const { user } = useUser();

  // Modal States
  const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
  const [isSupplierModalOpen, setSupplierModalOpen] = useState(false);
  const [isPriceListModalOpen, setPriceListModalOpen] = useState(false);
  const [isCustomizationsModalOpen, setCustomizationsModalOpen] = useState(false);

  // Snackbar
  const [openSnackbar] = useSnackbar({
    position: "top-center",
    style: {
      color: "white",
      backgroundColor: "#1a1a1a",
      fontSize: "16px",
      textAlign: "center",
      borderRadius: "8px",
      boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
      fontWeight: "500",
    },
  });

  // Form States
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState("AREA");
  const [metricUnit, setMetricUnit] = useState("cm");
  const [pricingRule, setPricingRule] = useState("Per Metric");
  const [productPrice, setProductPrice] = useState("");
  const [selectedPriceList, setSelectedPriceList] = useState(null);
  const [minDimensions, setMinDimensions] = useState({});
  const [productCategory, setProductCategory] = useState("");
  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [stockManagement, setStockManagement] = useState(false);
  const [stockQuantity, setStockQuantity] = useState("");
  const [productCost, setProductCost] = useState("");

  // Fractional Inputs States for inch and ft
  const [minWidthWhole, setMinWidthWhole] = useState("");
  const [minWidthFraction, setMinWidthFraction] = useState("");
  const [minHeightWhole, setMinHeightWhole] = useState("");
  const [minHeightFraction, setMinHeightFraction] = useState("");
  const [minLengthWhole, setMinLengthWhole] = useState("");
  const [minLengthFraction, setMinLengthFraction] = useState("");

  // Modal Form States
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newSupplierInfo, setNewSupplierInfo] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [newPriceListName, setNewPriceListName] = useState("");
  const [priceLists, setPriceLists] = useState([]);

  useEffect(() => {
    // Fetch suppliers
    fetchSuppliers(user.store_id).then((data) => {
      setSupplierList(data);
    });
    fetchStoreVariations(user.store_id).then((data) => {
      // remove the isStore == true
      let vars = [];
      data.forEach((d) => {
        if(d.isStore ){
          vars.push(d);
        }
      });
      setProducts(vars);

    });


    // Fetch price lists
    getStorePriceLists(user.store_id).then((data) => {
      setPriceLists(data);
    });
  }, [user.store_id]);

  const fractionToDecimal = (fraction) => {
    if (!fraction || fraction === "") {
      return 0;
    }
    const [numerator, denominator] = fraction.split("/").map(Number);
    if (denominator) {
      return numerator / denominator;
    }
    return 0;
  };

  const handleCreateProduct = () => {
    // Validation
    if (productName.trim() === "") {
      toast.error("Please enter product name");
      return;
    }
    if (productCategory === "") {
      toast.error("Please select a category");
      return;
    }
    if (selectedSupplier === "") {
      toast.error("Please select a supplier");
      return;
    }
    if (pricingRule === "Excel Sheet" && !selectedPriceList) {
      toast.error("Please select a price list");
      return;
    }
    if (pricingRule !== "Excel Sheet" && productPrice === "") {
      toast.error("Please enter a product price");
      return;
    }
    if (pricingRule !== "Excel Sheet" && isNaN(productPrice)) {
      toast.error("Product price must be a number");
      return;
    }
    if (stockManagement) {
      if (stockQuantity === "" || isNaN(stockQuantity)) {
        toast.error("Please enter a valid stock quantity");
        return;
      }
      if (productCost === "" || isNaN(productCost)) {
        toast.error("Please enter a valid product cost");
        return;
      }
    }

    // Handle minDimensions based on productType and metricUnit
    let minDims = {};
    // if (productType === "AREA") {
    //   if (metricUnit === "inch" || metricUnit === "ft") {
    //     if (minWidthWhole === "" || isNaN(minWidthWhole)) {
    //       toast.error("Please enter a valid whole number for minimum width");
    //       return;
    //     }
    //     if (minHeightWhole === "" || isNaN(minHeightWhole)) {
    //       toast.error("Please enter a valid whole number for minimum height");
    //       return;
    //     }
    //     const minWidthDecimal =
    //       Number(minWidthWhole || 0) + fractionToDecimal(minWidthFraction);
    //     const minHeightDecimal =
    //       Number(minHeightWhole || 0) + fractionToDecimal(minHeightFraction);
    //     minDims.minWidth = minWidthDecimal;
    //     minDims.minHeight = minHeightDecimal;
    //   } else {
    //     if (
    //       minDimensions.minWidth === "" ||
    //       isNaN(minDimensions.minWidth)
    //     ) {
    //       toast.error("Please enter a valid minimum width");
    //       return;
    //     }
    //     if (
    //       minDimensions.minHeight === "" ||
    //       isNaN(minDimensions.minHeight)
    //     ) {
    //       toast.error("Please enter a valid minimum height");
    //       return;
    //     }
    //   }
    // } else if (productType === "LENGTH") {
    //   if (metricUnit === "inch" || metricUnit === "ft") {
    //     if (minLengthWhole === "" || isNaN(minLengthWhole)) {
    //       toast.error("Please enter a valid whole number for minimum length");
    //       return;
    //     }
    //     const minLengthDecimal =
    //       Number(minLengthWhole || 0) + fractionToDecimal(minLengthFraction);
    //     minDims.minLength = minLengthDecimal;
    //   } else {
    //     if (
    //       minDimensions.minLength === "" ||
    //       isNaN(minDimensions.minLength)
    //     ) {
    //       toast.error("Please enter a valid minimum length");
    //       return;
    //     }
    //   }
    // }

        // Prepare product data

    // Prepare product data
    let newProduct = {
      name: productName,
      isPriceList: pricingRule === "Excel Sheet",
      isMetric: pricingRule === "Per Metric",
      category: productCategory,
      store_id: user.store_id,
      customizations: selectedCustomizations,
      supplierID: selectedSupplier,
      metricType: metricUnit,
      type: productType,
      minDimensions: { ...minDimensions, ...minDims },
    };

    if (pricingRule !== "Excel Sheet") {
      newProduct.price = productPrice;
    }

    if (stockManagement) {
      newProduct.isStock = true;
      newProduct.stock = {
        stock: stockQuantity,
        cost: productCost,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };
    }

    // Create product
    createStoreProduct(newProduct, selectedPriceList?.ref).then((res) => {
      if (res) {
        openSnackbar("Product Created Successfully");
        // Reset form
        setProductName("");
        setProductPrice("");
        setProductCategory("");
        setSelectedSupplier("");
        setSelectedCustomizations([]);
        setStockManagement(false);
        setStockQuantity("");
        setProductCost("");
        setMinDimensions({});
        setMinWidthWhole("");
        setMinWidthFraction("");
        setMinHeightWhole("");
        setMinHeightFraction("");
        setMinLengthWhole("");
        setMinLengthFraction("");
      } else {
        openSnackbar("An error occurred. Please try again.");
      }
    });
  };

  const handleCreateCategory = () => {
    if (newCategoryName.trim() === "") {
      toast.error("Please enter category name");
      return;
    }
    createStoreCategory(user.store_id, newCategoryName).then((res) => {
      if (res) {
        toast.success("Category Created Successfully");
        setNewCategoryName("");
        setCategoryModalOpen(false);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    });
  };

  const handleCreateSupplier = () => {
    const { name, email, phone } = newSupplierInfo;
    if (name.trim() === "" || email.trim() === "" || phone.trim() === "") {
      toast.error("Please fill all supplier fields");
      return;
    }
    const data = {
      supplierName: name,
      supplierEmail: email,
      supplierPhoneNumber: phone,
      creator: user.store_id,
      isDeleted: false,
      isStore: true,
    };
    createSupplier(data).then((res) => {
      if (res) {
        toast.success("Supplier Created Successfully");
        setNewSupplierInfo({ name: "", email: "", phone: "" });
        fetchSuppliers(user.store_id).then((data) => {
          setSupplierList(data);
          setSupplierModalOpen(false);
        });
      } else {
        toast.error("An error occurred. Please try again.");
      }
    });
  };

  return (
    <>
      <div className="flex flex-col items-center mt-24">
        <div className="w-full">
            <button
                className="bg-indigo-600 sm:ml-16 ml-4 mb-4 text-white font-semibold px-6 py-3 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => {
                    //go back to the previous page
                    window.history.back();
                }}
            >Go Back</button>
        </div>
        <h1 className="text-4xl font-extrabold mb-8">Create New Product</h1>
        <div className="w-full max-w-2xl bg-white shadow-lg rounded-lg p-8">
          {/* Product Name */}
          <div className="mb-6">
            <label className="block text-gray-800 font-medium mb-2">
              Product Name
            </label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter product name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>

          {/* Product Type */}
          <div className="mb-6">
            <label className="block text-gray-800 font-medium mb-2">
              Product Type
            </label>
            <div className="flex space-x-6">
              {["AREA"].map((type) => (
                <label key={type} className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio text-indigo-600"
                    name="productType"
                    value={type}
                    checked={productType === type}
                    onChange={() => setProductType(type)}
                  />
                  <span className="ml-2 text-gray-700">{type}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Metric Unit */}
          {productType !== "UNIT" && (
            <div className="mb-6">
              <label className="block text-gray-800 font-medium mb-2">
                Metric Unit
              </label>
              <select
                className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={metricUnit}
                onChange={(e) => {
                  setMetricUnit(e.target.value);
                  // Reset fractional inputs when changing metric unit
                  setMinWidthWhole("");
                  setMinWidthFraction("");
                  setMinHeightWhole("");
                  setMinHeightFraction("");
                  setMinLengthWhole("");
                  setMinLengthFraction("");
                  setMinDimensions({});
                }}
              >
                <option value="cm">Centimeter (cm)</option>
                {/* <option value="m">Meter (m)</option> */}
                <option value="inch">Inch (in)</option>
                {/* <option value="ft">Feet (ft)</option> */}
              </select>
            </div>
          )}

          {/* Pricing Rule */}
          <div className="mb-6">
            <label className="block text-gray-800 font-medium mb-2">
              Pricing Rule
            </label>
            <select
              className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={pricingRule}
              onChange={(e) => setPricingRule(e.target.value)}
            >
              <option value="Per Metric">Per Metric</option>
              {productType === "AREA" && (
                <option value="Excel Sheet">Excel Sheet</option>
              )}
            </select>
          </div>

          {/* Price or Price List */}
          {pricingRule === "Excel Sheet" ? (
            <div className="mb-6">
              <label className="block text-gray-800 font-medium mb-2">
                Price List
              </label>
              <div className="flex items-center space-x-2">
                <select
                  className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  value={selectedPriceList?.id || ""}
                  onChange={(e) =>
                    setSelectedPriceList(
                      priceLists.find((pl) => pl.id === e.target.value)
                    )
                  }
                >
                  <option value="">Select Price List</option>
                  {priceLists.map((pl) => (
                    <option key={pl.id} value={pl.id}>
                      {pl.price_list_name}
                    </option>
                  ))}
                </select>
                <button
                  className="text-indigo-600 hover:underline"
                  onClick={() => setPriceListModalOpen(true)}
                >
                  New Price List
                </button>
              </div>
            </div>
          ) : (
            <div className="mb-6">
              <label className="block text-gray-800 font-medium mb-2">
                Product Price
              </label>
              <input
                type="text"
                className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter product price"
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
              />
            </div>
          )}

          {/* Minimum Dimensions */}


          {/* Category Selection */}
          <div className="mb-6">
            <label className="block text-gray-800 font-medium mb-2">
              Product Category
            </label>
            <div className="flex items-center space-x-2">
              <select
                className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={productCategory}
                onChange={(e) => setProductCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                {user?.categories?.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              <button
                className="text-indigo-600 hover:underline"
                onClick={() => setCategoryModalOpen(true)}
              >
                New Category
              </button>
            </div>
          </div>

          {/* Supplier Selection */}
          <div className="mb-6">
            <label className="block text-gray-800 font-medium mb-2">
              Supplier
            </label>
            <div className="flex items-center space-x-2">
              <select
                className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={selectedSupplier}
                onChange={(e) => setSelectedSupplier(e.target.value)}
              >
                <option value="">Select Supplier</option>
                {supplierList.map((supplier) => (
                  <option key={supplier.id} value={supplier.id}>
                    {supplier.supplierName}
                  </option>
                ))}
              </select>
              <button
                className="text-indigo-600 hover:underline"
                onClick={() => setSupplierModalOpen(true)}
              >
                New Supplier
              </button>
            </div>
          </div>

          {/* Stock Management */}
          <div className="mb-6">
            {/* <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-indigo-600"
                checked={stockManagement}
                onChange={(e) => setStockManagement(e.target.checked)}
              />
              <span className="ml-2 text-gray-800 font-medium">
                Enable Stock Management
              </span>
            </label> */}
            {stockManagement && (
              <div className="mt-4 space-y-4">
                <div>
                  <label className="block text-gray-800 font-medium mb-2">
                    Stock Quantity
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Enter stock quantity"
                    value={stockQuantity}
                    onChange={(e) => setStockQuantity(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-gray-800 font-medium mb-2">
                    Product Cost (per item)
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Enter product cost"
                    value={productCost}
                    onChange={(e) => setProductCost(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>

          {/* Customizations */}
          <div className="mb-8">
            <label className="block text-gray-800 font-medium mb-2">
              Customizations
            </label>
            <button
              className="w-full bg-gray-100 border border-gray-300 rounded-md px-4 py-2 text-left focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => setCustomizationsModalOpen(true)}
            >
              {selectedCustomizations.length > 0
                ? `${selectedCustomizations.length} Customizations Selected`
                : "Select Customizations"}
            </button>
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <button
              className="w-full bg-indigo-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={handleCreateProduct}
            >
              Create Product
            </button>
          </div>
        </div>
      </div>

      {/* Modals */}
      {/* Customizations Modal */}
      <Transition appear show={isCustomizationsModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => setCustomizationsModalOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white rounded-lg max-w-md w-full mx-auto p-6 space-y-4 shadow-xl transition-all">
                <Dialog.Title className="text-2xl font-bold text-indigo-600">
                  Select Customizations
                </Dialog.Title>
                <div className="max-h-96 overflow-y-scroll">
                  {products.map((product) => (
                    <div
                      key={product.id}
                      className="flex items-center border-b py-2"
                    >
                      <Checkbox
                      className="ml-2 bg-gray-300"
                        checked={selectedCustomizations.includes(product.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedCustomizations((prev) => [
                              ...prev,
                              product.id,
                            ]);
                          } else {
                            setSelectedCustomizations((prev) =>
                              prev.filter((id) => id !== product.id)
                            );
                          }
                        }}
                      />
                      <span className="ml-2">{product.variation_title}</span>
                    </div>
                  ))}
                </div>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setCustomizationsModalOpen(false)}
                    className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    Done
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Category Modal */}
      <Transition appear show={isCategoryModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => setCategoryModalOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white rounded-lg max-w-md w-full mx-auto p-6 space-y-4 shadow-xl transition-all">
                <Dialog.Title className="text-2xl font-bold text-indigo-600">
                  Create New Category
                </Dialog.Title>
                <div className="space-y-4">
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Enter category name"
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                  />
                </div>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setCategoryModalOpen(false)}
                    className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleCreateCategory}
                    className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    Create
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Supplier Modal */}
      <Transition appear show={isSupplierModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => setSupplierModalOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white rounded-lg max-w-md w-full mx-auto p-6 space-y-4 shadow-xl transition-all">
                <Dialog.Title className="text-2xl font-bold text-indigo-600">
                  Create New Supplier
                </Dialog.Title>
                <div className="space-y-4">
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Supplier Name"
                    value={newSupplierInfo.name}
                    onChange={(e) =>
                      setNewSupplierInfo((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                  <input
                    type="email"
                    className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Supplier Email"
                    value={newSupplierInfo.email}
                    onChange={(e) =>
                      setNewSupplierInfo((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                  <PhoneInput
                    defaultCountry="CA"
                    countries={["CA", "US"]}
                    value={newSupplierInfo.phone}
                    onChange={(e) =>
                      setNewSupplierInfo((prev) => ({
                        ...prev,
                        phone: e,
                      }))
                    }
                    className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Supplier Phone Number"
                  />
                </div>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setSupplierModalOpen(false)}
                    className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleCreateSupplier}
                    className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    Create
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Price List Modal */}
      <Transition appear show={isPriceListModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => setPriceListModalOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white rounded-lg max-w-md w-full mx-auto p-6 space-y-4 shadow-xl transition-all">
                <Dialog.Title className="text-2xl font-bold text-indigo-600">
                  Upload Price List
                </Dialog.Title>
                <div className="space-y-4">
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Price List Name"
                    value={newPriceListName}
                    onChange={(e) => setNewPriceListName(e.target.value)}
                  />
                  <ExcelLoader
                    user={user}
                    name={newPriceListName}
                    isStore={true}
                    setUploaded={(bool) => {
                      if (bool) {
                        getStorePriceLists(user.store_id).then((data) => {
                          setPriceLists(data);
                          setPriceListModalOpen(false);
                        });
                      }
                    }}
                  />
                </div>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setPriceListModalOpen(false)}
                    className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}



// {productType !== "UNIT" && (
//   <div className="mb-6">
//     <label className="block text-gray-800 font-medium mb-2">
//       Minimum {productType === "AREA" ? "Dimensions" : "Length"}
//     </label>
//     {metricUnit === "inch" || metricUnit === "ft" ? (
//       productType === "AREA" ? (
//         <div className="flex space-x-4">
//           {/* Width */}
//           <div className="w-1/2">
//             <label className="block text-gray-700 mb-1">Width</label>
//             <div className="flex space-x-2">
//               <input
//                 type="number"
//                 className="w-1/2 border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//                 placeholder="Whole"
//                 value={minWidthWhole}
//                 onChange={(e) => setMinWidthWhole(e.target.value)}
//               />
//               <select
//                 className="w-1/2 border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//                 value={minWidthFraction}
//                 onChange={(e) => setMinWidthFraction(e.target.value)}
//               >
//                 <option value="">0</option>
//                 <option value="1/16">1/16</option>
//                 <option value="1/8">1/8</option>
//                 <option value="3/16">3/16</option>
//                 <option value="1/4">1/4</option>
//                 <option value="5/16">5/16</option>
//                 <option value="3/8">3/8</option>
//                 <option value="7/16">7/16</option>
//                 <option value="1/2">1/2</option>
//                 <option value="9/16">9/16</option>
//                 <option value="5/8">5/8</option>
//                 <option value="11/16">11/16</option>
//                 <option value="3/4">3/4</option>
//                 <option value="13/16">13/16</option>
//                 <option value="7/8">7/8</option>
//                 <option value="15/16">15/16</option>
//               </select>
//             </div>
//           </div>
  
//           <div className="w-1/2">
//             <label className="block text-gray-700 mb-1">Height</label>
//             <div className="flex space-x-2">
//               <input
//                 type="number"
//                 className="w-1/2 border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//                 placeholder="Whole"
//                 value={minHeightWhole}
//                 onChange={(e) => setMinHeightWhole(e.target.value)}
//               />
//               <select
//                 className="w-1/2 border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//                 value={minHeightFraction}
//                 onChange={(e) => setMinHeightFraction(e.target.value)}
//               >
//                 <option value="">0</option>
//                 <option value="1/16">1/16</option>
//                 <option value="1/8">1/8</option>
//                 <option value="3/16">3/16</option>
//                 <option value="1/4">1/4</option>
//                 <option value="5/16">5/16</option>
//                 <option value="3/8">3/8</option>
//                 <option value="7/16">7/16</option>
//                 <option value="1/2">1/2</option>
//                 <option value="9/16">9/16</option>
//                 <option value="5/8">5/8</option>
//                 <option value="11/16">11/16</option>
//                 <option value="3/4">3/4</option>
//                 <option value="13/16">13/16</option>
//                 <option value="7/8">7/8</option>
//                 <option value="15/16">15/16</option>
//               </select>
//             </div>
//           </div>
//         </div>
//       ) : (
   
//         <div className="w-full">
//           <label className="block text-gray-700 mb-1">Length</label>
//           <div className="flex space-x-2">
//             <input
//               type="number"
//               className="w-1/2 border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//               placeholder="Whole"
//               value={minLengthWhole}
//               onChange={(e) => setMinLengthWhole(e.target.value)}
//             />
//             <select
//               className="w-1/2 border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//               value={minLengthFraction}
//               onChange={(e) => setMinLengthFraction(e.target.value)}
//             >
//               <option value="">0</option>
//               <option value="1/16">1/16</option>
//               <option value="1/8">1/8</option>
//               <option value="3/16">3/16</option>
//               <option value="1/4">1/4</option>
//               <option value="5/16">5/16</option>
//               <option value="3/8">3/8</option>
//               <option value="7/16">7/16</option>
//               <option value="1/2">1/2</option>
//               <option value="9/16">9/16</option>
//               <option value="5/8">5/8</option>
//               <option value="11/16">11/16</option>
//               <option value="3/4">3/4</option>
//               <option value="13/16">13/16</option>
//               <option value="7/8">7/8</option>
//               <option value="15/16">15/16</option>
//             </select>
//           </div>
//         </div>
//       )
//     ) : productType === "AREA" ? (
//       <div className="flex space-x-4">
//         <input
//           type="text"
//           className="w-1/2 border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//           placeholder="Min Width"
//           value={minDimensions.minWidth || ""}
//           onChange={(e) =>
//             setMinDimensions((prev) => ({
//               ...prev,
//               minWidth: e.target.value,
//             }))
//           }
//         />
//         <input
//           type="text"
//           className="w-1/2 border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//           placeholder="Min Height"
//           value={minDimensions.minHeight || ""}
//           onChange={(e) =>
//             setMinDimensions((prev) => ({
//               ...prev,
//               minHeight: e.target.value,
//             }))
//           }
//         />
//       </div>
//     ) : (
//       <input
//         type="text"
//         className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//         placeholder="Min Length"
//         value={minDimensions.minLength || ""}
//         onChange={(e) =>
//           setMinDimensions((prev) => ({
//             ...prev,
//             minLength: e.target.value,
//           }))
//         }
//       />
//     )}
//   </div>
// )}