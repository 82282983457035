import React, { useState, useEffect } from "react";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import app from "../../utils/firebase"; // Import your Firestore configuration
import {
  getFirestore,
  doc,
  limit,
  startAfter,
  endBefore,
  limitToLast,
} from "firebase/firestore";
import ShowTotalPrice from "../../components/ShowTotalPrice";
import {
  deleteCreatedOrder,
  globalAlert,
  globalSuccess,
  processCreatedOrder,
  statusColor,
  sendMessages,
  sendMessageWithAttachment,
  handleCalculateItemTotal,
  getCustomerName
} from "../../utils/globalFunctions";
import StoreDashboardTemplate from "../../components/StoreDashboardTemplate";
import CustomerName from "../../components/CustomerName";
import { db } from "../../utils/firebase";
import { onSnapshot } from "firebase/firestore";
import CheckboxTable from "../../components/CheckboxTable";
import { Button, Checkbox, Table } from "flowbite-react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import ChatPopup from "../../components/MessagesPopup";
import { Modal } from "flowbite-react";
import Messages from "../../components/Messages";
import { updateLastSeenUser } from "../../utils/globalFunctions";
import axios from "axios";
import { addressURL } from "../../utils/globalFunctions";
import OrderEntries from "./OrderEntries";



const statuses = {
  "Production Completed": 'text-green-700 bg-green-50 ring-green-600/20 fixed-width text-center',
  "Processing": 'text-gray-600 bg-gray-50 ring-gray-500/10 fixed-width text-center',
  "Created": 'text-yellow-700 bg-yellow-50 ring-yellow-600/10 fixed-width text-center',
  "In Production": 'text-orange-700 bg-orange-50 ring-orange-600/10 fixed-width text-center',
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const OrderList = ({ user }) => {
  const [orders, setOrders] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [chatModal, setChatModal] = useState(false);
  const [chatOrderId, setChatOrderId] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [conversationsData, setConversationsData] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [lastDoc, setLastDoc] = useState(null); // State to keep track of the last document
  const [firstDoc, setFirstDoc] = useState(null); // State to keep track of the last document
  const [nextOrBack, setNextOrBack] = useState("none");
  const [isLastOrder, setIsLastOrder] = useState(false);
  const [orderID, setOrderID] = useState("");


  console.log(orders)

  // Function to listen to an individual conversation
  const listenToConversationAndLastMessage = (conversationId) => {

    // Reference to the conversation document
    const conversationRef = doc(db, "Conversations", conversationId);

    // Listener for the conversation document
    const unsubscribeConversation = onSnapshot(
      conversationRef,
      (docSnapshot) => {


        if (docSnapshot.exists()) {
          const conversationData = docSnapshot.data();


          // Reference to the messages sub-collection
          const messagesRef = collection(conversationRef, "Messages");
          const q = query(messagesRef, orderBy("createdAt", "desc"), limit(1));

          // Listener for the last message in the messages sub-collection
          const unsubscribeMessage = onSnapshot(q, (querySnapshot) => {
            let lastMessageData = null;
            if (!querySnapshot.empty) {
              lastMessageData = querySnapshot.docs[0].data();
            }

            // Update state with both conversation and last message data
            setConversationsData((prevData) => ({
              ...prevData,
              [conversationId]: {
                conversationData,
                lastMessageData,
              },
            }));
          });

          return () => {
            unsubscribeMessage();
          };
        }
      }
    );

    return () => {
      unsubscribeConversation();
    };
  };

  useEffect(() => {
    const unsubscribes = orders.map((order) =>
      listenToConversationAndLastMessage(order.order_id)
    );

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [orders]);



  const handleFileChange = (event) => {
    setAttachment(event.target.files[0]);
  };

  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "Order ID",
        // HeaderIcon: <HashtagIcon width={10} />,
      },
      {
        Header: "Customer Name",
      },

      {
        Header: "Status",
      },

      {
        Header: "Total",
        labelLeft: "$",
      },
      // {
      //   Header: 'Invoice'
      // },
      {
        Header: "Created At",
      },
    ],
    []
  );

  const secondaryNavigation = [
    // { name: 'Store Products', href: '#', icon: UserCircleIcon, current: false,
    // onClick: ()=> {
    //   nav("/store-products");
    // }},
    // { name: 'Global Customizations', href: '#', icon: FingerPrintIcon, current: false, onClick: ()=> {
    //   nav("/store-product-customizations");
    // } },
    // { name: 'Discover Products', href: '#', icon: BellIcon, current: true,
    // onClick: ()=> {
    //   nav("/discover-new-products");
    // }},
    // { name: 'Plan', href: '#', icon: CubeIcon, current: false },
    // { name: 'Billing', href: '#', icon: CreditCardIcon, current: false },
    // { name: 'Team members', href: '#', icon: UsersIcon, current: false },
  ];
  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content',"#ffffff");
    }
  }, []);

  const listenToChangesWithCondition = () => {
    let q = null;
    if (nextOrBack === "next") {
      q = query(
        collection(db, "Orders"),
        where("store_id", "==", user.store_id),
        where("is_deleted", "==", false),
        orderBy("created_at", "desc"),
        startAfter(lastDoc),
        limit(8)
      );
    } else if (nextOrBack === "back") {
      q = query(
        collection(db, "Orders"),
        where("store_id", "==", user.store_id),
        where("is_deleted", "==", false),
        orderBy("created_at", "desc"),
        endBefore(firstDoc),
        limitToLast(8)
      );
    } else {
      q = query(
        collection(db, "Orders"),
        where("store_id", "==", user.store_id),
        where("is_deleted", "==", false),
        orderBy("created_at", "desc"),
        limit(8)
      );
    }

    let ordersData = {}; // Store combined order and invoice data
    const invoiceUnsubscribes = new Map(); // Map to store invoice unsubscribes

    const unsubscribeOrders = onSnapshot(q, (querySnapshot) => {
      const documents = querySnapshot.docs;
      if (documents.length > 0) {
        // Update lastDoc for pagination

        setLastDoc(documents[documents.length - 1]);
        setFirstDoc(documents[0]);
        if (documents.length < 8) {
          setIsLastOrder(true);
        } else {
          //check if there is a order after that
          let qu = query(
            collection(db, "Orders"),
            where("store_id", "==", user.store_id),
            where("is_deleted", "==", false),
            orderBy("created_at", "desc"),
            startAfter(documents[documents.length - 1]),
            limit(1)
          );
          getDocs(qu).then((res) => {
            if (res.docs.length === 0) {
              setIsLastOrder(true);
            } else {
              setIsLastOrder(false);
            }
          });
        }

        querySnapshot.docChanges().forEach((change) => {
          const orderData = change.doc.data();
          const orderId = change.doc.id;

          if (change.type === "added" || change.type === "modified") {
            ordersData[orderId] = { invoiceData: {}, ...orderData };
            if(change.doc.data().is_deleted === true){
              delete ordersData[orderId];

            }
            if (change.doc.data().invoice)
              listenToInvoices(orderId, change.doc);
          }

          if (change.type === "removed") {
            delete ordersData[orderId];
            // Unsubscribe from the corresponding invoice listener if it exists
            if (invoiceUnsubscribes.has(orderId)) {
              invoiceUnsubscribes.get(orderId)();
              invoiceUnsubscribes.delete(orderId);
            }
          }
        });

        let Orders = Object.values(ordersData);


        // Orders.map((
        //   order
        // ) => order.orders.map((orderItem) => {
        //   console.log(orderItem.variants)
        //   //CHECK THE TYPE
        //   if (Array.isArray(orderItem.variants)){
        //     orderItem.variants = combineObjects(orderItem.variants)
        //     orderItem.variations = combineObjects(orderItem.variations)
        //   }
        //   return orderItem
        // })
        // )

        setOrders(Orders);
        setIsLoading(false);
      }
    });

    // Function to handle listening to invoices
    function listenToInvoices(orderId, orderDoc) {
      const invoiceRef = orderDoc.data().invoice;

      // Unsubscribe from previous listener if it exists
      if (invoiceUnsubscribes.has(orderId)) {
        invoiceUnsubscribes.get(orderId)();
      }

      const unsubscribeInvoice = onSnapshot(invoiceRef, (invoiceDoc) => {
        if (invoiceDoc.exists()) {
          // Update the corresponding order's invoice data
          ordersData[orderId].invoiceData = invoiceDoc.data();
          setOrders(Object.values(ordersData)); // Update state with the new data
        }
      });

      invoiceUnsubscribes.set(orderId, unsubscribeInvoice);
    }

    // Return a cleanup function
    return () => {
      unsubscribeOrders(); // Unsubscribe from orders
      invoiceUnsubscribes.forEach((unsub) => unsub()); // Unsubscribe from all invoices
    };
  };
  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = listenToChangesWithCondition(); // or listenToDocument(docId)

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, [pageCount]);

  const handleProcessOrder = async (d) => {
    if (!d) return;

    let email = user.email;
    let items = [];
    // await d.orders.forEach(async(order)=>{
    //   let amount = 0
    //   let description =[]
    //   let quantity = order.quantity
    //   let currency = "cad"

    //   amount = await handleCalculateItemTotal(order)

    //   let unit_amount = amount / quantity

    //   for(let i = 0; i < Object.keys(order.variants).length; i++){
    //     let variantName = Object.keys(order.variants)[i]
    //     let variantValue = order.variants[variantName].value

    //     description.push(variantValue)

    //   }

    //   description = order.selectedProduct.fabric_name + " (" + description.join(",") +  ")"

    //   items.push({
    //     amount: unit_amount * 100,
    //     currency: currency,
    //     quantity: quantity,
    //     description: description
    //   })

    // })

    let data = {
      email: email,
      items: items,
      order_id: d.order_id,
    };

    processCreatedOrder(d.order_id).then((res) => {
      if (res) {
        //time out few seconds before next one
        // setTimeout(() => {
        //   axios.post(addressURL + "/api/invoice-generate", {
        //     invoiceData: data
        //   }, {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Accept: "application/json",
        //     },
        //     withCredentials: true,
        //   }).then(res => {
        //     if(res.data.success){
        //       globalSuccess(
        //         "Order Processed",
        //       )
        //     }
        //   }
        //   ).catch(err => {
        //     console.log(err);
        //     globalAlert("Something went wrong")
        //   }
        //   )
        // }, 1000);
      } else {
        globalAlert("Error Processing Order");
      }
    });
  };
  const handleDeleteOrder = (id) => {
    if (orders.length === 1) {
      setLastDoc("");
      setFirstDoc("");
      setOrders([]);
    }
    deleteCreatedOrder(id).then((res) => {
      if (res) {
        globalSuccess("Order Deleted");
     
      } else {
        globalAlert("Error Deleting Order");
      }
    });
  };

 

  const sendMessage = () => {
    const messageInput = document.getElementById("messageInput");

    const message = messageInput.value;

    if (message.length === 0 && !attachment) {
      return;
    }

    if (attachment) {
      sendMessageWithAttachment(
        chatOrderId,
        message,
        user.storeName,
        attachment
      ).then((res) => {
        if (res) {
          messageInput.value = "";
          setAttachment(null);
        }
      });
      return;
    } else {
      sendMessages(chatOrderId, message, user.storeName, "").then((res) => {
        if (res) {
          messageInput.value = "";
        }
      });
    }
  };

  if (orderID.length > 0)
    return (
      <OrderEntries user={user} order_id={orderID} setOrderID={setOrderID} />
    );

  return (

    <StoreDashboardTemplate
      secondaryNavigation={secondaryNavigation}
      >
     <>


      <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center">
        <div className="w-full overflow-x-auto">
          <div className="flex justify-between items-center mb-2">
             <p className="text-gray-700 dark:text-gray-400 text-sm ml-1">
              Page <a className="font-bold">{pageCount + 1}</a>
             </p>

             <div>
              <button
                disabled={pageCount === 0 || isLoading}
                onClick={() => {
                  setNextOrBack("back");
                  setPageCount(pageCount - 1);
                }}
                className=" mr-1 hover:text-black cursor-pointer text-gray-800 font-bold py-2 px-4 rounded "
              >
                Previous
              </button>
              <button
                disabled={isLastOrder || isLoading}
                onClick={() => {
                  setNextOrBack("next");
                  setPageCount(pageCount + 1);
                }}
                className=" mr-1 hover:text-black cursor-pointer text-gray-800 font-bold py-2 px-4 rounded"
              >
                Next
              </button>
            </div>
          </div>
          <div className="table-container">
            <div className="w-full mx-auto">
              <Table className="mb-4 border" hoverable>
                <Table.Head>
                  {columns.map((column) => (
                    <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width ">
                      {
                        <div className="h-4">
                          {!isLoading && (
                            <a className="flex">
                              {column.HeaderIcon}
                              {column.Header}
                            </a>
                          )}
                        </div>
                      }
                    </Table.HeadCell>
                  ))}
                  <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width"></Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y ">
                  {isLoading ? (
                    Array.from({ length: 8 }).map((_, index) => (
                      <Table.Row key={index}>
                        {columns.map((column, colIndex) => (
                          <Table.Cell className="p-8 " key={colIndex}>
                            <Skeleton width={125} count={1} />
                          </Table.Cell>
                        ))}
                        <Table.Cell className="p-8 w-96">
                          <Skeleton count={1} />
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <>
                      {orders.map((d) => (
                        <Table.Row className={"bg-white"}>
                          <Table.Cell className="p-4">
                            {d.order_id.slice(-4).toUpperCase()}
                          </Table.Cell>
                          <Table.Cell className="p-4">
                            {
                              !isNaN(d.customer_id) ? d.customer_name : <CustomerName customer_id={d.customer_id} store_id={d.store_id}  />
                            }
                         
                          </Table.Cell>
                          <Table.Cell className={"p-4 text-white "}>
                            {/* <p
                              className={
                                statusColor[d.status] +
                                " p-2 rounded text-center font-bold text-xs fixed-width"
                              }
                            >
                              {d.status}
                            </p> */}
                                  <div
                            className={classNames(
                              statuses[d.status],
                              'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                            )}
                          >
                            {d.status}
                          </div>
                          </Table.Cell>

                          <Table.Cell className="p-4">
                            <>{d?.isDiscounted && d?.discountPercentage ? parseFloat(d.total - parseFloat(d.discountPercentage) * parseFloat(d.total) /100).toFixed(2) : d.total}</>
                          </Table.Cell>
                          {/* <Table.Cell className="p-6">
                        { d?.invoiceData?.stripeInvoiceUrl &&
                        <a
                          href={d?.invoiceData?.stripeInvoiceUrl}
                          target="_blank"
                          className="text-blue-500 hover:underline"
                        >
                        {d?.invoiceData?.stripeInvoiceStatus ? d?.invoiceData?.stripeInvoiceStatus?.toUpperCase() : "OPEN" }
                        </a>}
                      </Table.Cell> */}
                          <Table.Cell className="p-4">
                            {d.created_at.toDate().toLocaleDateString()}
                          </Table.Cell>
                          <Table.Cell className="p-4">
                            <div className="flex justify-center">
                              {d.status === "Created" && (
                                <Button
                                  size={"xs"}
                                  onClick={() => {
                                    handleDeleteOrder(d.order_id);
                                  }}
                                  className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                                >
                                  Delete
                                </Button>
                              )}
                              <Button
                                size={"xs"}
                                disabled={d.status !== "Created"}
                                onClick={() => {
                                  handleProcessOrder(d);
                                }}
                                className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                              >
                                Process
                              </Button>
                              <Button
                                size={"xs"}
                                // disabled={
                                //   d.status !== "Created"
                                // }
                                onClick={() => {
                                  //  navigate("/edit/order/" + d.order_id)
                                  setOrderID(d.order_id);
                                }}
                                className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4 hover:cursor-pointer"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                  />
                                </svg>
                              </Button>

                              <div className="relative">
                                {(conversationsData[d.order_id]?.lastMessageData
                                  ?.createdAt >
                                  conversationsData[d.order_id]
                                    ?.conversationData?.lastSeen[
                                    user.storeName
                                  ] ||
                                  (!conversationsData[d.order_id]
                                    ?.conversationData?.lastSeen[
                                    user.storeName
                                  ] &&
                                    conversationsData[d.order_id])) && (
                                  <div className="absolute z-10 top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                    <p>New</p>
                                  </div>
                                )}

                                {/* <Button
                                  size={"xs"}
                                  // disabled={
                                  //   d.status !== "Created"
                                  // }
                                  onClick={() => {
                                    updateLastSeenUser(
                                      d.order_id,
                                      user.storeName
                                    );
                                    setChatModal(true);
                                    setChatOrderId(d.order_id);
                                  }}
                                  className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                                    />
                                  </svg>
                                </Button> */}
                              </div>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </>
                  )}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={chatModal && setChatOrderId.length > 0}
        onClose={() => setChatModal(false)}
      >
        <Modal.Header>Order {chatOrderId.slice(-4)} Support</Modal.Header>
        <Modal.Body>
          <div className="h-96  ">
            <Messages order_id={chatOrderId} user={user} />
          </div>
          <div className="flex bg-white mt-2">
            <input
              type="text"
              className="border border-gray-300 p-2 w-full mr-2 rounded-md"
              placeholder="Enter your message here"
              id="messageInput"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
         
                  sendMessage();
                }
              }}
            />

            <Button color="gray" onClick={() => sendMessage()}>
              Send
            </Button>
          </div>

          <input
            type="file"
            className="border mt-2 border-gray-300 p-2 w-full mr-2 rounded-md"
            id="uploadImage"
            accept=".jpg,.jpeg,.png,.HEIC"
            multiple={false}
            onChange={handleFileChange}
          />
        </Modal.Body>
      </Modal>
    </>
      </StoreDashboardTemplate>
  );
};

export default OrderList;


