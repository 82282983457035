import React, { useEffect, useState } from 'react';
import { saveNotificationTokenToUser } from '../utils/firebase';

const NotificationToggle = () => {
  // Assuming 'isEnabled' reflects the user's preference stored in your app's state or local storage
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    // Initialize the toggle based on the current browser permission and user preference
    const browserPermissionGranted = Notification.permission === "granted";
    const userPreference = localStorage.getItem('notificationsEnabled') === 'true';
    setIsEnabled(browserPermissionGranted && userPreference);
  }, []);

  const handleToggle = () => {
    console.log(Notification.permission)
    if (Notification.permission === "default") {
      // Request permission if not previously granted or denied
      Notification.requestPermission().then(permission => {
        const granted = permission === "granted";
        setIsEnabled(granted);
        localStorage.setItem('notificationsEnabled', granted);
        saveNotificationTokenToUser();
      });
    } else {
      // Toggle the user's preference without changing browser permissions
      const newPreference = !isEnabled;
      setIsEnabled(newPreference);
      localStorage.setItem('notificationsEnabled', newPreference);
    }
  };

  useEffect(() => {

      saveNotificationTokenToUser();

  }, [isEnabled]);

  return (
    <label htmlFor="toggle" className="flex items-center cursor-pointer">
      <div className="relative">
        <input id="toggle" type="checkbox" className="sr-only" checked={isEnabled} onChange={handleToggle} />
        <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
        <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${isEnabled ? 'transform translate-x-full bg-blue-500' : 'bg-gray-400'}`}></div>
      </div>
      <div className="ml-3 text-gray-700 font-medium">
        Notifications {isEnabled ? 'Enabled' : 'Disabled'}
      </div>
    </label>
  );
};

export default NotificationToggle;