// src/templates/EmailTemplate.js
export const StoreNewsletterEmailTemplate = ({
    header,
    body,
    surveySection,
    votingSection,
    footer,
  }) => {
    return `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="UTF-8">
          <title>${header}</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              background-color: #f9f9f9;
              padding: 20px;
            }
            .container {
              max-width: 600px;
              margin: 0 auto;
              background-color: #ffffff;
              padding: 20px;
              border: 1px solid #dddddd;
            }
            .header {
              text-align: center;
              padding-bottom: 20px;
              border-bottom: 1px solid #dddddd;
            }
            .body {
              padding: 20px 0;
            }
            .footer {
              text-align: center;
              padding-top: 20px;
              border-top: 1px solid #dddddd;
              font-size: 12px;
              color: #888888;
            }
            .button {
              display: inline-block;
              padding: 10px 20px;
              background-color: #1d4ed8;
              color: #ffffff;
              text-decoration: none;
              border-radius: 4px;
            }
            .optional-section {
              margin-top: 20px;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="header">
              <h1>${header}</h1>
            </div>
            <div class="body">
              ${body}
              ${surveySection ? `<div class="optional-section">${surveySection}</div>` : ''}
              ${votingSection ? `<div class="optional-section">${votingSection}</div>` : ''}
            </div>
            <div class="footer">
              ${footer}
            </div>
          </div>
        </body>
      </html>
    `;
  };