import React, { useState, useEffect,Fragment } from "react";
import {
  fetchManufacturerProductVariations,
  createMaterial,
  fetchSuppliers,
  getPriceList
} from "../../utils/globalFunctions";
import Skeleton from "react-loading-skeleton";
import { Button, Checkbox, Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { useUser } from "../../utils/context";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ImageUploader from "../../components/ImageUploader";
import { Transition,Dialog } from "@headlessui/react";
import { serverTimestamp } from "firebase/firestore";
export default function CreateMaterial({ manufacturer }) {
  const [materials, setMaterials] = useState([]);
  const [selectedCustomizations, setSelectedCustomizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useUser();
  const [openSnackbar] = useSnackbar({
    position: "top-center",
    style: {
      color: "white",
      backgroundColor: "#2c323b",
      fontSize: "16px",
      textAlign: "center",
      borderRadius: "8px",
      opacity: "1",
      boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
      fontWeight: "500",
    },
  });

  // Material fields
  const [newMaterialName, setNewMaterialName] = useState("");
  const [tag, setTag] = useState("");
  const [newInventoryType, setNewInventoryType] = useState("UNIT");
  const [newMaterialType, setNewMaterialType] = useState("");
  const [stockManagement, setStockManagement] = useState(false);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [length, setLength] = useState("");
  const [units, setUnits] = useState("");
  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [showScanner, setShowScanner] = useState(false);
  const [downloadURL, setDownloadURL] = useState("");
  const [wholesaleEnabled, setWholesaleEnabled] = useState(false);
  const [wholesalePrice, setWholesalePrice] = useState(0);
  const [isPriceList, setIsPriceList] = useState(false);
  const [isLimited, setIsLimited] = useState(false);
  const [minQuantity, setMinQuantity] = useState(1);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [minLength, setMinLength] = useState(0);
  const [metricType, setMetricType] = useState("");
  const [metricTypeStock, setMetricTypeStock] = useState("");
  const [maxLength, setMaxLength] = useState(0);
  const [minWidth, setMinWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);
  const [minHeight, setMinHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [priceList, setPriceList] = useState([]);
  const [description,setDescription] = useState("")
  const [selectedPriceList, setSelectedPriceList] = useState("");
  const [isModalOpen,setModalOpen] = useState(false)
  const [cost, setCost] = useState(0);
  const nav = useNavigate();

  useEffect(() => {
    // Fetch suppliers
    fetchSuppliers(user.manufacturerID).then((data) => {
      setSupplierList(data);
    });


    getPriceList("dnLLP341yLe4HvKOXd8X",user.manufacturerID).then((data) => {
      setPriceList(data);
  });
  }, [user.manufacturerID]);

  const handleCreateMaterial = async () => {
    // Validate input data
    if (!newMaterialName || !newInventoryType || !newMaterialType) {
      alert("Please enter valid material details");
      return;
    }

    if (stockManagement && newInventoryType === "AREA") {
      if (!width || !height) {
        alert("Please enter valid area dimensions");
        return;
      }
    }

    if (stockManagement && newInventoryType === "LENGTH") {
      if (!length) {
        alert("Please enter valid length");
        return;
      }
    }

    if (stockManagement) {
      if (!units) {
        alert("Please enter valid units");
        return;
      }
    }

    // cost cannot be empty
    if (!cost) {
      alert("Please enter a valid cost");
      return;
    }
    if((newInventoryType === "LENGHT" || newInventoryType === "AREA") && !metricType){
      alert("Please select metric type");
      return;
    }

    // Generate a unique tag if not provided
    const materialTag = tag || generateUniqueTag();

    const newMaterial = {
      material_name: newMaterialName,
      material_name_lowercase: newMaterialName.toLowerCase(),
      tag: materialTag,
      unitType: newInventoryType,
      materialCategory: newMaterialType,
      manufacturerID: user.manufacturerID,
      creatorID: user.id,
      supplierID: selectedSupplier,
      description: description,
      isDeleted: false,
      cost: parseFloat(cost),
      isAvailable: true,
      images: downloadURL ? [downloadURL] : [],
      stockManagementEnabled: stockManagement,
      createdAt: serverTimestamp(),
      stockMetaData: stockManagement
        ? {
            metricType: metricTypeStock,
            Width:
              newInventoryType === "AREA" ? parseFloat(width) || null : null,
            Height:
              newInventoryType === "AREA" ? parseFloat(height) || null : null,
            Length:
              newInventoryType === "LENGTH" ? parseFloat(length) || null : null,
            Units: parseFloat(units) || null,
            AvailableUnits: parseFloat(units) || null,
            AvailableLength:  newInventoryType === "LENGTH" ?  parseFloat(length) : null,
            AvailableWidth: newInventoryType === "AREA" ?  parseFloat(width) : null,
            AvailableHeight: newInventoryType === "AREA" ?  parseFloat(height) : null,
            AvailableArea:
              parseFloat(width) && parseFloat(height)
                ? parseFloat(width) * parseFloat(height)
                : null,
          }
        : null,
      wholesaleEnabled: wholesaleEnabled,
      wholesaleMetaData: wholesaleEnabled
        ? {
            metricType: metricType,
            minQuantity: parseInt(minQuantity),
            minLength: newInventoryType === "LENGTH" ?  parseFloat(minLength) : null,
            minWidth: newInventoryType === "AREA" ?  parseFloat(minWidth) : null,
            minHeight: newInventoryType === "AREA" ?  parseFloat(minHeight) : null,
            price: isPriceList ? null : parseFloat(wholesalePrice),
            isPriceList: isPriceList,
            priceList: isPriceList ? selectedPriceList.ref : null,
            isLimited: isLimited,
            maxQuantity: parseFloat(maxQuantity) || null,
            maxLength: newInventoryType === "LENGTH" ?  parseFloat(maxLength) : null,
            maxWidth: newInventoryType === "AREA" ?  parseFloat(maxWidth) : null,
            maxHeight:newInventoryType === "AREA" ?  parseFloat(maxHeight) : null,
          }
        : null,
    };

    try {
      const res =   await createMaterial(newMaterial);
      if (res) {
        // Reset form fields
        setNewMaterialName("");
        setTag("");
        setDownloadURL("");
        setNewInventoryType("");
        setStockManagement(false);
        setWidth("");
        setHeight("");
        setLength("");
        setDescription("")
        setUnits("");
        setSelectedSupplier("");
        openSnackbar("Material Created Successfully");
      } else {
        openSnackbar("Something went wrong, please report this to admin");
      }
    } catch (error) {
      console.error("Error creating material:", error);
      openSnackbar("An error occurred while creating the material");
    }
  };

  // Function to generate a unique tag (e.g., UUID)
  const generateUniqueTag = () => {
    return `TAG-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  };



  return (
    <>
      <div className="pt-12 flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">Create Material</h1>
          <div className="flex mt-2">
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
              onClick={() => {
                nav("/materials");
              }}
            >
              Materials
            </button>
          </div>
        </div>
      </div>

      <div className="sm:w-1/2 w-full border mx-auto min-h-[500px] mt-8 rounded-lg">
        <h2 className="text-xl font-bold mb-4 text-center mt-2">
          New Material
        </h2>
        <div className="md:pl-24 md:pr-24 pl-8 pr-8">
          <ImageUploader
            downloadURL={downloadURL}
            setDownloadURL={setDownloadURL}
          />
          <input
            className="border p-2 mb-2 w-full"
            placeholder="Material Name"
            value={newMaterialName}
            onChange={(e) => setNewMaterialName(e.target.value)}
          />

          <div className="mb-2">
            <input
              className="border p-2 w-full"
              placeholder="Tag"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs mt-2"
              onClick={() => setShowScanner(true)}
            >
              Scan Barcode
            </button>
          </div>

          <textarea className="w-full" onChange={
            (e) => setDescription(e.target.value)
          } value={description} placeholder="Description" rows={4} />





          {/* Barcode Scanner Modal */}
          <Modal show={showScanner} onClose={() => setShowScanner(false)}>
            <Modal.Header>Scan Barcode</Modal.Header>
            <Modal.Body>
              <BarcodeScannerComponent
                width={500}
                height={500}
                onUpdate={(err, result) => {
                  if (result) {
                    setTag(result.text);
                    setShowScanner(false);
                  } else if (err) {
                    console.error(err);
                  }
                }}
              />
            </Modal.Body>
          </Modal>

          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="cost"
          >
            Cost
          </label>

          <input
            className="border p-2 mb-2 w-full"
            placeholder="Cost"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            type="number"
          />
          <select
            className="border p-2 mb-2 w-full"
            value={newInventoryType}
            onChange={(e) => setNewInventoryType(e.target.value)}
          >
            <option value="">Select Type</option>
            <option value="AREA">Area</option>
            <option value="LENGTH">Length</option>
            <option value="UNIT">Unit</option>
            <option value="BUNDLE">Bundle</option>
            <option value="BOX">Box</option>
          </select>




          <select
            className="border p-2 mb-2 w-full"
            value={newMaterialType}
            onChange={(e) => setNewMaterialType(e.target.value)}
          >
            <option value="">Select Material Category</option>
            {manufacturer?.materialTypes?.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>

          <select
            className="border p-2 mb-2 w-full"
            value={selectedSupplier}
            onChange={(e) => setSelectedSupplier(e.target.value)}
          >
            <option value="">Select Supplier</option>
            {supplierList.map((supplier, index) => (
              <option key={index} value={supplier.id}>
                {supplier.supplierName}
              </option>
            ))}
          </select>

          <div className="flex items-center mb-2">
            <Checkbox
              checked={stockManagement}
              className="
              bg-gray-500
              "
              onChange={(e) => setStockManagement(e.target.checked)}
            />
            <label className="ml-2">Enable Stock Management</label>
          </div>

{
  stockManagement &&
  
    ( newInventoryType === "LENGTH" || newInventoryType === "AREA") &&
     <select
       className="border p-2 mb-2 w-full"
       value={metricTypeStock}
       onChange={(e) => setMetricTypeStock(e.target.value)}
     >
       <option value="">Select Metric Type</option>
       <option value="cm">cm</option>
       <option value="m">m</option>
       <option value="inch">inch</option>
       <option value="ft">ft</option>
       <option value="yard">yd</option>
   </select>
   
   
}
          {stockManagement && newInventoryType === "AREA" && (
            <>
              <input
                className="border p-2 mb-2 w-full"
                placeholder="Width"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                type="number"
              />
              <input
                className="border p-2 mb-2 w-full"
                placeholder="Height"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                type="number"
              />
            </>
          )}

          {stockManagement && newInventoryType === "LENGTH" && (
            <input
              className="border p-2 mb-2 w-full"
              placeholder="Length"
              value={length}
              onChange={(e) => setLength(e.target.value)}
              type="number"
            />
          )}

          {stockManagement && (
            <input
              className="border p-2 mb-2 w-full"
              placeholder="Units"
              value={units}
              onChange={(e) => setUnits(e.target.value)}
              type="number"
            />
          )}

          <div className="flex items-center mb-2">
            <Checkbox
              checked={wholesaleEnabled}
              className="
              bg-gray-500
              "
              onChange={(e) => setWholesaleEnabled(e.target.checked)}
            />
            <label className="ml-2">Enable Wholesale</label>
          </div>

          {
            wholesaleEnabled &&
 ( newInventoryType === "LENGTH" || newInventoryType === "AREA") &&
  <select
    className="border p-2 mb-2 w-full"
    value={metricType}
    onChange={(e) => setMetricType(e.target.value)}
  >
    <option value="">Select Metric Type</option>
    <option value="cm">cm</option>
    <option value="inch">inch</option>
    <option value="ft">ft</option>
    <option value="yard">yd</option>
</select>
}
          {wholesaleEnabled && (
            <>
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="minQuantity"
              >
                Min Order Quantity
              </label>
              <input
                className="border p-2 mb-2 w-full"
                placeholder="Min Quantity"
                value={minQuantity}
                onChange={(e) => setMinQuantity(e.target.value)}
                type="number"
              />

              {newInventoryType === "LENGTH" && (
                <>
                  <label className="block text-sm font-medium text-gray-700">
                    Min Allowed Length
                  </label>
                  <input
                    className="border p-2 mb-2 w-full"
                    placeholder="Min Length"
                    value={minLength}
                    onChange={(e) => setMinLength(e.target.value)}
                    type="number"
                  />
                </>
              )}

              {newInventoryType === "AREA" && (
                <>
                  <label className="block text-sm font-medium text-gray-700">
                    Min Allowed Width
                  </label>
                  <input
                    className="border p-2 mb-2 w-full"
                    placeholder="Min Width"
                    value={minWidth}
                    onChange={(e) => setMinWidth(e.target.value)}
                    type="number"
                  />
                  <label className="block text-sm font-medium text-gray-700">
                    Min Allowed Height
                  </label>
                  <input
                    className="border p-2 mb-2 w-full"
                    placeholder="Min Height"
                    value={minHeight}
                    onChange={(e) => setMinHeight(e.target.value)}
                    type="number"
                  />
                </>
              )}

              <div className="flex items-center mb-2">
                <Checkbox
                  checked={isPriceList}
                  className="
                  bg-gray-500
                  "
                  onChange={(e) => setIsPriceList(e.target.checked)}
                />
                <label className="ml-2">Use Price List</label>
              </div>

              {isPriceList && (
                <>
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="priceList"
                  >
                    Wholesale Price List
                  </label>

                  <select
            className="border p-2 mb-2 w-full mt-2"
            value={priceList.id}
            onChange={(e)=>{
               setSelectedPriceList(priceList.find(
                    price => price.id === e.target.value
                ))
            }}
            >
            <option value="">Select Price List</option>
            {
                priceList.map(
                    (price,index) => (
                        price.type === newInventoryType &&
                        <option key={index} value={price.id}>{price.price_list_name}</option>
                    )
                )
            }
           </select> 
                </>
              )}

              {!isPriceList && (
                <>
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="wholesalePrice"
                  >
                    Wholesale Price
                  </label>
                  <input
                    className="border p-2 mb-2 w-full"
                    placeholder="Wholesale Price"
                    value={wholesalePrice}
                    onChange={(e) => setWholesalePrice(e.target.value)}
                    type="number"
                  />
                </>
              )}

              <div className="flex items-center mb-2">
                <Checkbox
                  checked={isLimited}
                  className="
                  bg-gray-500
                  "
                  onChange={(e) => setIsLimited(e.target.checked)}
                />
                <label className="ml-2">Set Limits</label>
              </div>

              {isLimited && (
                <>
                  <label className="block text-sm font-medium text-gray-700">
                    Max Allowed Quantity
                  </label>

                  <input
                    className="border p-2 mb-2 w-full"
                    placeholder="Max Quantity"
                    value={maxQuantity}
                    onChange={(e) => setMaxQuantity(e.target.value)}
                    type="number"
                  />

                  {newInventoryType === "LENGTH" && (
                    <>
                      <label className="block text-sm font-medium text-gray-700">
                        Max Allowed Length
                      </label>

                      <input
                        className="border p-2 mb-2 w-full"
                        placeholder="Max Length"
                        value={maxLength}
                        onChange={(e) => setMaxLength(e.target.value)}
                        type="number"
                      />
                    </>
                  )}

                  {newInventoryType === "AREA" && (
                    <>
                      <label className="block text-sm font-medium text-gray-700">
                        Max Allowed Width
                      </label>

                      <input
                        className="border p-2 mb-2 w-full"
                        placeholder="Max Width"
                        value={maxWidth}
                        onChange={(e) => setMaxWidth(e.target.value)}
                        type="number"
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Max Allowed Height
                      </label>
                      <input
                        className="border p-2 mb-2 w-full"
                        placeholder="Max Height"
                        value={maxHeight}
                        onChange={(e) => setMaxHeight(e.target.value)}
                        type="number"
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}

          <div className="flex w-full justify-center">
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mb-2 mt-2"
              onClick={handleCreateMaterial}
            >
              Create Material
            </button>
          </div>
        </div>
      </div>

            {/* Category Modal */}
            <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => setModalOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white rounded-lg max-w-md w-full mx-auto p-6 space-y-4 shadow-xl transition-all">
                <Dialog.Title className="text-2xl font-bold text-indigo-600">
                  Create New Category
                </Dialog.Title>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
