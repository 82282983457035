import React, { useState, useEffect } from "react";
import { createCustomer, updateStoreCustomer, getCustomers, deleteCustomer } from "../../utils/globalFunctions";
import Skeleton from "react-loading-skeleton";
import { Button, Table, Modal, Pagination } from "flowbite-react";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import StoreDashboardTemplate from "../../components/StoreDashboardTemplate";

export default function Customers({ user }) {
  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [editCustomer, setEditCustomer] = useState(null);
  const [newCustomer, setNewCustomer] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const customersPerPage = 8;

  useEffect(() => {
    getCustomers(user.store_id).then((data) => {
      setCustomers(data);
      setIsLoading(false);
    });
  }, [user.store_id]);

  const filterProducts = (customers, query) => {
    return customers.filter((customer) =>
      customer.customerName.toLowerCase().includes(query.toLowerCase())
    );
  };

  const handleCustomerDelete = (customer) => {
    deleteCustomer(user.store_id, customer.id).then((data) => {
      if (data) {
        getCustomers(user.store_id).then((data) => {
          setCustomers(data);
          setIsLoading(false);
        });
      }
    });
  };

  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = filterProducts(customers, searchQuery).slice(
    indexOfFirstCustomer,
    indexOfLastCustomer
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    { Header: "Customer Name" },
    { Header: "Email" },
    { Header: "Phone Number" },
    { Header: "Street Address" },
    { Header: "City" },
    { Header: "Country" },
    { Header: "" },
  ];

  return (
    <>
      <StoreDashboardTemplate navigationItems={[]} secondaryNavigation={[]}>
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            placeholder="Search by customer name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border-2 border-gray-500 w-64 rounded-lg px-4 py-2"
          />
          <Button
            color="indigo"
            onClick={() => {
              setNewCustomer(true);
              setEditCustomer({
                customerName: "",
                customerEmail: "",
                customerPhoneNumber: "",
                customerStreetAddress: "",
                customerCity: "",
                customerCountry: "",
              });
            }}
          >
            <PlusSmallIcon className="h-5 w-5 mr-1" />
            Add Customer
          </Button>
        </div>

        <div className="overflow-x-auto">
          <Table hoverable>
            <Table.Head>
              {columns.map((column, index) => (
                <Table.HeadCell key={index}>{column.Header}</Table.HeadCell>
              ))}
            </Table.Head>
            <Table.Body className="divide-y">
              {isLoading ? (
                Array.from({ length: 8 }).map((_, index) => (
                  <Table.Row key={index}>
                    {columns.map((_, colIndex) => (
                      <Table.Cell key={colIndex}>
                        <Skeleton className="w-32" />
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))
              ) : (
                currentCustomers.map((customer, index) => (
                  <Table.Row key={index} className="bg-white">
                    <Table.Cell>{customer.customerName}</Table.Cell>
                    <Table.Cell>{customer.customerEmail}</Table.Cell>
                    <Table.Cell>{customer.customerPhoneNumber}</Table.Cell>
                    <Table.Cell>{customer.customerStreetAddress}</Table.Cell>
                    <Table.Cell>{customer.customerCity}</Table.Cell>
                    <Table.Cell>{customer.customerCountry}</Table.Cell>
                    <Table.Cell>
                      <div className="flex items-center gap-2">
                      <Button
                        color="gray"
                        onClick={() => {
                          
                          setNewCustomer(false);
                          setEditCustomer(customer)
                        }}
                        className="mr-2"
                      >
                        Edit
                      </Button>
                      <Button
                        color="red"
                        onClick={() => handleCustomerDelete(customer)}
                      >
                        Delete
                      </Button>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </Table>
        </div>

        {!isLoading && (
          <Pagination
            className="mt-4"
            currentPage={currentPage}
            totalPages={Math.ceil(
              filterProducts(customers, searchQuery).length / customersPerPage
            )}
            onPageChange={handlePageChange}
          />
        )}
      </StoreDashboardTemplate>

      <Modal
  show={editCustomer}
  size="lg"
  onClose={() => {
    setEditCustomer(null);
  }}
  className="rounded-xl shadow-lg bg-white"
>
  <Modal.Header className=" p-4 font-semibold text-gray-800 border-b border-gray-200">
    {newCustomer ? "Add New Customer" : "Edit Customer"}
  </Modal.Header>
  <Modal.Body className="px-6 py-4">
    <div className="flex flex-col space-y-6">
      {/* Section for Customer Details */}
      <div className="rounded-lg bg-gray-50 p-4 shadow-md">
        <h3 className="text-md font-semibold mb-4 text-gray-700">Customer Details</h3>
        <div className="grid grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label className="text-sm text-gray-600 mb-2">Customer Name</label>
            <input
              type="text"
              value={editCustomer?.customerName}
              onChange={(e) =>
                setEditCustomer({
                  ...editCustomer,
                  customerName: e.target.value,
                })
              }
              className="border-2 border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-indigo-400 transition duration-150 ease-in-out"
              placeholder="Enter name"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm text-gray-600 mb-2">Customer Email</label>
            <input
              type="email"
              value={editCustomer?.customerEmail}
              onChange={(e) =>
                setEditCustomer({
                  ...editCustomer,
                  customerEmail: e.target.value,
                })
              }
              className="border-2 border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-indigo-400 transition duration-150 ease-in-out"
              placeholder="Enter email"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm text-gray-600 mb-2">Phone Number</label>
            <input
              type="text"
              value={editCustomer?.customerPhoneNumber}
              onChange={(e) =>
                setEditCustomer({
                  ...editCustomer,
                  customerPhoneNumber: e.target.value,
                })
              }
              className="border-2 border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-indigo-400 transition duration-150 ease-in-out"
              placeholder="Enter phone number"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm text-gray-600 mb-2">Street Address</label>
            <input
              type="text"
              value={editCustomer?.customerStreetAddress}
              onChange={(e) =>
                setEditCustomer({
                  ...editCustomer,
                  customerStreetAddress: e.target.value,
                })
              }
              className="border-2 border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-indigo-400 transition duration-150 ease-in-out"
              placeholder="Enter street address"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm text-gray-600 mb-2">City</label>
            <input
              type="text"
              value={editCustomer?.customerCity}
              onChange={(e) =>
                setEditCustomer({
                  ...editCustomer,
                  customerCity: e.target.value,
                })
              }
              className="border-2 border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-indigo-400 transition duration-150 ease-in-out"
              placeholder="Enter city"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm text-gray-600 mb-2">Country</label>
            <input
              type="text"
              value={editCustomer?.customerCountry}
              onChange={(e) =>
                setEditCustomer({
                  ...editCustomer,
                  customerCountry: e.target.value,
                })
              }
              className="border-2 border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-indigo-400 transition duration-150 ease-in-out"
              placeholder="Enter country"
            />
          </div>
        </div>
      </div>
    </div>
  </Modal.Body>
  <Modal.Footer className="border-t border-gray-200 py-4">
    <Button
      color="indigo"
      className="px-6 py-2 text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg shadow-md transition-all duration-200"
      onClick={() => {
        if (newCustomer) {
          createCustomer(user.store_id, editCustomer).then((data) => {
            if (data) {
              setEditCustomer(null);
              getCustomers(user.store_id).then((data) => {
                setCustomers(data);
                setIsLoading(false);
              });
            }
          });
        } else {

          updateStoreCustomer(user.store_id, editCustomer).then((data) => {
            if (data) {
              setEditCustomer(null);
              getCustomers(user.store_id).then((data) => {
                setCustomers(data);
                setIsLoading(false);
              });
            }
          });
        }
      }}
    >
      {newCustomer ? "Add Customer" : "Update Customer"}
    </Button>
    <Button
      color="gray"
      className="px-6 py-2 text-gray-700 bg-gray-200 hover:bg-gray-300 rounded-lg shadow-md transition-all duration-200"
      onClick={() => setEditCustomer(null)}
    >
      Cancel
    </Button>
  </Modal.Footer>
</Modal>

    </>
  );
}